import React from 'react'
import { Box, Card, Link, Typography} from '@material-ui/core';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { useTypographyStyles } from '../../../theme/useTypography';
import {PLANS_PLAIN_TEXT} from '../../../invitation/utils'
import { useSref } from '@uirouter/react';

const useStyles = tgMakeStyles(theme => ({}))

export function BusinessMembershipDetailsCard({business, ...props}){
    
    const typo = useTypographyStyles()
    const _currentMembership = [
        'Basic Member',
        'Basic Member with Digital Map Upgrade',
        'Basic Member with Digital Map + Circle Upgrade'
    ][
        PLANS_PLAIN_TEXT.indexOf(business.plan)
    ]
    const upgradeDialogSref = useSref('root.business-console.settings', {upgrade: true}, {inherit: true})

    return business && business.place
        ? (
            <Card component={Box} p={3} elevation={4} position="relative" {...props}>
                {/* business name */}
                <Typography className={`${typo.body3}`}>
                    <b>{business.place.name}</b>
                </Typography>
                {/* current membership plan */}
                <Typography className={`${typo.body2}`}>
                    {_currentMembership}
                </Typography>
                {/* <UpgradeBusinessMembershipPlanLink color="primary" className={`${typo.body3}`}>
                    Upgrade
                </UpgradeBusinessMembershipPlanLink> */}
                <Link
                    id="business-settings-open-upgrade-plan-dialog"
                    color="primary"
                    className={`${typo.body3} clickable`}
                    {...upgradeDialogSref}
                >
                    Upgrade
                </Link>
            </Card>
        )
        : <div>Loading...</div>
}

export default BusinessMembershipDetailsCard