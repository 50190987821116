import { IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import React from 'react'
import { useStyles } from '../place-profile/profileCss.classes'
import PrintIcon from '@material-ui/icons/Print';
import { showNotification } from '../../../notifications/state/actions';
import { dispatch } from 'slice';
import { openModal } from '../../../dialogs/standard/state/actions';
import PrintDirectionsForm from './PrintDirections.form';

export function PrintDirectionsIconButton({
    hotelId,
    place,
    info,
    getInfo,
    ...props
}) {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const classes = useStyles({ isMobile: !isDesktop })

    const openPrintDirectionsForm = (hotelId, place) => e => {
        if (!hotelId || !place) {
            dispatch(showNotification({ message: 'Oops! Something went wrong, please try again later.' }))
            return
        }

        let _info = info
        if (getInfo && typeof getInfo === 'function') {
            _info = getInfo()
        }
        dispatch(openModal({
            title: 'Setup and Share directions to '+place.name,
            component: PrintDirectionsForm,
            props: {
                hotelId,
                place,
                info: _info
            }
        }))
    }

    return (
        <Tooltip title="Print directions to this location"
            placement="left"
        >
            {/* onClick={printDirections(info.hotels[0].id, _place.id)} */}
            <IconButton onClick={openPrintDirectionsForm(hotelId, place)} style={{ margin: 0 }}>
                <PrintIcon
                    className={`${classes.copybtn}`}
                />
            </IconButton>
        </Tooltip>
    )
}