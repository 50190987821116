import { Box, Button, Typography } from '@material-ui/core'
import { useSref } from '@uirouter/react'
import React from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import useOBStyles from '../onboarding.classes'

export function MobileCTA(props){
    // console.log('MobileCTA', props)
    const {$state$} = props
    const classes = useOBStyles()
    const typo = useTypographyStyles()
    const plansSref = useSref($state$.data.cta_next)
    return (
        <Box className={`${classes.mobileCTAMD}`}>
            <Typography className={typo.subtitle6} align="center">
                {$state$.data.cta_txt}
            </Typography>
            <Button 
                id="invite-continue-to-membership-plans"
                {...plansSref}
                className="btn clickable"
                color="primary"
                variant="contained"
            >
                {$state$.data.cta_btn}
            </Button>
        </Box>
    )
}

export default MobileCTA