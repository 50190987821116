import React from 'react'
import LoopIcon from '@material-ui/icons/Loop';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles'

const useStyles = tgMakeStyles(theme => ({
    "@keyframes rotate360": {
        to: {transform: 'rotate(360deg)'}
    },
    rotate4Ever: {
        animation: '$rotate360 2s linear infinite'
    }
}))

export default function LoopProgress(props) {
    const classes = useStyles()
    return (
        <LoopIcon {...props} className={classes.rotate4Ever} />
    )
}
