import { Box, Button, Link, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import tracker from '../../../../lib/mixpanel'
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails'
import {sendEmail} from '../../../http/tg/email'
import { TgForm } from '../../../../lib/components/forms/DefaultTgForm'
import { dispatch } from 'slice'
import { showNotification } from '../../../notifications/state/actions'
import { closeModal } from '../../../dialogs/standard/state/actions'

export function InviteBusinessForm({businessName}){
    const typographies = useTypographyStyles()
    const [showForm, setShowForm] = useState(false)
    const {info} = useStateUserDetails()

    const handleFormSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const _payload = {
            business_name: businessName,
            contact_name: formData.get('contact_name'),
            contact_email: formData.get('contact_email')
        }
        const D = new Date()
        sendEmail({
            subject: "RECOMMEND TASTEGODS : new request",
            emailBody: [
                `RECOMMENDED BY ${info.username} from ${info.hotels[0].name}:\n`,
                ...Object.entries(_payload).map(([key, value]) => `${key} : ${value}`),
                `date: ${D.toLocaleString()}`
            ].join('\n')
        }).subscribe({
            complete: () => {
                dispatch(showNotification({message: 'Thanks for your vote!'}))
                dispatch(closeModal())
            },
            error: err => dispatch(showNotification({message: 'Opps, Something went wrong, please try again later!'}))
        })
    }
    const updateFormTracker = e => {
        tracker('form')('hotel_invites_business_form', {[e.target.name]: e.target.value})
    }
    return (
        <TgForm id="hotel-invites-business-form"
            onSubmit={handleFormSubmit}
            onChange={updateFormTracker}
        >
            <Box mb={4}>
                <Typography className={typographies.body2} align="center">
                    As part of the TasteGods hotel community, you have the power to determine which businesses are of most interest to you & your guests.
                </Typography>
            </Box>
            <Box mb={2}>
                <Typography align="center">
                    Already know someone at {businessName}?
                </Typography>
            </Box>
            <Box align="center" mb={2}>
                <Link 
                    id="hotel-invites-business-form-add-contact-details"
                    color="primary" 
                    className={`${typographies.body3} clickable`}
                    onClick={e => setShowForm(prev => !prev)}
                >
                    SHARE WITH US
                </Link>
            </Box>
            {showForm && <Box mb={2}>
                <TextField
                    style={{marginBottom: '1rem'}}
                    variant="outlined"
                    fullWidth 
                    type="text"
                    label="Name"
                    name="contact_name"
                />
                <TextField
                    variant="outlined"
                    fullWidth 
                    type="email"
                    label="Email"
                    name="contact_email"
                />
            </Box>}
            <Box align="center">
                <Button
                    id="submit-hotel-invites-business-form"
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="clickable"
                >
                    SUBMIT VOTE
                </Button>
            </Box>
        </TgForm>

    )
} 

export default InviteBusinessForm