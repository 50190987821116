import { useEffect, useState } from "react"
import { TG_API } from "../../http/tg"

export function useJuicerFeed(id){
    const [feed, setFeed] = useState()
    useEffect(() => {
        if(!id) return ()=>{}
        
        TG_API({
            url: `https://www.juicer.io/api/feeds/${id}`,
            method: 'get'
        }).then(res => setFeed(res.data))
        
        return ()=>{}
    }, [id])

    return feed
}

export default useJuicerFeed