import produce from 'immer';

export const INITIAL_STATE = {
    primary: null,
    secondary: null
}

export const rootToolBarReducer = (state, action) => {
    return produce(state, draft => {
        draft['primary'] = toolBarPrimaryReducer(state['primary'], action)
        draft['secondary'] = toolBarSecondaryReducer(state['secondary'], action)
    }) 
}

function toolBarPrimaryReducer(state, action){
    state = state || {
        map: {},
        flatArray: []
    }
    switch(action.type){
        case 'TOOLBAR/SET/PRIMARY':
            return produceNewArray(state, action.payload)
        case 'TOOLBAR/ADD/PRIMARY':
            return produceAddToArray(state, action.payload)
        case 'TOOLBAR/REMOVE/PRIMARY':
            return produceRemoveFromArrayByIndex(state, action.payload)
        case 'TOOLBAR/CLEAR/PRIMARY':
            return {
                map: {},
                flatArray: []
            }
        default:
            return state
    }    
}

function toolBarSecondaryReducer(state, action){
    state = state || {
        map: {},
        flatArray: []
    }
    switch(action.type){
        case 'TOOLBAR/SET/SECONDARY':
            return produceNewArray(state, action.payload)
        case 'TOOLBAR/ADD/SECONDARY':
            return produceAddToArray(state, action.payload)
        case 'TOOLBAR/REMOVE/SECONDARY':
            return produceRemoveFromArrayByIndex(state, action.payload)
        case 'TOOLBAR/CLEAR/SECONDARY':
            return {
                map: {},
                flatArray: []
            }
        default:
            return state
    }    
}

// PRODUCERS
const produceNewArray = produce( (draft, {tools, toolsId}) => {
    // reset map and flatArray using provided tools
    if(!toolsId) return draft; // this should not ever happen if component uses useToolBar hook
    if(Array.isArray(tools)){
        draft.map[toolsId] = tools
        draft.flatArray = tools
    } else {
        draft.map[toolsId] = [tools]
        draft.flatArray = [tools]
    }
})

const produceAddToArray = produce( (draft, {tools, toolsId}) => {
    // add to map and flatArray, uses toolId to target the correct map property
    if(!toolsId) return draft; // this should not ever happen if component uses useToolBar hook
    if(Array.isArray(tools)){
        if(draft.map[toolsId]){
            // simply add provided tools to existing array
            draft.map[toolsId] = draft.map[toolsId].concat(tools)
        } else {
            // create new map prop with provided tools
            draft.map[toolsId] = tools
        }
        // flatten map into flatArray prop
        draft.flatArray = Object.keys(draft.map).map(k => draft.map[k]).flat()
    } else {
        if(draft.map[toolsId]){
            // simply add provided tool to existing array
            draft.map[toolsId].push(tools)
        } else {
            // create new map prop with provided tool
            draft.map[toolsId] = [tools]
        }
        // flatten map into flatArray prop
        draft.flatArray = Object.keys(draft.map).map(k => draft.map[k]).flat()
    }
})

const produceRemoveFromArrayByIndex = produce( (draft, {tools, toolsId}) => {
    // remove one or more tools from the toolbelt targeted with toolsId
    // ATTENTION : unlike set and add, tools can only be an integer or an array of integers
    if(!toolsId) return draft; // this should not ever happen if component uses useToolBar hook
    if(Number.isInteger(tools)){
        draft.map[toolsId].splice(tools, 1)
    } else if (Array.isArray(tools)){
        const stateCopy = [...draft.map[toolsId]]
        tools.forEach(idx => {
            if(Number.isInteger(idx)){
                // we use indexOf because draft is mutated in each iteration.
                // so the provided indexes become incorrect after the first succesful iteration
                // TODO : make an implementation that accepts values other than integer and use indexOf to find it
                const idxToRemove = draft.map[toolsId].indexOf(stateCopy[idx])
                draft.map[toolsId].splice(idxToRemove, 1)
            }
        });
    }
    // flatten map into flatArray prop
    draft.flatArray = Object.keys(draft.map).map(k => draft.map[k]).flat()
})