import produce from 'immer'

export const initial_state = {}

export function adminBusinessDataReducer(state, action){
    if(!state) {state = initial_state}
    switch (action.type) {
        case 'BUSINESS/ADMIN/SET/BUSINESS/DATA':
            if(!state[action.payload.id]) return state
            return produce(draft => {
                draft[action.payload.id] = {
                    ...state[action.payload.id],
                    ...action.payload
                };
            })(state)
        case 'BUSINESS/ADMIN/DELETE/IMAGE':
            if(!action.payload.businessId || !state[action.payload.businessId]) return state
            if(state[action.payload.businessId].place.images.length < 1) return state
            return produce(draft => {
                const idx = state[action.payload.businessId].place.images.findIndex(img => img.id === action.payload.imageId)
                if(idx === -1) { return state }
                else { draft[action.payload.businessId].place.images.splice(idx, 1) }
            })(state)
        case 'BUSINESS/ADMIN/SET/COVER':
            if(!action.payload.businessId || !state[action.payload.businessId]) return state
            return produce(draft => {
                let url = URL.createObjectURL(action.payload.file)
                draft[action.payload.businessId].place.images = [
                    {url, id: `temp-${Date.now()}`}, 
                    ...state[action.payload.businessId].place.images || []
                ]
            })(state)
        case 'BUSINESS/ADMIN/SET/BUSINESS/FIELD/DATA':
            if(!state[action.payload.businessId] || !action.payload.key) return state
            return produce(draft => {
                draft[action.payload.businessId][action.payload.key] = action.payload.value;
            })(state)
        case 'BUSINESS/ADMIN/SET/PLACE/DATA':
            if(!action.payload.businessId || !state[action.payload.businessId]) return state
            return produce(draft => {
                
                draft[action.payload.businessId].place = {
                    ...draft[action.payload.id].place,
                    ...action.payload.place
                }
            })(state)
        case 'BUSINESS/ADMIN/SET/PLACE/FIELD/DATA':
            if(!action.payload.businessId || !state[action.payload.businessId] || !action.payload.key) return state
            return produce(draft => {
                draft[action.payload.businessId].place[action.payload.key] = action.payload.value
            })(state)
        case 'BUSINESS/ADMIN/SET/PLACE/ATTRIBUTE/FIELD/DATA':
            if(!action.payload.businessId || !state[action.payload.businessId] || !action.payload.key) return state
            return produce(draft => {
                draft[action.payload.businessId].place.attributes[action.payload.key] = action.payload.value
            })(state)
        case 'BUSINESS/ADMIN/SET/BUSINESS/FIELD/VISIBILITY':
            if(!action.payload.businessId || !state[action.payload.businessId] || !action.payload.key) return state
            return produce(draft => {
                draft[action.payload.businessId].visibility[action.payload.key] = action.payload.value
            })(state)
        case 'BUSINESS/ADMIN/UNSET/DATA':
            return initial_state
        default:
            return state;
    }
}