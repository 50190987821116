import React from 'react'
// import Mixpanel from '../../../../../lib/mixpanel';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { Drawer, Box } from '@material-ui/core';

export function EditHotelMapListUI({
    hotel, 
    map, 
    customMapId, 
    ContentComponent, 
    ...props
}) {
    const {params} = useCurrentStateAndParams()
    
    const router = useRouter()

    const handleDrawerToggle = open => e => {
        if(typeof open === 'undefined'){
            router.stateService.go('.', {list: !params.list})
        } else {
            router.stateService.go('.', {list: open})
        }
    }

    return (
        <Drawer
            variant="temporary"
            anchor={'left'}
            open={params.list}
            onClose={handleDrawerToggle(false)}
            // props below mainly ensure the drawer is not full screen and does not cover the toolbar and tabbar
            ModalProps={{
                container: window.document.getElementById('content-container'), // attach drawer to content div (see AppLayout component)
                style: {position: 'absolute'}, // by default position = fixed
                disableEnforceFocus: true,
                BackdropProps: {
                    position: 'absolute', // by default position = fixed
                }, 
                hideBackdrop: true,
                keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{ 
                style: { 
                    position: 'absolute', // by default position = fixed
                    width:"100%"
                } 
            }} 
            
        >
            <Box mb={6}>
                { ContentComponent && params.list
                    && <ContentComponent {...{map, hotel, customMapId}}/>
                }   
            </Box>
        </Drawer>
    )
}

export default EditHotelMapListUI