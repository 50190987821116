import React from 'react'
import { useStateUserHotels } from "../../../user/state/hooks/useStateUserHotels"
import EditHotelMapGoogleMapUI from './EditHotelMap.googleMapUI'
import StandAloneGoogleMap from '../../../../lib/google-map/components/GoogleMap/StandaloneGoogleMap'
import EditHotelMapListUI from './EditHotelMap.listUI'
import { Box, Button, IconButton, useMediaQuery } from '@material-ui/core'
import { EditHotelMapPlaces } from './EditHotelMapPlaces'
import { useEditHotelMap } from '../hooks/useEditHotelMap'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { COLORS } from '../../../theme/colors'
import { useEffect } from 'react'
import { useRouter } from '@uirouter/react'

export function EditHotelMapCTA(props){
    const [editHotelMap, {
        save
    }] = useEditHotelMap()

    const router = useRouter()
    const exit = e => {
        e.preventDefault(); e.stopPropagation()
        router.stateService.go('root.hotel-console.maps.dashboard')
    }

    return (
        <Box display="flex"
            bgcolor={COLORS.get('blue').main}
        >
            <Box color="white" position="absolute">
                <IconButton onClick={exit} style={{margin: 0}} color="inherit">
                    <ArrowBackOutlinedIcon />
                </IconButton>
            </Box>
            <Box color="white" display="flex" justifyContent="center" flexGrow="1">
                <Button  style={{margin: 0}}
                    onClick={e=>save(editHotelMap)}
                    variant="text"
                    color="inherit"
                >
                        SAVE
                    </Button>
            </Box>
        </Box>
    )
}

export function EditHotelMap({$stateParams, ...props}){
    const router = useRouter()
    const [ehm] = useEditHotelMap()
    useEffect( () => {
        ehm && router.stateService.go('.', {pristine: ehm.pristine})
        return ()=>{}
    }
    , [ehm])

    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))
    const hotels = useStateUserHotels()
    const _hotel = hotels && hotels.length
        ? hotels[0]
        : null

    return (
        <Box display="flex"
            height="100%"
            flexDirection="column"
        >
            {isMobile && <Box flex="none">
                <EditHotelMapCTA />
            </Box>}
            <Box position="relative" flexGrow="1">
                {_hotel
                &&  <StandAloneGoogleMap
                        center={_hotel}
                        render={(googleMapInstance) => (
                            <>
                                {/* google map UI and logic */}
                                <EditHotelMapGoogleMapUI
                                    {...{
                                        map: googleMapInstance, 
                                        hotel: _hotel,
                                        customMapId: $stateParams.customMapId
                                    }
                                }/>
                                {/* drawer linked to digital map slice data */}
                                <EditHotelMapListUI
                                    {...{
                                        map: googleMapInstance,
                                        hotel: _hotel,
                                        customMapId: $stateParams.customMapId
                                    }}
                                    // ContentComponent is given
                                    ContentComponent={EditHotelMapPlaces}
                                />
                            </>
                        )}
                    />
                }
            </Box>
        </Box>
    )
}
export default EditHotelMap