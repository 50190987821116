import React from 'react'
import { Box, Button, Card, Typography } from "@material-ui/core";
import { formatDistance } from 'date-fns';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { useSref } from '@uirouter/react';
import { dispatch } from 'slice';
import {getBusinessLastUpdate} from '../../utils'
import { showNotification } from '../../../notifications/state/actions';
import { useTypographyStyles } from '../../../theme/useTypography';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { TYPOGRAPHY_STYLES } from '../../../theme/typographies';
import { putBusiness } from '../../../http/tg/business';
import {setAdminBusinessData} from '../../state/actions'

const useStyles = tgMakeStyles(theme => ({
    card: {
        '& .lastUpdate,& .info': {
            marginBottom: theme.spacing(2)
        },
        '& .btn': {
            margin: 0,
            '& .MuiButton-label': {
                ...TYPOGRAPHY_STYLES.body1    
            }
        },
        '& .btn:nth-of-type(1)': {
            marginRight: theme.spacing(1)
        },
        '& .btn:nth-of-type(2)': {
            marginLeft: theme.spacing(1)
        }
    }
}))

export function ConfirmNoChangeCard({business, ...props}){
    const typo = useTypographyStyles()
    const classes = useStyles()

    const editProfileSref = useSref('root.business-console.profile')
    
    if(!business) return null
    const _lastupdateD = getBusinessLastUpdate(business)
    const _showSelf = differenceInCalendarDays(new Date(), _lastupdateD) >= 7
    if(!_showSelf) return null
    const _formattedDateDistance = formatDistance(_lastupdateD, new Date(), {addSuffix: true})

    const updatelastUpdate = e => {
        putBusiness(business.id, {description: business.description})
            .subscribe({
                next: res => {
                    dispatch(setAdminBusinessData(res.data))
                },
                complete: () => {dispatch(showNotification({message: 'Your profile has been updated!'}))},
                error: err => {dispatch(showNotification({message: 'Unable to connect, please try again later'}))}
            })
    }
    return (
        <Card elevation={4} 
            component={Box}
            p={4}
            {...props}
            className={`${props.className} ${classes.card}`}
        >
            <Typography
                className={`${typo.body2} lastUpdate`}
                align="center"
            >
                Your last update was <b className={`${typo.body3}`}>{_formattedDateDistance}</b>.
            </Typography>

            <Typography className={`${typo.body4} info`}
                align="center"
            >
                Concierges rely on up-to-date information.
                No changes to make? Simply confirm here.
            </Typography>
            <Box 
                display="flex" justifyContent="center"
            >
                <Button 
                    id="navigate-to-edit-business-profile"
                    className="btn clickable"
                    {...editProfileSref}
                    color="primary" 
                    variant="outlined"
                >
                    EDIT
                </Button>
                <Button 
                    id="confirm-no-change-in-business-profile"
                    className="btn clickable"
                    onClick={updatelastUpdate} 
                    color="primary"
                    variant="contained"
                >
                    NO CHANGE
                </Button>
            </Box>
        </Card>
    )
}

export default ConfirmNoChangeCard