import React, {useEffect, useState} from 'react'
import {toDataURL} from 'qrcode'
import GetAppIcon from '@material-ui/icons/GetApp';
import { IconButton } from '@material-ui/core';

export default function DownloadQRCode ({text, colorDark, colorLight, ...props}) {
    const [encodedString, setEncodedString] = useState(null)
    const _dark = colorDark ? colorDark : '#000000'
    const _light = colorLight ? colorLight : '#ffffff'
    
    useEffect(() => {
        const opts = {
            errorCorrectionLevel: 'H',
            color: {
                dark:_dark,
                light:_light
            }
        }
        toDataURL(text.toString(), opts)
            .then(setEncodedString)
            .catch(err => {console.error(err)})
    }, [text, _dark, _light])

    return (
        <React.Fragment>
            {/* {encodedString && <img {...props} src={encodedString}></img>} */}
            {encodedString && (
                <IconButton component="a" {...props} href={encodedString} download="qrcode">
                    <GetAppIcon />
                </IconButton>
            )}
        </React.Fragment>
    )
}