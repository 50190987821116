import {BehaviorSubject, Subject, of} from 'rxjs';
import {tap, scan, filter, pluck, distinctUntilChanged, catchError, merge, map, shareReplay, share, debounceTime} from 'rxjs/operators';
import produce from 'immer';

// default loggers can be mutated to change default behavior
// ie. DEFAULT_STATE_LOGGER.next = console.log.bind(0, 'this is not the default logger anymore')
export const DEFAULT_STATE_LOGGER = {next: ()=>void 0, complete: ()=>void 0, error: ()=>void 0}
export const DEFAULT_SLICE_LOGGER = {next: ()=>void 0, complete: ()=>void 0, error: ()=>void 0}
/**
 * ACTIONS :
 * SETUP action subject. Is the entry point for ALL actions
 * DEFINE and EXPORT dispatch function
 */
const ACTION_SUBJECT = new Subject().pipe(
    // maybe need to share() this pipe so as to not run actions reducer more than necessary
    share(),
    // tap into incoming actions, do logging here is required
    // tap(console.log.bind(0, 'action = ')),
    // handle actions of type function. Function actions do not trigger a new state to be emitted.
    // but can dispatch any action synchronously or asynchronously
    tap(action => {
        let sideEffect = (!!action && typeof action == 'function')
            ? action
            : void 0
        sideEffect && sideEffect();
    }),
    // filter out side effect & actions without a type
    filter(action => (typeof action != 'function') && (!!action && !!action.type))
);
// actions as an observable (without next function)
const ACTION_OBSERVABLE = ACTION_SUBJECT.asObservable().pipe(
    shareReplay(1)
);
// DEFINE dispatch. dispatch is the ONLY way to send actions to State reducers
export const dispatch = action => {
    ACTION_SUBJECT.next(action)
}

/**
 * ROOT STATE :
 * SETUP initial immutable state
 * SETUP slice BehaviorSubject
 * SETUP state subject to merge incoming slices of data and emit the new root state
 * 
 * NOTE : 
 * subjects (like regular observables) are lazy and basically won't do anything at all unless they have at least one Observer subscribed
 */

 /**
  * Merge slice into state
  * @params {any} base
  * @params {SliceObject} slice
  * @return {Immutable} 
  */
const mergeSlice = produce((state, slice) => {
    if(!!slice){
        state[slice.name] = slice.data
    } else {
        return state
    }
})
const _INITIAL_MASTER_STATE = produce(Object.create(null), draft => draft)
const SLICE_SUBJECT = new BehaviorSubject()
const STATE_SUBJECT = SLICE_SUBJECT
    .asObservable().pipe(
        scan(
            mergeSlice
            , _INITIAL_MASTER_STATE
        ),
        // share makes sure scan is common to all subscribers, 
        // and replay ensures at least one value is pushed on subscription
        shareReplay(1),
        catchError(console.log.bind(0, 'error in STATE_SUBJECT'))
    )
/**
 * returns an observable that only emits new values at <path>
 * Notably, this function also manages a dictionary of pathed observables
 * so subsequent calls to the same slice & path return the same Observable instance
 * ie. values returned by .path() can be compared with "===" equality (useful for binding to component hooks)
 * @param {string|array} [path] 
 */
function pathAsObservable(path){
    // where this = Observable
    if(!path) return this;
    if(!this['_paths_']) this['_paths_'] = {};
    if(this['_paths_'][path]){
        // return already existing slice
        return this['_paths_'][path]
    }else{
        // parse path 
        let pathArr = (typeof path === 'string' || path instanceof String)
            ? path.split('.')
            : (path instanceof Array)
            ? path
            : void 0;
        // create new observable using pluck (similar to _.pick)
        let pathObservable = this.pipe(
            pluck(...pathArr),
            // returns an empty object if undefined.
            map(obj => typeof obj === 'undefined'? produce(Object.create(null), draft => draft) : obj),
            // only emit if slice has changed
            distinctUntilChanged()
        )
        // register new pathed slice on source observable
        this['_paths_'][path] = pathObservable
        return pathObservable
    }
}
// add method to observable
STATE_SUBJECT['path'] = pathAsObservable.bind(STATE_SUBJECT)
// Mandatory subscription so Subjects are active (because subjects are lazy!)
// do logging here 
STATE_SUBJECT
    // debounce here only to reduce logs
    .pipe(debounceTime(10))
    .subscribe(
    DEFAULT_STATE_LOGGER
    // console.log.bind(0, 'STATE success')
    // console.log.bind(0, 'STATE error'),
    // console.log.bind(0, 'STATE completed')
)
/**
 * $LICE :
 * SETUP $LICES to hold references from created $lices
 * DEFINE and EXPORT $lice function :
 */
export const $LICES = Object.create(null);
/**
 * creates a state $lice identified by <name>, sets up state reducer <reducer> and start with state = <initialState>
 * if $lice<name> has laready been created, do not set it up again.
 * $lice sets up the reducer to be called for every new incoming actions and push new state values to SLICE_SUBJECT so it can be merged into root state
 * @param {String} name - the slice name, used to re-use slice
 * @param {Function} [reducer] - takes in a state and an action, return new state
 * @param {any} [initialState]
 * @return {Observable} - with .path method available
 */
export function $lice (name, reducer, initialState){
    if(!name || typeof name != 'string') throw TypeError('$lice : slice name must be a string')
    // if(name && reducer && $LICES[name]) console.warn(`$lice : "${name}" has already been created. Make sure you do not provide a reducer for "${name}" more than once`)
    if(name && !reducer && !$LICES[name]) throw TypeError(`$lice "${name}" has not been created yet. You may have forgotten to provide a rootReducer for this slice`)
    if(!$LICES[name] && typeof reducer != 'function') throw TypeError('$lice : slice reducer must be a function')
    if(!$LICES[name] && typeof initialState === 'undefined') throw TypeError('$lice : initial state is required to create a new slice')
    // initialise slice if not already created
    if(!$LICES[name]){
        const SLICE_REDUCER_OBSERVABLE = ACTION_OBSERVABLE.pipe(
            // only fired once when slice is created, used to mae sure the reducer is ran once at least
            merge(of({type: '__$LICE/ACTION/INIT__'})),
            scan((state, action) => {
                try {
                    return reducer(state, action)
                } catch (error) {
                    // we do not want scan to error, but we can show the error in console so it is not completely silent
                    console.error(`an error occured in slice<${name}> reducer :`, {
                        error,
                        state,
                        action
                    });
                    // return last state as if nothing happened
                    return state
                }
            }, initialState),
            // emits new state slice to the root slice subject
            tap(dataSlice => {
                SLICE_SUBJECT.next({
                    name: name,
                    data: dataSlice 
                })
            })
        );
        // Mandatory subscription so Observable is active (because subjects are lazy!)
        // do slice level logging here 
        SLICE_REDUCER_OBSERVABLE.subscribe(DEFAULT_SLICE_LOGGER)
        // finally assign slice in $LICES
        $LICES[name] = SLICE_REDUCER_OBSERVABLE   
    }
    // create Observable from STATE_SUBJECT, emit only the target <name> slice
    const slice = STATE_SUBJECT.path(name)
    // add path method to slice observable if not already there
    if(!slice['path']) slice['path'] = pathAsObservable.bind(slice)
    return [slice, dispatch]
};