import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import OpenModalLink from '../../../../dialogs/standard/components/OpenStandardDialog.Link'
import useObStyles from '../onboarding.classes'
import { RecommendTasteGodsForm } from '../Recommendation.form'

export function MembershipOnboardingSuccess(){
    
    const typo = useTypographyStyles()
    const obClasses = useObStyles()
    
    return (
        <Box height="100vh" className={`${obClasses.container}`}>
            <Typography
                className={`
                    ${typo.h1}
                    ${obClasses.mXL}`}
                style={{color: '#292952'}}
                align="center"
            >
                <b>SUCCESS!</b>
            </Typography>
            <Typography
                className={`
                    ${typo.h6}
                    ${obClasses.mXL}`}
                align="center"
            >
                Your membership is now pending review, business verification and account preparations. We'll reach out to you within 1-3 business days with final steps and your Circle Membership confirmation!
            </Typography>
            <OpenModalLink
                BoxProps={{
                    className: `${typo.subtitle3} ${obClasses.mXL}`,
                    style: {textAlign: 'center'}
                }}
                modalTitle="RECOMMEND ANOTHER BUSINESS TO INVITE" 
                modalComponent={RecommendTasteGodsForm}
            >
                RECOMMEND ANOTHER BUSINESS TO INVITE
            </OpenModalLink>
        </Box>
    )
}
export default MembershipOnboardingSuccess