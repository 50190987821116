import {ErrorPageDiv} from '../components/ErrorPageDiv'
import React from 'react';

const errorRoutes = [
    {
        name: 'root.error',
        url: '/error',
        params: {
            tgErrorCode:null
        },
        data: {public: true},
        views: {
            'content': {
                component: ({$stateParams})=>{
                    return (<ErrorPageDiv tgErrorCode={$stateParams.tgErrorCode}/>)
                } 
            }
        }   
    },
]

export default errorRoutes;