export const STATUSES = {
    unidentified: 1,
    identifying: 2,
    identified: 3,
    failed: 4,
    // not currently used :
    unidentifying: 5,
    update: {
        updating: 11,
        updated: 12,
        failed: 13
    },
    password: {
        updating: 21,
        updated: 22,
        failed: 23
    }
}