import React from 'react'
import BottomNavBar from '../../../navigation/components/concierge/MobileNavBar.BottomNavigation'
import { useMediaQuery } from '@material-ui/core'
import Mixpanel from '../../../../lib/mixpanel'
import DesktopNavBar from '../../../navigation/components/concierge/DesktopNavBar'
import { hasAgreedDocument, openAgreementsConfirmationOverlay, submitOutOfDateAgreements } from '../../../legal'
import {manageMapsRoutes} from './manageMapsRoutes'
import {userCollectionsRoutes} from './userCollectionsRoutes'
import {hotelConsoleSearchRoutes} from './hotelConsoleSearchRoutes'
import {hotelConsoleSettingsRoutes} from './hotelConsoleSettingsRoutes'

function getOutOfDateHotelAgreements(){
    const tou = hasAgreedDocument('Terms of Use (ToU)')
    const pp = hasAgreedDocument('Privacy Policy (PP)')
    const _agreements = []
    !tou && _agreements.push('Terms of Use (ToU)')
    !pp && _agreements.push('Privacy Policy (PP)')
    return _agreements
}

export default function conciergesRoutes(router){
    trackConciergeConsole(router)
    return [
        {
            name: 'root.hotel-console',
            url: '/hotel',
            abstract: true,
            onEnter: (t, s) => {
                const _outOfDate = getOutOfDateHotelAgreements()
                return openAgreementsConfirmationOverlay(_outOfDate)
                    .then(() => submitOutOfDateAgreements())
            },
            views: {
                'nav-bar@root': ConciergeNavbar
            }
        },
        ...manageMapsRoutes,
        ...userCollectionsRoutes,
        ...hotelConsoleSettingsRoutes,
        ...hotelConsoleSearchRoutes,
    ]
}

export function trackConciergeConsole(router){
    
    // register platform super prop
    router.transitionService.onEnter(
        {entering: 'root.hotel-console.**'}, 
        () => {
            Mixpanel('register')({platform: 'hotel'})
        }
    )

    // register custom vs standard 
    router.transitionService.onEnter(
        {entering: function(state){
            return [
                'root.hotel-console.maps.map', 
                'root.hotel-console.maps.dashboard',
                'root.hotel-console.maps.map.edit', 
                'root.hotel-console.deprecated.customise'
            ].indexOf(state.name) !== -1}
        },
        trans => {
            Mixpanel('register')({
                map_type: trans.params().customMapId ? 'custom' : 'standard',
                map_id: trans.params().customMapId ? trans.params().customMapId : undefined
            });
            trans.params().customMapId
                ? Mixpanel('register')({map_id: trans.params().customMapId})
                : Mixpanel('unregister')('map_id')
        }
    )

    // register map vs grid super prop
    router.transitionService.onEnter(
        {entering: function(state){
            return [
                'root.hotel-console.maps.map.edit', 
                'root.hotel-console.deprecated.customise'
            ].indexOf(state.name) !== -1}
        },
        trans => {
            Mixpanel('register')({
                poi_view: trans.params().list ? 'grid' : 'map'
            });
        }
    )
    router.transitionService.onRetain(
        {retained: function(state){
            return [
                'root.hotel-console.maps.map.edit', 
                'root.hotel-console.deprecated.customise'
            ].indexOf(state.name) !== -1}
        },
        trans => {
            Mixpanel('register')({poi_view: trans.params().list ? 'grid' : 'map'});
        }
    )

    // clear super prop on exit
    router.transitionService.onExit(
        {exiting: function(state){
            return [
                'root.hotel-console.maps.map.edit', 
                'root.hotel-console.deprecated.customise'
            ].indexOf(state.name) !== -1}
        },
        () => {
            Mixpanel('unregister')('poi_view');
        }
    )
    
    // clear map_type when leaving map console
    router.transitionService.onExit(
        {exiting: 'root.hotel-console'},
        () => {
            Mixpanel('unregister')('map_type');
        }
    )
}

export function ConciergeNavbar(props) {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    return (
        <>
            { isDesktop
                ? <DesktopNavBar />
                : <BottomNavBar />
            }
        </>
    )
}