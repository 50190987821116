import React, {useRef} from 'react'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { TextField, InputAdornment, IconButton, Divider } from '@material-ui/core'
import {dispatch} from 'slice'
import { showNotification } from '../../../modules/notifications/state/actions'
import { useTypographyStyles } from '../../../modules/theme/useTypography'

/* 
    A read only TextField that takes in the text to copy to clipboard when
    clicking the button in endAdornment
*/

export default function CopyTextToClipboardTextField({text, IconButtonProps, ...props}) {
    const typography = useTypographyStyles()
    const _hiddenInput = useRef()
    const _text = text || ''
    const _IconButtonProps = IconButtonProps
    const _TextFieldProps = props
    const handleClick = e => {
        _hiddenInput.current.select()
        _hiddenInput.current.setSelectionRange(0, 99999); /*For mobile devices*/
        // reset focus.
        _hiddenInput.current.blur()

        /* Copy the text inside the text field */
        document.execCommand("copy");
        
        dispatch(showNotification({
            message: 'Successfully copied to clipboard.'
        }))
    }
    return (
        <React.Fragment>
            <TextField
                {..._TextFieldProps}
                className={typography.h6}
                fullWidth
                variant='outlined'
                // inputProps={{ref : _hiddenInput}}
                inputRef={_hiddenInput}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Divider orientation='vertical'/>
                            <IconButton
                                {..._IconButtonProps}
                                onClick={handleClick}
                            >
                                <FileCopyOutlinedIcon></FileCopyOutlinedIcon>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                readOnly
                type="text"
                value={_text}
                />
        </React.Fragment>
    )
}
