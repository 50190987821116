import React from 'react'
import { BottomNavigation, BottomNavigationAction, Box } from '@material-ui/core'
import MapIcon from '@material-ui/icons/MapOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SettingsIcon from '@material-ui/icons/Settings';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { useSrefActive} from '@uirouter/react';

const useBottomNavStyles = tgMakeStyles(theme => ({
    withBoxShadow: {
        boxShadow: '0px -2px 5px rgba(163,163,163,0.5)'
    },
    navActionRoot: {
        margin: '8px 12px 12px 12px'
    }
}))

export default function BottomNavBar(props) {
    const {BoxProps, classes} = props
    const srefHomeActive = useSrefActive('root.hotel-console.maps.dashboard', {}, 'Mui-selected')
    const srefCollectionsActive = useSrefActive('root.hotel-console.collections', {}, 'Mui-selected')
    const srefSettingsActive = useSrefActive('root.hotel-console.settings', {}, 'Mui-selected')
    const _classes = useBottomNavStyles()
    return (
        <Box p={0} m={0} {...BoxProps} className={_classes.withBoxShadow}>
            <BottomNavigation
                classes={classes}
                showLabels
            >
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefHomeActive}
                    label="Maps"
                    icon={<MapIcon />} />
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefCollectionsActive}
                    label="Favorites"
                    icon={<FavoriteIcon />} />
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefSettingsActive} 
                    label="Settings" 
                    icon={<SettingsIcon />} />
            </BottomNavigation>
        </Box>
    )
}