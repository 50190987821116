import { Box, Fab, IconButton, SvgIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React, { useEffect } from 'react';

import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import Mixpanel from '../../../../lib/mixpanel';

import { TranslationToggleButton } from '../../../language/components/TranslationToggleButton';
import { useTranslationHook } from '../../../language/state/hooks/useTranslationHook';
import { useTypographyStyles } from '../../../theme/useTypography';
import { PoiCardHeader } from '../../../maps/digital-map/common/components/poi-card/PoiCardHeader';

import AboutText from './AboutText';
import GoogleMapDirectionsFab from './GoogleMapDirectionsFab';
import TipBox from './TipBox';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import useJuicerFeed from '../../../business/hooks/useJuicerFeed';
import { isPremium } from '../../../business/utils';
import { SeeMapOnFab } from './SeeOnMapFab';
import { TodayOpeningHours } from './TodayOpeningHours';

const useStyles = tgMakeStyles(theme => ({
    FabRoot: {
        boxShadow: 'none',
        marginLeft: '0.25rem',
        marginRight: '0.25rem',
        marginTop: '0',
        marginBottom: '0.25rem',
    },
    aboutText: {
        marginBottom: theme.spacing(3)
    },
    transButtonRoot : {
        padding : 0
    },
    box : {
        marginTop : '1.5rem',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
        overflow: 'hidden',
        '&:last-child': {
            marginBottom : '1.5rem',
        }
    },
    boxDense : {
        marginBottom : '0',
        // marginTop : '0',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
    }
}))

export default function PoiCardForGuestUi({
    map,
    isMobile,
    isCompact,
    onCompactToggle,
    isMapDisplay,
    isCustom,
    feature,
    BoxProps ,
    translation,
    hotelName,
    handleSeeOnMap,
    ...props
}){
    const typography = useTypographyStyles()
    const classes = useStyles()

    let poiData = {};
    feature.forEachProperty((val, key) => {
        poiData = {
            ...poiData,
            [key]: val
        }
    })
    const _business = poiData.place
    const _isPremium = isPremium(_business)

    const _feed = useJuicerFeed(_business && _business.feed ? _business.feed.juicerId : null)
    const handleShowMoreInfo = onCompactToggle

    // console.log(translation)
    const {texts,showTrans,setShowTrans} = useTranslationHook ({
        translation,
        tipText:poiData.tip,
        aboutText:_business.place.about,
        id:poiData.googlePlaceId
    })

    // track details impression
    useEffect( () => {
        if(!isCompact){
            Mixpanel('track')('impression_poi_details', {
                GooglePlaceID: poiData.googlePlaceId,
                poiName: poiData.name,
            })
        }
        return ()=>{}
    }, [isCompact])

    return (
        <Box p={0} m={0} {...BoxProps}>
            {/* heading + name + address */}
            <PoiCardHeader {...{
                clampName: isCompact,
                isCompact,
                isMobile, 
                isCustom,
                isMapDisplay,
                poiData
            }} />
            <Box>
                {/* Social links */}
                {(_isPremium && _feed && !isCompact) && (
                        <Box 
                            textAlign='center'
                            className={classes.boxDense}
                        >  
                            {_feed.sources.length > 0 
                                ? _feed.sources.map(source => {
                                    const platform = source.source
                                    const _isValidPlatform = platform === 'Facebook' || platform === 'Instagram'
                                    const _urldomain = platform ===  'Facebook'
                                        ? 'https://www.facebook.com/'
                                        : 'https://www.instagram.com/'
                                    const _urlpath = source.term_type === 'username'
                                        ? source.term
                                        : `explore/tags/${source.term}/`
                                    const _url = _urldomain+_urlpath
                                    
                                    return _isValidPlatform
                                        ? (
                                            <a href={_url} target="_blank" style={{color: 'inherit'}}>
                                                <IconButton style={{margin: 0}}>
                                                    {platform === 'Facebook'
                                                        ? <FacebookIcon color="inherit"/>
                                                        : <InstagramIcon color="inherit"/>
                                                    }
                                                </IconButton>
                                            </a>
                                        )
                                        : null
                                })
                                : (<Box className="spacer" style={{marginTop : '1.5rem'}}></Box>)
                            }
                        </Box>
                )}

                {/* Today's Opening Hours*/}
                {(!isCompact && _business.place && _business.place.openingHours && _business.place.openingHours.length > 0) && (
                    <Box className={classes.boxDense}>
                        <TodayOpeningHours
                            place={_business.place}
                            showLastUpdate={false}
                        />
                    </Box>
                )}

                {/* action buttons */}
                <Box 
                    textAlign='center' 
                    className={classes.box}
                >
                    <GoogleMapDirectionsFab
                        // props required for component logic
                        {...{
                            lat: poiData.lat, 
                            lng: poiData.lng, 
                            googlePlaceId: poiData.googlePlaceId, 
                            name: poiData.name, 
                        }}
                        // props applied to Box element wrapping Fab
                        BoxProps={{
                            display: 'inline-block'
                        }}
                        // classes applied to Fab element
                        classes={{
                            root: classes.FabRoot,
                            label: typography.body2
                        }}
                    />
                    {(!isCompact && handleSeeOnMap) && (
                        <SeeMapOnFab 
                            feature={feature}
                            map={map}
                            classes={{
                                root: classes.FabRoot,
                                label: typography.body2
                            }}
                            BoxProps={{
                                display: 'inline-block'
                            }}
                            handleSeeOnMap={handleSeeOnMap}
                        />
                    )}
                    {/* this button only shows in compact mode */}
                    { isCompact && (_business.place.about || poiData.tip)
                        &&
                        <MoreInfoFab
                            classes={{
                                root: classes.FabRoot,
                                label: typography.body2
                            }}
                            variant='extended'
                            color='primary'
                            onClick={handleShowMoreInfo}
                        />
                    }
                </Box>

                <Box  className={classes.box}>
                    {/* translate about & tip button */}
                    { (!isCompact && translation.length > 0) && (_business.place.about || poiData.tip) && 
                        <TranslationToggleButton show={showTrans} onClick={()=>{setShowTrans(prev => !prev)}}/>
                    }
                    {/* poi about */}
                    { !isCompact && _business.place.about && 
                        <AboutText 
                            className={classes.aboutText}
                        >
                            {texts.aboutText}
                        </AboutText>
                    }
                    
                    {/* poi tip */}
                    { !isCompact && poiData.tip &&
                        <TipBox hotelName={hotelName}>
                            {texts.tipText}
                        </TipBox>
                    }
                </Box>
                
            </Box>
        </Box>
    );
}

function MoreInfoFab(props){
    const {BoxProps, ...forwardedProps} = props
    const theme = useTheme()
    return (
        <Box p={0} m={0} display='inline-block' {...BoxProps}>
            <Fab 
                {...forwardedProps}
            >
                <SvgIcon viewBox="0 0 352 512" 
                    style={{height: '24px', width: '24px', marginRight: theme.spacing(1)}}
                    color='inherit'
                >
                    <path fill="currentColor" 
                        d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z" />
                </SvgIcon>
                More Info
            </Fab>
        </Box>
    )
}