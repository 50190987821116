import produce from 'immer';

export const INITIAL_STATE = {
    open: false,
    fullScreen: false,
    title: null,
    component: null,
    onClose: null,
    props: {}
}

export function rootModalReducer(state, action){
    switch (action.type) {
        case 'MODAL/OPEN':
            return produceModalState(
                state, 
                true,
                action.payload.fullScreen,
                action.payload.title, 
                action.payload.component, 
                action.payload.props,
                // action.payload.size,
                action.payload.onBack,
                action.payload.DialogProps,
                action.payload.onClose)
        case 'MODAL/CLOSE':
            return produceModalState(state, false)
        default:
            return state;
    }
}

const produceModalState = produce(
    (draft, open, fullScreen, title, component, props, /* size,  */onBack, DialogProps, onClose) => {
        draft.open = open;
        draft.fullScreen = fullScreen ? fullScreen : false;
        draft.title = title || '';
        draft.component = component ? component : null;
        draft.props = props ? props : {};
        // draft.size = size
        draft.onBack = onBack
        draft.DialogProps = DialogProps
        draft.onClose = onClose
})