import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import {COLORS} from '../../../theme/colors'

export const useStyles = tgMakeStyles((theme)=>{
    return {
        box : {
            marginBottom : ({isMobile,isMapDisplay})=>{
                const spaceNum = isMobile ? 1 : isMapDisplay ? 3 : 2;
                return theme.spacing(spaceNum)
            },
            textAlign: 'center'
        },
        clamp : {
            '@supports (-webkit-line-clamp: 3)': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical'
            }
        },
        clamp4 : {
            '@supports (-webkit-line-clamp: 5)': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical'
            }
        },
        btnToggle: {
            transition: 'all .3s',
            flexGrow: '1',
            margin: 0,
            '&,&:focus': {
                backgroundColor: 'rgb(226,226,232)',
                color: 'inherit',
            },
            '&.open.active': {
                backgroundColor: '#585879',
                color: 'white'
            },
            '&:hover': {
                backgroundColor: '#585879',
                color: 'white',
                cursor: 'pointer'
            }
        },
        operationStatus: {
            border: '3px solid #C6AD7C',
            borderRadius: '0.375rem'
        },
        visitGuidelines: {
            backgroundColor:'#F8F6F7',
            borderRadius: '0.375rem',
            '& .warning': {
                backgroundColor: '#E8E7E9',
                color: '#A3A3A3'
            }
        },
        copybtn: {
            transition: 'all .3s',
            color: COLORS.get('grey')[4],
            '&:hover': {
                cursor: 'pointer',
                color: COLORS.get('gold')['main']
            }
        },
        favBtn: {
            transition: 'all .3s',
            color: COLORS.get('grey')[4],
            '&:hover': {
                cursor: 'pointer',
                color: COLORS.get('gold')['main']
            },
            '&.active': {
                color: COLORS.get('gold').main
            }
        }
    }
});

export default useStyles