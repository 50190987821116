import { useEffect } from 'react';
import useStateAuthStatus from '../../../authentication/state/hooks/useStateAuthStatus';
import { useStateUserDetails } from './useStateUserDetails';
import { dispatch } from 'slice';
import { setUserDetails, unsetUserDetails } from '../actions';
import { STATUSES } from '../meta';
// import Mixpanel from '../../../../lib/mixpanel'

export function useEffectRefreshUserDetails(){
    const {isAuthenticated} = useStateAuthStatus();
    const userDetails = useStateUserDetails();
    const {status} = userDetails;
    
    // side effect triggered by isAuthenticated & user details change
    useEffect(() => {
        // authenticated but no identified (ie: need to fetch user info)
        if(isAuthenticated && status && !status.isIdentified){
            // identifying
            if(status.code === STATUSES.identifying){
                // do nothing 
                void 0
            } else 
            // previous attempt failed
            if(status.code === STATUSES.failed){
                // do nothing 
                void 0
            } else {
                dispatch(setUserDetails())
            }
        // not authenticated anymore but still identified (ie: need to unidentify. user need to authenticate)
        } else if(!isAuthenticated && status && status.isIdentified) {
            dispatch(unsetUserDetails())
        }
        return () => void 0;
    }, [isAuthenticated, status])
}