import React from 'react'
import { BottomNavigation, BottomNavigationAction, Box } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { useSrefActive, useSref } from '@uirouter/react';
import SettingsIcon from '@material-ui/icons/Settings';

const useBottomNavStyles = tgMakeStyles(theme => ({
    withBoxShadow: {
        boxShadow: '0px -2px 5px rgba(163,163,163,0.5)'
    },
    navActionRoot: {
        margin: '8px 12px 12px 12px'
    }
}))

export default function BottomNavBar(props) {
    const {BoxProps, classes} = props
    // const srefHome = useSref('root.hotel-map.home')
    const srefHomeActive = useSrefActive('root.hotel-map.home', {}, 'Mui-selected')
    const srefMapActive = useSrefActive('root.hotel-map.map', {list: false}, 'Mui-selected')
    const srefListActive = useSrefActive('root.hotel-map.map', {list: true}, 'Mui-selected')
    const srefSetting = useSrefActive('root.hotel-map.setting', {}, 'Mui-selected')
    const _classes = useBottomNavStyles()
    return (
        <Box p={0} m={0} {...BoxProps} className={_classes.withBoxShadow}>
            <BottomNavigation
                classes={classes}
                showLabels
            >
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefHomeActive}
                    // {...srefHome}
                    label="Home"
                    icon={<HomeIcon />} />
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefMapActive} 
                    label="Map" 
                    icon={<MapOutlinedIcon />} />
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefListActive}
                    label="List"
                    icon={<ListIcon />} />
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefSetting}
                    label="Settings"
                    icon={<SettingsIcon />} />
            </BottomNavigation>
        </Box>
    )
}