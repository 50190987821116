import { $lice } from "slice";
import { useState, useEffect } from "react";
import { INITIAL_STATE } from '../state/reducers';
import { rootReducer as DigitalMapRootReducer } from '../state/reducers/index'

export function useStateGeoJsonOverlay(name){
    
    const [dMap$] = $lice('digitalmap', DigitalMapRootReducer, INITIAL_STATE)
    
    const [files, setFiles] = useState([]);
    useEffect(() => {
        let sub = dMap$.path('overlays.geojson').subscribe(setFiles)
        return () => {
            sub.unsubscribe()
        };
    }, [dMap$])
    return files
}