import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles";
import { TYPOGRAPHY_STYLES } from "../../../../theme/typographies";

export const useStyles = tgMakeStyles(theme => {
    return {
        card: {
            height: '100%',
            padding: theme.spacing(6),
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            borderRadius: '1rem',
            ...TYPOGRAPHY_STYLES.body1
        },
        title: {
            ...TYPOGRAPHY_STYLES.subtitle3,
            '& .valign': {
                verticalAlign: 'middle'
            },
            '& .ok': {color: 'green'},
            '& .warning': {color: 'red'}
        },
        billsummary: {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > *': {
                flexBasis: '50%',
                flexGrow: 0,
                flexShrink: 0
            },
            '& > *.right': {
                textAlign: 'right'
            }
        },
        halo1: {
            position: 'absolute',
            bottom: 0, left: 0,
            transform: 'translateX(-50%)'
        },
        halo2: {
            position: 'fixed',
            top: 0, right: 0,
            transform: 'translateX(50%)'
        }
    }
})

export default useStyles