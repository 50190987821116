import { Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import HotelLogoImg from '../../../../hotel/components/HotelLogoImg';
import { TasteGodsCreditsLink } from '../../../../navigation/components/TasteGodsCreditsLink';

export const ContentWrapper = ({hotel,children}) => {
    // Change styling based on user window size.
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))

    const hotelLogoProps = {
        hotel,
        style : {
            maxWidth: '120px', height: 'auto'
        }
    }

    return (
        <Box>
            {isMobile &&
                <Box textAlign={'center'} m={2}>
                    <HotelLogoImg {...hotelLogoProps} />
                </Box>
            }
            {children}
            {isMobile &&
                <Box m={2} pb={2}>
                    <TasteGodsCreditsLink
                        BoxProps={{
                            px: 2,
                            component: 'footer',
                            flex: 'none',
                            flexDirection: 'column',
                        }}
                        imgProps={{
                            style : {
                                maxWidth : '140px'
                            }
                        }}
                    />
                </Box>
            }
        </Box>
    );
}