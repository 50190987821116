import React from 'react'
import { BottomNavigation, BottomNavigationAction, Box } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { useSrefActive, useSref } from '@uirouter/react';
import EditSvgIcon from '../svgIcons/EditIcon.SvgIcon';

const useBottomNavStyles = tgMakeStyles(theme => ({
    withBoxShadow: {
        boxShadow: '0px -2px 5px rgba(163,163,163,0.5)'
    },
    navActionRoot: {
        margin: '8px 12px 12px 12px'
    }
}))

export function BottomNavBar(props) {
    const {BoxProps, classes} = props
    const srefHome = useSref('root.business-console.dashboard')
    const srefHomeActive = useSrefActive('root.business-console.dashboard', {}, 'Mui-selected')
    const srefProfile = useSref('root.business-console.profile')
    const srefProfileActive = useSrefActive('root.business-console.profile', {}, 'Mui-selected')
    const srefSettingsActive = useSrefActive('root.business-console.settings', {}, 'Mui-selected')
    const _classes = useBottomNavStyles()
    return (
        <Box p={0} m={0} {...BoxProps} className={_classes.withBoxShadow}>
            <BottomNavigation
                classes={classes}
                showLabels
            >
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefHomeActive}
                    {...srefHome}
                    label="Home"
                    icon={<HomeIcon />} />
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefProfileActive}
                    {...srefProfile}
                    label="My Profile"
                    icon={<EditSvgIcon />} 
                />
                <BottomNavigationAction
                    classes={{
                        root: _classes.navActionRoot
                    }} 
                    {...srefSettingsActive} 
                    label="Settings" 
                    icon={<SettingsIcon />} 
                />
            </BottomNavigation>
        </Box>
    )
}

export default BottomNavBar