import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import { UIView } from '@uirouter/react'
import DesktopToolBarBox from '../toolBar/components/DesktopToolBarBox';
import ContextDrawer from '../contextDrawer/components/ContextDrawer'

export default function DesktopLayout() {
    const theme = useTheme()
    return (
        <Box 
            display='flex' 
            flexDirection='column'
            position='fixed'
            width='100%'
            height='100%'
            className={'root-parent'}
        >
            {/* header does not grow inside flex container */}
            <Box flex='none'
                zIndex={2}
            >
                <UIView name="header"/>
            </Box>
            {/* content area, grows to fill screen height between header and toolbar */}
            <Box flexGrow='1'
                overflow='hidden'
                // zIndex and position != static create a new stacking context. ie: all children are behind header 
                // this is used to make sure the drawers attached to content-container are not over the header's shadows
                zIndex={1}
                position='relative'
                
                // control size of tool bar 
                display='flex'
                flexDirection='row'
                width={'100%'}
            >   
                 <Box
                    id='desktop-navbar-container'
                    position='relative'
                    flex="none"
                    // zIndex on navbar is required to ensure navbar shadow are on top of DigitalMapDrawer (poi list)
                    zIndex={theme.zIndex.modal+1}
                    maxWidth={theme.spacing(25)}
                    boxShadow='0px 4px 20px rgba(163,163,163,0.5)'
                >
                    <UIView name="nav-bar"/>
                </Box>
                <Box
                    position='relative'
                    overflow='hidden'
                    flexGrow={1}
                    // id is used to target content container to open drawer sized to content
                    id='content-container'
                >
                    <Box 
                        overflow='auto'
                        id='content-scroll'
                        height='100%'
                    >
                        <UIView name="content"/>
                        <UIView name="content-footer"/>
                    </Box>
                    <ContextDrawer />
                </Box>
                <Box
                    id='desktop-toolbar-container'
                    position='relative'
                    flex="none"
                    boxShadow='-4px 0px 20px rgba(163,163,163,0.5)'
                >
                    <DesktopToolBarBox />
                </Box>
            </Box>
        </Box>
    )
}
