import { tgMakeStyles } from "../../../lib/styles/TgMakeStyles";

export const useStyles = tgMakeStyles(theme => ({
    mb: {
        marginBottom: '1.5rem'
    },
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        '& > *': {
            margin: 'auto'
        }
    },
    inputcontainer: {
        position: 'relative',
        color: 'white',
        display: 'flex',
        border: '2px solid white',
        borderRadius: '2rem',
        '& > input': {
            textAlign: "center",
            paddingLeft: '1rem',
            flexGrow: 1,
            color: 'inherit',
            backgroundColor: 'transparent',
            border: 'none'
        },
        '& > input:focus':{
            outline: 'none'
        },
        '& > button': {
            flexGrow: 0,
            color: 'inherit'
        },
    }
}))

export default useStyles