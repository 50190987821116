import { Box, Card, Checkbox, Container, FormControlLabel, IconButton, Link, ListItem, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
// import CreateIcon from '@material-ui/icons/Create';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect } from 'react';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
// import { COLORS } from '../../../theme/colors';
import Mixpanel from '../../../../lib/mixpanel'

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { useTypographyStyles } from '../../../theme/useTypography';
import { useEditHotelMap } from '../hooks/useEditHotelMap';
import { EditHotelMapCTA } from './EditHotelMap';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import { openFeatureInContextDrawer } from '../../../maps/digital-map/listeners/openFeatureInContextDrawer';
import { dispatch } from 'slice';
import { deleteHotelPlace, editHotelPlace } from '../state/editHotelMapReducer';
import EditHotelMapPlaceForm from './EditHotelMapPlaceForm';
// import { closeContextDrawer, setContextDrawer } from '../../../contextDrawer/state/actions';
import { closeModal, openModal } from '../../../dialogs/standard/state/actions';
import { PLANS_PLAIN_TEXT } from '../../../invitation/utils';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import StarBorderIcon from '@material-ui/icons/StarBorder';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { COLORS } from '../../../theme/colors';

const CLOSED_STATUSES = ['CLOSED_TEMPORARILY', 'CLOSED_PERMANENTLY']
const noMarginCss = {
    margin: 0,
    border: 'none',
    borderRadius: '0.5rem'
}
const useStyles = tgMakeStyles(theme => ({
    liButton: {
        ...noMarginCss,
        alignItems: 'flex-start',
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        maxWidth: '100%'
    },
    placeOrderPin: {
        width: '2rem',
        height: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        color: 'white',
        backgroundColor: COLORS.get('grey')[3],
        marginRight: '1rem',
        position: 'relative',
        bottom: '-0.5rem',
        zIndex: 1
    }
}))

export const EditHotelMapPlaces = () => {

    const classes = useStyles()
    const [
        editHotelMap,
        {
            toggleOrderedMap,
            toggleSortedMap,
            resetPlacesOrder,
            moveHotelPlace
        }
    ] = useEditHotelMap()
    const _hotelPlaces = editHotelMap && editHotelMap.tgt && editHotelMap.tgt.hotelPlaces
        ? editHotelMap.tgt.hotelPlaces
        : null
    const _sorted = editHotelMap && editHotelMap.tgt && editHotelMap.tgt.alphabeticallyOrdered
    // console.log('editHotelMap in list', editHotelMap)
    const typo = useTypographyStyles()
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))

    const onDragEnd = ({source, destination}) => {
        moveHotelPlace(source.index, destination.index)
    }

    useEffect( () => {
        if(_sorted){
            resetPlacesOrder()
        }
        return () => {}
    }, [_sorted])

    return (
        <Container maxWidth="xs" style={{margin: 0, padding: 0}}>
            <Box
                display="flex" 
                height="100%"
                flexDirection="column"
            >
                {isMobile && <Box flex="none">
                    <EditHotelMapCTA />
                </Box>}
                <Box position="relative" flexGrow="1" m={3}>
                    {/* <Box height="100%" overflow="scroll"> */}
                        {/* This is where hotel map places can be edited: {editHotelMap && editHotelMap.tgt && editHotelMap.tgt.title ? editHotelMap.tgt.title : 'unknown'} */}
                        
                        <Box mb={2}>
                            <Typography className={`${typo.subtitle1}`}>
                                All places on this map
                            </Typography>
                        </Box>
                        
                        <Typography className={`${typo.body2}`}>
                            Drag the cards to change the order for your guests
                        </Typography>
                        
                        {editHotelMap && editHotelMap.tgt && (
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={editHotelMap.tgt.alphabeticallyOrdered}
                                    onChange={toggleSortedMap}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label={
                                    <>
                                        <Typography className={`${typo.body2}`}>
                                            Order the places from A to Z
                                        </Typography>
                                        <Typography className={`${typo.caption1}`}>
                                            (un-check this box to drag & drop places in a custom order for guests)
                                        </Typography>
                                    </>
                                }
                            />
                        )}

                        {editHotelMap && editHotelMap.tgt && (
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={editHotelMap.tgt.ordered}
                                    onChange={toggleOrderedMap}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label={<Typography className={`${typo.body2}`}>Assign numbers to places on the map</Typography>}
                            />
                        )}
                        
                        {/* <Box> */}
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="hotelPlaces">  
                                {(provided, snapshot) => (  
                                    <Box
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        // style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {_hotelPlaces && _hotelPlaces.map( (hp, idx) => (
                                                
                                                <Draggable key={idx} draggableId={`draggable${idx}`} isDragDisabled={editHotelMap.tgt.alphabeticallyOrdered} index={idx}>
                                                    {(provided, snapshot) => (
                                                        <Box
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            mb={1}
                                                        >
                                                            {editHotelMap.tgt.ordered && (
                                                                <Box className={`${classes.placeOrderPin}`} mb={0.25}>
                                                                    {hp.order}
                                                                </Box>
                                                            )}
                                                            <EditHotelMapPlaceListItem
                                                                hotelPlace={hp}
                                                                index={idx}
                                                                ordered={editHotelMap.tgt.ordered}
                                                                mb={2}
                                                            />
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            
                                        ))}
                                        {provided.placeholder}
                                    </Box>
                                )}
                            </Droppable>
                        </DragDropContext>

                    {/* </Box> */}
                </Box>
            </Box>
        </Container>
    )
}

function EditHotelMapPlaceListItem({
    hotelPlace, 
    ordered,
    index,
    ...props
}){
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const typo = useTypographyStyles()
    const classes = useStyles()
    
    const poi = hotelPlace.place
    const _place = poi.place
    const address = _place ? _place.location : 'unknown'
    
    function onSuccessEdit(editedHotelPlace){
        dispatch(editHotelPlace(editedHotelPlace))
        dispatch(closeModal())
        // dispatch(closeContextDrawer())
    }
    
    function onSelect(e){
        e.stopPropagation()
        const eventProps = {
            GooglePlaceID: hotelPlace.place.place.googlePlaceId,
            poiName: hotelPlace.place.place.name
        }
        Mixpanel('track')('click_poi', eventProps)

        // show feature details in card
        dispatch(openModal({
            title: 'Tell Your Guest About',
            component: EditHotelMapPlaceForm,
            fullScreen: !isDesktop,
            props: {
                hotelPlace: hotelPlace,
                onContinue: onSuccessEdit,
            } 
        }))
    }
    const onDelete = hp => e => {
        e.stopPropagation()
        dispatch(deleteHotelPlace(index))
    }
    return (
        <ListItem
            {...props}
            component={Card}
            elevation={4}
            // onClick={() => { onSelect(poi) }}
            // button
            disableGutters={true}
            divider={true}
            classes={{
                button: classes.liButton
            }}
        >
            <Box width="100%" pl={2}>
                <Box mb={0.5} display="flex" alignItems="center">
                    <CircleMemberLabel place={poi} />
                    <Box className={`spacer`} flexGrow="1"></Box>
                    <Box flex="none">
                        <IconButton style={{margin: '0 .25rem', padding: 0}} onClick={onSelect}>
                            <EditIcon />
                        </IconButton>
                        <IconButton style={{margin: '0 .25rem', padding: 0}} onClick={onDelete(hotelPlace)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box display={'flex'} width="100%" alignItems="center">
                    <Typography
                        variant="inherit"
                        component='div'
                        className={poi.plan ? typo.subtitle6 : typo.subtitle4}
                        style={{flexGrow: 1}}
                    >
                        {_place.name}
                    </Typography>
                </Box>
                <Box display={'flex'} width="100%" alignItems="center" /* height="1.5rem" */>
                    <Typography
                        variant="body1"
                    >
                        {address}
                    </Typography>
                </Box>
                {/* if permanently closed */}
                { CLOSED_STATUSES.indexOf(_place.businessStatus) !== -1 &&
                    <Box display={'flex'} width="100%" alignItems="center" /* height="1.5rem" */>
                            <Typography
                                className={typo.subtitle4}
                                color="error"
                            >
                                {`${_place.businessStatus === 'CLOSED_PERMANENTLY' ? 'Permanently' : 'Temporarily'} closed`}
                            </Typography>
                    </Box>
                }
            </Box>
        </ListItem>
    )
}

export function CircleMemberLabel({place}){
    const typo = useTypographyStyles()
    return place.plan 
    ? (
        <Typography className={`${typo.body3}`} color="primary">
            <b>CIRCLE MEMBER</b>
            {place.plan === PLANS_PLAIN_TEXT[1]
                ? <MapOutlinedIcon color="primary" fontSize="inherit" style={{margin: '0 0.25rem 0 0.5rem'}}/>
                : place.plan === PLANS_PLAIN_TEXT[2]
                ? <>
                    <MapOutlinedIcon color="primary" fontSize="inherit" style={{margin: '0 0.25rem 0 0.5rem'}}/>
                    <ChatOutlinedIcon color="primary" fontSize="inherit" style={{margin: '0 0.25rem'}}/>
                </>
                : null
            }
        </Typography>
    )
    : null
}