import { useState, useEffect } from 'react';
import { $lice } from 'slice';
import { INITIAL_STATE as initialUserState, userRootReducer } from '../reducers';

export {
    useStateUserDetails
}

const [user$] = $lice('tguser', userRootReducer, initialUserState)
function useStateUserDetails(){
    const [details, setDetails] = useState({})
    useEffect(() => {
        let sub = user$.path('details').subscribe(function(n){
            setDetails(n)
        })
        return () => {sub.unsubscribe()}
    }, [])

    return details
}