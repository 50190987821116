import produce from 'immer';
import highlightedFeatureReducer from './highlightedFeature';
import geoJsonDataReducer from './geoJsonDataOverlayReducer';

export const INITIAL_STATE = {
    overlays: {
        /* 
            these overlay arrays contain object representation of an overlays layer :
            for geojson : {active: bool, name: string, gmData: google.maps.Data}
        */
        geojson: []
    },
    highlightedFeature: null
}

export function rootReducer(state, action){
    return produce(state, draft => {
        draft['overlays']['geojson'] = geoJsonDataReducer(state['overlays']['geojson'], action)
        draft['highlightedFeature'] = highlightedFeatureReducer(state['highlightedFeature'], action)
    })
}

export function gglLayer(active, name, gmData, source){
    return {
        active,
        name,
        gmData,
        source
    }
}