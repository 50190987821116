import { Box, IconButton, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useTypographyStyles } from '../../../theme/useTypography';
import { useStyles } from './profileCss.classes'
// import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import GoogleMapDirectionsLink from '../../../hotel/guest-console/components/GoogleMapDirections.Link';
import { PlaceOpenStatus } from './PlaceOpenStatus';
import { showNotification } from '../../../notifications/state/actions';
import { dispatch } from 'slice';
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails';
import { deleteUserPlace, postUserPlace } from '../../../http/tg/user';
import { assignUserFavs } from '../../../user/state/actions';
import { setClipboard } from '../../../../lib/utils';
import { PrintDirectionsIconButton } from '../place-directions/PrintDirections.IconButton';

const BETA_HOTELS = process.env.REACT_APP_BETA_FEATURES_HID

function useIsBetaUser(userInfo) {
    const [bool, setBool] = useState(false)
    useEffect(() => {
        if (!userInfo || (userInfo.hotels && userInfo.hotels.length === 0)) {
            setBool(false)
        } else {
            const betaList = BETA_HOTELS
                ? BETA_HOTELS.split(',').map(strId => Number(strId))
                : []
            setBool(betaList.includes(userInfo.hotels[0].id))
        }
        return () => { }
    }, [userInfo])
    return bool
}

export default function ProfileHeader({
    place,
    showLinks = true
}) {
    const { info } = useStateUserDetails()
    const isBetaUser = useIsBetaUser(info)
    const _place = place ? place.place : null
    const _isfaved = info && info.favorites && info.favorites.length > 0
        // in some cases, fav list is populated locally with googlePlaceId (ie: when location is not assigned a placeId yet)
        ? info.favorites.indexOf(_place.id) > -1 || info.favorites.indexOf(_place.googlePlaceId) > -1
        : false
    const typographies = useTypographyStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const classes = useStyles({ isMobile: !isDesktop })

    const copyBusinessProfileLink = (e) => {
        const _linkTxt = `${document.location.protocol}//${document.location.host}/hotel/search/business/${_place.googlePlaceId}`
        setClipboard(_linkTxt)
            .then(result => {
                dispatch(showNotification({
                    message: 'Successfully copied to clipboard!'
                }))
            })
            .catch(err => {
                const el = document.createElement('textarea');
                el.value = _linkTxt;
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                dispatch(showNotification({
                    message: 'Successfully copied to clipboard!'
                }))

                console.log('error async setClipboard', err)
            })
    }

    const toggleFav = faved => e => {
        if (faved) {
            deleteUserPlace(_place.googlePlaceId)
                .subscribe({
                    next: res => {
                        const _ids = res.data.map(v => v.place.id)
                        dispatch(assignUserFavs(_ids))
                        dispatch(showNotification({ message: 'Successfully removed!' }))
                    },
                    error: err => {
                        dispatch(showNotification({ message: 'Oops! Something went wrong, please try again later.' }))
                    }
                })
        } else {
            postUserPlace(_place.googlePlaceId)
                .subscribe({
                    next: res => {
                        const _ids = res.data.map(v => v.place.id)
                        // when the location that is liked was not yet persisted in DB.place, GooglePlaceId is the only thing available to decide if location is in favs (after success fav, place id is subsequently avialable from API)
                        !_place.id && _ids.push(_place.googlePlaceId) // push google place id as well so UI can show updated fav status
                        dispatch(assignUserFavs(_ids))
                        dispatch(showNotification({ message: 'Successfully added!' }))
                    },
                    error: err => {
                        dispatch(showNotification({ message: 'Oops! Something went wrong, please try again later.' }))
                    }
                })
        }
    }

    return (
        <>
            <PlaceOpenStatus place={place}
                showLastUpdate={false}
                showOperational={false}
            />
            <Box className={classes.box}>
                <Typography
                    className={typographies.h4 + ' ' + classes.clamp}
                >
                    {_place.name}
                </Typography>
            </Box>
            <Box className={classes.box}>
                <Typography className={typographies.body2}>
                    <GoogleMapDirectionsLink
                        lat={_place.lat}
                        lng={_place.lng}
                        googlePlaceId={_place.googlePlaceId}
                        name={_place.name}
                    >
                        {_place.location}
                    </GoogleMapDirectionsLink>
                </Typography>
            </Box>
            {showLinks && (
                <Box className={classes.box}>

                    {_isfaved
                        ? (
                            <Tooltip title="Remove from your Favorites"
                                placement="left"
                            >
                                <IconButton onClick={toggleFav(_isfaved)} style={{ margin: 0 }}>
                                    <FavoriteIcon className={`${classes.favBtn} active`} />
                                </IconButton>
                            </Tooltip>
                        )
                        : (
                            <Tooltip title="Add to your Favorites"
                                placement="left"
                            >
                                <IconButton onClick={toggleFav(_isfaved)} style={{ margin: 0 }}>
                                    <FavoriteBorderIcon className={`${classes.favBtn}`} />
                                </IconButton>
                            </Tooltip>
                        )
                    }

                    {place.plan &&
                        <Tooltip title="Copy link to share with a colleague"
                            placement="left"
                        >
                            <IconButton onClick={copyBusinessProfileLink} style={{ margin: 0 }}>
                                <FileCopyOutlinedIcon
                                    className={`${classes.copybtn}`}
                                />
                            </IconButton>
                        </Tooltip>
                    }

                    {info && isBetaUser && <PrintDirectionsIconButton
                        hotelId={info.hotels[0].id}
                        place={_place}
                    />}
                </Box>
            )}
            {/* BUSINESS DESCRIPTION */}
            {place.plan && (
                <Box className={classes.box}>
                    <Typography
                        className={`${typographies.body2} ${classes.clamp}`}
                    >
                        <i>{place.description}</i>
                    </Typography>
                </Box>)
            }
        </>
    )
}