import { Box, Checkbox, Fab, FormControlLabel, IconButton, Input, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { PLANS_PLAIN_TEXT } from '../../../invitation/utils';
import { COLORS } from '../../../theme/colors';
import { UpgradeMembershipPlanDialogLink } from './dialogs/UpgradeMembershipPlanDialog';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import StoreIcon from '@material-ui/icons/Store';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { deleteBusinessImage, getBusinessPropertyVisibility } from '../../../http/tg/business';
import { toImageURL } from '../../../../lib/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import { showNotification } from '../../../notifications/state/actions';
import { dispatch } from 'slice';
import { deleteAdminBusinessImage } from '../../state/actions';
import { closeModal } from '../../../dialogs/standard/state/actions';
import { openConfirmationDialog } from '../../../dialogs/confirmation/states/actions';
import { isPremium } from '../../utils';

const useStyles = tgMakeStyles(theme => ({
    control: {
        '& .MuiButtonBase-root': {
            margin: 0,
            padding: theme.spacing(0.5)
        }
    },
    icons: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, max-content)',
        gridTemplateRows: 'repeat(2, max-content)'
    },
    deleteImageBtn: {
        position: 'absolute',
        top: 0, right: 0,
        margin: theme.spacing(1)
    },
    uploadImgBtn: {
        '&:hover': {
            boxShadow: theme.shadows[4],
            cursor: 'pointer'
        }
    }
}))

const useMuiIconBtnClasses = tgMakeStyles(theme => ({
    root: {
        border: `2px solid ${COLORS.get('grey')[3]}`,
        borderRadius: '.5rem',
        fontSize: '2rem'
    },
    checked: {
        borderColor: COLORS.get('gold').main
    }
}))

export function FirstImpressionSummary({business, ...props}){
    // console.log(business)
    const typo = useTypographyStyles()
    
    const _isPremium = isPremium(business)
    const [pendingPlan, setPendingPlan] = useState(false)
    useEffect( () => {
        const LSPlanIndex = Number(localStorage.getItem(`biz:up:${business.id}`))
        // pending plan is now active >> remove localStorage pendingplan record
        if(LSPlanIndex > 0 && PLANS_PLAIN_TEXT.indexOf(business.plan) >= LSPlanIndex){
            localStorage.removeItem(`biz:up:${business.id}`)
            setPendingPlan(false)
        }
        // pending plan is not yet activated
        if(LSPlanIndex > 0 && PLANS_PLAIN_TEXT.indexOf(business.plan) < LSPlanIndex){
            setPendingPlan(true)
        }
        return () => {}
    }, [])

    return (
        <Box {...props}>
            <Box my={2}>
                <Typography className={`${typo.body3}`}>Information Shared with Guests</Typography>
            </Box>
            <Box display="flex">
                <EditBusinessVisibilityControls 
                    {...{
                        isPremium: _isPremium,
                        pendingPlan,
                        business
                    }}
                />

                {pendingPlan && (
                    <Box ml={2} display="flex" alignItems="center">
                        <Typography 
                            className={`${typo.body2}`}
                            style={{color: COLORS.get('grey')[3]}}
                        >
                            Features from your upgrade are coming soon.
                        </Typography>
                    </Box>
                )}
            </Box>
            
            {_isPremium && <>
                <Box my={2}>
                    <Typography className={`${typo.body3}`}>Choose icon:</Typography>
                </Box>
                <EditBusinessMapIconControls business={business}/>
            </>
            }

            {_isPremium && <>
                <Box my={2}>
                    <Typography className={`${typo.body3}`}>Display cover photo:</Typography>
                </Box>
                <EditBusinessProfileCoverControl business={business}/>
            </>}
        </Box>
    )
}
export default FirstImpressionSummary

function EditBusinessVisibilityControls({
    isPremium,
    pendingPlan,
    business
}){
    const classes = useStyles()
    return <Box display="flex" flexDirection="column" pl={4}>
        <FormControlLabel
            style={{pointerEvents: 'none'}}
            className={`${classes.control}`}
            checked={true}
            value={true}
            control={
                <Checkbox
                    color="primary"
                    size="small"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                />
            }
            label={<span style={{color: COLORS.get('gold').main}}><b>Address (required)</b></span>}
        />
        <UpgradeMembershipPlanDialogLink 
            id="open-premium-upgrade-tease-dialog-website-checkbox"
            className="clickable"
            color="inherit"
            active={!isPremium}
        >
            <FormControlLabel
                name="visibility_website"
                label={<span style={{color: (!isPremium || pendingPlan) ? 'inherit' : COLORS.get('gold').main}}>
                    Website
                    </span>}
                disabled={pendingPlan}
                checked={isPremium ? business.visibility && business.visibility.website : pendingPlan}
                value={isPremium ? business.visibility && business.visibility.website : pendingPlan}
                className={`${classes.control}`}
                control={
                    <Checkbox
                        color="primary"
                        size="small"
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                    />
                }
            />
        </UpgradeMembershipPlanDialogLink>

        <UpgradeMembershipPlanDialogLink 
            id="open-premium-upgrade-tease-dialog-social-media-checkbox"
            className="clickable"
            color="inherit"
            active={!isPremium}
        >
            <FormControlLabel
                name="visibility_social"
                label={<span style={{color: (!isPremium || pendingPlan) ? 'inherit' : COLORS.get('gold').main}}>
                    Social Media
                    </span>}
                disabled={pendingPlan}
                checked={isPremium ? business.visibility && business.visibility.social : pendingPlan}
                value={isPremium ? business.visibility && business.visibility.social : pendingPlan}
                className={`${classes.control}`}
                control={
                    <Checkbox
                        color="primary"
                        size="small"
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                    />
                }
            />
        </UpgradeMembershipPlanDialogLink>

        <UpgradeMembershipPlanDialogLink 
            id="open-premium-upgrade-tease-dialog-hours-checkbox"
            className="clickable"
            color="inherit"
            active={!isPremium}
        >
            <FormControlLabel
                name="visibility_hours"
                label={<span style={{color: (!isPremium || pendingPlan) ? 'inherit' : COLORS.get('gold').main}}>
                    Hours
                    </span>}
                disabled={pendingPlan}
                checked={isPremium ? business.visibility && business.visibility.hours : pendingPlan}
                value={isPremium ? business.visibility && business.visibility.hours : pendingPlan}
                className={`${classes.control}`}
                control={
                    <Checkbox
                        color="primary"
                        size="small"
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                    />
                }
            />
        </UpgradeMembershipPlanDialogLink>
        
        <UpgradeMembershipPlanDialogLink 
            id="open-premium-upgrade-tease-dialog-about-checkbox"
            className="clickable"
            color="inherit"
            active={!isPremium}
        >
            <FormControlLabel
                name="visibility_about"
                label={<span style={{color: (!isPremium || pendingPlan) ? 'inherit' : COLORS.get('gold').main}}>
                    About
                    </span>}
                disabled={pendingPlan}
                checked={isPremium ? business.visibility && business.visibility.about : pendingPlan}
                value={isPremium ? business.visibility && business.visibility.about : pendingPlan}
                className={`${classes.control}`}
                control={
                    <Checkbox
                        color="primary"
                        size="small"
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                    />
                }
            />
        </UpgradeMembershipPlanDialogLink>
    </Box>
}

function EditBusinessMapIconControls({
    business
}){
    const classes = useStyles()
    const muiIconClasses = useMuiIconBtnClasses()
    return <Box className={`${classes.icons}`}>
        <Radio
            checked={!business.place.attributes.mapIcon || business.place.attributes.mapIcon === "default"}
            id="default-map_icon"
            name="map_icon"
            classes={muiIconClasses}
            checkedIcon={<RadioButtonUncheckedIcon color="primary"/>}
            icon={<RadioButtonUncheckedIcon color="disabled"/>}
            // checkedIcon={<LocationOnIcon color="primary"/>}
            // icon={<LocationOnIcon color="disabled"/>}
            value="default"
        />
        <Radio
            checked={business.place.attributes.mapIcon === "dining"} 
            id="dining-map_icon"
            name="map_icon"
            classes={muiIconClasses}
            checkedIcon={<LocalDiningIcon color="primary"/>}
            icon={<LocalDiningIcon color="disabled"/>}
            value="dining"
        />
        <Radio
            checked={business.place.attributes.mapIcon === "bar"} 
            id="bar-map_icon"
            name="map_icon"
            classes={muiIconClasses}
            checkedIcon={<LocalBarIcon color="primary"/>}
            icon={<LocalBarIcon color="disabled"/>}
            value="bar"
        />
        <Radio
            checked={business.place.attributes.mapIcon === "cafe"} 
            id="cafe-map_icon"
            name="map_icon"
            classes={muiIconClasses}
            checkedIcon={<LocalCafeIcon color="primary"/>}
            icon={<LocalCafeIcon color="disabled"/>}
            value="cafe"
        />
        <Radio
            checked={business.place.attributes.mapIcon === "store"} 
            id="store-map_icon"
            name="map_icon"
            classes={muiIconClasses}
            checkedIcon={<StoreIcon color="primary"/>}
            icon={<StoreIcon color="disabled"/>}
            value="store"
        />
        <Radio
            checked={business.place.attributes.mapIcon === "landmark"} 
            id="landmark-map_icon"
            name="map_icon"
            classes={muiIconClasses}
            checkedIcon={<AccountBalanceIcon color="primary"/>}
            icon={<AccountBalanceIcon color="disabled"/>}
            value="landmark"
        />
    </Box>
}

function EditBusinessProfileCoverControl({
    business
}){
    const classes = useStyles()
    const hasImage = !!(business.place.images && business.place.images[0])
    const _url = hasImage 
        ? business.place.images[0].url
        : null

    const deleteImage = id => e => {
        e.preventDefault()
        e.stopPropagation()
        let regex = new RegExp('^temp-*')
        const isTemp = regex.test(business.place.images[0].id)
        if(isTemp){
            dispatch(showNotification({message: 'Cover photo was deleted'}))
            dispatch(deleteAdminBusinessImage(business.id, id))
        } else {
            dispatch(openConfirmationDialog({
                title: 'ARE YOU SURE?',
                text: 'Click DELETE to permanently delete this image',
                answerOptions:[ 'Back', 'Delete'],
                onConfirm : ()=>{
                    dispatch(closeModal())
                    deleteBusinessImage(business.id, business.place.images[0])
                        .subscribe({
                            complete: res => {
                                dispatch(showNotification({message: 'Cover photo was deleted'}))
                                dispatch(deleteAdminBusinessImage(business.id, id))
                            },
                            error: err => {
                                // console.log(err)
                                dispatch(showNotification({message: 'Unable to delete Cover photo, please try again later.'}))
                            }
                        })
                },
                onDismissed : ()=>{
                    dispatch(closeModal())
                }
            }))
        }
    }

    return <Box>
        <Input
            id="edit-business-profile-cover"
            name="business_profile_cover"
            inputProps={{accept: ".png,.jpg,.jpeg"}}
            style={{
                position: 'absolute', overflow: 'hidden',
                height: 0, width: 0
            }}
            type="file"
        >
        </Input>
        <Box component="label" 
            htmlFor="edit-business-profile-cover"
            display="flex" justifyContent="center" alignItems="center"
            height="10rem" 
            m={2} 
            bgcolor={COLORS.get('grey')[2]} 
            borderRadius="1rem"
            style={{
                pointerEvents: hasImage ? 'none' : 'all',
                position: 'relative',
                backgroundImage: `url("${toImageURL(_url)}")`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                color: COLORS.get('grey')[3]
            }}
            className={`${classes.uploadImgBtn}`}
        >
            {!hasImage ? 'Click to upload image' : ''}
            {hasImage && <Fab
                color="inherit"
                className={`${classes.deleteImageBtn}`}
                onClick={deleteImage(business.place.images[0].id)}
                style={{pointerEvents: 'all'}}
            >
                <DeleteIcon />
            </Fab>}
        </Box>
        <Typography align="center">
            Format: .png, .jpeg, .jpg
        </Typography>
    </Box>
}