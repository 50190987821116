import React, { useEffect } from 'react'
import Mixpanel from '../../../../lib/mixpanel'
import ToUpdf from '../../../../assets/pdf/legal/TG Terms of Use.pdf'
import { PdfArticle } from './PdfArticle'

export function TermsOfServicesArticle(props) {

    useEffect(() => {
        Mixpanel('track')("impression_terms", props);
    }, [])

    return <PdfArticle file={ToUpdf} filename="TG_Terms_of_Use.pdf" />
}