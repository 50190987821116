import { Box, Collapse, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { DAYS, DAYS_SHORT } from '../../../place/admin/components/store-hours/metadata'
import { useTypographyStyles } from '../../../theme/useTypography'
import {formatDistanceToNow, getDay, max, parse} from 'date-fns'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// import {useStyles} from '../../../explore/components/place-profile/profileCss.classes'

const useStyles = tgMakeStyles((theme)=>{
    return {
        box : {
            marginBottom : ({isMobile,isMapDisplay})=>{
                const spaceNum = isMobile ? 1 : isMapDisplay ? 3 : 2;
                return theme.spacing(spaceNum)
            },
            textAlign: 'center'
        },
        asBtn: {
            '&:hover': {
                cursor: 'pointer'
            }
        }
    }
})

// dunb component used to display open periods for a given POI.
// periods must already be grouped by day when they are fed to the component via props
const DayStoreHours = ({periodsGroupedByDay, dayIndex}) => {
    const typo = useTypographyStyles()
    const typoClass = (dayIndex === 4 || dayIndex === 5 || dayIndex === 6)
        ? typo.body3
        : typo.body2
    return (
        <tr>
            <td style={{textAlign: 'right', verticalAlign: 'baseline', paddingRight: '1rem'}}>
                <Typography className={typoClass}>
                    {DAYS_SHORT[dayIndex]}
                </Typography>
            </td>
            <td style={{verticalAlign: 'baseline'}}>
                {periodsGroupedByDay && periodsGroupedByDay.length > 0 
                    ? periodsGroupedByDay.map((period, idx) => {
                            return (
                            <Typography key={idx} className={typoClass}>
                                {period.opening} - {period.closing}
                            </Typography>
                        )})
                    : (
                        <Typography className={typoClass} align="left">
                            <i>Closed</i>
                        </Typography>
                    )
                }
            </td>
        </tr>
    )
}
const TodayStoreHours = ({
    periodsGroupedByDay,
    expanded
}) => {
    const typo = useTypographyStyles()
    const typoClass = typo.body2
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            {/* <Box style={{textAlign: 'right', verticalAlign: 'baseline', paddingRight: '1rem'}}> */}
            <Box style={{paddingRight: '1rem'}} display="flex" alignItems="center">
                <Typography className={typoClass}>
                    <b>Today</b>
                </Typography>
                {expanded 
                    ? <ArrowDropUpIcon color="inherit"/>
                    : <ArrowDropDownIcon color="inherit"/>
                }
            </Box>
            <Box style={{verticalAlign: 'baseline'}}>
                {periodsGroupedByDay && periodsGroupedByDay.length > 0 
                    ? periodsGroupedByDay.map((period, idx) => {
                            return (
                            <Typography key={idx} className={typoClass}>
                                {period.opening} - {period.closing}
                            </Typography>
                        )})
                    : (
                        <Typography className={typoClass} align="left">
                            <i>Closed</i>
                        </Typography>
                    )
                }
            </Box>
        </Box>
    )
}

export function TodayOpeningHours({
    place,
    showLastUpdate=true
}){
    const [days, setDays] = useState([])
    const typo = useTypographyStyles()
    const _lastUpdateDate = max([
        parse(place.lastUpdate, 'dd.MM.yyyy HH:mm:ss', new Date(0)),
        parse(place.lastOpeningHoursUpdate, 'dd.MM.yyyy HH:mm:ss', new Date(0)),
    ])
    
    useEffect(() => {
        if(!place || !place.openingHours) return ()=>{}
        const storeHours = parsePeriods(place.openingHours)
        setDays(DAYS.map((d, i) => {
            return storeHours.filter(period => {
                return period.weekday.toLowerCase() === d
            })
        }))
    }, [place])
    
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [today, setToday] = useState([])
    useEffect(() => {
        const _sundayFirst = [days[6], days[0], days[1], days[2], days[3], days[4], days[5]]
        const _today = _sundayFirst[getDay(new Date())]
        setToday(_today)
    }, [days])

    return (
        <Box>
            <Box onClick={e => {setOpen(prev => !prev)}} 
                display="flex" justifyContent="center"
                className={classes.asBtn}
            >
                {today && <TodayStoreHours
                        expanded={open}
                        periodsGroupedByDay={today} 
                        dayIndex={getDay(new Date())}
                    />
                }
            </Box>
            <Collapse in={open}>
                <Box display="flex" justify="center" className={classes.box} mt={2}>
                    {days[0] && days[0][0] && !days[0][0].closing
                        ? (
                            <Typography 
                                key="idx" 
                                className={typo.body2} 
                                align="center"
                            >
                                Never closes
                            </Typography>
                        )
                        : days.length > 0
                            ? (
                                <table style={{margin: 'auto'}}>
                                    <tbody>
                                    {days.map((periods, idx) => {
                                        return (
                                            <DayStoreHours 
                                                key={idx} 
                                                periodsGroupedByDay={periods} 
                                                dayIndex={idx}
                                            />
                                        )})
                                    }
                                    </tbody>
                                </table>
                            )
                            : <Typography className={typo.body2} align="center">No Opening Hours details</Typography>
                    }
                </Box>
                {showLastUpdate && <Box>
                    <Typography 
                        align="center"
                        className={typo.body2}
                    >
                        Last Updated {formatDistanceToNow(_lastUpdateDate, {addSuffix: true})}
                    </Typography>
                </Box>}
            </Collapse>
        </Box>
    )
}

export default TodayOpeningHours

// utility functions to parse opening hours periods
function parsePeriods(periods){
    const _P = [...periods]
    const _periods = removeUselessPeriods(_P)
    // merge contiguous periods
    const _contiguous = mergeContiguousPeriods(_periods)
    return _contiguous
}
function removeUselessPeriods(periods){
    // because of a backend bug
    return periods.filter((p,i, ps) => {
        return p.opening !== p.closing
    })
}
function mergeContiguousPeriods(periods){
    const _periods = [...periods]
    
    const adjustedPeriods = _periods.reduce( (acc, period, i, ps) => {
        const cTime = period.closing
        const NXTDAY = nextDay(period.weekday)
        
        const _contiguousIdx = _periods.findIndex( nxtp => {
            let nextday = nxtp.weekday.toLowerCase()
            let nxtOpTime = nxtp.opening
            // find a contiguous period for the next day
            let isContiguous = nextday === NXTDAY && cTime === nxtOpTime
            
            return isContiguous
        })
        const _contiguousPeriod = _contiguousIdx === -1
            ? void 0
            : _periods.splice(_contiguousIdx, 1)[0]

        acc.push({
            ...period,
            closing: _contiguousPeriod 
                ? _contiguousPeriod.closing 
                : period.closing
        })
        return acc
    }, [])

    return adjustedPeriods
}

function nextDay(day){
    return DAYS[
        1+DAYS.findIndex(v => v.toLowerCase() === day.toLowerCase()) === DAYS.length
            ? 0
            : 1+DAYS.findIndex(v => v.toLowerCase() === day.toLowerCase())
    ]
}