import React, { useState } from 'react'
import { Box, Card, IconButton, Typography} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
// import EditUserDetailsForm from './EditUserDetailsForm';
// import { dispatch } from 'slice';
// import { openModal } from '../../dialogs/standard/state/actions';
import { useStateUserDetails } from '../state/hooks/useStateUserDetails';
import {useTypographyStyles} from '../../theme/useTypography'
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { UserDetailsForm } from './UserDetailsForm';

const useStyles = tgMakeStyles(theme => ({
    btn: {
        position: 'absolute',
        top: 0, right: 0
    }
}))

export function UserDetailsCard({userInfo, ...props}){
    
    const typo = useTypographyStyles()
    const classes = useStyles()
    
    const {info} = useStateUserDetails()

    const [edit, setEdit] = useState(false)
    
    return info && info.id
        ? !edit 
            ?(
                <Card component={Box} p={3} elevation={4} position="relative" {...props}>
                    <IconButton color="secondary" 
                        id="open-edit-user-form"
                        className={`${classes.btn} clickable`}
                        onClick={e => setEdit(true)}
                    >
                        <EditIcon></EditIcon>
                    </IconButton>
                    {/* admin name */}
                    <Typography className={`${typo.body3}`}>
                        <b>{info.name}</b>
                    </Typography>
                    {/* job title */}
                    <Typography className={`${typo.body2}`}>
                        {info.jobTitle}
                    </Typography>
                    {/* email address */}
                    <Typography className={`${typo.body2}`}>
                        {info.mail}
                    </Typography>
                    {/* password */}
                    <Typography className={`${typo.body2}`}>
                        Password *****
                    </Typography>
                </Card>
            )
            : (
                <Card component={Box} p={3} elevation={4} position="relative" {...props}>
                    <UserDetailsForm userInfo={info} onCancel={e => setEdit(false)}/>
                </Card>
            )
        : <div>Loading ...</div>
}

export default UserDetailsCard