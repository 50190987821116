import { TG_API } from '../';
import { from, of, throwError } from 'rxjs';
import {defaultLocaleCompare} from '../../../../lib/utils'

const BASE_URL = '/hotels'

const LDM_BASE_URL = 'ldm/hotel'

// GET 
// get hotel data from hotelId
export const getHotelById = hId => from(TG_API({
    method: 'GET',
    url: BASE_URL+'/'+hId
}))

export const getHotelMaps = (options = {}) => from(TG_API({
    method: "GET",
    url: LDM_BASE_URL+'/mapApi/',
    params: {
        returnPlaces: true,
        ...options,
        noDefault: true
    }
}))

export const getPublicHotelMapById = (mapId, hid, translate) => {
    const reqConfig = {
        method: 'GET',
        url: `${LDM_BASE_URL}/map`,
        params: {
            mapId: !!mapId ? mapId : void 0,
            hotelId: hid,
            translate: translate
        }
    }
    return from(TG_API(reqConfig))
}

export const getHotelMapById = (id, options = {}) => from(TG_API({
    method: "GET",
    url: `${LDM_BASE_URL}/mapApi/${id}`,
    params: {
        returnPlaces: true,
        ...options,
        noDefault: true
    }
}))
export const getHotelUnnamedMaps = (options = {}) => getHotelMaps({
    ...options,
    titleEmpty: true
})
export const getHotelNamedMaps = (options = {}) => getHotelMaps({
    ...options,
    titleOnly: true
})

export const postNewHotelMap = (options = {}) => {
    return from(TG_API({
        method: 'POST',
        url: LDM_BASE_URL+'/mapApi/',
        params: {
            noDefault: true
        },
        data: {
            title: options.title
        }
    }))
}

export const postDuplicateMap = (id) => from(TG_API({
    method: "POST",
    url: `${LDM_BASE_URL}/map/duplicate/${id}`
}))

export const deleteHotelMap = mapId => from(TG_API({
    method: "DELETE",
    url: `${LDM_BASE_URL}/mapApi/${mapId}`
}))

export const putHotelMap = ({
    id, 
    title, 
    ordered,
    alphabeticallyOrdered
}) => from(TG_API({
    method: 'PUT',
    url: `${LDM_BASE_URL}/mapApi/${id}`,
    data: {
        title, ordered, alphabeticallyOrdered
    }
}))

export const postHotelMapPlace = ({
    tip,
    order
}, {
    googlePlaceId,
    hotelId,
    hotelMapId
}) => {
    try {
        if (!hotelId || !googlePlaceId) {
            throw 'saveHotelDigimapCustomPOI: hotel and googlePlaceId must be provided'
        }
        
        return from(TG_API({
            method: 'POST',
            url: LDM_BASE_URL+'/placeApi',
            params: {
                hotelId,
                mapId: hotelMapId,
            },
            data: {
                googlePlaceId,
                tip,
                order
            }
        }))

    } catch (error) {
        return throwError(error)
    }
}

export const postUpdateHotelMapPlaces = (hotelMapId, hotelPlaces) => {
    if(!hotelMapId || !hotelPlaces) return throwError('map id and hotel places must be provided')
    const payload = hotelPlaces.map( (hp) => {
        return {
            id: hp.id,
            tip: hp.tip,
            order: hp.order
        }
    })
    return from(TG_API({
        method: 'POST',
        url: `${LDM_BASE_URL}/map/updateplaces/${hotelMapId}`,
        data: payload
    }))
}

export const deleteHotelMapPlace = (placeId, mapId) => {
    return from(TG_API({
        method: 'DELETE',
        url: `${LDM_BASE_URL}/placeApi/${placeId}`,
        params: {
            mapId: mapId
        }
    }))
}

export const getHotelToPlaceDirections = ({
    hotelId,
    language,
    mode,
    departure_time,
    hideElement,
    place,
    extraInfo,
    email
}) => {
    if(!hotelId || !place) return throwError('hotelId and place must be provided')
    const _params = {
        hotelId,
        language,
        mode,
        departure_time,
        hideElement,
        preview: true
    }
    const _data = {
        place,
        extraInfo
    }
    if(email) {
        _data.email = email.recipient
        _data.subject = email.subject
        _data.emailBody = email.body
    }
    return from(TG_API({
        method: 'POST',
        responseType: "blob",
        url: `${LDM_BASE_URL}/printout`,
        params: _params, 
        data: _data
    }))
}


// get hotel standard digital map POI data from hotel Id
export const getDefaultHotelMapById = (hotelId,lang) => from(
    TG_API.get(LDM_BASE_URL + '/mapApi/default',
        {
            params: {
                hotelId: hotelId,
                translate: lang
            }
        })
    .then((res)=>{
        /* 
        res.data includes metadata properties default, hotel, id, title and hotelPlaces 
        Currently callers only expect res.data to be the array of POIs
        */
        res.data = res.data.hotelPlaces.sort((a,b)=>defaultLocaleCompare(a.name,b.name))
        return res
    }
))

// get hotel Map Places array
export const getHotelMapPlacesArrayById = (hotelMapId,hotelId,lang) => from(TG_API({
    method: 'GET',
    url: LDM_BASE_URL + '/mapApi/' + hotelMapId,
    params : {
        hotelId: hotelId,
        translate : lang,
        noDefault: true
    }
}).then((res)=>{
    res.data = res.data.hotelPlaces.sort((a,b)=>defaultLocaleCompare(a.name,b.name))
    return res
}))

// /!\ Delete all custom POI associated with the given custom map ID.
// This effectively deletes a custom map altogether
// export const deleteCustomHotelDigimap = customMapId => from(TG_API({
//     method: 'DELETE',
//     url: LDM_BASE_URL+'/mapApi/'+customMapId
// }))

// POST
// Save a custom POI for the hotel ID and custom map ID provided in payload
// if no custom map id is provided, it will be created automatically (ie: a new custom map)
export const saveHotelDigimapCustomPOI = ({
    hotelId,
    mapId,
    tip,
    about,
    googlePlaceId
}) => {
    try {
        // console.log('saveHotelDigimapCustomPOI', customPOIPaylaod)
        if (!hotelId || !googlePlaceId) {
            throw 'saveHotelDigimapCustomPOI: hotel and googlePlaceId must be provided'
        }
        
        return from(TG_API({
            method: 'POST',
            url: LDM_BASE_URL+'/placeApi',
            params: {
                hotelId,
                mapId
            },
            data: {
                googlePlaceId,
                tip,
                about
            }
        }))

    } catch (error) {
        return throwError(error)
    }
}

// DELETE
// Delete custom POI from a custom POI ID
export const deleteHotelDigimapCustomPOI = ({
    placeId, 
    hotelId, 
    mapId
}) => from(TG_API({
    method: 'DELETE',
    url: LDM_BASE_URL+'/placeApi/'+placeId,
    params: {
        hotelId,
        mapId
    }
}))

// PUT 
// update a custom POI using custom POI ID and a payload (fields not specified in the payload are not reset to empty fields)
export const updateHotelDigimapCustomPOI = ({
    placeId, 
    hotelId, 
    mapId,
    about,
    tip
}) => {
    try {
        if(!placeId){return throwError('updateHotelDigimapCustomPOI: provided custom POI ID is invalid')}
        if(!(about || tip)){return throwError('updateHotelDigimapCustomPOI: payload cannot be empty')}
        
        return from(TG_API({
            method: 'PUT',
            url: LDM_BASE_URL+'/placeApi/'+placeId,
            params: {
                hotelId,
                mapId
            },
            data: {
                about,
                tip
            }
        }))   
    } catch (error) {
        return throwError(error)
    }
}
// updateHotelDigimapCustomPOI(2, {tip: 'a new tip for this custom POI', about: 'this a POI added by a developer to test a feature'})

export function getHotelTeamPlaces(hId){
    try {
        const reqConfig = {
            method: "GET",
            url: `${LDM_BASE_URL}/${hId}/userplaces`
        }
        return from(TG_API(reqConfig))
    } catch (error) {
        return throwError(error)
    }
}