import React from 'react'
import { Grid, Typography, Divider, Box, useTheme, Button/* , Dialog, Fade, DialogTitle */ } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditUserDetailsForm from './EditUserDetailsForm';
import { dispatch } from 'slice';
import { openModal } from '../../dialogs/standard/state/actions';

const UserPropertyDiv = ({propName, propValue, disabled}) => {
    const handleOpen = key => () => {
        dispatch(openModal({
            title: `Edit value for ${key}`,
            component: EditUserDetailsForm,
            props: {[key]: true}
        }))
    };
    
    function toFormattedFieldName(propName){
        switch (propName) {
            case 'hotel':
                return 'Hotel Team'
            case 'mail':
                return 'Email';
            case 'alias':
                return 'Alias';
            case 'password':
                return 'Password   *TasteGods does not store your passwords';
            case 'name':
                return 'Name';
            default:
                return propName;
        }
    }
    return (
        <Grid item
            xs={12} md={6}>
            <Typography variant="body2">
                {toFormattedFieldName(propName)}
            </Typography>
            <Grid container alignItems="center">
                <Grid item xs={9}>
                    <Typography variant="body1">
                        <b>{propValue}</b>
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button color="secondary" 
                        // variant="outlined"
                        onClick={handleOpen(propName)}
                        disabled={disabled}>
                        <EditIcon></EditIcon>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default function UserDetailsDiv({userInfo}) {
    // console.log('userInfo', userInfo)
    // TODO : first name, last name, email address, hotel, jobTitle
    const theme = useTheme();
    // these fields are simple text fields that can be edited via simple text input
    const _USER_TEXT_KEYS = [
        'name',
        'alias',
        'mail'
    ]
    
    // fields are ordered
    const _fields = Object.entries(userInfo).reduce( (acc, k, idx, arr) => {
        switch (k[0]) {
            case 'hotel':
                acc[0] = k;
                break;
            case 'name':
                acc[1] = k;
                break;
            case 'alias':
                acc[2] = k;
                break;
            case 'mail':
                acc[3] = k;
                break;
            case 'password':
                acc[4] = k;
                break;
            default:
                acc.push(k)
                break;
        }
        return acc;
    }, new Array(5))

    const _userSimpleTextFields = _fields.filter(
        ( [key] ) => _USER_TEXT_KEYS.indexOf(key) > -1
    )
    
    return (
        <Box style={{
                backgroundColor: theme.palette.common.white,
                padding: theme.spacing(2)
            }}>
            <Typography 
                variant="h4"
                style={{marginBottom: theme.spacing(4)}}
                color="primary"
            >
                Your Profile
            </Typography>
            <Grid 
                container
                spacing={2}
            >
                {userInfo.hotels && userInfo.hotels[0] &&
                    (<UserPropertyDiv propName={'hotel'} propValue={userInfo.hotels[0].name} disabled={true}/>)}
                <Divider />
                {_userSimpleTextFields && _userSimpleTextFields.length && _userSimpleTextFields.map(([key, value])=> value 
                    ?(
                        <UserPropertyDiv key={key} propName={key} propValue={value} />
                    )
                    : void 0)
                }
                <UserPropertyDiv propName={'password'} propValue={'********'} />
            </Grid>
        </Box>
    )
}

// '  dಠ_ಠb <(TasteGods does not store your passwords) '