import { Box, Button, Typography } from '@material-ui/core'
import { useRouter } from '@uirouter/react'
import React, { useEffect, useState } from 'react'
import { fromEvent } from 'rxjs'
import { distinct } from 'rxjs/operators'
import { dispatch } from 'slice'
import { showNotification } from '../../../../notifications/state/actions'
import { useTypographyStyles } from '../../../../theme/useTypography'
import { sendMembershipForm } from '../../../utils'
import useOBStyles from '../onboarding.classes'


export function DesktopCTASubmitForm({isVisible, ...props}){
    
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        let sub = fromEvent(document.querySelector('#content-scroll'), 'scroll')
            .pipe(distinct())
            .subscribe(e => {
                setVisible(isVisible())
            })

        return () => {sub.unsubscribe()}
    }, [])

    const {$state$, $stateParams} = props
    const router = useRouter()

    const typo = useTypographyStyles()
    const classes = useOBStyles()
    // const plansSref = useSref($state$.data.cta_next)
    const handleSubmitForm = e => {
        dispatch({type: 'BUSINESS/INVITE/FORM/SUBMIT/START'})
        sendMembershipForm($stateParams)
        .subscribe({
            next: () => {
                dispatch({type: 'BUSINESS/INVITE/FORM/SUBMIT/SUCCESS'})
                router.stateService.go('root.welcome.invited.tour.plans.business-details.review.success')
            },
            error: err => {
                dispatch({type: 'BUSINESS/INVITE/FORM/SUBMIT/ERROR'})
                dispatch(showNotification({message: 'Unable to submit your Details. Please try again later.'}))
            },
            complete: () => {
            }
        })
    }
    return (
        <Box
            className={`${classes.desktopCTA} ${visible ? 'on' : ''}`}
        >
            <Typography className={typo.subtitle6} align="center">
                {$state$.data.cta_txt}
            </Typography>
            <Button 
                id="invite-submit-new-membsership-form"
                onClick={handleSubmitForm}
                className={`${classes.btn} clickable`}
                color="primary" variant="contained"
            >
                {$state$.data.cta_btn}
            </Button>
        </Box>
    )
}

export default DesktopCTASubmitForm