import produce from 'immer';

export default function highlightedFeatureReducer(state, action){
    /* 
        NOTES : here we mutate instances of Feature instances. 
        It feels like a little dirty shortcut
    */
    // state is a Data.Feature instance
    switch (action.type) {
        case 'HIGHLIGHTED/FEATURE/ASSIGN':
            return produce(state, draft => {
                // if state, it is the previous Feature instance, 
                // need to remove highlight before assigning to new Feature coming from action.payload
                state && state.setProperty('highlighted', false)
                // action.payload is a google map Data.Feature instance
                const Feature = action.payload;
                Feature.setProperty('highlighted', true)
                return Feature
            })
        case 'HIGHLIGHTED/FEATURE/UNASSIGN':
            return produce(state, draft => {
                state && state.setProperty('highlighted', false)
                return null
            })
        default:
            return state;
    }
}