import React from 'react'
import { SvgIcon } from "@material-ui/core";

export function SortAZSvgIcon(props){
    return <SvgIcon overflow="visible"
        preserveAspectRatio="none" viewBox="0 0 24 24" width="28" height="28"
        {...props}
    >
        <g>
            <path 
                id="edit"
                d="M16.34,4.65c0.03-0.1,0.06-0.2,0.08-0.3V4.16V3.95l0,0c0,0,0,0.1,0,0.21l0.12,0.49l0.75,2.27h-1.71  L16.34,4.65z M10.84,17.65c0.01-0.18-0.13-0.32-0.31-0.33c-0.01,0-0.01,0-0.02,0h-2V3C8.52,2.82,8.38,2.68,8.2,2.67  c-0.01,0-0.01,0-0.02,0h-2C6.01,2.66,5.86,2.8,5.85,2.98c0,0.01,0,0.01,0,0.02v14.33h-2c-0.14-0.01-0.27,0.08-0.31,0.21  c-0.05,0.12-0.03,0.27,0.07,0.36l3.33,3.33c0.07,0.06,0.15,0.09,0.24,0.09c0.09,0,0.17-0.03,0.24-0.09l3.32-3.32  c0.06-0.07,0.1-0.16,0.1-0.25l0,0L10.84,17.65z M19.54,18.89h-1.25v1.24h-2.58H15.4h-0.15l0,0L15.36,20l0.22-0.27l3.84-5.52v-0.88  h-5.9v2.38h1.25v-1.2h2.42c0.1,0.01,0.21,0.01,0.31,0h0.15l0,0l-0.11,0.09c-0.08,0.09-0.16,0.18-0.22,0.28l-3.84,5.51v0.94h6.08  V18.9l0,0L19.54,18.89z M20.47,9.55h-0.72l-2.4-6.88h-1.69l-2.39,6.89h-0.73v1.1h3v-1.1h-0.79l0.49-1.5h2.53l0.49,1.5h-0.78v1.1h3  v-1.1l0,0L20.47,9.55z"
                vectorEffect="non-scaling-stroke" />
        </g>
    </SvgIcon>
}
export default SortAZSvgIcon