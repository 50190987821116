import React from 'react'
import  OpenModalLink  from '../../dialogs/standard/components/OpenStandardDialog.Link'
import { AddendumBusiness01AArticle } from './articles/AddendumBusiness01AArticle'


export function AddendumBusiness01ALink({trackingProps, ...forwardProps}) {
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle="Addendum - Business - 01A"
            modalComponent={AddendumBusiness01AArticle}
            modalComponentProps={trackingProps}>
                {forwardProps.children ? forwardProps.children : 'Business Membership Details'}
        </OpenModalLink>
    )
}
export default AddendumBusiness01AArticle
