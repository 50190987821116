import { useRouter } from '@uirouter/react'
import React, { useState } from 'react'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { Box, IconButton, Typography } from '@material-ui/core';
import { closeModal } from '../../dialogs/standard/state/actions';
import { dispatch } from 'slice';
import {useTypographyStyles} from '../../theme/useTypography'
import useStyles from './invitation.classes'
import { useMediaQuery } from '@material-ui/core'
import tracker from '../../../lib/mixpanel';

export function InvitationCodeForm(){

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const typo = useTypographyStyles()
    const classes = useStyles()
    const router = useRouter()
    const [state, setState] = useState({
        error: undefined
    })

    const handleFormSubmit = e => {
        e.preventDefault()
        let formData = new FormData(e.target)
        let _code = formData.get('code')
        // console.log(formData.get('code'))
        router.stateService.go('root.welcome.invited.tour', {code: _code})
            .then(s => dispatch(closeModal()))
            .catch(e => {
                setState( prev => ({...prev, error: e}) )
            })
    }

    const updateFormTracker = e => {
        tracker('form')('invitation_code_form', {[e.target.name]: e.target.value})
    }

    return <form
        id="invitation-code-form"
        onSubmit={handleFormSubmit}
        className={`${classes.form}`}
        onChange={updateFormTracker}
    >
        <Box>
            <Typography
                className={`${isDesktop ? typo.h2 : typo.subtitle6} ${classes.mb}`}
                align="center"
            >
                Kindly enter your invitation code
            </Typography>
            <div className={`${classes.inputcontainer} ${classes.mb}`}>
                <input
                    className={`${typo.subtitle6}`}
                    id="code" 
                    name="code" 
                    type="text"
                    type="text"
                    autoFocus
                />
                <IconButton 
                    id="submit-invitation-code-form"
                    className="clickable"
                    type="submit"
                >
                    <ArrowForwardRoundedIcon />
                </IconButton>
            </div>
            <Typography
                className={`${typo.body1}`} 
                align="center"
                style={{visibility: state.error ? 'initial' : 'hidden'}}
            >
                Incorrect Code: please re-enter or reach out to TasteGods if your code has expired
            </Typography>
        </Box>
    </form>
}

export default InvitationCodeForm