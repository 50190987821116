export function setAdminBusinessData(payload){
    return {
        type: 'BUSINESS/ADMIN/SET/BUSINESS/DATA',
        payload: payload
    }
}
export function setAdminBusinessFieldData(businessId, key, value){
    return {
        type: 'BUSINESS/ADMIN/SET/BUSINESS/FIELD/DATA',
        payload: {
            businessId,
            key,
            value
        }
    }
}

export function setAdminBusinessPlaceData(businessId, payload){
    return {
        type: 'BUSINESS/ADMIN/SET/PLACE/DATA',
        payload: {
            businessId: businessId,
            place: payload
        }
    }
}
export function setAdminBusinessPlaceFieldData(businessId, key, value){
    return {
        type: 'BUSINESS/ADMIN/SET/PLACE/FIELD/DATA',
        payload: {
            businessId: businessId,
            key,
            value
        }
    }
}
export function setAdminBusinessPlaceAttributeFieldData(businessId, key, value){
    return {
        type: 'BUSINESS/ADMIN/SET/PLACE/ATTRIBUTE/FIELD/DATA',
        payload: {
            businessId: businessId,
            key,
            value
        }
    }
}
export function setAdminBusinessFieldVisibility(businessId, key, value){
    return {
        type: 'BUSINESS/ADMIN/SET/BUSINESS/FIELD/VISIBILITY',
        payload: {
            businessId: businessId,
            key,
            value
        }
    }
}

export function setAdminBusinessProfileCover(businessId, file){
    return {
        type: 'BUSINESS/ADMIN/SET/COVER',
        payload: {
            businessId: businessId,
            file
        }
    }
}

export function deleteAdminBusinessImage(businessId, imgId){
    return {
        type: 'BUSINESS/ADMIN/DELETE/IMAGE',
        payload: {
            businessId: businessId,
            imageId: imgId
        }
    }
}