import React from 'react'
import  OpenModalLink  from '../../dialogs/standard/components/OpenStandardDialog.Link'
import { ImprintArticle } from './articles/ImprintArticle'

export function ImprintLink({trackingProps, ...forwardProps}) {
    return (
        <OpenModalLink
            {...forwardProps}
            modalTitle="Imprint" 
            modalComponent={ImprintArticle}
            modalComponentProps={trackingProps}
        >
                Imprint
        </OpenModalLink>
    )
}
