import { TG_API } from '../';
import { from } from 'rxjs';

// const BASE_URL = '/invitation';
const LDM_URL = '/ldm/invitationCode';

export const getInvitationData = ({code}) => {
    const reqConfig = {
        method: 'GET',
        url: LDM_URL+'/'+code
    }
    return from(TG_API(reqConfig))
}

export const postInvitationReply = ({code,text}) => {
    const reqConfig = {
        method: 'POST',
        url: LDM_URL+'/'+code
    }
    return from(TG_API(reqConfig))
}

export const postInvitationImage = ({code, file}) => {
    // const reqConfig = {
    //     method: 'POST',
    //     url: LDM_URL+'/'+code+'/upload',
    //     data: {
    //         file
    //     }
    // }
    // return from(TG_API(reqConfig))
    return Promise.resolve()
}