import {useEffect, useCallback} from 'react'
import { dispatch } from 'slice'

/**
 * Generates toolbar API ready to be used by components
 * toolsId is used internally by the returned API to target only relevant tools. Allows separate components to not affect each others tools
 * toolsId, if provided, can also be used to do the opposite. ie: have components affect each others tools if they all call useToolBar with the same toolsid
 * NOTE : 
 * @param {String} toolsId (optional) a string used as namespace for the tools handled by the returned API. If not provided, useToolBar will generate a random one for you
 * @returns {Object} the object properties are all functions scoped using toolsId {
 *      setPrimary: function,
 *      addPrimary: function,
 *      removePrimary: function,
 *      setSecondary: function,
 *      addSecondary: function,
 *      removeSecondary: function,
 *      clear: function
 * }
 */
export const useToolBar = (toolsId) => {
    // toolsId is used to target the right tool buttons
    // it gets hard coded in the returned functions so dev doesn't have to keep track of it
    const _toolsId = toolsId || getRandomText(5)
    // dispatch action with hardcoded toolsId
    const _dispatchToolBarAction = (type, tools) => {
        tools
            && dispatch({
            type: type,
            payload: {
                tools,
                toolsId: _toolsId
            }
        })
    }
    const setP = useCallback(primary => {
        primary
            && _dispatchToolBarAction(
                    'TOOLBAR/SET/PRIMARY',
                    primary
                )
    }, [toolsId])
    const setS = useCallback(secondary => {
        secondary 
            && _dispatchToolBarAction(
                    'TOOLBAR/SET/SECONDARY',
                    secondary
                )
    }, [toolsId])
    const addP = useCallback(primary => {
        primary
            && _dispatchToolBarAction(
                    'TOOLBAR/ADD/PRIMARY',
                    primary
                )
    }, [toolsId])
    const addS = useCallback(secondary => {
        secondary 
            && _dispatchToolBarAction(
                    'TOOLBAR/ADD/SECONDARY',
                    secondary
                )
    }, [toolsId])
    const rmP = useCallback(indexes => {
        if(indexes || indexes === 0){
            _dispatchToolBarAction(
                'TOOLBAR/REMOVE/PRIMARY',
                indexes
            )
        }
    }, [toolsId])
    const rmS = useCallback(indexes => {
        if(indexes || indexes === 0){
            _dispatchToolBarAction(
                'TOOLBAR/REMOVE/SECONDARY',
                indexes
            )   
        }
    }, [toolsId])
    const clear = useCallback((which) => {
        if([1, 2].includes(which)){
            switch (which) {
                case 1:
                    dispatch({type: 'TOOLBAR/CLEAR/PRIMARY'})
                    break;
                case 2:
                    dispatch({type: 'TOOLBAR/CLEAR/SECONDARY'})
                    break;
                default:
                    break;
            }
        } else if(!which){
            dispatch({type: 'TOOLBAR/CLEAR/SECONDARY'})
            dispatch({type: 'TOOLBAR/CLEAR/PRIMARY'})
        }
    }, [toolsId])
    return {
        setPrimary: setP,
        addPrimary: addP,
        removePrimary: rmP,
        setSecondary: setS,
        addSecondary: addS,
        removeSecondary: rmS,
        clear: clear
    }
}

function getRandomText(length=15){
    return Math.random().toString(36).substring(2, length) + Math.random().toString(36).substring(2, length);
}