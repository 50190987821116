import React from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, SvgIcon } from '@material-ui/core'
import { useSrefActive } from '@uirouter/react'
import HomeIcon from '@material-ui/icons/Home';
import { useTheme } from '@material-ui/styles';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { useTypographyStyles } from '../../../theme/useTypography';
import { TasteGodsCreditsLink } from '../TasteGodsCreditsLink';
import SettingsIcon from '@material-ui/icons/Settings';
import EditSvgIcon from '../svgIcons/EditIcon.SvgIcon';

const useNavBarStyles = tgMakeStyles(theme => ({
    ListItemRoot: {
        '&': {
            margin: 0,
            marginTop: theme.spacing(3),
            padding: `0px ${theme.spacing(2)}`,
            color: theme.palette.greyscale.grey_3,
        },

        '&.Mui-selected': {
            backgroundColor: 'initial',
            color: theme.palette.primary.main
        },

        '&.Mui-selected::before': {
            content: '""',
            position: 'absolute',
            left: 0, top: 0,
            height: '100%',
            width: '5px',
            backgroundColor: theme.palette.primary.main
        }
    },
    ListItemIconRoot: {
        color: 'inherit'
    },
    ListItemTextRoot: {
        color: 'inherit'
    }
}))

export function DesktopNavBar(props) {
    
    const typography = useTypographyStyles()
    const {BoxProps} = props
    const _classes = useNavBarStyles()
    const theme = useTheme()
    const srefHomeActive = useSrefActive('root.business-console.dashboard', {}, 'Mui-selected')
    const srefExploreActive = useSrefActive('root.business-console.profile', {}, 'Mui-selected')
    const srefSettingsActive = useSrefActive('root.business-console.settings', {}, 'Mui-selected')

    return (
        <Box p={0} m={0} {...BoxProps}
            height='100%'
            display='flex'
            justifyContent='center'
            alignItems='stretch'
            flexDirection='column'
            borderRight={`1px solid ${theme.palette.grey[200]}`}
        >
            <Box flexGrow='1'>
                <List component='nav' disablePadding>
                    {/* Navigation Links */}
                    <ListItem {...srefHomeActive} 
                        button
                        classes={{root: _classes.ListItemRoot}}
                    >
                        <ListItemIcon classes={{root: _classes.ListItemIconRoot}}>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary='Home'
                            classes={{root: _classes.ListItemTextRoot}}
                            className={typography.h6}
                        />
                    </ListItem>
                    <ListItem {...srefExploreActive} 
                        button
                        classes={{root: _classes.ListItemRoot}}
                    >
                        <ListItemIcon classes={{root: _classes.ListItemIconRoot}}>
                            <EditSvgIcon />
                        </ListItemIcon>
                        <ListItemText primary='My Profile'
                            classes={{root: _classes.ListItemTextRoot}}
                            className={typography.h6}
                        />
                    </ListItem>
                    <ListItem {...srefSettingsActive} 
                        button
                        classes={{root: _classes.ListItemRoot}}
                    >
                        <ListItemIcon classes={{root: _classes.ListItemIconRoot}}>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary='Settings'
                            classes={{root: _classes.ListItemTextRoot}}
                            className={typography.h6}
                        />
                    </ListItem>
                </List>
            </Box>
            {/* TasteGods credit */}
            <TasteGodsCreditsLink 
                BoxProps={{
                    pb: 1,
                    component: 'footer',
                    flex: 'none',
                    flexDirection: 'column'
                }}
            />
        </Box>
    )
}
export default DesktopNavBar