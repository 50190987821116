import { Box, ClickAwayListener, Collapse, IconButton, List, TextField, Typography, useMediaQuery } from '@material-ui/core'
import { useRouter } from '@uirouter/react'
import React, { useEffect, useState } from 'react'
import { dispatch } from '../../lib/slice'
import { tgMakeStyles } from '../../lib/styles/TgMakeStyles'
import { setContextDrawer } from '../contextDrawer/state/actions'
import { InsideCircleProfile, OutsideCircleProfile } from '../explore/components/place-profile'
import { useTypographyStyles } from '../theme/useTypography'
import CollectionItemListItem from './CollectionItem.ListItem'
import { useStateRecentlyViewed } from './state/useStateRecentlyViewed'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SortAZSvgIcon from './SortAZ.SvgIcon'
import SortZASvgIcon from './SortZA.SvgIcon'
import { COLORS } from '../theme/colors'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { getPlace } from '../http/tg/ldm-place'

const useStyles = tgMakeStyles(theme => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: isDesktop => isDesktop ? `50%` : '1fr',
        gridTemplateRows: 'max-content',
        alignItems: 'stretch',
        gridAutoFlow: 'row',
        gridGap: theme.spacing(2)
    },
    inputRoot: {
        paddingRight: 0
    },
    inputElem: {
        // these dimensions are hardcoded to match the height specs as well as ensuring the popover sticks to the bottom of the input element
        // using rem here. GOAL is >> input content height = search button height
        height: '1.1875rem', // '19px' = 1.1875rem
        paddingTop: '0.90625rem', // '14.5px' = 0.90625rem
        paddingBottom: '0.90625rem'
    },
    searchButton: {
        margin: 0,
        backgroundColor: COLORS.get('gold').main,
        borderRadius: 0,
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        color: 'white',
        '&:hover': {
            backgroundColor: COLORS.get('gold').dark
        }
    }
}))

export function Collection(props) {
    const {collection, $stateParams} = props
    const [_collection, setCollection] = useState(collection)
    
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const typo = useTypographyStyles()
    const classes = useStyles(isDesktop)
    
    const {touch} = useStateRecentlyViewed()
    const selectPlace = (poi) => e => {
        getPlace(poi.place.googlePlaceId).subscribe({
            next: res => {
                const poi = res.data
                const drawerProps = {
                    component : () => poi.plan ? <InsideCircleProfile place={poi}/> : <OutsideCircleProfile place={poi}/>,
                    onCloseFn: ()=>{},
                    fullScreen: true
                }
                touch(poi)
                dispatch(setContextDrawer(drawerProps))
            },
            error: err => console.log(err)
        })
    }
    const router = useRouter()
    const backToPreviousState = e => {
        router.stateService.go('^')
    }
    
    const [searchOpen, setSearchOpen] = useState(false)
    const [azSorted, setAzSorted] = useState(true)
    
    const [qTerm, setQTerm] = useState('')
    const filterByTerm = e => {
        setQTerm(e.target.value)
    }
    useEffect( () => {
        if(!qTerm) {
            setCollection(azSorted ? collection : [...collection.reverse()])
        } else {
            const term = RegExp(qTerm, 'i')
            setCollection(prev => prev.filter( i => {
                return i.place.name.match(term) || i.place.location.match(term)
            }))
        }
        return () => {}
    }, [qTerm])

    const getCollectionName = id => id === 'new'
                                        ? 'New to Tastegods'
                                    : id === 'own'
                                        ? 'My Favorites'
                                    : id === 'team'
                                        ? `My Team's Favorites`
                                        : 'Unknown'

    return (
    <Box>
        <Box>
            <IconButton onClick={backToPreviousState}>
                <ArrowBackIcon/>
            </IconButton>
        </Box>
        {collection.length > 0
            ? (
            <Box mx={2}>
                <Box mb={3}
                    className={`${classes.grid}`}
                >
                     <Box display="flex" alignItems="center" flexWrap="wrap">
                        <Typography
                            className={`${typo.subtitle1}`}
                        >
                            {getCollectionName($stateParams.id)}
                        </Typography>
                        <Box flexGrow="1"></Box>
                        {$stateParams.id !== 'new' && <Box className="search-btn">
                            <IconButton
                                onClick={e => {
                                    e.stopPropagation()
                                    setSearchOpen(true)
                                }}
                                style={{margin: 0}}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Box>}
                        {$stateParams.id !== 'new' && <Box>
                            <IconButton
                                onClick={e => {
                                  setCollection(prev => [...prev.reverse()])
                                  setAzSorted(prev => !prev)
                                }}
                                style={{margin: 0}}
                            >
                                {azSorted
                                    ? (<SortAZSvgIcon />)
                                    : (<SortZASvgIcon />)
                                }
                            </IconButton>
                        </Box>}
                        <Box flexBasis="100%" flexShrink="0" flexGrow="1">
                            <Collapse
                                in={searchOpen} 
                                timeout={{ enter: 200, exit: 200 }}
                            >
                                <ClickAwayListener 
                                    mouseEvent="onMouseDown"
                                    touchEvent="onTouchStart"
                                    onClickAway={e => {
                                        e.stopPropagation()
                                        setSearchOpen(false)
                                    }}
                                >
                                    <Box>
                                        <TextField
                                            type="text"
                                            value={qTerm}
                                            variant="outlined"
                                            placeholder={`search within "${getCollectionName($stateParams.id)}"`}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    adornedEnd: classes.inputRoot,
                                                    input: classes.inputElem
                                                },
                                                endAdornment: (
                                                    <Box display="flex">
                                                        {qTerm && (
                                                            <IconButton style={{margin: 0}} onClick={e=>setQTerm('')}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )}
                                                        <IconButton
                                                            classes={{
                                                                root: classes.searchButton
                                                            }}
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </Box>
                                                )
                                            }}
                                            onChange={filterByTerm}
                                        />
                                    </Box>
                                </ClickAwayListener>
                            </Collapse>
                        </Box>
                    </Box>
                </Box>
                <Box mb={2}>
                    <Typography className={`${typo.body1}`}>
                        {`${_collection.length} business${_collection.length > 1 ? 'es' : ''} ${qTerm ? 'found' : 'added'}`}
                    </Typography>
                </Box>
                {/* COLLECTIONS LIST */}
                <List
                    component="nav"
                >
                    <Box mb={3} 
                        className={`${classes.grid}`}
                    >
                        {_collection.map((v, idx) => (
                            <CollectionItemListItem
                                key={idx}
                                poi={v}
                                onSelect={selectPlace(v)}
                            />
                        ))}
                    </Box>
                </List>
                
            </Box>
        )
        : (
            <Box mx={2}>
                <Box mb={3}>
                    <Typography
                        className={`${typo.subtitle1}`}
                    >
                        {getCollectionName($stateParams.id)}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                >
                    <Box width="15rem">
                        <Typography align="center"
                            className={`${typo.body2}`}
                            style={{lineHeight: '2rem'}}
                        >
                            <span>No businesses in your portfolio yet! Click</span><FavoriteBorderIcon style={{margin: '0 1rem 0 1rem', transform: 'translateY(25%)'}} /> <span> on any business profile to add it here for quick & easy access.</span>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )}
    </Box>
    )
}

export default Collection