import { Box, Button, TextField, Typography } from '@material-ui/core'
import { useCurrentStateAndParams } from '@uirouter/react'
import React, { useState } from 'react'
import { dispatch } from 'slice'
import tracker from '../../../../lib/mixpanel'
import { closeModal } from '../../../dialogs/standard/state/actions'
import { sendEmail } from '../../../http/tg/email'
import { showNotification } from '../../../notifications/state/actions'
import useObStyles from './onboarding.classes'

export function RecommendTasteGodsForm(){

    const {params} = useCurrentStateAndParams()
    const obClasses = useObStyles()
    const [alreadyRecommended, setAlreadyRecommended] = useState(localStorage.getItem(`inv:${params.code}`))

    const handleSubmitRecommendation = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const payload = {
            business_name: formData.get('business_name'),
            contact_name: formData.get('contact_name'),
            contact_email: formData.get('contact_email')
        }
        // console.log(payload)
        sendEmail({
            subject: `RECOMMEND TASTEGODS : new request`,
            emailBody: [
                `RECOMMENDED BY "${params.business_name}":\n`,
                ...Object.entries(payload).map(([key, value]) => `${key} : ${value}`),
                `date: ${(new Date()).toLocaleString()}`
            ].join('\n')
        })
        .subscribe({
            next: () => {
                setAlreadyRecommended(true)
                dispatch(closeModal())
                dispatch(showNotification({message: 'Thank you for recommending TasteGods!'}))
                localStorage.setItem(`inv:${params.code}`, 1)
            },
            error: err => {
                dispatch(closeModal())
                dispatch(showNotification({message: 'Unable to send recommendation, please try again later'}))
            }
        })
    }

    const updateFormTracker = e => {
        tracker('form')('invited_recommend_another_business_form', {[e.target.name]: e.target.value})
    }
    return !alreadyRecommended
        ? (
            <form 
                id="invited-recommend-another-business-form"
                onSubmit={handleSubmitRecommendation}
                onChange={updateFormTracker}
            >
                
                <TextField 
                    className={`${obClasses.mbXS} ${obClasses.mtL}`}
                    id="recommend_tg_business_name"
                    name="business_name"
                    variant="outlined"
                    fullWidth
                    type="text"
                    label='Business Name'
                    required
                />
                <TextField 
                    className={obClasses.mXS}
                    id="recommend_tg_contact_name"
                    name="contact_name"
                    variant="outlined"
                    fullWidth
                    type="text"
                    label='Contact Name'
                    required
                />
                <TextField 
                    className={`${obClasses.mtXS} ${obClasses.mbL}`}
                    id="recommend_tg_contact_email"
                    name="contact_email"
                    variant="outlined"
                    fullWidth
                    type="email"
                    label='Contact Email'
                    required
                />
                <Box display="flex" justifyContent="flex-end"
                >
                    <Button
                        id="invite-recommendation-form-submit"
                        variant="contained"
                        type="submit"
                        color="primary"
                        className={`${obClasses.btn} clickable`}
                    >
                        SUBMIT
                    </Button>
                </Box>
            </form>
        )
        : <>
            <Typography variant="h6" color="primary" align="center" className={obClasses.mL}>
                THANK YOU <br/><br/> To tailor the experience of new hotel & business members, we currently have a waitlist. We will review your recommendation shortly!
            </Typography>
        </>
}