import produce from 'immer';
import cookies from 'cookies-js';
import pkgJson from '../../../../../package.json'
import { hasAgreedDocument } from '../../../legal';

// Add hotel users routes here 
// this covers the welcome page and other pages NOT under hotel.tg.com
export const TG_CONSENT_KEY = 'consent:tg'
export const TG_GUEST_CONSENT_KEY = 'consent:guest.tg'

// TODO : use same pattern for Ng TG >> export const HOTEL_TG_CONSENT_KEY = 'consent:hotel.tg'
export const INITIAL_STATE = {
    consent: {
        // 0 = false, 1 = true
        [TG_CONSENT_KEY]: isAllAgreementsValid() ? 1 : 0,
        [TG_GUEST_CONSENT_KEY]: isAllAgreementsValid() ? 1 : 0
    }
}

export function cookiesRootReducer(state, action){
    return produce(state, draft => {
        draft['consent'] = cookiesAgreementReducer(state['consent'], action)
    })
}

// currently hardcoded TG_CONSENT_KEY in reducer, 
// later on if we have multiple policies to agree to, the key should come from action.payload
function cookiesAgreementReducer(state, action){
    switch (action.type) {
        case 'COOKIES/CONSENT/AGREE':
            return produce(state, draft => {
                draft[action.payload.cookieType] = 1
                // cookies.set(action.payload.cookieType, '1')
                // also keep track of individual document agreement when consenting to cookies disclaimer 
                const _storage = action.payload.cookieType === TG_GUEST_CONSENT_KEY // guests only store consent per session
                    ? sessionStorage
                    : localStorage
                _storage.setItem('agree:Terms of Use (ToU)', [...pkgJson.version.split('.').map(str => Number(str)), Date.now()])
                _storage.setItem('agree:Privacy Policy (PP)', [...pkgJson.version.split('.').map(str => Number(str)), Date.now()])
            })
        case 'COOKIES/CONSENT/DISAGREE':
            return produce(state, draft => {
                draft[action.payload.cookieType] = 0
                // cookies.set(action.payload.cookieType, '0')
                const _storage = action.payload.cookieType === TG_GUEST_CONSENT_KEY
                    ? sessionStorage
                    : localStorage
                _storage.removeItem('agree:Terms of Use (ToU)')
                _storage.removeItem('agree:Privacy Policy (PP)')
            })
        default:
            return state;
    }
}

function isAllAgreementsValid(){
    const ToUValid = hasAgreedDocument('Terms of Use (ToU)')
    const PPValid = hasAgreedDocument('Privacy Policy (PP)')
    return ToUValid && PPValid
}