import { $lice } from "slice";
import { useEffect } from "react";
import { INITIAL_STATE } from '../state/reducers';
import { rootReducer as digitalMapRootReducer } from '../state/reducers/index'
import { makeDataListener } from '../../../../lib/google-map/utils'

export function useGeoJsonListeners(options){
    // custom hook requires options.map to attach layer to the right map
    // and options.listeners where each object key is an event name and value is the listener to add
    // ie: options = {map, {click: console.log}}
    const { map, listeners={} } = options
    // sync up with digital map slice
    const [dMap$] = $lice('digitalmap', digitalMapRootReducer, INITIAL_STATE)
    
    useEffect(() => {
        // for each listener, generate a function that registers the listener given a map and array of fiels
        const _listeners = Object.entries(listeners)
            .map(([event, listener]) => {
                return makeDataListener(event, listener)
            })

        const subs = [
            dMap$.path('overlays.geojson').subscribe({
                next: DataOverlays => {
                    _listeners.forEach(listener => {
                        // do register listener with new files value
                        listener(map, DataOverlays)
                    })
                },
                error: console.log.bind(0, 'an error occured in overlays.geojson subscription')
            })
        ]
        // cleanup
        return x => {
            // cleanup subscription
            subs.forEach(s => s.unsubscribe())
        }
    }, [dMap$, map, listeners])
}