import { Box, Button, Card, Grid, IconButton, Typography } from '@material-ui/core'
import React, { useRef } from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useRouter, useSref } from '@uirouter/react'
import { PLANS, sendMembershipForm } from '../../../utils'
import useOBClasses from '../onboarding.classes'
import useRClasses from './detailsReview.classes'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { dispatch } from 'slice'
import { showNotification } from '../../../../notifications/state/actions'
import { COLORS } from '../../../../theme/colors'
import { isInViewport } from '../../../../../lib/utils'
import DesktopCTASubmitForm from './CTASubmitForm.Box'
import halo from '../../../../../assets/png/halo.png'

export function MembershipPlanReview(props) {
    // console.log('MembershipPlanReview', props)
    const { $stateParams } = props
    const router = useRouter()

    const stepHeaderRef = useRef()

    // const code = $stateParams.code
    const selected_plan = $stateParams.selected_plan
    const business_name = $stateParams.business_name
    const business_address = $stateParams.business_address
    const primary_phone_number = $stateParams.primary_phone_number
    const primary_email = $stateParams.primary_email
    const business_website = $stateParams.business_website
    const google_profile_link = $stateParams.google_profile_link
    const admin_full_name = $stateParams.admin_full_name
    const admin_email = $stateParams.admin_email
    const billing_name = $stateParams.billing_name
    const billing_address = $stateParams.billing_address
    const vat_id = $stateParams.vat_id

    const previousSref = useSref('^')
    const typo = useTypographyStyles()
    const obClasses = useOBClasses()
    const rClasses = useRClasses()

    const handleSubmitForm = e => {
        dispatch({type: 'BUSINESS/INVITE/FORM/SUBMIT/START'})
        sendMembershipForm($stateParams)
        .subscribe({
            next: () => {
                dispatch({type: 'BUSINESS/INVITE/FORM/SUBMIT/SUCCESS'})
                router.stateService.go('root.welcome.invited.tour.plans.business-details.review.success')
            },
            error: err => {
                dispatch({type: 'BUSINESS/INVITE/FORM/SUBMIT/ERROR'})
                dispatch(showNotification({message: 'Unable to submit your Details. Please try again later.'}))
            }
        })
    }

    return (
        <>
            <Box>
                <img src={halo} className={rClasses.halo2}/>
                <Box 
                    ref={stepHeaderRef}
                    className={`${obClasses.stepHeading}`}
                >
                    <IconButton 
                        id="invite-header-back-to-business-details-form"
                        {...previousSref} 
                        className="backBtn clickable"
                    >
                        <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
                    </IconButton>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        STEP 1: TOUR THE TASTEGODS CIRCLE
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        {`>>`}
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        STEP 2: SELECT YOUR MEMBERSHIP
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        {`>>`}
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        STEP 3: COMPLETE YOUR BUSINESS DETAILS
                    </Typography>
                    <Button 
                        id="invite-header-submit-new-membsership-form`"
                        variant="contained"
                        color="primary"
                        className={`${obClasses.btn} clickable`}
                        onClick={handleSubmitForm}
                    >
                        SUBMIT
                    </Button>
                </Box>
                <Box
                    className={`${obClasses.container}`}
                    pb={0}
                >
                    <Card
                        component={Box}
                        elevation={4}
                        p={2}
                        mt={2}
                        style={{position: 'relative'}}
                    >
                        <img src={halo} className={rClasses.halo1}/>
                        <Grid container spacing={4} alignItems="stretch">
                            <Grid item md={4}>
                                <Box 
                                    display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                                    height="100%"
                                    color="#292952"
                                    mb={6}
                                >
                                    <Typography className={`${typo.h1} ${obClasses.mbM}`}>PLEASE</Typography>
                                    <Typography className={typo.h0}>REVIEW</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                <Card
                                    component={Box}
                                    elevation={4}
                                    className={`${rClasses.card}`}
                                >
                                    <Typography
                                        className={`${rClasses.title} ${obClasses.mbXS}`}
                                    >
                                        YOUR MEMBERSHIP
                                    </Typography>

                                    <Typography>{PLANS[0].name}</Typography>
                                    {selected_plan > 0 && <Typography>{PLANS[selected_plan].name}</Typography>}

                                    <Typography
                                        className={`${rClasses.title} ${obClasses.mtL} ${obClasses.mbXS}`}
                                    >
                                        BILLING SUMMARY
                                    </Typography>
                                    <Box className={`${rClasses.billsummary}`}>
                                        <Typography>Basic Account</Typography>
                                        <Typography className="right">0€</Typography>
                                        {selected_plan > 0 && (
                                            <React.Fragment>
                                                <Typography>Upgrade</Typography>
                                                <Typography className="right">{`${PLANS[selected_plan].price}/month`}</Typography>
                                            </React.Fragment>
                                        )}
                                        <Typography>Setup Fee</Typography>
                                        <Typography className="right">0€</Typography>
                                    </Box>

                                    <Typography
                                        className={`${rClasses.title} ${obClasses.mtL} ${obClasses.mbXS}`}
                                    >
                                        BILLING DETAILS
                                    </Typography>
                                    <Typography>{billing_name}</Typography>
                                    <Typography>{billing_address}</Typography>
                                    {vat_id && <Typography>{vat_id}</Typography>}
                                    
                                    {!!$stateParams.selected_plan && (
                                        <React.Fragment>
                            
                                            <Typography
                                                color="primary"
                                                align="center"
                                                className={`${obClasses.mtL} ${typo.subtitle4}`}
                                            >
                                                An invoice will be sent after membership submission
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </Card>
                            </Grid>
                            <Grid item md={4}>
                                <Card
                                    component={Box}
                                    elevation={4}
                                    className={`${rClasses.card}`}
                                >
                                    <Typography
                                        className={`${rClasses.title} ${obClasses.mbXS}`}
                                    >
                                        YOUR BUSINESS IN THE CIRCLE
                                    </Typography>

                                    <Typography>{business_name}</Typography>
                                    <Typography>{business_address}</Typography>
                                    <Typography>{primary_email}</Typography>
                                    <Typography>{primary_phone_number}</Typography>
                                    <Typography>{business_website}</Typography>

                                    <Typography
                                        className={`${obClasses.mL} ${rClasses.title}`}
                                    >
                                        <span className={`valign`}>Google Business Profile</span> {
                                            google_profile_link 
                                                ? <CheckCircleIcon className={`valign ok`}/>
                                                : <ErrorOutlineIcon className={`valign warning`}/>
                                            }
                                    </Typography>

                                    <Typography
                                        className={`${rClasses.title} ${obClasses.mtL} ${obClasses.mbXS}`}
                                    >
                                        YOUR ADMIN ACCESS
                                    </Typography>
                                    <Typography>{admin_full_name}</Typography>
                                    <Typography>{admin_email}</Typography>
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
            </Box>
            {/* CTA appears at bottom */}
            <DesktopCTASubmitForm {...props} 
                isVisible={()=>{
                    return !isInViewport(stepHeaderRef.current)
                }}
            />
        </>
    )
}

export default MembershipPlanReview