import React from 'react'
import { useStateToolBar } from '../state/hooks/useStateToolBar'
import { Box, Divider } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';

const useStyles = tgMakeStyles(theme => ({
    noShadow: {
        "& *": {
            boxShadow: 'none'
        }
    }
}))

export default function DesktopToolBarBox() {
    const classes = useStyles()
    const theme = useTheme()
    const {primary, secondary} = useStateToolBar()
    const isToolBarEmpty = Boolean(
        (!primary && !secondary)
        || (!primary.flatArray && !secondary.flatArray)
        || (primary.flatArray.length === 0 && secondary.flatArray.length === 0)
    )
    
    return (
        <Box
            position='relative'
            height='100%'
            pt={5.5}
            pb={2}
            overflow='auto'
            borderLeft={`1px solid ${theme.palette.grey[200]}`}
        >
            <Box
                display='flex'
                flexDirection='column'
                alignItems='stretch'
                justifyContent='space-around'
            >
                {primary && primary.flatArray && primary.flatArray.length > 0 && primary.flatArray.map((Comp, idx) => (
                        <Box 
                            key={idx} 
                            m={2}
                            mt={0}
                            className={classes.noShadow}
                            display="flex"
                            justifyContent="stretch"
                        >
                            <Comp/>
                        </Box>                
                    ))
                }
            </Box>
            <Box mb={2}>
                {!isToolBarEmpty && <Divider variant='middle'/>
                }
            </Box>
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-around'
                alignItems='center'
            >
                {secondary && secondary.flatArray && secondary.flatArray.length > 0 && secondary.flatArray.map((Comp, idx) => (
                        <Box 
                            key={idx} 
                            m={2}
                            mt={0}
                            className={classes.noShadow}
                        >
                            <Comp/>
                        </Box>                 
                    ))
                }
            </Box>
        </Box>
    )
}
