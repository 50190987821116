export const INITIAL_SEARCH_CRITERIA = 
    {
        searchStr : null,
        hotelId : null,
        center : null
    };

export const INITIAL_PAGINATOR_META_PARAMS = {
    page: 1,
    per_page: 10,
}