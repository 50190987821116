import { Box, Button, FormControlLabel, TextField, Typography, Checkbox, InputAdornment, IconButton } from '@material-ui/core'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import { useRouter, useSref } from '@uirouter/react'
import useStateBusinessMembershipForm from '../../../state/hooks/useStateBusinessMembershipForm'
import { dispatch } from 'slice'
import OpenModalLink from '../../../../dialogs/standard/components/OpenStandardDialog.Link'
import { PrivacyPolicyLink } from '../../../../legal/components/PrivacyPolicyLink'
import { TermsOfServiceLink } from '../../../../legal/components/TermsOfServiceLink'
import useOBStyles from '../onboarding.classes'
import useBDClasses from './businessDetails.classes'
import { COLORS } from '../../../../theme/colors'
import GoogleConnectionInfoDialog from '../GoogleConnectionInfo.box'
import GoogleUrlInfoDialog from '../GoogleUrlInfo.box'
import { AddendumByInvitationCodeLink } from '../AddendumByInvitationCode.Link'

export function MemberBusinessDetailsForm(props){
    // console.log('MemberBusinessDetailsForm', props)
    const {$state$, $stateParams} = props

    const router = useRouter()
    const previousSref = useSref('^')
    const typo = useTypographyStyles()
    const obClasses = useOBStyles()
    const bdClasses = useBDClasses()
    
    const _form = useStateBusinessMembershipForm()
    // console.log('form', _form)
    const business_name = _form.business_name
    const business_address = _form.business_address
    const primary_phone_number = _form.primary_phone_number
    const primary_email = _form.primary_email
    const business_website = _form.business_website
    const google_profile_link = _form.google_profile_link
    const admin_full_name = _form.admin_full_name
    const admin_email = _form.admin_email
    const billing_name = _form.billing_name
    const billing_address = _form.billing_address
    const vat_id = _form.vat_id
    const agreed = _form.agreed

    const handleSubmit = e => {
        e.preventDefault()
        
        router.stateService.go('root.welcome.invited.tour.plans.business-details.review', {
            business_name: business_name.value,
            business_address: business_address.value,
            primary_phone_number: primary_phone_number.value,
            primary_email: primary_email.value,
            business_website: business_website.value,
            google_profile_link: google_profile_link.value,
            admin_full_name: admin_full_name.value,
            admin_email: admin_email.value,
            billing_name: billing_name.value,
            billing_address: billing_address.value,
            vat_id: vat_id.value,
            agreed: agreed.value,
        })

    }

    const handleTextFieldChange = field => (e) => {
        const val = field === 'agreed' ? e.target.checked : e.target.value
        dispatch({
            type: 'BUSINESS/INVITE/FORM/SET',
            payload: {
                key: field,
                value: val
            }
        })
    }

    return (
            <form onSubmit={handleSubmit}
                className={`${obClasses.container}`}
                id="invited-new-membership-business-details-form"
            >
                
                <Box className={`
                    ${obClasses.stepHeading}
                    noPadding
                    ${obClasses.mbM}
                    `}
                >
                    <IconButton 
                        id="invite-back-to-membership-plans"
                        {...previousSref} 
                        className="backBtn clickable"
                    >
                        <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
                    </IconButton>
                    <Typography 
                        className={typo.subtitle6} 
                        align="center"
                    >
                        STEP 3: COMPLETE YOUR BUSINESS DETAILS
                    </Typography>
                </Box>
                {business_name && (
                    <TextField
                        className={obClasses.mXS}
                        required={business_name.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={business_name.label}
                        value={business_name.value}
                        onChange={handleTextFieldChange('business_name')}
                    />
                )}
                {business_address && (
                    <TextField
                        className={obClasses.mXS}
                        required={business_address.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={business_address.label}
                        value={business_address.value}
                        onChange={handleTextFieldChange('business_address')}
                    />
                )}
                {primary_phone_number && (
                    <TextField
                        className={obClasses.mXS}
                        required={primary_phone_number.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={primary_phone_number.label}
                        value={primary_phone_number.value}
                        onChange={handleTextFieldChange('primary_phone_number')}
                    />
                )}
                {primary_email && (
                    <TextField
                        className={obClasses.mXS}
                        required={primary_email.required}
                        variant="outlined"
                        fullWidth 
                        type="email"
                        label={primary_email.label}
                        value={primary_email.value}
                        onChange={handleTextFieldChange('primary_email')}
                    />
                )}
                {business_website && (
                    <TextField
                        className={obClasses.mXS}
                        required={business_website.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={business_website.label}
                        value={business_website.value}
                        onChange={handleTextFieldChange('business_website')}
                    />
                )}

                <Box 
                    display="flex" alignItems="center"
                    className={`
                        ${obClasses.mtM}
                        ${obClasses.mbXS}`}
                >
                    <Typography
                        style={{
                            flexGrow: 0.2,
                            color: COLORS.get('gold')['main'],
                            verticalAlign: 'middle'
                        }}
                    >
                        CONNECT TO GOOGLE BUSINESS
                    </Typography>
                    <OpenModalLink
                        modalComponent={GoogleConnectionInfoDialog}
                        modalTitle='Why is my Google Business account important?'
                        style={{color: 'inherit'}}
                    >
                        <IconButton 
                            id="invite-open-google-connection-info-dialog"
                            className="clickable"
                            style={{
                                margin: 0, padding: 0
                            }}
                        >
                            <InfoOutlinedIcon />
                        </IconButton>
                    </OpenModalLink>
                </Box>

                {google_profile_link && (
                    <TextField
                        className={obClasses.mXS}
                        required={google_profile_link.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={google_profile_link.label}
                        value={google_profile_link.value}
                        onChange={handleTextFieldChange('google_profile_link')}
                        InputProps={{
                            endAdornment: 
                                <InputAdornment position="end">
                                    <OpenModalLink
                                        modalComponent={GoogleUrlInfoDialog}
                                        modalTitle='How do I find the URL link to my Google My Business profile?'
                                    >
                                        <IconButton
                                            id="invite-open-google-url-info-dialog" 
                                            className="clickable">
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </OpenModalLink>
                                </InputAdornment>
                        }}
                        
                    />
                )}

                <Typography style={{color: COLORS.get('gold')['main']}} 
                    className={`${obClasses.mtM} ${obClasses.mbXS}`}
                >
                    ACCESS TO YOUR CIRCLE PROFILE
                </Typography>

                {admin_full_name && (
                    <TextField
                        className={obClasses.mXS}
                        required={admin_full_name.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={admin_full_name.label}
                        value={admin_full_name.value}
                        onChange={handleTextFieldChange('admin_full_name')}
                    />
                )}
                {admin_email && (
                    <TextField
                        className={obClasses.mXS}
                        required={admin_email.required}
                        variant="outlined"
                        fullWidth 
                        type="email"
                        label={admin_email.label}
                        value={admin_email.value}
                        onChange={handleTextFieldChange('admin_email')}
                    />
                )}

                
                <Typography 
                    className={`${obClasses.mtM} ${obClasses.mbXS}`}
                    color="primary"
                >
                    BILLING DETAILS
                </Typography>

                {billing_name && (
                    <TextField
                        className={obClasses.mXS}
                        required={billing_name.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={billing_name.label}
                        value={billing_name.value}
                        onChange={handleTextFieldChange('billing_name')}
                    />
                )}
                {billing_address && (
                    <TextField
                        className={obClasses.mXS}
                        required={billing_address.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={billing_address.label}
                        value={billing_address.value}
                        onChange={handleTextFieldChange('billing_address')}
                    />
                )}
                {vat_id && (
                    <TextField
                        className={obClasses.mXS}
                        required={vat_id.required}
                        variant="outlined"
                        fullWidth 
                        type="text"
                        label={vat_id.label}
                        value={vat_id.value}
                        onChange={handleTextFieldChange('vat_id')}
                    />
                )}
                {agreed && (
                    <FormControlLabel
                        style={{display: 'flex'}}
                        control={<Checkbox 
                            checked={agreed.value}
                            onChange={handleTextFieldChange('agreed')}
                            name="agreed"
                            required={agreed.required}
                            />
                        }

                        label={
                            <div>
                                I agree to the <TermsOfServiceLink /> and <PrivacyPolicyLink /> <AddendumByInvitationCodeLink code={$stateParams.code}/>
                            </div>
                        }
                        
                        classes={{
                            label: bdClasses.agreeLabel
                        }}
                        
                    />
                )}

                <Box 
                    className={`
                        ${obClasses.mobileCTA}
                        ${bdClasses.mobileCTA}`}
                >
                    <Typography 
                        className={`
                            ${typo.subtitle6}`} 
                        align="center"
                    >
                        {$state$.data.cta_txt}
                    </Typography>
                    <Button 
                        id="invite-footer-continue-to-form-review"
                        className="clickable"
                        type="submit" 
                        color="primary" 
                        variant="contained"
                    >
                        {$state$.data.cta_btn}
                    </Button>
                </Box>
            </form>
    )
}
export default MemberBusinessDetailsForm