import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import BusinessProfile from './BusinessProfile'
import ProfileHeader from './ProfileHeader'
import  OpenModalLink  from '../../../dialogs/standard/components/OpenStandardDialog.Link'
import { COLORS } from '../../../theme/colors'
import InviteBusinessForm from '../hotel-invite-business/InviteBusinessForm'
import GoogleMapWithMarkerBox from '../../../../lib/google-map/components/GoogleMap/GoogleMapWithMarkerBox'

export function OutsideCircleProfile({place}){
    const _place = place ? place.place : null
    const typographies = useTypographyStyles()
    return (
        <>
            <ProfileHeader place={place} />
            <Divider />
            <Box my={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography 
                    className={typographies.body2}
                    style={{marginBottom: '1rem'}}
                >
                    <i>Outside of the Circle</i>
                </Typography>
                <Box>
                    <OpenInviteBusinessModalLink 
                        id="business-profile-open-invite-business-form"
                        businessName={_place.name}
                        className={`${typographies.body2} clickable`}
                    />
                </Box>
                
            </Box>
        </>       
    )
}

export function InsideCircleProfile({place, showLinks=true}){
    return (
        <>
            {/* insert a google map to load google map library. Child coomp "BusinessProfile" expects google maps to be loaded (TO BE FIXED) */}
            <Box display="none">    
                <GoogleMapWithMarkerBox lat={0} lng={0}/>
            </Box>
            <ProfileHeader place={place} showLinks={showLinks}/>
            <Divider />
            <BusinessProfile place={place} />
        </> 
    )
}

export function OpenInviteBusinessModalLink({
    trackingProps, 
    businessName,
    ...forwardProps
}) {
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle={
                <Typography>
                    VOTE TO INVITE<br/> <span style={{color: COLORS.get('gold')['main']}}>{businessName}</span>
                </Typography>   
            }
            modalComponent={() => <InviteBusinessForm businessName={businessName}/>}
            modalComponentProps={trackingProps}
        >
            {forwardProps.children ? forwardProps.children : 'Invite to TasteGods'}
        </OpenModalLink>
    )
}