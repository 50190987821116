import { Box, Typography, List, useMediaQuery } from '@material-ui/core'
import React, { useState } from 'react'
import { useTypographyStyles } from '../theme/useTypography'
import CollectionCard from './CollectionCard'
import GroupIcon from '@material-ui/icons/Group';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StoreIcon from '@material-ui/icons/Store';
import { useStateRecentlyViewed } from './state/useStateRecentlyViewed';
// import { useTheme } from '@material-ui/styles'
import {CollectionItemListItem} from './CollectionItem.ListItem'
import { InsideCircleProfile, OutsideCircleProfile } from '../explore/components/place-profile';
import { dispatch } from '../../lib/slice';
import { setContextDrawer } from '../contextDrawer/state/actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { tgMakeStyles } from '../../lib/styles/TgMakeStyles';
import { getPlace } from '../http/tg/ldm-place';

const useStyles = tgMakeStyles(theme => ({
    grid: {
        display: 'grid',
        gridTemplateColumns: isDesktop => isDesktop ? `repeat(2, calc(50% - ${theme.spacing(1)}))` : '1fr',
        gridTemplateRows: 'max-content',
        alignItems: 'stretch',
        gridAutoFlow: 'row',
        gridGap: theme.spacing(2)
    }
}))

export function CollectionsHome(props){

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const classes = useStyles(isDesktop)
    const COLLECTIONS = [
        {icon: <StoreIcon />, title: 'New to TasteGods', id: 'new'},
        {icon: <FavoriteIcon />, title: 'My Favorites', id: 'own'},
        {icon: <GroupIcon />, title: `My Team's Favorites`, id: 'team'},
    ]
    const [collecStartIdx, setcolleStartcIdx] = useState(0)
    const typo = useTypographyStyles()
    const {places, touch} = useStateRecentlyViewed()
    const selectRecentlyViewed = (poi) => e => {
        getPlace(poi.place.googlePlaceId).subscribe({
            next: res => {
                const poi = res.data
                const drawerProps = {
                    component : () => poi.plan ? <InsideCircleProfile place={poi}/> : <OutsideCircleProfile place={poi}/>,
                    onCloseFn: ()=>{},
                    fullScreen: true
                }
                touch(poi)
                dispatch(setContextDrawer(drawerProps))
            },
            error: err => console.log(err)
        })
    }

    return (
        <Box p={2}>
            <Box my={3}>
                <Typography
                    className={`${typo.subtitle1}`}
                >
                    Collections For You
                </Typography>
            </Box>
            
            <Box display="flex" alignItems="stretch">
                {COLLECTIONS
                    .filter((c, i) => {
                        // this is an ugly way of limiting the number of collections on mobile
                        return isDesktop 
                            ? true
                            : collecStartIdx === 0
                                ? i != 2
                                : i != 0 
                    })
                    .map( (c, i) => (
                        <CollectionCard
                            key={i}
                            style={{marginLeft: i === 0 ? 0 : ''}}
                            icon={c.icon}
                            title={c.title}
                            id={c.id}
                        />
                ))}
                {!isDesktop && (
                    <Box 
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexGrow="1"
                        onClick={e => setcolleStartcIdx(prev => prev ? 0 : 1)}
                    >
                        {collecStartIdx === 0 
                            ? <ChevronRightIcon fontSize="large" />
                            : <ChevronLeftIcon  fontSize="large"/>}
                    </Box>
                )}
            </Box>

            {places.length > 0 && (
                <Box>
                    <Box my={3}>
                        <Typography
                            className={`${typo.subtitle1}`}
                        >
                            Recently Viewed
                        </Typography>
                    </Box>
                    <List 
                        component="nav"
                    >
                        <Box mb={3} 
                            className={`${classes.grid}`}
                        >
                            {places.filter((v,i) => i < 10).map((v, idx) => (
                                <CollectionItemListItem
                                    key={idx}
                                    poi={v}
                                    onSelect={selectRecentlyViewed(v)}
                                />
                            ))}
                        </Box>
                    </List>
                    
                </Box>
            )}
        </Box>
    )
}

export default CollectionsHome