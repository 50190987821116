import { Collapse, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import { dispatch } from 'slice';
import google_translation_logo from '../../../assets/png/google_translation_logo.png';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { openModal } from '../../dialogs/standard/state/actions';
import { TranslationInfo } from './TranslationInfo';

const useTranslationToggleButtonStyle = tgMakeStyles(theme => ({
    transButtonRoot : {
        padding : 0
    },
    infoIcon : {
        verticalAlign : 'middle',
        '&:hover': {
            cursor: 'pointer'
        },
        marginRight:theme.spacing(0.5),
    },
    img : {
        marginRight:theme.spacing(0.5),
        verticalAlign: `text-bottom`,
        height : theme.spacing(2)
    },
    hover : {
        '&:hover': {
            cursor: 'pointer'
        },
    }
}))

export const TranslationToggleButton = ({show,onClick}) => {
    const classes = useTranslationToggleButtonStyle();
    const onInfoClick = () => {
        dispatch(openModal({
            component : TranslationInfo
        }))
    }

    return (
        <>
            <Collapse in={show}>
                <span onClick={onClick} className={`${classes.hover}`}>
                    <Typography color='primary'>
                    <img src={google_translation_logo} alt={'google logo'} className={classes.img}/>
                        <span style={{display:'inline-block'}}>
                            See Original
                        </span>
                    </Typography> 
                </span>
            </Collapse>
            <Collapse in={!show}>
                <Typography color='primary' className={`${classes.hover}`}>
                    <InfoOutlinedIcon className={`${classes.infoIcon}`} onClick={onInfoClick}/>
                    <span onClick={onClick}>
                        Translate with Google
                    </span>
                </Typography>
            </Collapse>
        </>
    )
}