import { Typography } from '@material-ui/core';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import React from 'react';
import errorMessageJson from '../../../assets/json/errorMessages.json';
import baloon0Png from '../../../assets/png/baloon-0.png';
import baloon1Png from '../../../assets/png/baloon-1.png';

const useStyle = tgMakeStyles(theme => ({
    imageWrapper : {
        position: 'relative',
        margin: 'auto',
        height: 250,
        width: 250,
        [theme.breakpoints.up('sm')]: {
            width: 500,
            height: 500,
        },
        '& > img' : {
            width: '50%',
            position: 'absolute'
        },
        '& > img:nth-child(1)' : {
            top: '10%',
            left: 25,
            [theme.breakpoints.up('sm')]: {
                left:50
            },
        },
        '& > img:nth-child(2)' : {
            top: '30%',
            right: 25,
            [theme.breakpoints.up('sm')]: {
                right:50
            },
        },
        '& > h1' : {
            display: 'inline-block',
            position: 'absolute',
            top: '48%',
            width: '40%',
            right:35,
            // fontSize: '3.5rem',
            color : theme.palette.secondary.contrastText,
            [theme.breakpoints.up('sm')]: {
                right: 70,
                fontSize:'default'
            },
        }
    },
}))

export const ErrorPageDiv = ({errorObj, tgErrorCode} ) => {

    const classes = useStyle();

    let _errorObj = {};

    if (tgErrorCode){
        _errorObj = {
            ...errorMessageJson[tgErrorCode]
        }
    } 
    // Override obj if specified.
    if (errorObj) {
        _errorObj = {
            ..._errorObj,
            ...errorObj
        }
    }

    const errorHeader = _errorObj.errorHeader ? _errorObj.errorHeader :  "OOPS, AN ERROR!",
        errorText = _errorObj.errorText ? _errorObj.errorText :  "Something went wrong ;(",
        dispErrorCode = _errorObj.dispErrorCode ? _errorObj.dispErrorCode :  (<MoodBadIcon fontSize='inherit'/>);

    return ( 
        <div>
            <div className={classes.imageWrapper}>
                <img src={baloon0Png} alt="baloon0Png"/>
                <img src={baloon1Png} alt="baloon1Png"/>
                <Typography variant='h1' align="center">
                    {dispErrorCode}
                </Typography>                
            </div>
            <Typography color="primary" variant='h2' align="center">
                {errorHeader}
            </Typography>
            <Typography variant="body1" align="center">
                {errorText}
            </Typography>
        </div>
    );
}