import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import blackLogo from '../../../../assets/png/logo_black_with_text.png'
import ImageBox from '../../../../lib/components/images/ImageBox'
import Mixpanel from '../../../../lib/mixpanel'

export function ImprintArticle(props) {

    useEffect(() => {
        Mixpanel('track')("impression_imprint", props);
    }, [])

    return (
        <Box component="article">
            <Box component="header" paddingBottom={2}
                display="flex" flexDirection="column"
                alignItems="center">
                <ImageBox src={blackLogo}
                    width="100%" height="15rem">
                </ImageBox>
                <Typography variant="h5" display="inline" color="secondary">
                    TG Network GmbH
                </Typography>
            </Box>
            <Typography variant="body1" align="center">
                Auguststrasse 63
            </Typography>
            <Typography variant="body2" align="center" paragraph>
                10117 Berlin 
            </Typography>
            <Box mb={4}>
                <Typography align="center">Email: <a href="mailto:contact@tastegods.com ">contact@tastegods.com </a></Typography>
                <Typography align="center">Tel: <a href="tel:+493024638761">+49 (0) 30 24 63 87 61</a></Typography>
            </Box>
            <Box mb={2}>
                <Typography variant="body2">
                    Managing Director: Shaul Margulies
                </Typography>
                <Typography variant="body2">
                    Court of Registration: Amtsgericht Berlin - Charlottenburg 
                </Typography>
                <Typography variant="body2">
                    Registration Number: HRB 210480 B
                </Typography>
                <Typography variant="body2">
                    UST-ID/VAT: DE326483188
                </Typography>
            </Box>
            <Box mb={2}>
                <Typography variant="body2">
                    <i>Platform of the EU Commission regarding online dispute resolution: <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>. For any disputes, please reach out to <a>circle@tastegods.com</a>.</i>
                </Typography>
            </Box>
            <footer>
                <strong>2021 © TasteGods</strong>
            </footer>
        </Box>
    )
}