import React, { useEffect, useRef } from 'react'
import { Box, Typography, Button, useMediaQuery } from '@material-ui/core'
import { dispatch } from 'slice';

import GoogleMapWithMarkerBox from '../../../../lib/google-map/components/GoogleMap/GoogleMapWithMarkerBox'

import { closeModal } from '../../../dialogs/standard/state/actions';
// import { useStateUserHotels } from '../../../user/state/hooks/useStateUserHotels';
// import { TgToggleTextBox } from '../../../template/components/TgTextBox';
import { useTypographyStyles } from '../../../theme/useTypography';
// import { addEditHotelMapPlace, sameGooglePlaceId } from '../state/editHotelMapReducer';
// import { getPlace } from '../../../http/tg/ldm-place';
import { CollapsableTextField } from '../../../../lib/components/inputs/CollapsableTextInput';
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails';
import { PrintDirectionsIconButton } from '../../../explore/components/place-directions/PrintDirections.IconButton';

const AddHotelMapPlaceSuccess = props => {
    const typography = useTypographyStyles()
    useEffect(function closeModalAsync(){
        setTimeout(e => dispatch(closeModal()), 4000)
    }, [])
    return (
        <Box>
            {/* DONE! */}
            <Typography variant='inherit' 
                paragraph  align='center'
                color='primary'
                className={typography.h1}
            >
                DONE!
            </Typography>
            {/* Confirmation text */}
            <Typography variant='inherit'
                paragraph align='center'
                className={typography.h6}
            >
                {`${props.name || 'The new location'} was successfully added.`}
            </Typography>
        </Box>
    )
}

export function EditHotelMapPlaceForm({
    hotelPlace,
    onContinue
}){
    const {info} = useStateUserDetails()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const typography = useTypographyStyles()
    const formRef = useRef()
    const handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        
        const newHotelPlace = {
            ...hotelPlace,
            tip: formData.get('tip')
        }
        
        onContinue(newHotelPlace)
    }
    return hotelPlace 
    ? (
        <Box component={'form'}
            ref={formRef}
            onSubmit={handleSubmit}
            p={0}
            position='relative'
            height="100%"
            overflow="auto"
        >
            {/* POI name */}
            <Typography
                variant='inherit' className={typography.h1}
                paragraph align='center'
            >
                {hotelPlace.place.place.name}
            </Typography>
            {/* POI address */}
            <Typography 
                variant='inherit' className={typography.h6}
                paragraph align='center'
            >
                {hotelPlace.place.place.location}
            </Typography>

            {info && <Box display="flex" justifyContent="center">
                <PrintDirectionsIconButton
                    hotelId={info.hotels[0].id}
                    place={hotelPlace.place.place}
                    getInfo={()=>{
                        let formData = new FormData(formRef.current)
                        return formData.get('tip')
                    }}
                />
            </Box>}
            {/* Embedded Google map instance showing the POI location */}
            <GoogleMapWithMarkerBox
                BoxProps={{
                    height: '30vh',
                    // negtive margin is used to get the map to fill width beyond container padding
                    mx: isDesktop ? 0 : 0,
                    my: 4
                }}
                lat={hotelPlace.lat} lng={hotelPlace.lng}
            />
            {hotelPlace && hotelPlace.place && hotelPlace.place.place.about && (
                <Box mb={4}>
                    <Box mb={1}>
                        <Typography className={`${typography.h3}`}>
                            ABOUT
                        </Typography>
                    </Box>
                    <Typography>
                        {hotelPlace.place.place.about}
                    </Typography>
                </Box>
            )}

            <CollapsableTextField
                name="tip"
                label="Add More Information"
                subtext="i.e. details for a booking made, tips for a great experience"
                multiline={true}
                rows="5"
                defaultValue={hotelPlace.tip}
            />
            
            {/* Button ADD (next step) */}
            <Box textAlign='right'>
                <Button variant='contained' 
                    color='primary'
                    type='submit'
                    classes={{
                        label: typography.h3
                    }}
                >
                    CONTINUE
                </Button>
            </Box>
        </Box>
    )
    : <div>No place to see</div>
}

export default EditHotelMapPlaceForm