import React from 'react'
import { tgMakeStyles } from '../../../../../../lib/styles/TgMakeStyles';
import { TYPOGRAPHY_STYLES } from '../../../../../theme/typographies';
import { Box, Typography } from '@material-ui/core';
// import { useTypographyStyles } from '../../../../../theme/useTypography';
import ImageBox from '../../../../../../lib/components/images/ImageBox';
import { toImageURL } from '../../../../../../lib/utils';

import LocalDiningIcon from '@material-ui/icons/LocalDining';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import StoreIcon from '@material-ui/icons/Store';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import { PLANS_PLAIN_TEXT } from '../../../../../invitation/utils';
import { isPremium } from '../../../../../business/utils';
import { COLORS } from '../../../../../theme/colors';

const useStyle = tgMakeStyles((theme)=>{
    return {
        box : {
            marginTop : '1.5rem',
            // marginBottom : '1.5rem',
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
        },
        poiHeader : {
            textAlign : 'center'
        },
        poiName : {
            ...TYPOGRAPHY_STYLES.h1,
            '-webkit-line-clamp': 'none'
        },
        poiNameClamped : {
            ...TYPOGRAPHY_STYLES.h1,
            '@supports (-webkit-line-clamp: 3)': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical'
            }
        },
        poiAddress : () => (
            TYPOGRAPHY_STYLES.body2
        ),
        profilePic: {
            height: '10rem',
            width: '100%'
        },
        hovergold: {
            '&:hover': {
                color: COLORS.get('gold').main
            }
        }
    }
});

export const PoiCardHeader = ({
    clampName,
    isCompact,
    isMobile, 
    isMapDisplay, 
    poiData,
    BoxProps
}) => {
    const classes = useStyle({
        isMobile, isMapDisplay, clampName
    })

    // const typography = useTypographyStyles()
    const hasImage = !!(poiData.place.place.images && poiData.place.place.images[0])
    const _isValidUrl = new RegExp('(^https://|^http://)')
    const _website = poiData && poiData.place && poiData.place.place.website
        ? _isValidUrl.test(poiData.place.place.website) ? poiData.place.place.website : `https://${poiData.place.place.website}`
        : null

    return (
        <Box    
            {...BoxProps} 
            className={classes.poiHeader}
        >
            {/* profile picture */}
            {(hasImage && !isCompact) && (
                <ImageBox 
                    src={toImageURL(poiData.place.place.images[0].url)}
                    background={{size: 'cover'}}
                    className={`${classes.profilePic}`}
                />
            )}
            <Box className={classes.box}>
                {/* Hide if gridview and desktop view  */}
                {(!isMapDisplay) 
                    ? null
                    : (
                        <>
                            {/* selected map icon */}
                            <Box>
                                {isPremium(poiData.place) && (
                                    <Box display='flex' 
                                        justifyContent='center'
                                        alignItems='center' m={0.5}
                                    >
                                        <BusinessMapIcon business={poiData.place} color="primary" />
                                    </Box>
                                )}
                            </Box>

                            {/* business name */}
                            <Box>
                                <Typography 
                                    className={clampName 
                                        ? classes.poiNameClamped 
                                        : classes.poiName
                                    }
                                >
                                    {poiData.name}
                                </Typography>
                            </Box>   
                        </>
                    )
                }

                {/* show temporary closed for closed business */}
                {poiData && poiData.place && poiData.place.place.businessStatus === 'CLOSED_TEMPORARILY' && 
                    <Box>
                        <Typography 
                            className={classes.poiAddress}
                            color="error"
                        >
                            <b>Temporarily Closed</b>
                        </Typography>
                    </Box>
                }
            </Box>
            
            <Box className={classes.box}>
                {/* address */}
                <Typography className={classes.poiAddress} style={{marginBottom: '.25rem'}}>
                    {poiData.address}
                </Typography>
                
                {/* website */}
                {(_website && !isCompact) && (
                    <a href={_website} target="_blank" style={{textDecoration: 'none', color: 'inherit'}}>
                        <Typography className={`${classes.poiAddress} ${classes.hovergold}`} style={{textDecoration: 'underline'}}>
                            {_website}
                        </Typography>
                    </a>
                )}
            </Box>
        </Box>
    )
}

export function BusinessMapIcon({business, ...props}){
    const _isPremium = isPremium(business)
    const ICONS_MAP = {
        // default: LocationOnIcon,
        default: function(){return null},
        dining: LocalDiningIcon,
        bar: LocalBarIcon,
        cafe: LocalCafeIcon,
        store: StoreIcon,
        landmark: AccountBalanceIcon
    }

    const Icon = !_isPremium
        ? ICONS_MAP.default
        : business.place.attributes
            ? ICONS_MAP[business.place.attributes.mapIcon] || function(){return null}
            : function(){return null}
    return business
        ? (<Icon {...props} />)
        : null
}