import React from 'react';
import { dispatch } from 'slice';
import { FormControl, Button, Typography, LinearProgress, TextField, useMediaQuery } from '@material-ui/core';
import { doLogin } from '../state/actions';
import {TgForm} from '../../../lib/components/forms/DefaultTgForm';
import { unsetUserDetails } from '../../user/state/actions';
import useStateAuthStatus from '../state/hooks/useStateAuthStatus';
import { STATUSES } from '../state/meta';
import goldLogo from '../../../assets/png/logo_gold_with_text.png';
import  OpenModalLink from '../../dialogs/standard/components/OpenStandardDialog.Link';
import RecoverPasswordForm from './RecoverPasswordForm';
import ImageBox from '../../../lib/components/images/ImageBox';
import {useTypographyStyles} from '../../theme/useTypography'
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';

const useStyles = tgMakeStyles(theme => ({
    noMargin: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
    }
}))

export default function LoginForm(){
    const classes = useStyles()
    const typography = useTypographyStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const handleLogin = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const credentials = {
            username: formData.get('username'),
            password: formData.get('password')
        }
        dispatch(unsetUserDetails())
        dispatch(doLogin(credentials))
    }
    const {error, code} = useStateAuthStatus()
    return (
        <TgForm 
            onSubmit={handleLogin}
            id="login-form"
        >
            {isDesktop && 
                <ImageBox src={goldLogo}
                    width="100%" height="15rem">
                </ImageBox>
            }
            <FormControl fullWidth={true} margin="dense">
                <TextField id="login-form-username"
                    autoFocus
                    name="username"
                    label="Your username"
                    type="email"
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    color="primary"/>
            </FormControl>
            <FormControl fullWidth={true} margin="dense">
                <TextField id="login-form-password"
                    name="password"
                    label="Your password"
                    type="password"
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    color="primary"/>
            </FormControl>
            <Typography color="error" variant="body2" align="center">
                {error}
            </Typography>
            <OpenModalLink 
                id="login-form-forgot-password-link"
                className="clickable"
                modalTitle=" " modalComponent={RecoverPasswordForm}
            >
                <Typography variant="body2" color="primary">Forgot your password?</Typography>
            </OpenModalLink>
            <FormControl fullWidth={false} margin="normal">
                <Button
                    id="login-form-submit"
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="clickable"
                    classes={{
                        root: classes.noMargin,
                        label: typography.h3
                    }}
                    >
                        CONTINUE
                </Button>
                {code === STATUSES.authenticating && <LinearProgress variant="query" color="secondary"/>}
            </FormControl>
            
        </TgForm>
    )
}