import React from 'react'
import { Button } from '@material-ui/core'
import { dispatch } from 'slice'
import { openModal } from '../state/actions'

export function OpenModalButton(props) {
    const {modalTitle, modalComponent, modalComponentProps, modalFullScreen} = props
    const handleClickLink = e => {
        e.preventDefault()
        let modalConfig = {
            title: modalTitle,
            component: modalComponent,
            props: modalComponentProps,
            fullScreen: modalFullScreen
        }
        modalConfig && modalConfig.title && modalConfig.component && dispatch(openModal(modalConfig))
    }
    const forwardedProps = Object.assign({}, props)
    delete forwardedProps.modalTitle;
    delete forwardedProps.modalComponent;
    delete forwardedProps.modalComponentProps
    delete forwardedProps.modalFullScreen
    
    return (
        <Button {...forwardedProps} onClick={handleClickLink}></Button>
    )
}
