import React from 'react'
import { Box, ol, ListItem, Typography } from '@material-ui/core'
import { useListSyleDecimal } from '../../../legal'
import useObClasses from './onboarding.classes'

export function GoogleConnectionInfoDialog(){

    const obClasses = useObClasses()
    const classes = useListSyleDecimal()

    return (
        <Box>
            <Typography className={`${obClasses.mtL}`}>
                We connect with <b>Google API</b> to pull:
            </Typography>
            <ol className={classes.olDiscOutside} component="ol" style={{transform: 'translateX(0.5rem)'}}>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        Your exact location (latitude & longitude coordinates)
                    </Typography>
                </ListItem>
                <ListItem style={{paddingTop: 0}}>
                    <ol className={classes.olDiscOutside} style={{marginLeft: '1rem', paddingTop: 0}}>
                        <ListItem className={classes.liBlock} style={{paddingTop: 0, paddingBottom: 0}}>
                            <Typography variant="body2" display="inline">
                                This also allows us to share easy directions with guests via Google Maps
                            </Typography>
                        </ListItem>
                    </ol>
                </ListItem>
                <ListItem className={classes.liBlock}>
                    <Typography variant="body2" display="inline">
                        Your weekly hours (specific days with special hours are not pulled)
                    </Typography>
                </ListItem>
                <ListItem className={classes.liBlock}>
                    <Typography variant="body2" display="inline">
                        A notification if you are temporarily or permanently closed (so that concierges do not accidentally send guests to your location)
                    </Typography>
                </ListItem>
            </ol>
        </Box>
    )
}

export default GoogleConnectionInfoDialog