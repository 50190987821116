import { Box, TextField, Typography, Grid, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import { GoogleMyBusinessInfoDialogLink } from './dialogs/GoogleMyBusinessInfoDialog'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import {  WhatConciergeWantToKnowInfoDialogLink } from './dialogs/WhatConciergeWantToKnowInfoDialog'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { COLORS } from '../../../theme/colors'
import CheckGoogleUpdatesLink from './CheckGoogleUpdates.Link'

const useStyles = tgMakeStyles(theme => ({
    control: {
        marginBottom: theme.spacing(2)
    },
    linkIcon: {
        '&:hover': {
            cursor: 'pointer',
            color: COLORS.get('gold').main    
        }
    }
}))

export function EditBasicInformationFormControls({
    business, 
    ...props
}){
    const typo = useTypographyStyles()
    const classes = useStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))

    return business 
        ? isDesktop 
            ? (
                <Grid container spacing={2}>
                    
                    <Grid item md={6}>
                        {/* business name */}
                        <Box mb={2} mt={2}>
                            <TextField
                                fullWidth
                                value={business.place.name} 
                                id="business_name"
                                name="business_name"
                                type="text"
                                variant="outlined"
                                label="Business Name"
                            />
                        </Box>
                        <Box className={`${classes.control}`}>
                            <Box mb={1}>
                                <Typography className={`${typo.body3}`}>Tagline</Typography>
                            </Box>
                            <Box mb={1}>
                                <Typography className={`${typo.body4}`}>A quick introduction to your business</Typography>
                            </Box>
                            <TextField
                                fullWidth
                                value={business.description}
                                id="business_description"
                                name="business_description"
                                type="text"
                                multiline
                                rows="2"
                                variant="outlined"
                            />
                            <Typography className={`${typo.body4}`} align="right">
                                Maximum 114 characters
                            </Typography>
                        </Box>
                        <Box className={`${classes.control}`}>
                            <Box className={`${typo.body3}`} display="flex" alignItems="center" mb={1}>
                                About
                                <WhatConciergeWantToKnowInfoDialogLink
                                    id="open-what-concierges-want-to-know-info-dialog"
                                    className="clickable"
                                    color="inherit"
                                >
                                    <HelpOutlineIcon 
                                        color="primary"
                                        style={{marginLeft: '.5rem'}}
                                        fontSize="inherit"
                                    />
                                </WhatConciergeWantToKnowInfoDialogLink>
                            </Box>
                            <Box mb={1}>
                                <Typography className={`${typo.body4}`}>
                                    Share your story: a description of your business, special features, and a brief history.    
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                value={business.place.about} 
                                id="business_about"
                                name="business_about"
                                type="text"
                                multiline
                                rows="6"
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    
                    <Grid item md={6}>
                        <Box mb={3} position="relative" display="flex" justifyContent="space-between">
                            <Box>
                                <Box className={`${typo.body3}`} display="flex" alignItems="center">
                                    Address
                                    <GoogleMyBusinessInfoDialogLink
                                        id="address-open-google-my-business-info-dialog"
                                        className="clickable"
                                        color="inherit"
                                    >
                                        <HelpOutlineIcon 
                                            color="primary"
                                            style={{marginLeft: '.5rem'}}
                                            fontSize="inherit"
                                        />
                                    </GoogleMyBusinessInfoDialogLink>
                                </Box>
                                <Box width="60%">
                                    <Typography className={`${typo.body4}`}>
                                        {business.place.location}
                                    </Typography>
                                </Box>
                            </Box>
                            <CheckGoogleUpdatesLink businessId={business.id} id="address-check-google-updates-link"/>
                        </Box>

                        <TextField
                            className={`${classes.control}`}
                            fullWidth
                            value={business.place.internationalPhoneNumber} 
                            id="business_phone_number"
                            name="business_phone_number"
                            type="text"
                            variant="outlined"
                            label="Phone Number"
                        />
                        <TextField
                            className={`${classes.control}`}
                            fullWidth
                            value={business.email} 
                            id="business_email"
                            name="business_email"
                            type="email"
                            variant="outlined"
                            label="Email Address"
                        />
                        <TextField
                            className={`${classes.control}`}
                            fullWidth
                            value={business.place.website} 
                            id="business_website"
                            name="business_website"
                            type="text"
                            variant="outlined"
                            label={'Website'}
                        />
                    </Grid>

                </Grid>
            )
            : (
               <>
                    <Box mb={2} mt={2}>
                        <TextField
                            fullWidth
                            value={business.place.name} 
                            id="business_name"
                            name="business_name"
                            type="text"
                            variant="outlined"
                            label="Business Name"
                        />
                    </Box>
                    <Box className={`${classes.control}`}>
                        <Box mb={1}>
                            <Typography className={`${typo.body3}`}>Tagline</Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography className={`${typo.body4}`}>A quick introduction to your business</Typography>
                        </Box>
                        <TextField
                            fullWidth
                            value={business.description}
                            id="business_description"
                            name="business_description"
                            type="text"
                            multiline
                            rows="2"
                            variant="outlined"
                        />
                        <Typography className={`${typo.body4}`} align="right">
                            Maximum 114 characters
                        </Typography>
                    </Box>

                    <Box mb={3} display="flex" justifyContent="space-between">
                        <Box>
                            <Box className={`${typo.body3}`} display="flex" alignItems="center">
                                Address
                                <GoogleMyBusinessInfoDialogLink
                                    id="address-open-google-my-business-info-dialog"
                                    className="clickable"
                                    color="inherit"
                                >
                                    <HelpOutlineIcon 
                                        color="primary"
                                        style={{marginLeft: '.5rem'}}
                                        fontSize="inherit"
                                    />
                                </GoogleMyBusinessInfoDialogLink>
                            </Box>
                            <Typography className={`${typo.body4}`}>
                                {business.place.location}
                            </Typography>
                        </Box>
                        <CheckGoogleUpdatesLink businessId={business.id} style={{textAlign: 'right'}} id="address-check-google-updates-link"/>
                    </Box>

                    <TextField
                        className={`${classes.control}`}
                        fullWidth
                        value={business.place.internationalPhoneNumber} 
                        id="business_phone_number"
                        name="business_phone_number"
                        type="text"
                        variant="outlined"
                        label="Phone Number"
                    />
                    <TextField
                        className={`${classes.control}`}
                        fullWidth
                        value={business.email} 
                        id="business_email"
                        name="business_email"
                        type="email"
                        variant="outlined"
                        label="Email Address"
                    />
                    <TextField
                        className={`${classes.control}`}
                        fullWidth
                        value={business.place.website} 
                        id="business_website"
                        name="business_website"
                        type="text"
                        variant="outlined"
                        label={'Website'}
                    />

                    <Box className={`${classes.control}`}>
                        <Box className={`${typo.body3}`} display="flex" alignItems="center" mb={1}>
                            About
                            <WhatConciergeWantToKnowInfoDialogLink
                                id="open-what-concierges-want-to-know-info-dialog"
                                className="clickable"
                                color="inherit"
                            >
                                <HelpOutlineIcon 
                                    color="primary"
                                    style={{marginLeft: '.5rem'}}
                                    fontSize="inherit"
                                />
                            </WhatConciergeWantToKnowInfoDialogLink>
                        </Box>
                        <Box mb={1}>
                            <Typography className={`${typo.body4}`}>
                                Share your story: a description of your business, special features, and a brief history.    
                            </Typography>
                        </Box>
                        <TextField
                            fullWidth
                            value={business.place.about}
                            id="business_about"
                            name="business_about"
                            type="text"
                            multiline
                            rows="6"
                            variant="outlined"
                        />
                    </Box>
               </> 
            )
        : <div>Loading...</div>
}