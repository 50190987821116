import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTypographyStyles } from '../../theme/useTypography'

export function TranslationInfo() {
    const typoClasses = useTypographyStyles()
    return (
        <Box component="article">
            <Typography paragraph className={typoClasses.body2}>
                This service may contain translations powered by Google. Google disclaims all warranties related to the translations, express or implied, including any warranties of accuracy, reliability, and any implied warranties of merchantability, fitness for a particular purpose and noninfringement. 
            </Typography>

            <Typography paragraph className={typoClasses.body2}>
            TasteGods website has been translated for your convenience using translation software powered by Google Translate. Reasonable efforts have been made to provide an accurate translation, however, no automated translation is perfect nor is it intended to replace human translators.
            </Typography>

            <Typography paragraph className={typoClasses.body2}>
            The official text is the English version of the website. Any discrepancies or differences created in the translation are not binding and have no legal effect for compliance or enforcement purposes. If any questions arise related to the accuracy of the information contained in the translated website, refer to the English version of the website which is the official version.
            </Typography>
        </Box>
    )
}
