import { Box, Button, Card, Collapse, Container, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import { useCurrentStateAndParams, useRouter } from '@uirouter/react'
import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { TYPOGRAPHY_STYLES } from '../../../theme/typographies';
import { getBusinessLastUpdate, isPremium } from '../../utils';
import { formatDistance } from 'date-fns';
import { useStateAdminBusiness } from '../../state/hooks/useStateAdminBusiness';
import { useTypographyStyles } from '../../../theme/useTypography';
import tracker from '../../../../lib/mixpanel';
import { dispatch } from 'slice';
import { WhyEnglishInfoDialog } from './dialogs/WhyEnglishInfoDialog';
import OpenModalLink from '../../../dialogs/standard/components/OpenStandardDialog.Link';
import { EditBasicInformationFormControls } from './EditBasicInformationFormControls';
import {setAdminBusinessData, setAdminBusinessFieldData, setAdminBusinessFieldVisibility, setAdminBusinessPlaceAttributeFieldData, setAdminBusinessPlaceFieldData, setAdminBusinessProfileCover} from '../../state/actions'
import { EditBusinessOperationsFormControls } from './EditBusinessOperationsFormControls';
import { ManageBusinessSocialMedia } from './ManageBusinessSocialMedia';
import FirstImpressionSummary from './FirstImpressionSummary';
import { postBusinessPlaceAttribute, postBusinessPropertyVisibility, putBusiness, uploadBusinessImageCover } from '../../../http/tg/business';
import { showNotification } from '../../../notifications/state/actions';
import { PreviewBusiness } from './PreviewBusiness';
import { forkJoin, from, of, throwError } from 'rxjs';
import { catchError, concatMap, mergeMap, tap, toArray } from 'rxjs/operators';
import { PLANS_PLAIN_TEXT } from '../../../invitation/utils';
import { useStateUserHotels } from '../../../user/state/hooks/useStateUserHotels';

const useStyles = tgMakeStyles(theme => ({
    form: {
        flexGrow: 1,
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        '& > .top': {
            order: 1,
            flex: 'none'
        },
        '& > .middle': {
            order: 2,
            flexGrow: '1',
            overflow: 'auto'
        },
        '& > .bottom': {
            order: 3,
            flex: 'none'
        },
        '& .btn': {
            borderRadius: 0,
            margin: 0,
            '& .MuiButton-label': {
                ...TYPOGRAPHY_STYLES.subtitle5
            }
        },
        '& .btn.blue': {
            backgroundColor: '#585879'
        }
    },
    collapsBtn: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))

export function EditBusinessProfileForm({$stateParams, business}){
    // const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const classes = useStyles()
    const isMedium = useMediaQuery(theme => theme.breakpoints.up('md'))

    const _business =  useStateAdminBusiness($stateParams.id)
    const _isPremium = isPremium(_business)
    
    const router = useRouter()

    // takes a form event and return an Observable<Response>
    function updateBusiness(e){
        const formData = new FormData(e.target)
        const payload = {
            operationStatus: formData.get('business_operationstatus'),
            description: formData.get('business_description'),
            email: formData.get('business_email'),
            visitGuidelines: formData.get('business_visitguidelines'),
            name: formData.get('business_name'),
            internationalPhoneNumber: formData.get('business_phone_number'),
            website: formData.get('business_website'),
            about: formData.get('business_about')
        }
        return putBusiness(_business.id, payload)
    }
    // takes a form event and return Observable<Response>
    function updateBusinessAttributes(e){
        if(!_isPremium) return of(null)
        const formData = new FormData(e.target)
        const payload = {
            key: 'mapIcon',
            value: formData.get('map_icon')
        }
        // console.log('payload', payload)
        return postBusinessPlaceAttribute(_business.id, payload)
            .pipe(catchError(err => {
                if(err.error = "attribute value already se") {
                    return of(null)
                } else {
                    return throwError(err)
                }
            }))
    }
    // takes a form event and return Observable<Response>
    function updateBusinessPropertyVisibility(e){
        if(!_isPremium) return of(null)
        const formData = new FormData(e.target)
        const payloads = [
            {domain: 'place', property: 'website', visible: !!formData.get('visibility_website')},
            {domain: 'business', property: 'feed', visible: !!formData.get('visibility_social')},
            {domain: 'place', property: 'openingHours', visible: !!formData.get('visibility_hours')},
            {domain: 'place', property: 'about', visible: !!formData.get('visibility_about')}
        ]
        return from(payloads).pipe(
            mergeMap(payload => postBusinessPropertyVisibility(_business.id, payload)),
            toArray()
        )
    }
    // takes a form event and return Observable<Response | null>
    function setProfileCover(e){
        const formData = new FormData(e.target)
        const file = formData.get('business_profile_cover')
        const _formData = new FormData()
        _formData.append('file', file)
        // console.log('file', file.size > 0)
        return file && file.size > 0
            ? uploadBusinessImageCover(_business.id, _formData)
            : of(null)
    }

    const handleFormSubmit = e => {
        e.persist() // tells React to use the correct value of e inside async callbacks
        e.preventDefault()
        
        forkJoin([
            setProfileCover(e),
            updateBusinessPropertyVisibility(e),
            updateBusinessAttributes(e)
        ]).pipe(
            concatMap(x => updateBusiness(e)),
            tap(x => {dispatch(showNotification({message: 'Your profile has been updated! Changes are now live for concierges.'}))})
        ).subscribe({
                next:  res => {dispatch(setAdminBusinessData(res.data))},
                complete: () => {
                    router.stateService.go('.', {saved: true}, {inherit: true})
                },
                error: err => {
                    console.log(err)
                    dispatch(showNotification({message: 'Oops, something went wrong, please try again later.'}))
                }
            })
    }

    const handleFormFieldChange = (e) => {
        router.stateService.go('.', {saved: false}, {inherit: true})
        switch (e.target.name) {
            // uploaded a profile pic
            case 'business_profile_cover':
                console.log('business_profile_cover')
                dispatch(setAdminBusinessProfileCover(business.id, e.target.files[0]))
                break;
            // property visibility
            case 'visibility_website':
                dispatch(setAdminBusinessFieldVisibility(business.id, 'website', e.target.checked))
                break;
            case 'visibility_social':
                dispatch(setAdminBusinessFieldVisibility(business.id, 'social', e.target.checked))
                break;
            case 'visibility_hours':
                dispatch(setAdminBusinessFieldVisibility(business.id, 'hours', e.target.checked))
                break;
            case 'visibility_about':
                dispatch(setAdminBusinessFieldVisibility(business.id, 'about', e.target.checked))
                break;
            // place fields
            case 'map_icon':
                dispatch(setAdminBusinessPlaceAttributeFieldData(business.id, 'mapIcon', e.target.value))
                break;
            case 'business_name':
                dispatch(setAdminBusinessPlaceFieldData(business.id, 'name', e.target.value))
                break;
            case 'business_phone_number':
                dispatch(setAdminBusinessPlaceFieldData(business.id, 'internationalPhoneNumber', e.target.value))
                break;
            case 'business_website':
                dispatch(setAdminBusinessPlaceFieldData(business.id, 'website', e.target.value))
                break;
            case 'business_about':
                dispatch(setAdminBusinessPlaceFieldData(business.id, 'about', e.target.value))
                break;
            // business fields
            case 'business_description':
                if(e.target.value.length < 115){
                    dispatch(setAdminBusinessFieldData(business.id, 'description', e.target.value))
                }
                break;
            case 'business_email':
                dispatch(setAdminBusinessFieldData(business.id, 'email', e.target.value))
                break;
            case 'business_operationstatus':
                dispatch(setAdminBusinessFieldData(business.id, 'operationStatus', e.target.value))
                break;
            case 'business_visitguidelines':
                dispatch(setAdminBusinessFieldData(business.id, 'visitGuidelines', e.target.value))
                break;
            default:
                break;
        }
        tracker('form')('admin_edit_business_profile_form', {[e.target.name]: e.target.value})
    }
    
    const [mobilePreview, setMobilePreview] = useState(false)
    const openPreview = e => {
        if(!isMedium){
            setMobilePreview(true)
        }
    }

    return _business
        // show form if business is loaded
        ? (
            <Box display="flex" 
                position='relative'
                height='100%'
                overflow='hidden'
            >
                <form
                    id="admin-edit-business-profile-form"
                    onSubmit={handleFormSubmit}
                    onChange={handleFormFieldChange}
                    className={`${classes.form}`}
                >
                    {/* preview button only appears on mobile */}
                    {!mobilePreview && !isMedium && (
                        <Button
                            id="admin-preview-business-profile-changes"
                            color="primary" 
                            variant="contained" 
                            className={`${isMedium ? 'bottom' : 'top'} btn clickable blue`}
                            onClick={openPreview}
                        >
                            <VisibilityIcon style={{marginRight: '0.5rem'}}/>  See preview
                        </Button>
                    )}

                    {!mobilePreview
                        // form controls ...
                        ? (
                            <Box className="middle">
                                <FormControls business={_business}/> 
                            </Box>
                        )
                        // ... or mobile preview
                        : (
                            <Box className="middle">
                                {_business.pruned 
                                    ? <PreviewBusiness
                                        business={_business}
                                        businessPruned={_business.pruned}
                                        onClose={e => setMobilePreview(false)}
                                    />
                                    : <></>
                                }
                            </Box>
                        )}
                    <Button 
                        id="admin-save-business-profile-changes"
                        type="submit"
                        variant="contained" 
                        color="primary" 
                        className={`${isMedium ? 'top' : 'bottom'} btn clickable`}
                    >
                        SAVE CHANGES
                    </Button>
                </form>
                {isMedium && (
                    <Container 
                        maxWidth="xs"
                        component={Box}
                        disableGutters={true}
                        position='relative'
                        height='100%'
                        overflow="scroll"
                        minHeight="100%"
                    >
                        {_business.pruned 
                        ? <PreviewBusiness 
                            businessPruned={_business.pruned}
                            business={_business}
                            onClose={e => setMobilePreview(false)}
                            showBack={false}
                        />
                        : <></>}
                    </Container>
                )}
            </Box>
        )
        // show loading until business is loaded
        : (
            <div>Loading...</div>
        )
}
export default EditBusinessProfileForm


// COMPONENTS
function FormControls({
    business, 
    ...props
}){
    const router = useRouter()
    const toggleSection = idx => e => {
        const PARAMS = ['basic', 'operation', 'social', 'impressions']
        const _param = PARAMS[idx]
        router.stateService.go('.', {[_param]: !params[_param]}, {inherit: true})
    }
    const classes = useStyles()
    const {params} = useCurrentStateAndParams()
    const _business = business
    const typo = useTypographyStyles()
    return (
        <Box p={2}>
            <Box display="flex" 
                justifyContent="flex-end" alignItems="center"
                className={`${typo.body4}`}
            >
                <OpenModalLink
                    id="open-why-english-info-dialog"
                    modalComponent={WhyEnglishInfoDialog}
                    className="clickable"
                >
                    <InfoOutlinedIcon 
                        style={{marginRight: '.5rem'}}
                        fontSize="inherit"
                        color="primary"
                    />
                    Warum auf Englisch?
                </OpenModalLink>
            </Box>
            
            {/* name + last update */}
            <Box my={4}>
                <Box mb={1}>
                    <Typography align="center" className={`${typo.subtitle1}`}>
                        {_business.place.name}
                    </Typography>
                </Box>    
                <Typography align="center" className={`${typo.body4}`}>
                    {`Last update saved ${formatDistance(getBusinessLastUpdate(_business), new Date(), {addSuffix: true})}`}
                </Typography>
            </Box>

            <Box my={4}>
                <Typography className={`${typo.subtitle6}`}>MY CIRCLE PROFILE</Typography>
                <Typography className={`${classes.body2}`}>What concierges see in the Circle</Typography>
            </Box>

            <CollapsableFormSection
                id="basic-info-section"
                text={`Basic Info`}
                open={params.basic}
                onToggle={toggleSection(0)}
            >
                <EditBasicInformationFormControls business={_business} />
            </CollapsableFormSection>
            
            <CollapsableFormSection
                id="current-operations-section"
                text={`Current Operations`}
                open={params.operation}
                onToggle={toggleSection(1)}
            >
                <EditBusinessOperationsFormControls business={_business} />
            </CollapsableFormSection>
            
            <CollapsableFormSection
                id="social-media-section"
                text={`Social Media`}
                open={params.social}
                onToggle={toggleSection(2)}
            >
                <ManageBusinessSocialMedia business={_business} />
            </CollapsableFormSection>
                        
            <Box my={4}>
                <Typography className={`${typo.subtitle6}`}>MY PROFILE ON DIGITAL MAPS</Typography>
                <Typography className={`${classes.body2}`}>What guests see when you're recommended</Typography>
            </Box>

            <CollapsableFormSection
                id="first-impression-section"
                text={`First Impression`}
                open={params.impressions}
                onToggle={toggleSection(3)}
            >
                <FirstImpressionSummary business={_business} /> 
            </CollapsableFormSection>
        </Box>
    )
}

function CollapsableFormSection({
    text, 
    open=false,
    onToggle=()=>{},
    ...props
}){

    const typo = useTypographyStyles()
    const classes = useStyles()

    return (
        <Card {...props}
            style={{
                [open ? 'boxShadow' : 'invalid']: `2px 2px 15px #585879`
            }}
            component={Box} my={2}
            elevation={open ? 5 : 3}
            p={2}
        >
            <Box display="flex" onClick={onToggle} className={`${classes.collapsBtn}`}>
                <Typography
                    component={Box}
                    flex="none"
                    className={`${typo.subtitle1}`}
                >
                    {text}
                </Typography>
                <div style={{flexGrow: 1}}></div>
                <IconButton component={Box} m={'0 !important'} p={'0 !important'} onClick={onToggle}>
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </Box>
            <Collapse 
                in={open} 
                timeout={{ enter: 200, exit: 200 }}
            >
                {props.children}
            </Collapse>
        </Card>
    )
}