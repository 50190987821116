import {useEffect} from 'react';
import { of } from 'rxjs';
import { dispatch, $lice } from 'slice';
import { removeFilesFromMap, seedGeoJsonDataWith } from '../../state/actions';
import { INITIAL_STATE } from '../../state/reducers';
import { rootReducer as DigitalMapRootReducer } from '../../state/reducers/index'
import {lightTheme} from '../../../../theme/cssTheme'

export function useGeojsonToGoogleMapData(geojson, {
    map,
    targetOverlay='geojson',
    withTeardown=true,
    MuiTheme,
    styleFeatureFn,
    source
}={}){
    // link the slice to a GoogleMap instance. picks an overlay to display on the map
    useEffect( function plotOverlayDataOnMap(){
        if(!map) return
        let sub = $lice('digitalmap', DigitalMapRootReducer, INITIAL_STATE)[0]
            .path(`overlays.${targetOverlay}`)
            .subscribe(overlays => {
                overlays.forEach(layer => {
                    if(layer && layer.active){
                        layer.gmData.getMap() !== map && layer.gmData.setMap(map)
                    } else if(layer && !layer.active){
                        layer.gmData.setMap(null)
                    }
                })
            })
        return () => {
            sub.unsubscribe()
            if(withTeardown){
                // TODO : parameterise this logic using targetOverlay so the teardown is not limited to files overlay
                dispatch(removeFilesFromMap())
            }
        };
    }, [targetOverlay, map])
    
    useEffect(function loadDigitalMapData(){
        if(!geojson) return ()=>{}

        let sub = of(geojson)
            .subscribe(
                geojson => {
                    // console.log('seed geojson:', geojson)
                    // dispatch(fileAddPoints(geojson.name, geojson.object, {
                    dispatch(seedGeoJsonDataWith(
                        geojson.name,
                        geojson.object, 
                        {
                            style : styleFeatureFn || defaultStyleFeatureFunction
                        },
                        source
                    ))
                },
                error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                        } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        }
                        console.log(error.config);
                }
            )

        // effect teardown
        return () => {
            sub.unsubscribe()
        }
    }, [geojson, MuiTheme, styleFeatureFn])
}

function defaultStyleFeatureFunction(feature){
    // the styling function used when instantiating the google.maps.Data object /layer
    const isHighlighted = feature.getProperty('highlighted');
    const isHovered = feature.getProperty('hovered');
    return {
        title: feature.getProperty('name'),
        icon: {
            path: `M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z`,
            strokeColor: 'white',
            anchor: new window.google.maps.Point(24, 42),
            // properties below change when Feature is highlighted and/or hovered
            fillOpacity: isHighlighted 
                ? 1 
                : 0.8,
            fillColor: !isHighlighted && !isHovered
                // default
                ? lightTheme.palette.primary.main
                // highlighted OR hovered
                : lightTheme.palette.primary.dark,
            scale: !isHighlighted ? 1 : 1.15,
        }
    }
}