import React from 'react'
import { useStateToolBar } from '../state/hooks/useStateToolBar'
import { Box } from '@material-ui/core'

export default function MobileToolBarBox() {
    const {primary, secondary} = useStateToolBar()
    return (
        <Box position='relative'>
            <Box display='flex'
                position='relative'
                justifyContent='space-around'
                width='100%'
            >
                {secondary && secondary.flatArray && secondary.flatArray.length > 0 && secondary.flatArray.map((Comp, idx) => (
                        <Box  key={idx} 
                            flexGrow={1}
                        >
                            <Comp/>
                        </Box>
                    ))
                }
            </Box>
            <Box position='absolute' 
                left='50%' top='0'
                display='flex' justifyContent='center'
                style={{
                    transform: 'translateX(-50%)translateY(-50%)'
                }}
            >
                {primary && primary.flatArray && primary.flatArray.length > 0 && primary.flatArray.map((Comp, idx) => (
                        <Comp key={idx}/>
                    ))
                }
            </Box>
        </Box>
    )
}
