import { Box, Grid, Link, Typography, useMediaQuery } from '@material-ui/core'
import { useCurrentStateAndParams, useRouter } from '@uirouter/react'
import React, { useEffect } from 'react'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles'
import { useTypographyStyles } from '../../../theme/useTypography'
import UserDetailsCard from '../../../user/components/UserDetailsCard'
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails'
import { useStateAdminBusiness } from '../../state/hooks/useStateAdminBusiness'
import BusinessMembershipDetailsCard from './BusinessMembershipDetailsCard'
import { openUpgradeBusinessMembershipPlanDialog } from './dialogs/UpgradeBusinessMembershipPlanDialog'

const useStyles = tgMakeStyles(theme => ({
    section: {
        display: 'flex',
        flexDirection: 'column',
        '& > .stretch': {
            flexGrow: 1
        }
    }
}))

export function BusinessAdminSettings({/* $stateParams */}){

    const {params} = useCurrentStateAndParams()
    const _business = useStateAdminBusiness(params.id)
    const typo = useTypographyStyles()
    const classes = useStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))

    const {info} = useStateUserDetails()
    
    const router = useRouter()
    useEffect( () => {
        params.upgrade && _business && openUpgradeBusinessMembershipPlanDialog({
            onClose: e => {router.stateService.go('.', {upgrade: false})},
            props: {
                business: _business
            }
        })
        return ()=> {}
    }, [params.upgrade, _business])

    return (
        <Box p={3} minHeight="100%">
            <Box>
                <Typography className={`${isDesktop ? typo.subtitle1 : typo.h6}`}>
                    Settings
                </Typography>
            </Box>
            
            <Grid 
                container 
                spacing={2}
                alignItems="stretch"
            >
                <Grid item xs={12} md={6} className={`${classes.section}`}>
                    <Box mb={2} mt={4}>
                        <Typography className={`${isDesktop ? typo.body3 : typo.subtitle4}`}>
                            My Personal Details
                        </Typography>
                    </Box>
                    
                    {info && info.id
                        ?(
                            <UserDetailsCard userInfo={info} className="stretch"/>
                        )
                        : (
                            <Typography align="center">Loading...</Typography>
                        )
                    }
                </Grid>
                
                <Grid item xs={12} md={6} className={`${classes.section}`}>
                    <Box mb={2} mt={4}>
                        <Typography className={`${isDesktop ? typo.body3 : typo.subtitle4}`}>
                            My Business
                        </Typography>
                    </Box>
                    
                    {_business 
                        ? (
                            <BusinessMembershipDetailsCard business={_business} className="stretch"/>
                        )
                        : (
                            <Typography align="center">Loading...</Typography>
                        )
                    }
                </Grid>
            </Grid>
            
            <Box mb={1} mt={4}>
                <Typography className={`${isDesktop ? typo.body3 : typo.subtitle4}`} align="center">
                    <b>Need help?</b>
                </Typography>
            </Box>
            <Box align="center">
                <Link 
                    id="business-settings-contact-tg"
                    color="inherit" 
                    href="mailto:contact@tastegods.com"
                    className="clickable"
                >
                    Contact TasteGods
                </Link>
            </Box>
        </Box>
    )
}

export default BusinessAdminSettings