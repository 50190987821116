import React, { useEffect } from 'react';
import { useCurrentStateAndParams } from '@uirouter/react'
import { useMediaQuery, useTheme } from '@material-ui/core';
import { dispatch } from 'slice';

import Mixpanel from '../../../../lib/mixpanel'

import useStateTranslation from '../../../language/state/hooks/useStateTranslation';
import { getTranslation } from '../../../language/state/actions/translationActions';
import { useGeoJsonListeners } from '../../../maps/digital-map/hooks/useEffectSetGeoJsonListeners';
import { openFeatureInContextDrawer } from '../../../maps/digital-map/listeners/openFeatureInContextDrawer';

import PoiCard from './PoiCard'
import { useHotelPlacesToGeojson, usePublicHotelMapAsync } from '../../hooks';
import { doHoverFeature } from '../../../maps/digital-map/listeners/doHoverFeature';
import { undoHoverFeature } from '../../../maps/digital-map/listeners/undoHoverFeature';
import { useGeojsonToGoogleMapData } from '../../../maps/digital-map/base/hooks/useGeojsonToGoogleMapData';
// import { PLANS_PLAIN_TEXT } from '../../../invitation/utils';
import { isPremium } from '../../../business/utils';
import { useHotelMarker } from '../../../maps/digital-map/base/hooks/useHotelMarker';

export default function DigitalMapGuestUI({
    map,
    hotel,
    hotelMapId
}) {
    const theme = useTheme()

    const hotelMap = usePublicHotelMapAsync(hotelMapId, hotel.id)
    
    const _hotelPlaces = hotelMap
        ? hotelMap.hotelPlaces
        : null
    const geojson = useHotelPlacesToGeojson(_hotelPlaces)

    useGeojsonToGoogleMapData(geojson, {
        map,
        styleFeatureFn: styleFeatureFunction(hotelMap && hotelMap.ordered, theme),
        source: hotelMap
    })

    // Set up params for translation API 
    const languageApiParams = {
        hotelId: hotel.id,
        customMapId: hotelMapId
    }

    // Pick up changes of target language and update fetched translation texts
    const [translationState] = useStateTranslation();
    useEffect(()=>{
        if (translationState.language){
            // Mixpanel('people.set')({'language': translationState.language})
            Mixpanel('register')({
                language: translationState.language
            })
            dispatch(getTranslation({
                ...languageApiParams,
                language : translationState.language
            }))
        }
    // Remove languageApiParams from dependencies to infinite update
    },[translationState.language])

    // const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));
    useGeoJsonListeners({
        map,
        listeners: {
            mouseover: doHoverFeature,
            mouseout: undoHoverFeature,
            click: getOnClickPin({
                translationState,
                hotel
            })
        }
    })

    useHotelMarker({map, hotel})

    return null
}

function styleFeatureFunction(isOrderedMap, theme){
    const ICONS_FONT_MAP = {
        bar: "\ue540",
        dining: "\ue561",
        cafe:  "\ue541",
        store: "\ue8d1",
        landmark: "\ue84f"
    }
    return function(feature){
        // the styling function used when instantiating the google.maps.Data object /layer
        const isHighlighted = feature.getProperty('highlighted');
        const isHovered = feature.getProperty('hovered');
        const business = feature.getProperty('place')
        const _isPremium = isPremium(business)
            
        const place = feature.getProperty('place').place
        const mapIcon = _isPremium
            ? place.attributes && place.attributes.mapIcon
                ? place.attributes.mapIcon
                : 'default'
            : 'default'

        const _circleIcon = {
            path: 0, // path: window.google.maps.SymbolPath.CIRCLE,
            strokeWeight: 0,
            scale: !isHighlighted ? 18 : 22,
            // properties below change when Feature is highlighted and/or hovered
            fillOpacity: 1,
            fillColor: !isHighlighted && !isHovered
                // default
                ? theme.palette.primary.main
                // highlighted OR hovered
                : isHovered
                    ? theme.palette.primary.dark
                    : 'black'
        }
        const _circleIconSmall = {
            ..._circleIcon,
            scale: isHovered 
                ? 12
                : !isHighlighted 
                    ? 10 
                    : 13,
        }
        return {
            title: feature.getProperty('name'),
            label: isOrderedMap 
                ? {
                    text: feature.getProperty('order'),
                    color: 'white',
                    fontSize: '1rem',
                    fontWeight: '700'
                } 
                // : void 0,
                : mapIcon !== "default" 
                    ? {
                        text: ICONS_FONT_MAP[mapIcon],
                        fontFamily: "Material Icons",
                        color: "#ffffff",
                        fontSize: "18px",
                    }
                    : void 0,
            icon: isOrderedMap
                ? _circleIcon
                : mapIcon === "default"
                    ? _circleIconSmall
                    : _circleIcon,
            zIndex: isHovered 
                ? 3
                : isHighlighted
                    ? 2
                    : 1
        }
    }
}

function getOnClickPin({
    translationState,
    hotel
}){ // returns a GMFeatureClick handler
    return (onFeatureClickEvent,feature) => {

        // track click event 
        if (onFeatureClickEvent) onFeatureClickEvent.stop();
        const _feature = onFeatureClickEvent ? onFeatureClickEvent.feature : feature
        const eventProps = {
            GooglePlaceID: _feature.getProperty('googlePlaceId'),
            poiName: _feature.getProperty('name')
        }
        // Mixpanel('track')('DigMap_clickPin', eventProps)
        Mixpanel('track')('click_poi', eventProps)

        // show feature details in card
        openFeatureInContextDrawer({
            Component: PoiCardWrapperComp,
            props : {
                translation : translationState.translation,
                hotelName: hotel && hotel.name
            },
            noContainerPadding: true
        })(onFeatureClickEvent,feature)
    }
}

function PoiCardWrapperComp({
    feature, 
    ...forwardedProps
}){

    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));
    const {params} = useCurrentStateAndParams()
    
    return <PoiCard 
            {...{
                isMobile,
                isMapDisplay: !params.list,
                feature,
            }}
            {...forwardedProps}
        />
}
