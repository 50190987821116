import { Box, ListItem, ListItemText, Typography, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import React, { useEffect, useState } from 'react';
import { dispatch, dispatch as sliceDispatch } from 'slice';

import AutoCompleteBoxV2 from '../../../../lib/components/utils/AutoCompleteBoxV2';

import { getAutocompleteResults, resetAutocomplete } from '../../../autocomplete/states/actions/autocompleteActions';
import { useStateAutocomplete } from '../../../autocomplete/states/hooks/useStateAutocomplete';
import { INITIAL_AUTOCOMP_CRITERIA } from '../../../autocomplete/states/meta';
import { closeModal, openModal } from '../../../dialogs/standard/state/actions';
import { showNotification } from '../../../notifications/state/actions';
import { PaginationBox } from '../../../search/components/PaginationBox.ButtonGroup';
import { useSearchPaginatorHooks } from '../../../search/state/hooks/useSearchPaginatorHooks';
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails';
import { useTypographyStyles } from '../../../theme/useTypography';
import { getPlace } from '../../../http/tg/ldm-place';

import AddHotelMapPlaceForm from './AddHotelMapPlaceForm';
import { openConfirmationDialog } from '../../../dialogs/confirmation/states/actions';
import SearchResultsList from '../../../explore/components/search-results/SearchResults.List';

const CLOSED_STATUSES = ['CLOSED_TEMPORARILY', 'CLOSED_PERMANENTLY']

export const SearchNewHotelPlace = ({ map }) => {
    
    const typography = useTypographyStyles()

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const {params} = useCurrentStateAndParams()
    const [autocomplete, reducerDispatch] = useStateAutocomplete();
    const { info } = useStateUserDetails();
    const [autoCriteria, setAutoCriteria] = useState({
        ...INITIAL_AUTOCOMP_CRITERIA,
        // TODO : https://app.asana.com/0/626853556519404/1157879041898249/f
        // We don't allow users to search google places through autocomplete API.
        // type: [RESULT_TYPE.VENUE, RESULT_TYPE.LANDMARK, RESULT_TYPE.SERVICE]
    })
    const { 
        poiSearch, 
        searchCriteria, 
        onSearchClick, 
        getNextPage, 
        getPreviousPage, 
        getFirstPage, 
        getLastPage, 
        getPageByNumber 
    } = useSearchPaginatorHooks()

    const onInputChage = (str) => {
        setAutoCriteria(prev => ({
            ...prev,
            searchStr: str
        }))
    }

    const onAutocompSelect = (data) => {
        // console.log('data', data)
        getPlace(data.id)
            .subscribe({
                next: res => {
                    // console.log('get place result', res)
                    onNextModal(res.data)
                },
                error: res => {
                    sliceDispatch(showNotification({
                        message: 'Oops! something went wrong !!'
                    }))
                }
            })
    }

    const onNextModal = placeData => {
        // console.log('onNextModal poiData =', poiData)
        // TODO : handle permanently closed POI properly.
        // https://app.asana.com/0/626853556519404/1155961164133402/f
        const _placeData = placeData.place
        const businessStatus = _placeData.businessStatus
        if (CLOSED_STATUSES.indexOf(businessStatus) !== -1){
            sliceDispatch(showNotification({
                message: `Oops! This POI is ${businessStatus === 'CLOSED_PERMANENTLY' ? 'permanently' : 'temporarily'} closed!!! `
            }))
            return false;
        } else {  
            sliceDispatch(openModal({
                title: 'Tell Your Guest About',
                component: AddHotelMapPlaceForm,
                props: {
                    map,
                    name : _placeData.name,
                    googlePlaceId : _placeData.googlePlaceId || _placeData.place_id,
                    lat : _placeData.lat ||  _placeData.geometry.location.lat,
                    lng : _placeData.lng ||  _placeData.geometry.location.lng,
                    address : _placeData.vicinity || _placeData.location || _placeData.streetAddress,
                    onSuccess: res => {
                        dispatch(closeModal())
                    }
                },
                fullScreen: isDesktop ? false : true,
                // luckily fullScreen seems to superseed maxWidth and fullWidth
                DialogProps: {
                    maxWidth: 'md',
                    fullWidth: true
                },
                // action performed when user clicks modal back arrow. ie: go back to search 
                onBack: () => sliceDispatch(openModal({
                    title: '',
                    component: SearchNewHotelPlace,
                    props: {map},
                    fullScreen : isDesktop ? false : true,
                    DialogProps: {
                        maxWidth: 'md',
                        fullWidth: true,
                        PaperProps: {
                            style: {
                                minHeight: '20rem'
                            }
                        }
                    }
                }))
            }))
        }
    }

    const onInputEnter = (str) => {
        reducerDispatch(resetAutocomplete())
        onSearchClick(str);
    }

    // Set hotelId asynchronously
    useEffect(() => {
        if (info && info.hotels) {
            // console.log('info', info)
            setAutoCriteria(prev => ({
                ...prev,
                hotelId: info.hotels[0].id
            }))
        }
    }, [info])

    useEffect(() => {
        // console.log('criteria', autoCriteria)
        getAutocompleteResults(autoCriteria, reducerDispatch)
    }, [autoCriteria.searchStr])

    // Provisional error handling.
    useEffect(() => {
        if (autocomplete.error) {
            console.error(autocomplete.error)
        }
    }, [autocomplete.error])

    useEffect(() => {
        if (poiSearch.error) {
            sliceDispatch(showNotification({
                message: 'Oops! something went wrong !!'
            }))
        }
    }, [poiSearch.error])

    return (
        <Box >
            {/* HEADING */}
            <Typography
                variant='inherit' 
                paragraph
                className={typography.h5}
            >
                Add New Spot
            </Typography>

            {/* INPUT */}
            <Box mb={2}>
                <AutoCompleteBoxV2 
                    TextFieldProps={{
                        variant: 'outlined',
                        color: 'primary',
                        fullWidth: true
                    }}
                    options={autocomplete.results}
                    OptionListComp={OptionListComp}
                    onOptionSelect={onAutocompSelect}
                    maxOptions={5}
                    onChange={(e, str) => onInputChage(str)}
                    loading={autocomplete.loading}
                    onSearch={onInputEnter}
                    HelpTextComp={HelpTextComp}
                />
            </Box>

            {/* LOADING */}
            { poiSearch.loading 
                ? <CircularProgress size={'1em'} color="inherit" /> 
                : null
            }

            {/* RESULTS */}
            { poiSearch.pageResult && poiSearch.pageResult.length > 0 && !poiSearch.loading 
                // if results are ready to be displayed
                ? (<SearchResultsList
                    searchResult={poiSearch}
                    onSelect={onNextModal}
                />)
                // if there are no results for this search
                : (poiSearch.pageResult.length === 0 && searchCriteria.searchStr && !poiSearch.loading)
                    && (
                        <Box flexGrow={1}>
                            <Typography variant="inherit" color='primary' component='div' className={typography.subtitle1}>Search Results</Typography>
                            <Typography variant="inherit" className={typography.caption1} >No Results</Typography>
                        </Box>
                    )
            }

            {/* PAGINATION */}
            { poiSearch.pageResult.length > 0 
                // display pagination if multiple pages
                && (
                    <Box style={{textAlign:'center'}}>
                        <PaginationBox
                            displayedPageNum={poiSearch.metaData.page}
                            totalPageNum={poiSearch.metaData.pages}
                            getFirstPage={getFirstPage}
                            getPreviousPage={getPreviousPage}
                            getNextPage={getNextPage}
                            getLastPage={getLastPage}
                            getPageByNumber={getPageByNumber}
                        />
                    </Box>
                )
            }
        </Box>
    );
}

const CompactResultBox = ({ poi, onSelect }) => {
    const _poi = poi.place
    const typography = useTypographyStyles()
    const address = _poi.location
    
    return (
        <ListItem onClick={() => { onSelect(poi) }} button disableGutters={true} divider={true}>
            <Box>
                <Typography 
                    variant="inherit" 
                    component='div' 
                    className={typography.subtitle4}
                >
                    {_poi.name}
                </Typography>
                <Typography 
                    variant="body1"
                >
                    {address}
                </Typography>
                {/* if permanently closed */}
                { CLOSED_STATUSES.indexOf(_poi.businessStatus) !== -1 && 
                    <Typography variant="body2" color="error">
                        {`${_poi.businessStatus === 'CLOSED_PERMANENTLY' ? 'Permanently' : 'Temporarily'} closed`}
                    </Typography>
                }
            </Box>
        </ListItem>
    )
}

function GenerateEmphasizeNode({ targetStr, searchStr }){
    let textNode
    try {
        const parsed = targetStr.replace(new RegExp(searchStr, "gi"), (match) => (`<b>${match}</b>`));
        textNode = (<span dangerouslySetInnerHTML={{ __html: parsed }}></span>)
        // As the error happens here is not so important to stop application, ignore all the error here
    } catch (error) {
        textNode = (<span>{targetStr}</span>)
    }
    return textNode
}

const OptionListComp = props => {
    return (
        <ListItemText
            style={{margin: '0px'}}
            primary={GenerateEmphasizeNode({ targetStr: props.name, searchStr: props.searchStr })}
            primaryTypographyProps={{
                paragraph: true,
                component: 'p'
            }}
        >    
        </ListItemText>
    )
}

const HelpTextComp = () => {
    const typography = useTypographyStyles()
    return (
        <>
            <Typography 
                variant="inherit"
                paragraph
                className={typography.subtitle1} 
                color='primary'
            >
                Autocomplete Suggestions
            </Typography>
            <Typography 
                variant="inherit"
                paragraph
                className={typography.caption1} 
            >
                Press ENTER for the full search
            </Typography>
        </>
    )
}