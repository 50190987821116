import { Box, Card, Typography } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../../../theme/useTypography'
import img01 from '../../../../../../assets/png/PP-Concierge_Network.png'
import img02 from '../../../../../../assets/png/PP-Business_profile-Patisserie+Bergamot.png'
import img03 from '../../../../../../assets/png/PP_Pin-Zoom.png'
import img04 from '../../../../../../assets/png/PP-event_card-NewYears.png'
import img05 from '../../../../../../assets/png/PP-Map_Hotel+Pin.png'
import img06 from '../../../../../../assets/png/PP-Hotel-Filters.png'
import img07 from '../../../../../../assets/png/PP-Hotel_TG-minicard.png'
import gif01 from '../../../../../../assets/gif/PP-Basic-final.gif'
import gif02 from '../../../../../../assets/gif/PP-Digital_Map-final.gif'
import svg01 from '../../../../../../assets/svg/icon-add_images.svg'
import svg02 from '../../../../../../assets/svg/icon-link_channels.svg'
import svg03 from '../../../../../../assets/svg/icon-share_story.svg'
import svg04 from '../../../../../../assets/svg/icon-calendar.svg'
import svg05 from '../../../../../../assets/svg/icon-envelope.svg'
import useOnBoardStyles from '../../onboarding.classes'
import useTourStyles from '../tour.classes'
import { COLORS } from '../../../../../theme/colors'

export function MembershipTour(props){
    const typo = useTypographyStyles()
    const obClasses = useOnBoardStyles()
    const tourClasses = useTourStyles()

    return (
        <Box className={obClasses.container}>
            <Typography 
                className={`
                    ${typo.subtitle6} 
                    ${obClasses.stepHeading}
                    ${obClasses.mbL}`}
                align="center"
            >
                STEP 1: TOUR THE TASTEGODS CIRCLE
            </Typography>
            <Box>
                <Box className={`${tourClasses.section}`}>
                    <Typography 
                        className={`
                            ${typo.h6}
                            ${obClasses.mbS}
                            ${tourClasses.welcomeCircle}`}
                        align="center"
                    >
                        WELCOME TO <br/><b>TASTEGODS</b>
                    </Typography>
                    
                    <Typography 
                        className={`
                            ${typo.body1}
                            ${obClasses.mbL}`}
                        align="center"
                    >
                        <i>Home of <b>the Circle</b>: a digital platform connecting hotel concierges with local businesses</i>
                    </Typography>
                    
                    <Typography 
                        className={`${typo.body1}`}
                    >
                        Hotel concierges are the <b>ultimate insiders</b>; sharing recommendations and making bookings for <b>guests daily</b>.
                    </Typography>
                    
                    <img src={img01} 
                        className={`
                            ${tourClasses.networkImg}
                            ${obClasses.mS}`}
                    />
                    <Typography 
                        className={`
                            ${typo.body1}`}
                        align="right"
                    >
                        Join <b>the Circle</b> to connect directly with concierge teams in your city: promote your business, share crucial information, and foster a long-term relationship.
                    </Typography>
                </Box>

                <Box className={`${tourClasses.section}`}>
                    <Typography 
                        className={`
                            ${typo.h2}
                            ${obClasses.mbS}
                            ${tourClasses.sectionHeading}`}
                        align="center"
                    >
                        YOUR PROFILE <br/>IN THE CIRCLE
                    </Typography>
                    <Typography
                        color="primary"
                        className={`
                            ${typo.body2}
                            ${obClasses.mbL}`}
                        align="center"
                    >
                        All of the important details for a concierge, at a glance
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.subtitle1}
                            ${obClasses.mbS}`}
                    >
                        Feed in existing social media content
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.body1}
                            ${obClasses.mbL}`}
                    >
                        Bring attention to your current marketing efforts and personality without doubling the work.
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.subtitle1}
                            ${obClasses.mbS}`}
                        align="right"
                    >
                        Highlight important information
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.body1}
                            ${obClasses.mbL}`}
                        align="right"
                    >
                        Announce operational changes with ease; simply update your profile to show how & when your business is ready to welcome guests this week.
                    </Typography>
                    <Box className={`${tourClasses.grid1}`}>
                        <Box
                            className={`${tourClasses.blue1}`}
                        />
                        
                        <img 
                            className={`${tourClasses.profileGif}`}
                            src={gif01} 
                        />
                        
                        <Card
                            elevation={4}
                            className={`${tourClasses.infoExample}`}
                        >
                            <Typography
                                className={`${obClasses.mbS}`}
                            >
                                Special brunch cocktail list designed especially for takeaway, stop by Sat-Sun from 11-18 to order/pickup & enjoy a walk by the Spree with drinks!
                            </Typography>
                            <Typography >
                                <b>Last updated 2 days ago</b>
                            </Typography>
                        </Card>
                        <Card
                            elevation={4}
                            className={`${tourClasses.infoExample}`}
                        >
                            <Typography
                                className={`${obClasses.mbS}`}
                            >
                                Special brunch cocktail list designed especially for takeaway, stop by Sat-Sun from 11-18 to order/pickup & enjoy a walk by the Spree with drinks!
                            </Typography>
                            <Typography >
                                <b>Last updated 2 days ago</b>
                            </Typography>
                        </Card>
                    </Box>
                </Box>

                <Box className={`${tourClasses.section}`}>
                    <Typography
                        className={`
                            ${typo.h2}
                            ${obClasses.mbS}
                            ${tourClasses.sectionHeading}`}
                        align="center"
                    >
                        DIGITAL MAPS<br/>UPGRADE
                    </Typography>
                    <Typography
                        color="primary"
                        className={`
                            ${typo.body2}
                            ${obClasses.mbL}`}
                        align="center"
                    >
                        Increase your visibility with guests when a concierge recommends you
                    </Typography>
                    <Typography
                        align="right"
                        className={`
                            ${typo.subtitle1}
                            ${obClasses.mbS}`}
                    >
                        Unlike outdated paper maps, digital maps have <span style={{color: COLORS.get('gold')['main']}}>zero</span> advertising clutter
                    </Typography>
                    <Typography
                        align="right"
                        className={`
                            ${typo.body1}
                            ${obClasses.mbS}`}
                    >
                        Each business on the hotel's map is a genuine endorsement from a concierge.
                    </Typography>
                    <Typography
                        align="right"
                        className={`
                            ${typo.body1}
                            ${obClasses.mbL}`}
                    >
                        New maps are frequently created & tailored to each guest's request. Shared with guests at check in, the concierge desk or throughout their stay; word of mouth is now in digital form.
                    </Typography>
                    <Box display="flex"
                        className={`${obClasses.mbL}`}
                    >
                        <img src={gif02}
                            style={{
                                width: '50vw',
                                height: 'auto',
                                margin: 'auto'
                            }}
                        />
                    </Box>
                    <Typography 
                        className={`
                            ${typo.subtitle1}
                            ${obClasses.mbS}`}
                    >
                        Craft your first impression
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.body1}
                            ${obClasses.mbL}`}
                    >
                        When a concierge has added you to a map, capture the guest's interest with an introduction that sets you apart and showcases your unique specialties.
                    </Typography>
                    <Box className={`${obClasses.mbL}`}>
                        <Box 
                            className={`
                                ${tourClasses.firstImpressionImgContainer}
                                ${obClasses.mbL}`}
                        >
                            <img src={img03}></img>
                            <img src={img02} className={`${obClasses.shadow}`}></img>
                        </Box>
                        
                        <Box 
                            display="flex" justifyContent="space-around"
                            className={`
                                ${tourClasses.firstImpressionIcons}`}
                        >
                            <Card component={Box}
                                elevation={4}
                            >
                                <img src={svg01}></img>
                                <Typography 
                                    align="center"
                                    className={`${typo.body3}`}
                                >
                                    Add<br/> Images
                                </Typography>
                            </Card>
                            <Card component={Box}
                                elevation={4}
                            >
                                <img src={svg02}></img>
                                <Typography 
                                    align="center"
                                    className={`${typo.body3}`}
                                >
                                    Share your story
                                </Typography>
                            </Card>
                            <Card component={Box}
                                elevation={4}
                            >
                                <img style={{stroke: 'black'}} src={svg03}></img>
                                <Typography 
                                    align="center"
                                    className={`${typo.body3}`}
                                >
                                    Link to your channels
                                </Typography>
                            </Card>
                        </Box>
                    </Box>
                </Box>

                <Box className={`${obClasses.mbL}`}>
                    <Typography
                        className={`
                            ${typo.h2}
                            ${obClasses.mbS}
                            ${tourClasses.sectionHeading}`}
                        align="center"
                    >
                        <span style={{color: COLORS.get('gold')['main']}}>CIRCLE</span> UPGRADE
                    </Typography>
                    <Typography 
                        color="primary"
                        className={`
                            ${typo.body2}
                            ${obClasses.mbL}`}
                        align="center"
                    >
                        Increase your visibility with concierges
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.subtitle1}
                            ${obClasses.mbS}`}
                    >
                        Promote your events & news to concierges
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.body1}
                            ${obClasses.mbL}`}
                    >
                        Concierges are always looking for memorable experiences, even with last minute guest requests. Make sure your events are easy to find, share and book from the Circle.
                    </Typography>
                    <Box 
                        className={`
                            ${tourClasses.eventsImgContainer}
                            ${obClasses.mbL}`}
                    >
                        <div>
                            <img src={svg04}></img>
                        </div>
                        <img src={img04} className={`${obClasses.shadow}`}></img>
                    </Box>
                    <Typography
                        className={`
                            ${typo.subtitle1}
                            ${obClasses.mbS}`}
                    >
                        Build a professional network easily
                    </Typography>
                    <Typography
                        className={`
                            ${typo.body1}
                            ${obClasses.mbL}`}
                    >
                        Connect directly with hotels in the Circle; concierges value first-hand knowledge, experience & relationships as powerful tools to expertly guide guests.
                    </Typography>
                    <Box 
                        className={`
                            ${tourClasses.hotelImgContainer}`}
                    >
                        <img src={svg05}></img>
                        <img src={img05} className={`${obClasses.shadow}`} style={{borderRadius: '50%'}}></img>
                        <img src={img07} className={`${obClasses.shadow}`}></img>
                        <img src={img06} className={`${obClasses.shadow}`}></img>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default MembershipTour