import { Box, Button, Card, IconButton, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { dispatch } from 'slice'
import { tgMakeStyles } from '../../../../../lib/styles/TgMakeStyles'
import {OpenModalLink} from '../../../../dialogs/standard/components/OpenStandardDialog.Link'
import { closeModal, openModal } from '../../../../dialogs/standard/state/actions'
import { TYPOGRAPHY_STYLES } from '../../../../theme/typographies'
import { useTypographyStyles } from '../../../../theme/useTypography'
import CheckIcon from '@material-ui/icons/Check';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { COLORS } from '../../../../theme/colors'
import { PLANS_PLAIN_TEXT } from '../../../../invitation/utils'
import { useStateUserDetails } from '../../../../user/state/hooks/useStateUserDetails'
import { sendEmail } from '../../../../http/tg/email'
import { showNotification } from '../../../../notifications/state/actions'
import { useRouter } from '@uirouter/react'

const useStyles = tgMakeStyles(theme => ({
    card: {
        position: 'relative',
        minHeight: '3rem',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: COLORS.get('gold').main,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all .3s',
        '&.selected': {
            backgroundColor: COLORS.get('blue').main,
        },
        '&.disabled': {
            backgroundColor: 'white',
            color: COLORS.get('grey')[3],
            pointerEvents: 'none'
        },
        '&:hover': {
            cursor: 'pointer'
        },
        
        '& .icon': {
            position: 'absolute',
            top: 0, right: 0,
            margin: 0
        },
        '& .planName': {
            flexGrow: 1,
            textAlign: 'center',
            ...TYPOGRAPHY_STYLES.subtitle4
        }
    },
    btn: {
        '& .MuiButton-label': {
            ...TYPOGRAPHY_STYLES.body1
        }
    }
}))

export function UpgradeBusinessMembershipPlanForm({business}){

    const classes = useStyles()
    const typo = useTypographyStyles()
    const router = useRouter()

    const {info} = useStateUserDetails()

    const [selectedPlan, setSelectedPlan] = useState(PLANS_PLAIN_TEXT.indexOf(business.plan))
    const sendUpgradeRequest = e => {
        e.preventDefault()
        console.log('send')
        const D = new Date()
        const _payload = {
            "Business name": business.place.name,
            "Business ID": business.id,
            "Admin user email": info.mail,
            "Admin user ID": info.id,
            "Requested plan": PLANS_PLAIN_TEXT[selectedPlan],
        }
        sendEmail({
            subject: `BUSINESS REQUEST : MEMBERSHIP PLAN UPGRADE`,
            emailBody: [
                'A BUSINESS IS REQUESTING A PLAN UPGRADE:\n',
                ...Object.entries(_payload).map(([key, value]) => `${key} : ${value}`),
                `date: ${D.toLocaleString()}`
            ].join('\n')
        }).subscribe({
            next: result => {
                router.stateService.go('.', {upgrade: false})
                dispatch(closeModal())
                dispatch(showNotification({message: 'Thank you! Our TasteGods team will reach out shortly with your new account setup.'}))
                // also stores desired plan in localStorage
                localStorage.setItem(`biz:up:${business.id}`, selectedPlan)
            },
            error: err => {dispatch(showNotification({message: 'Unable to send upgrade request. Please reach us at circle@tastegods.com and we will assit you ;)'}))}
        })
    }

    const selectPlan = idx => e => {
        console.log(PLANS_PLAIN_TEXT[idx])
        setSelectedPlan(idx)
    }

    return (
        <form onSubmit={sendUpgradeRequest}>
            {/* basic member */}
            <Card elevation={4}
                onClick={selectPlan(0)}
                className={`${classes.card} clickable selected`}
            >
                <Typography
                    className={`planName`}
                >
                    Basic Member
                </Typography>
                <IconButton color="inherit" className={`icon`}>
                    <CheckIcon />
                </IconButton>
            </Card>
            {/* Dmaps upgrade */}
            <Card elevation={4}
                onClick={selectPlan(1)}
                className={`${classes.card} clickable ${selectedPlan === 1 ? 'selected' : ''}`}
            >
                <Typography
                    className={`planName`}
                >
                    Digital Maps Upgrade
                </Typography>
                <IconButton color="inherit" className={`icon`}>
                    {selectedPlan === 1 ? <CheckIcon /> : <AddCircleIcon />}
                </IconButton>
            </Card>
            {/* Dmaps + circle upgrade */}
            <Card elevation={4}
                onClick={selectPlan(2)}
                className={`${classes.card} clickable ${selectedPlan === 2 ? 'selected' : ''}`}
            >
                <Typography
                    className={`planName`}
                >
                    Digital Maps<br/>
                    <span 
                        style={{
                            color: selectedPlan === 2 ? COLORS.get('gold').main : COLORS.get('blue').main
                        }}
                    >
                        + Circle
                    </span> Upgrade
                </Typography>
                <IconButton color="inherit" className={`icon`}>
                    {selectedPlan === 2 ? <CheckIcon /> : <AddCircleIcon />}
                </IconButton>
            </Card>
            {/* industry icon */}
            <Card elevation={4}
                className={`${classes.card} clickable disabled`}
            >
                <Typography
                    className={`planName`}
                >
                    Industry Icon Upgrade<br/>
                    <span style={{...TYPOGRAPHY_STYLES.caption1}}>INVITE ONLY</span>
                </Typography>
            </Card>
            {/* submit */}
            <Box align="center" mb={2}>
                <Button 
                    type="submit"
                    color="primary" 
                    variant="contained" 
                    className={`${classes.btn}`}
                >
                    SUBMIT
                </Button>
            </Box>

            <Typography className={`${typo.body4}`}>
                Our TasteGods team will reach out shortly with your new account setup.
            </Typography>
        </form>
    )
}


export function UpgradeBusinessMembershipPlanLink({trackingProps, ...forwardProps}){
    const typo = useTypographyStyles()
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle={(
                <Typography className={`${typo.subtitle6}`}>
                    UPGRADE<br/>YOUR MEMBERSHIP
                </Typography>
            )}
            modalComponent={UpgradeBusinessMembershipPlanForm}
            modalComponentProps={trackingProps}>
                {forwardProps.children ? forwardProps.children : 'Upgrade'}
        </OpenModalLink>
    )
}

export const openUpgradeBusinessMembershipPlanDialog = (args) => {
    let modalConfig = {
        ...args,
        title: (
            <Typography style={{...TYPOGRAPHY_STYLES.subtitle6}}>
                UPGRADE<br/>YOUR MEMBERSHIP
            </Typography>
        ),
        component: UpgradeBusinessMembershipPlanForm
    }
    modalConfig && modalConfig.component && dispatch(openModal(modalConfig))
}