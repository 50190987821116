import guestRoutes from '../guest-console/routes/guestRoutes'
import conciergeRoutes from '../hotel-console/routes/conciergeRoutes'

export function hotelRoutes(router){
    return [
        ...guestRoutes(router),
        ...conciergeRoutes(router)
    ]
} 

export default hotelRoutes