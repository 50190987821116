export const COLORS = new Map([
    ['gold', {
        main: '#C6AD7C',
        light: '#D7C6A3',
        dark: '#947E4F',
        inactive: '#D7C6A3',
        contrastText: '#ffffff'
    }],
    ['blue', {
        main: '#11113F',
        light: '#585879',
        dark: '#0A0A26',
        inactive: '#585879',
        contrastText: '#ffffff'
    }],
    ['whiteTaintedRed', '#F8F6F7'],
    ['grey', {
        1: '#F8F6F7',
        2: '#E8E7E9',
        3: '#A3A3A3',
        4: '#464646',
        5: '#757575'
    }]
])