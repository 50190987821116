import { from } from 'rxjs';
import {TG_API} from '../'
import { getDefaultHotelMapById, getHotelMapPlacesArrayById, getPublicHotelMapById } from '../hotels';
import { defaultLocaleCompare } from '../../../../lib/utils';
import { map, tap } from 'rxjs/operators';
import { TRANS_INITIAL_STATE } from '../../../language/state/hooks/useStateTranslation';

export const retriveTranslationOfMap = ({
    hotelId,
    customMapId,
    language
}) => {
    const stroageKey = getKey({hotelId,customMapId})

    // When english is chosen, api call is not necessary
    if (language === TRANS_INITIAL_STATE.language){
        // fit the structure of response to that of hotel http module 
        return from(Promise.resolve({
            data : TRANS_INITIAL_STATE.translation
        }))
    }

    const localData = getTranslationFromStorage({stroageKey,language})

    if (localData) {
        return from(Promise.resolve({data:localData}))
    } else {
        return getPublicHotelMapById(customMapId,hotelId,language)
        .pipe(
            map((res)=>{
                res.data = res.data.hotelPlaces.sort((a,b)=>defaultLocaleCompare(a.name,b.name))
                return res
            }),
            tap((res)=>{
                setTranslationToLocal({
                    stroageKey,
                    language,
                    res: res.data
                })
            })
        )
    }
}


const getTranslationFromStorage = ({stroageKey,language}) => {

    const result = JSON.parse(window.localStorage.getItem(stroageKey))
    if (!result) {
        return null
    }
    return result[language];
}

const setTranslationToLocal = ({stroageKey,language,res}) => {
    let localData = JSON.parse(window.localStorage.getItem(stroageKey));
    localData = localData ? localData : {};
    localData[language] = res;
    window.localStorage.setItem(stroageKey,JSON.stringify(localData))
}

const getKey = ({hotelId,customMapId}) => {
    return hotelId ? `hotel-${hotelId}` :  `map-${customMapId}`;
}

// get hotel custom POI (along with standrd digimap POIs) from custom Map Id
export const getTranslationByPoiId = (poiId,lang) => from(TG_API({
    method: 'GET',
    url: `/hotels/customPOI/api/${poiId}`,
    params : {
        translate : lang
    }
}).then((res)=>{
    res.data = res.data.sort((a,b)=>defaultLocaleCompare(a.name,b.name))
    return res
}))

export const setLanguageToLocal = (lang) => {
    window.localStorage.setItem('language',lang);
}

export const getLanguageFromLocal = () => {
    return window.localStorage.getItem('language');
}