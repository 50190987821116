import produce from 'immer';

export const autocompleteReducers = (state, action) => {

    switch (action.type) {
        case 'ATTEMPT_AUTOCOMPLETE':
            return produce(state, draft => {
                draft.loading = action.payload.loading
                draft.cancelToken = action.payload.cancelToken
                draft.error = null
            })
        case 'COMPLETE_AUTOCOMPLETE':
            return produce(state, draft => {
                draft.loading = action.payload.loading;
                draft.cancelToken = null
            })
        case 'RECEIVE_AUTOCOMPLETE_RESULTS':
            return produce(state, draft => {
                draft.results = action.payload.results
            })
        case 'ERROR_ON_AUTOCOMPLETE':
            return produce(state, draft => {
                draft.error = action.payload.error
            })
        case 'RESET_AUTOCOMPLETE':
            return produce(state, draft => {
                // deconstruct sytax can't be applied to draft instanse. instead we use Object.keys to reflect all the values. 
                Object.keys(action.payload).map(v => {
                    draft[v] = action.payload[v]
                })
            })
        default:
            return state;
    }
}