import { Box, Card, Collapse, Container, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useRouter } from '@uirouter/react';
import React, { useEffect, useMemo, useState } from 'react';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import Mixpanel from '../../../../lib/mixpanel'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { TranslationToggleButton } from '../../../language/components/TranslationToggleButton';
import useStateTranslation from '../../../language/state/hooks/useStateTranslation';
import { useTranslationHook } from '../../../language/state/hooks/useTranslationHook';
import { TYPOGRAPHY_STYLES } from '../../../theme/typographies';
import { useTypographyStyles } from '../../../theme/useTypography';
import { ExpansionPanelItem } from '../../../maps/digital-map/common/components/GridView/ExpansionPanel';
// import { FeatureTabsDisplay } from '../../../maps/digital-map/common/components/GridView/FeatureTabsBox';
// import { FeatureGridDisplay } from '../../../maps/digital-map/common/components/GridView/GridView';
import { useStateGeoJsonOverlay } from '../../../maps/digital-map/hooks';

import GoogleMapDirectionsFab from './GoogleMapDirectionsFab';
import { SeeMapOnFab } from './SeeOnMapFab';
import TipBox from './TipBox';
import AboutText from './AboutText';
import { BusinessMapIcon } from '../../../maps/digital-map/common/components/poi-card/PoiCardHeader';
import PoiCardForGuestUi from './PoiCard';
import { COLORS } from '../../../theme/colors';

const useStyles = tgMakeStyles(theme => ({
    placeOrderPin: {
        width: '2rem',
        height: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        color: 'white',
        backgroundColor: COLORS.get('grey')[3],
        marginRight: '1rem',
        position: 'absolute',
        top: '-1.5rem',
        zIndex: 1
    },
    collapsBtn: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))


export default function GmFeaturesAsList({
    map,
    features,
    hotel
}) {
    const classes = useStyles()
    // Retrive google.maps.Data instance which actually includes features.
    const geoJsonDataLayers = useStateGeoJsonOverlay();
    
    // console.log('geoJsonDataLayers', geoJsonDataLayers, geoJsonDataLayers.source)

    const [gmData, setGmData] = useState()
    const [hotelMap, setHotelMap] = useState()
    useEffect(()=> {
        // we use only one layer for now: geoJsonDataLayers[0]
        geoJsonDataLayers.length > 0 && setGmData(geoJsonDataLayers[0].gmData);
        geoJsonDataLayers.length > 0 && setHotelMap(geoJsonDataLayers[0].source);
    },[geoJsonDataLayers])

    // callback when handleSeeOnMap is clicked.
    const router = useRouter();
    const handleSeeOnMap = (feature) => {
        Mixpanel('track')('click_see_on_map', {
            GooglePlaceID: feature.getProperty('googlePlaceId'),
            poiName: feature.getProperty('name')
        })
        // Toggle to map view through ui router 
        router.stateService.go('root.hotel-map.map',{list: false})
        // trigger click event. 
        .then(window.google.maps.event.trigger(gmData, 'click',null,feature))
        // Pan to where feature should be.
        // For now, map just pan to the last LatLng point of the feature.
        feature.getGeometry().forEachLatLng((val)=>{
            map.panTo(val);
        });
    }
    
    return features && features.length > 0
        ? (
            <Container maxWidth="xs" style={{marginTop: '2rem'}}>
                {features.map( (feature, idx) => {
                    return (
                        <Box key={idx} position="relative">
                            {hotelMap && hotelMap.ordered && (
                                <Box className={`${classes.placeOrderPin}`} mb={0.25}>
                                    {idx+1}
                                </Box>
                            )} 
                            <HotelPlaceFeatureExpandableListItem 
                                feature={feature}
                                map={map}
                                handleSeeOnMap={handleSeeOnMap}
                                hotelName={hotel && hotel.name}
                            />
                        </Box>
                    )
                })}
            </Container>
        )
        : (
            <Container maxWidth="xs">
                <Typography>There are no locations to display</Typography>
            </Container>
        )
}

function HotelPlaceFeatureExpandableListItem({
    feature,
    map,
    handleSeeOnMap,
    hotelName,
    ...props
}){

    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));
    const typo = useTypographyStyles()
    const classes = useStyles()
    
    const [translationState] = useStateTranslation();

    const [open, setOpen] = useState(false)
    const onToggle = e => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(prev => !prev)
    }

    const _business = feature.getProperty('place')

    const _name = feature.getProperty('name')

    return (
        <Card {...props}
            style={{
                [open ? 'boxShadow' : 'invalid']: `2px 2px 15px #585879`
            }}
            component={Box} mb={4}
            elevation={open ? 5 : 3}
        >
            <Box display="flex" 
                onClick={onToggle} 
                className={`${classes.collapsBtn}`}
                p={2}
            >
                <Typography
                    component={Box}
                    flex="none"
                    className={`${typo.subtitle1}`}
                >
                    {_name}
                </Typography>
                <div style={{flexGrow: 1}}></div>
                <BusinessMapIcon color="primary" business={_business}/>
                <IconButton component={Box} 
                    m={'0 !important'} p={'0 !important'} 
                    onClick={onToggle}
                >
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
            </Box>
            <Collapse 
                in={open} 
                timeout={{ enter: 200, exit: 200 }}
            >
                {/* {props.children} */}
                {open && <PoiDetails
                    trackingProps={{
                        GooglePlaceID: feature.getProperty('googlePlaceId'),
                        poiName: feature.getProperty('name'),
                    }}
                >
                    <PoiCardForGuestUi 
                        BoxProps={{pb: '1rem'}}
                        isMapDisplay={false}
                        translation={translationState.translation} 
                        isMobile={isMobile}
                        feature={feature}
                        map={map} 
                        handleSeeOnMap={handleSeeOnMap}
                        hotelName={hotelName}
                    />
                </PoiDetails>
                }
            </Collapse>
        </Card>
    )
}

const useItemCompStyles = tgMakeStyles(theme => ({
    FabLabel : {
        ...TYPOGRAPHY_STYLES.body2
    },
    ButtonWrapperBox : {
        display:'block',
        marginRight:theme.spacing(1),
        marginBottom:theme.spacing(1),
    },
    FabRoot : {
        boxShadow: 'none',
    },
    poiAddress : {
        ...TYPOGRAPHY_STYLES.body1
    },
    text : {
        ...TYPOGRAPHY_STYLES.body1
    },
    transButtonRoot : {
        padding : 0
    },
    buttonWrapper : ({isMobile})=>{
        return  {
            // textAlign : isMobile ? 'left' : 'center',
            textAlign : 'left',
            margin : `${theme.spacing(2)} 0`
        }
    },
    seeOnMapFabRoot : {
        boxShadow: 'none',
        background: 'transparent',
        color: theme.palette.primary.main,
        border: "2px solid"
    }
}))

const ItemComp = props => {
    const {
        isMobile,
        isCustom, 
        feature, 
        map, 
        handleSeeOnMap, 
        translation } = props;
    
    const classes = useItemCompStyles({isMobile})
    
    let poiData = {};
    feature.forEachProperty((val, key) => {
        poiData = {
            ...poiData,
            [key]: val
        }
    })

    const panelItem = {
        feature,
        expandIcon : <ExpandMoreIcon />,
        isCustom,
        isMobile,
        summaryText : <PanelHeader isCustom={isCustom} text={poiData.name} isMobile={isMobile}/>
    };

    const directionsProps = {
        lat: poiData.lat,
        lng: poiData.lng,
        googlePlaceId: poiData.googlePlaceId,
        name: poiData.name,
        classes : {
            label : classes.FabLabel,
            root : classes.FabRoot 
        },
        BoxProps : {
            className : classes.ButtonWrapperBox
        }
    }

    const seeMapOnFabProps = {
        feature,
        map,
        classes : {
            label : classes.FabLabel,
            root : classes.seeOnMapFabRoot
        },
        BoxProps : {
            className : classes.ButtonWrapperBox
        },
        handleSeeOnMap
    }

    const {texts,showTrans,setShowTrans} = useTranslationHook({
        translation,
        tipText:poiData.tip,
        aboutText:poiData.place.place.about,
        // id:poiData.id
        id:poiData.googlePlaceId
    })

    return (
        <Box my={2}>
            <ExpansionPanelItem {...panelItem}>
                <PoiDetails
                    trackingProps={{
                        GooglePlaceID: poiData.googlePlaceId,
                        poiName: poiData.name,
                    }}
                >
                    <Box width={"100%"} textAlign='left'>
                        {poiData && poiData.place && poiData.place.place.businessStatus === 'CLOSED_TEMPORARILY' && 
                            <Typography className={classes.poiAddress}
                                color="error"
                            >
                                <b>Temporarily Closed</b>
                            </Typography>
                        }
                        <Typography className={classes.poiAddress}>
                            {poiData.address}
                        </Typography>
                    </Box>
                    <Box 
                        className={`${classes.buttonWrapper}`}>
                        <GoogleMapDirectionsFab {...directionsProps}/>
                        <SeeMapOnFab {...seeMapOnFabProps}/>
                    </Box>
                    {/* Translation button */}
                    
                    {translation.length > 0 && (poiData.place.place.about || poiData.tip) && 
                        <TranslationToggleButton show={showTrans} onClick={()=>{setShowTrans(prev => !prev)}}/>
                    }
                    {/* poi about */}
                    {poiData.place.place.about && 
                        <AboutText 
                            className={classes.text}
                        >
                            {texts.aboutText}
                        </AboutText>
                    }
                    
                    {/* poi tip */}
                    {poiData.tip &&
                        <TipBox>
                            <span className={classes.text}>
                                {texts.tipText}
                            </span>
                        </TipBox>
                    }
                </PoiDetails>
            </ExpansionPanelItem>
        </Box>
    )
}

function PoiDetails({trackingProps, ...forwardProps}){
    useEffect( () => {
        // console.log('track impression_poi_details', trackingProps)
        Mixpanel('track')('impression_poi_details', trackingProps)
        return ()=>{}
    }, [])
    return (
        <>
            {forwardProps.children}
        </>
    )
    
}

const usePanelHeaderStyles = tgMakeStyles(theme => ({
    headerText : ({isMobile}) => {
        let typo = isMobile ? TYPOGRAPHY_STYLES.subtitle2 : TYPOGRAPHY_STYLES.h5;
        return {
            ...typo,
            ...{
                textTransform: isMobile ? 'uppercase' : 'none',
                wordBreak: "break-all"
            }

        }
    },
    // truncate if text goes beyound 2 lines
    textTruncate : ({isMobile}) => { 
        return  isMobile ? 
        {
            "@supports (-webkit-line-clamp: 2)": {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': `2`,
                '-webkit-box-orient': 'vertical'
            }
        } : null
    }
}))

const PanelHeader = ({expanded, text, isCustom, isMobile}) => {
    const classes = usePanelHeaderStyles({isMobile})
    return (
        <Typography 
            className={`${classes.headerText} ${expanded ? null : classes.textTruncate}`}
            color={isCustom ? 'secondary':'primary'}>
            {text}
        </Typography>
    )
}


    // const [splitFeatures,setSplitFeatures] = useState({
    //     customFeatures: [], standardFeatures: []    
    // })

    // // /!\ no more need to split hotel places
    // useMemo(()=> {
    //     const { customFeatures, standardFeatures } = features.reduce((acum, feat, idx) => {
    //         if (feat.getProperty('custom')) {
    //             acum.customFeatures.push(feat)
    //         } else {
    //             acum.standardFeatures.push(feat)
    //         }
    //         return acum
    //     }, { customFeatures: [], standardFeatures: [] })

    //     setSplitFeatures({
    //         customFeatures, standardFeatures
    //     })
    // },[features])


// orgnize props that are shared between desktop and mobile
    // const featureCategories = [];
    // if (splitFeatures.customFeatures.length > 0) {
    //     featureCategories.push({
    //         headerNode : (props)=> <Typography variant="h2" className={typoClasses.h2} {...props}>
    //                                 {/* Break the paragraph at 'for you' part */}
    //                                     <span style={{display : 'inline-block'}}>Recommended </span>  
    //                                     <span style={{display : 'inline-block'}}>&nbsp;for You</span>
    //                                 </Typography>,
    //         listsComp : ()=>{
    //             return (
    //                 <>
    //                     {splitFeatures.customFeatures.length > 0 && splitFeatures.customFeatures.map((val,idx)=>{
    //                         return (
    //                             <ItemComp 
    //                                 translation={translationState.translation} 
    //                                 key={idx} 
    //                                 isMobile={isMobile} 
    //                                 feature={val} 
    //                                 isCustom={true} 
    //                                 map={map} 
    //                                 handleSeeOnMap={handleSeeOnMap}/>
    //                         )
    //                     })}
    //                     {splitFeatures.customFeatures.length === 0 && <p>You have not yet added any personalised recommendations.</p>}
    //                 </>
    //         )},
    //         color : 'secondary'
    //     })
    // } 
    
    // featureCategories.push({
    //     headerNode : (props)=>null,
    //     listsComp : ()=>{
    //         return (
    //             <>
    //                 {splitFeatures.standardFeatures.length > 0 &&  
    //                     splitFeatures.standardFeatures.map((val,idx)=>{
    //                     return (
    //                         <ItemComp 
    //                             key={idx} 
    //                             translation={translationState.translation} 
    //                             isMobile={isMobile} 
    //                             feature={val} 
    //                             map={map} 
    //                             handleSeeOnMap={handleSeeOnMap}
    //                         />
    //                     )
    //                 })}
    //                 {splitFeatures.standardFeatures.length === 0 && <p>Please make sure you are connected to the internet.</p>}
    //             </>
    //     )},
    //     color : 'primary'
    // })

    // const props = {
    //     featureCategories,
    //     hotel        
    // }