import axious from 'axios';
import { autocomplete } from '../../../http/tg/autocomplete';
import { INITIAL_STATE } from '../hooks/useStateAutocomplete';

export const getAutocompleteResults = ({ searchStr, per_page, hotelId,type, }, dispatch) => {
    // cancelToken includes method to cancel Http request.
    const [api$, cancelToken] = autocomplete({ searchStr, per_page, hotelId,type })
    // store cancelToken to state. Next time this method is called and if cancelToken is stored,
    // cancel method is called inside reducers
    dispatch(attemptAutocomplete(cancelToken))

    api$
    .subscribe({
        next: res => {
            dispatch(receiveAutocomplete(res))
        },
        complete: () => {
            dispatch(completeGetAutocomplete())
        },
        error: (e) => {
            if (!axious.isCancel(e)){
                dispatch(errorOnGetAutocomplete(e))
                dispatch(completeGetAutocomplete())
            }
        }
    })
}

export const attemptAutocomplete = (cancelToken) => {
    return {
        type: 'ATTEMPT_AUTOCOMPLETE',
        payload: {
            loading: true,
            cancelToken
        }
    }
}

export const errorOnGetAutocomplete = error => {
    return {
        type: 'ERROR_ON_AUTOCOMPLETE',
        payload: {
            error
        }
    }
}

export const receiveAutocomplete = (res) => {
    return {
        type: 'RECEIVE_AUTOCOMPLETE_RESULTS',
        payload: res
    }
}

export const completeGetAutocomplete = () => {
    return {
        type: 'COMPLETE_AUTOCOMPLETE',
        payload: {
            loading: false
        }
    }
}

export const resetAutocomplete = () => {
    return {
        type : 'RESET_AUTOCOMPLETE',
        payload : INITIAL_STATE
    }
}