import { Box, ButtonBase, Drawer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { dispatch } from 'slice';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { closeContextDrawer } from '../state/actions';
import { useStateContextDrawer } from '../state/hooks/useStateContextDrawer';


const useStyles = tgMakeStyles(theme => ({
    paperRoot: ({noPadding}) => {
        const BASE_PAPER_STYLE = {
            boxShadow: theme.shadows[10],
            paddingLeft : theme.spacing(noPadding ? 0 : 4),
            paddingRight : theme.spacing(noPadding ? 0 : 4),
            paddingTop: theme.spacing(6),
            paddingBottom: 0,
        }
        return BASE_PAPER_STYLE;
    },
    swipeHandle: {
        height: theme.spacing(.5),
        width: '25%',
        backgroundColor: theme.palette.greyscale.grey_2,
        margin: 'auto',
        marginTop: '1rem',
        marginBottom: '1rem',
        borderRadius: theme.spacing(.25)
    },
    swipeHandleWrapper : {
        width: '100%',
        height : theme.spacing(4)
    }
}))

// NOTE : not super happy with the end result for the swipe feature
// TODO : isolate swipe logic on separate module (custom hook aand/or plain functions)
export default function MobileContextDrawer(){
    const {
        open, 
        component, 
        onCloseFn,
        fullScreen,
        noPadding
    } = useStateContextDrawer()
    const InjectedComp = component

    // execute callback when drawer is closed.
    // NOTE : Use onClose property when material ui is updated to v4.9.0
    useEffect(()=>{
        if (!open){
            onCloseFn && onCloseFn()
        }
    },[open])
    
    const classes = useStyles({noPadding})
    
    // initially the drawer is open in state = 'mini'
    // when fully open, state = 'full'
    const [swipeState, setSwipeState] = useState('mini')
    // paperInlineStyle is the value that is applied to the drawer's paper CSS
    const [paperInlineStyle, setPaperInlineStyle] = useState({height: 'auto'})
    // paperFinalHeight is the height in pixel after swipe action has completed
    const [paperFinalHeight, setPaperFinalHeight] = useState(0)
    // grab the drawer element to be able to get clientHeight
    const paperRef = useRef()
    const paperInitialHeight = useMemo(() => {
        const clientH = paperRef.current
            ? paperRef.current.clientHeight
            : 0
        if(open){
            setPaperFinalHeight(clientH)
        } else {
            // reset values for next time we open
            setPaperFinalHeight(clientH)
            setPaperInlineStyle({
                height: 'auto',
                maxHeight: '50%'
            })
            setSwipeState('mini')
        }
        return clientH
    }, [open])
  
    // fired when user is swiping, adjust the sizez of the drawer to follow swipe motion
    function handleSwipeStart(e){
      if(e.dir === 'Up'){
        setPaperInlineStyle({
            height: paperInitialHeight + e.deltaY,
            maxHeight: 'initial'
        })
      }
      else if (e.dir === 'Down'){
        setPaperInlineStyle({
            height: paperFinalHeight + e.deltaY,
            maxHeight: 'initial'
        })
      }
    }
  
    function handleOpenMini(){
        setPaperInlineStyle({
            height: 'auto',
            maxHeight: '50%'
        })
        setSwipeState('mini')
    }
    function handleOpenFull(){
        setPaperInlineStyle({
            height: '100%',
            maxHeight: 'initial'
        })
        setSwipeState('full')
    }
    function handleClose(){
        dispatch(closeContextDrawer())
        // onCloseFn && onCloseFn()
    }

    useEffect(() => {
        setPaperFinalHeight(paperRef.current.clientHeight)
    }, [swipeState])
    
    // handle completed swipe event :
    // either expand to full height
    // or reduce from full to minified 
    // or close drawer
    function handleSwipeDone(e){
      if(e.dir === 'Down' && swipeState === 'mini'){
        handleClose()
      }
      else if (e.dir === 'Down' && swipeState === 'full'){
        handleOpenMini()
      }
      else if (e.dir === 'Up'){
        handleOpenFull()
      }
    }
  
    // get react swipeable props from custom hook
    const _react_swipeable_config_object = {
      delta: 20,                           // min distance(px) before a swipe starts
      preventDefaultTouchmoveEvent: true   // preventDefault on touchmove, required to prevent window refresh when swiping down
    }
    const swipeHandlers = useSwipeable({
      onSwiping: handleSwipeStart,
      onSwipedDown: handleSwipeDone,
      onSwipedUp: handleSwipeDone,
      ..._react_swipeable_config_object
    })

    return (
        <Drawer 
            variant='persistent'
            anchor='bottom'
            open={open}
            classes={{
                paper: classes.paperRoot
            }}
            ModalProps={{
                keepMounted: false
            }}
            PaperProps={{
                ref: paperRef,
                style: fullScreen 
                    ? {height: '100vh'} 
                    : paperInlineStyle
            }}
        >
            {/* drawer buttons : swipe and close */}
            <Box flexGrow='none' 
                display='flex'
                justifyContent='center'
                position='absolute' width='100%'
                top={0} left={0}
            >
                {/* swipe */}
                {!fullScreen && <ButtonBase className={classes.swipeHandleWrapper} {...swipeHandlers}>
                    <Box className={classes.swipeHandle}></Box>
                </ButtonBase>}

                {/* close */}
                <IconButton
                    style={{
                        position: 'absolute',
                        top: 0, right: 0,
                        margin: 0
                    }}
                    type="button" 
                    onClick={handleClose} 
                    aria-label="reset"
                >
                    <CloseIcon color="inherit" />
                </IconButton>
            </Box>
            <Box flexGrow={1} overflow='auto'>
                { InjectedComp
                    && <InjectedComp 
                        isCompact={swipeState === 'mini'}
                        onCompactToggle={handleOpenFull}
                    />
                }
            </Box>
        </Drawer>
    );
}