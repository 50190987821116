import { Paper, useTheme } from '@material-ui/core';
import React from 'react';
import { WelcomeFooter } from '../../footer/components/WelcomeFooterNew';
import UserDetailsDiv from "../components/UserDetailsDiv";
import { useStateUserDetails } from '../state/hooks/useStateUserDetails';

export function UserDetailsRoot(){
    const theme = useTheme()
    const details = useStateUserDetails()
    return details && details.info && details.info.alias
        ? (
            <div>
                <Paper
                    variant="elevation"
                    elevation={8}
                    style={{margin: '1em'}}
                >
                    <UserDetailsDiv 
                        userInfo={details.info}
                    />
                </Paper>
                <Paper
                    style={{
                        margin: '1em',
                        backgroundColor: theme.palette.primary.main
                    }}
                >
                    <WelcomeFooter 
                        fullScreen={true}
                    />
                </Paper>
            </div>
        )
        : 'LOADING ...'
}

const userRoutes = [
    {
        name: 'root.user',
        url: '/user',
        abstract: true
    },
    {
        name: 'root.user.settings',
        url: '/settings',
        views: {
            'content@root': {
                component: UserDetailsRoot
            }
        }
    }
]

export default userRoutes;