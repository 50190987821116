import { login, logout, isAuthenticated, recoverPassword as recPassword, resetPassword as resPassword, unauthenticate } from "../../../http/tg/authentication";
import { dispatch } from 'slice';
import { closeHeader } from "../../../header/state/actions";
import { closeModal } from "../../../dialogs/standard/state/actions";
import { showNotification } from "../../../notifications/state/actions";

export const doLogin =  credentials => () => {
    dispatch(attemptLogin());
    login(credentials).subscribe({
        complete: () => {
            // close modals 
            dispatch(closeModal())
            // close header pannel
            dispatch(closeHeader())
            dispatch(loginSucceeded())
        },
        error: e => {dispatch(loginFailed(e))}
    })
}

const attemptLogin = () => ({type: 'AUTH/ATTEMPTS/LOGIN'})
const loginSucceeded = () => ({type: 'AUTH/SUCCESS/LOGIN'})
const loginFailed = (e) => ({
    type: 'AUTH/FAILED/LOGIN',
    payload: e
})

export const doLogout = (opts={}) => () => {
    dispatch(attemptLogout());
    logout().subscribe({
        complete: () => {
            // close modals 
            !opts.keepModal &&  dispatch(closeModal())
            // close header pannel
            dispatch(closeHeader())
            dispatch(logoutSucceeded())
        },
        error: e => {dispatch(logoutFailed(e))}
    })
}

const attemptLogout = () => ({type: 'AUTH/ATTEMPTS/LOGOUT'})
const logoutSucceeded = () => ({type: 'AUTH/SUCCESS/LOGOUT'})
const logoutFailed = (e) => {
    // Even when logout API request is failed, user should be unauthenticated so that
    // we can prevent user to access auth required states in UI ROUTES
    unauthenticate(); 
    return {type: 'AUTH/FAILED/LOGOUT'}
}

export const recoverPassword = payload => () => {
    dispatch(attemptRecoverPassword())
    recPassword(payload).subscribe({
        complete: () => {
            dispatch(recoverPasswordSucceeded())
            dispatch(showNotification({
                message: 'We have sent you a recovery email. Check your mailbox and follow the instructions.'
            }))
        },
        error: (error) => {dispatch(recoverPasswordFailed(error))}
    })
}

const attemptRecoverPassword = () => ({type: 'AUTH/ATTEMPTS/RECOVER/PASSWORD'})
const recoverPasswordSucceeded = () => ({type: 'AUTH/SUCCESS/RECOVER/PASSWORD'})
const recoverPasswordFailed = (e) => ({
    type: 'AUTH/FAILED/RECOVER/PASSWORD',
    payload: e
})
export const recoverPasswordReset = () => ({type: 'AUTH/RESET/RECOVER/PASSWORD'})

export const resetPassword = payload => () => {
    dispatch(attemptResetPassword())
    resPassword(payload).subscribe({
        complete: () => {
            dispatch(resetPasswordSucceeded())
            dispatch(showNotification({
                message: 'Your password has been successfuly reset.'
            }))
        },
        error: (error) => {dispatch(resetPasswordFailed(error))}
    })  
}

const attemptResetPassword = () => ({type: 'AUTH/ATTEMPTS/RESET/PASSWORD'})
const resetPasswordSucceeded = () => ({type: 'AUTH/SUCCESS/RESET/PASSWORD'})
const resetPasswordFailed = (e) => ({
    type: 'AUTH/FAILED/RESET/PASSWORD',
    payload: {
        message: e.response.data.error
    }
})
export const resetPasswordReset = () => ({type: 'AUTH/RESET/RESET/PASSWORD'})

export const refreshAuthStatus = () => ({
    type: 'AUTH/REFRESH/STATUS',
    payload: {
        isAuthenticated: isAuthenticated()
    }
})