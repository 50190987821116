import { Box, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import { GoogleMyBusinessInfoDialogLink } from './dialogs/GoogleMyBusinessInfoDialog'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OpeningHours from '../../../explore/components/place-profile/OpeningHours'
import { WhatGuestShouldKnowInfoDialogLink } from './dialogs/WhatGuestShouldKnowInfoDialog'
import { WhatConciergeWantToKnowTodayInfoDialogLink } from './dialogs/WhatConciergeWantToKnowTodayInfoDialog'
import CheckGoogleUpdatesLink from './CheckGoogleUpdates.Link';

export function EditBusinessOperationsFormControls({business, ...props}){
    const typo = useTypographyStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))
    return business 
        ? isDesktop 
            ?(
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Box mb={2} mt={2}>
                            <Box className={`${typo.body3}`} display="flex" alignItems="center" mb={1}>
                                Summary of Operations
                                <WhatConciergeWantToKnowTodayInfoDialogLink
                                    id="open-what-concierge-want-to-know-info-dialog"
                                    className="clickable"
                                    color="inherit"
                                >
                                    <HelpOutlineIcon
                                        color="primary" 
                                        style={{marginLeft: '.5rem'}}
                                        fontSize="inherit"/>   
                                </WhatConciergeWantToKnowTodayInfoDialogLink>
                            </Box>
                            <Box mb={1}>
                                <Typography className={`${typo.body4}`}>
                                    Announce current services, changes and instructions to book
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                value={business.operationStatus}
                                id="business_operationstatus"
                                name="business_operationstatus"
                                type="text"
                                multiline
                                rows="4"
                                variant="outlined"
                            />
                        </Box>
                        
                        <Box mb={2}>
                            <Box className={`${typo.body3}`} display="flex" alignItems="center" mb={1}>
                                Important Info Before Visiting
                                <WhatGuestShouldKnowInfoDialogLink
                                    id="open-what-guest-want-to-know-info-dialog"
                                    className="clickable"
                                    color="inherit"
                                >
                                    <HelpOutlineIcon
                                        color="primary" 
                                        style={{marginLeft: '.5rem'}}
                                        fontSize="inherit"/>
                                </WhatGuestShouldKnowInfoDialogLink>
                                
                            </Box>
                            <Box mb={1}>
                                <Typography className={`${typo.body4}`}>
                                    Highlight details to share with guests in advance to ensure a smooth experience
                                </Typography>
                            </Box>
                            <TextField
                                fullWidth
                                value={business.visitGuidelines}
                                id="business_visitguidelines"
                                name="business_visitguidelines"
                                type="text"
                                multiline
                                rows="4"
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        {/* open hours */}
                        <Box mb={2} mt={2}>
                            <Box className={`${typo.body3}`} display="flex" alignItems="flex-start" mr={2}>
                                Hours
                                <GoogleMyBusinessInfoDialogLink
                                    id="hours-open-google-my-business-info-dialog"
                                    className="clickable"
                                    color="inherit"
                                >
                                    <HelpOutlineIcon
                                        color="primary" 
                                        style={{marginLeft: '.5rem'}}
                                        fontSize="inherit"/>
                                </GoogleMyBusinessInfoDialogLink>
                            </Box>
                            <OpeningHours place={business.place} showLastUpdate={false}/>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <CheckGoogleUpdatesLink businessId={business.id} id="hours-check-google-updates-link"/>
                        </Box>
                    </Grid>
                </Grid>
            )
            : (
                <>
                    {/* open hours */}
                    <Box>
                        <Box display="flex" mt={2} mb={2}>
                            <Box className={`${typo.body3}`} display="flex" alignItems="flex-start" mr={2}>
                                Hours
                                <GoogleMyBusinessInfoDialogLink
                                    id="hours-open-google-my-business-info-dialog"
                                    className="clickable"
                                    color="inherit"
                                >
                                    <HelpOutlineIcon
                                        color="primary" 
                                        style={{marginLeft: '.5rem'}}
                                        fontSize="inherit"/>
                                </GoogleMyBusinessInfoDialogLink>
                            </Box>
                            <OpeningHours place={business.place} showLastUpdate={false}/>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" mb={2}>
                            <CheckGoogleUpdatesLink businessId={business.id} id="hours-check-google-updates-link"/>
                        </Box>
                    </Box>

                    <Box mb={2}>
                        <Box className={`${typo.body3}`} display="flex" alignItems="center" mb={1}>
                            Summary of Operations
                            <WhatConciergeWantToKnowTodayInfoDialogLink
                                id="open-what-concierge-want-to-know-info-dialog"
                                className="clickable"
                                color="inherit"
                            >
                                <HelpOutlineIcon
                                    color="primary" 
                                    style={{marginLeft: '.5rem'}}
                                    fontSize="inherit"/>   
                            </WhatConciergeWantToKnowTodayInfoDialogLink>
                        </Box>
                        <Box mb={1}>
                            <Typography className={`${typo.body4}`}>
                                Announce current services, changes and instructions to book
                            </Typography>
                        </Box>
                        <TextField
                            fullWidth
                            value={business.operationStatus}
                            id="business_operationstatus"
                            name="business_operationstatus"
                            type="text"
                            multiline
                            rows="4"
                            variant="outlined"
                        />
                    </Box>
                    
                    <Box mb={2}>
                        <Box className={`${typo.body3}`} display="flex" alignItems="center" mb={1}>
                            Important Info Before Visiting
                            <WhatGuestShouldKnowInfoDialogLink
                                id="open-what-guest-want-to-know-info-dialog"
                                className="clickable"
                                color="inherit"
                            >
                                <HelpOutlineIcon
                                    color="primary" 
                                    style={{marginLeft: '.5rem'}}
                                    fontSize="inherit"/>
                            </WhatGuestShouldKnowInfoDialogLink>
                            
                        </Box>
                        <Box mb={1}>
                            <Typography className={`${typo.body4}`}>
                                Highlight details to share with guests in advance to ensure a smooth experience
                            </Typography>
                        </Box>
                        <TextField
                            fullWidth
                            value={business.visitGuidelines}
                            id="business_visitguidelines"
                            name="business_visitguidelines"
                            type="text"
                            multiline
                            rows="4"
                            variant="outlined"
                        />
                    </Box>
                </>
            )
        : <div>Loading...</div>
}