import {makeStyles} from '@material-ui/styles'

/**
 * Warapper function for makeStyles.
 * makeStyles injects style tags in a different order between dev and prod,
 * which produces different layout in different environment.
 * 
 * Ref :
 * https://material-ui.com/styles/api/#makestyles-styles-options-hook
 * https://github.com/mui-org/material-ui/issues/16456
 * https://app.asana.com/0/626853556519404/1163748961855601/f
 */
export function tgMakeStyles(){
    // convert arguments into pure array.
    const args = [].slice.call(arguments);
    // set index default to 1 in options object, so that make style style tags are injected after default tags.
    args[1] = {
        ...{
            index:1
        },
        ...args[1],
    }
    return makeStyles(...args)
}
