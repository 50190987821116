import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles";
import { COLORS } from "../../../../theme/colors";
import { TYPOGRAPHY_STYLES } from "../../../../theme/typographies";

export const useStyles = tgMakeStyles(theme => {
    return {
        plansgrid: {
            display: 'grid',
            rowGap: '2rem',
            '& button': {
                borderRadius: '1.5625rem',
                minWidth: '10rem',
                minHeight: '2.25rem',
                ...TYPOGRAPHY_STYLES.h4
            },
            '& button.select_plan': {
                color: 'white',
                backgroundColor: '#292952',
            }
        },
        planBox: {
            padding: theme.spacing(2),
            boxShadow: `2px 2px 30px ${COLORS.get('grey')[4]}`,
            borderRadius: '1rem',
            transition: 'all .3s',
            // blue etc. deals with the text for circle upgrade
            '& .blue': {
                color: '#292952'
            },
            '& .blue .circleTxt': {
                color: COLORS.get('gold').main
            },
            '&.selected .blue .circleTxt': {
                color: '#292952'
            },
            // free deals with the basic plan
            '&.free.selected': {
                backgroundColor: '#292952',
                color: 'white'
            },
            // generic classes for selectable plans
            '&.selected': {
                backgroundColor: COLORS.get('gold').main,
                color: '#292952'
            },
            '&:hover': {
                boxShadow: `2px 2px 30px #292952`
            },
            '&.selected button': {
                backgroundColor: 'white',
                color: COLORS.get('grey')[4]
            },
            // disabled icon upgrade
            '&.disabled': {
                color: COLORS.get('grey')[3]
            }
        },
        summaryBox: {
            backgroundColor: '#292952',
            boxShadow: `2px 2px 30px ${COLORS.get('grey')[4]}`,
            borderRadius: '1rem',
            transition: 'all .3s',
            border: '4px solid #292952',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& > p': {
                color: 'white'
            }
        },
        summaryTable: {
            paddingLeft: '25%',
            paddingRight: '25%',
            tableLayout: 'max-content',
            backgroundColor: COLORS.get('grey')[1],
            color: `${COLORS.get('grey')[4]} !important`,
            borderSpacing: '1rem'
        }
    }
})

export default useStyles