import React from 'react'
import {dispatch} from 'slice';
import {openConfirmationDialog} from '../../../dialogs/confirmation/states/actions'

import MapsDashboard from '../components/MapsDashboard';
import { getHotelMapById } from '../../../http/tg/hotels';
import MapSummary from '../components/MapSummary';
import { map } from 'rxjs/operators';
import { Box } from '@material-ui/core';
import { useRouter } from '@uirouter/react';
import EditHotelMap from '../components/EditHotelMap';
import { clearHotelMap } from '../state/editHotelMapReducer';

export const manageMapsRoutes = [
    {
        name: 'root.hotel-console.maps',
        abstract: true,
        url: "/maps",
        // views: {
        //     'content@root': StandardMapQRCode
        // }  
    },
    {
        name: 'root.hotel-console.maps.dashboard',
        url: '/dashboard',
        views: {
            'content@root': MapsDashboard
        }
    },
    {
        name: 'root.hotel-console.maps.map',
        url: '/map/:customMapId',
        params: {
            customMapId: {
                type: 'int'  
            }
        },
        resolve: [
            {
                token: 'map',
                deps: ['$stateParams'],
                resolveFn: function($stateParams){
                    console.log()
                    return getHotelMapById($stateParams.customMapId)
                        .pipe(map(res => res.data))
                        .toPromise()
                }
            }
        ],
        views: {
            'content@root': MapSummaryPage,
        }
    },
    {
        name: 'root.hotel-console.maps.map.edit',
        url: '/edit?list',
        params: {
            list: {
                type: 'bool',
                value: false,
                dynamic: true,
                squash: true,
                inherit: true
            },
            pristine: {
                type: 'bool',
                value: true,
                dynamic: true
            }
        },
        views: {
            'content@root': {
                component: EditHotelMap
            }
        },
        onExit : (trans, state)=>{
            // Check if user transit to out of edit mode state.
            // we don't check whether map is actually edited or not.
            // check if next state is one of children state of custom mode.
            if (
                trans.to().name.indexOf('root.hotel-console.maps.map') === -1
                && !trans.params('from').pristine
            ){
                // return promise to stop transtion.
                return new Promise((resolve,reject)=>{
                    dispatch(openConfirmationDialog({
                        title: 'DO YOU WANT TO LEAVE?',
                        text: 'If you leave without saving you will lose all changes',
                        answerOptions:[ 'LEAVE', 'STAY'],
                        onConfirm : ()=>{
                            trans.abort()
                            reject();
                        },
                        onDismissed : ()=>{
                            trans.promise.then(success => dispatch(clearHotelMap()))
                            resolve(true);
                        }
                    }))
                })
            } else {
                trans.promise.then(success => dispatch(clearHotelMap()))
                return true
            }
        }
    },
    {
        name: 'root.hotel-console.deprecated',
        url: "/deprecated",
        views: {
            'content@root': () => (<div>DEPRECATED ROUTE</div>)
        }  
    },
    {
        name: 'root.hotel-console.deprecated.customise',
        url: "/customise-standard-map",
        views: {
            'content@root': () => (<div>DEPRECATED ROUTE</div>)
        }  
    },
]

export default manageMapsRoutes

function MapSummaryPage({map, $transition$}){
    const router = useRouter()
    return <Box p={2}>
        <MapSummary 
            map={map} 
            onDelete={() => {router.stateService.go('root.hotel-console.maps.dashboard')}}
        />
    </Box>
}