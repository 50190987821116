import { useReducer } from 'react';
import { poiSearchReducer } from '../reducers/poiSearchReducer';

const INITIAL_STATE = {
    loading: false,
    hasNextPage: true,
    results: [],
    pageResult : [],
    metaData: {
        limit: null,
        page: null,
        pages: null,
        total: null
    },
    error : null,
    cancelToken : null
}

const useStatePoiSeach = () => {

    const [poiSearch, dispatch] = useReducer(
        // reducer
        poiSearchReducer,
        // initial state
        INITIAL_STATE
    )

    return [poiSearch, dispatch];
}

export default useStatePoiSeach;