import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles";
import { TYPOGRAPHY_STYLES } from "../../../../theme/typographies";

export const useStyles = tgMakeStyles(theme => ({
    mobileCTA: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white'
    },
    continue: {
        borderRadius: '1.5625rem',
        minWidth: '10rem',
        minHeight: '2.25rem',
        ...TYPOGRAPHY_STYLES.h4
    },
    halo1: {
        position: 'absolute',
        bottom: 0, left: 0,
        transform: 'translateX(-50%)'
    },
    halo2: {
        position: 'fixed',
        top: 0, right: 0,
        transform: 'translateX(50%)'
    }
}))

export default useStyles