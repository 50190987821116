import React from 'react'
import { useMediaQuery, Fab } from "@material-ui/core"
import AddIcon from '@material-ui/icons/Add'
import { dispatch } from "slice"

import { openModal } from "../../../../dialogs/standard/state/actions"
import ToolBarButton from "../../../../toolBar/components/ToolBarButton"
import { SearchNewHotelPlace } from "../../components/SearchNewHotelPlace"

export function getAddHotelMapPlaceCTA( map ){
    // this button opens the modal to search a new place to add to the map
    return function AddHotelMapPlaceCTA(){
        const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
        const onClick=()=> {
            dispatch(openModal({
                component: SearchNewHotelPlace,
                props: {
                    map
                },
                fullScreen : isDesktop ? false : true,
                DialogProps: {
                    maxWidth: 'md',
                    fullWidth: true,
                    PaperProps: {
                        style: {
                            minHeight: '20rem'
                        }
                    }
                }
            }))
        }
    
        return (
    
            <ToolBarButton
                mobile={(
                    <Fab color='primary' 
                        onClick={onClick}
                    >
                        <AddIcon></AddIcon>
                    </Fab>
                )}
                desktop={(
                    <Fab color='primary' 
                        onClick={onClick}
                        variant='extended'
                        style={{flexGrow: 1}}
                    >
                        <AddIcon></AddIcon>
                        ADD A PLACE
                    </Fab>
                )}
            >
            </ToolBarButton>
        )
    }
}