import React, { useEffect } from 'react'
import {TgForm} from '../../../lib/components/forms/DefaultTgForm'
import { FormControl, Button, Typography, /* Box, */ TextField, LinearProgress } from '@material-ui/core'
import { dispatch } from 'slice';
import { recoverPassword, recoverPasswordReset } from '../state/actions';
import useStateAuthStatus from '../state/hooks/useStateAuthStatus';
import goldLogo from '../../../assets/png/logo_gold_with_text.png';
import { STATUSES } from '../state/meta';
import { closeModal } from '../../dialogs/standard/state/actions';
import ImageBox from '../../../lib/components/images/ImageBox';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';

const useStyles = tgMakeStyles(theme => ({
    noMargin: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
    }
}))

export default function RecoverPasswordForm() {
    const authStatus = useStateAuthStatus()
    const classes = useStyles()
    // close modal when status = 'recovered'
    useEffect(() => {
        if(authStatus && authStatus.recover && authStatus.recover.password
            && authStatus.recover.password.code === STATUSES.recover.password.recovered){
            dispatch(closeModal())
        }
        return () => {}
    }, [authStatus])

    // on component dismount, do reset state 
    // Notice that the effect only returns the cleanup function
    useEffect(() => function resetStateOnDismount(){
        dispatch(recoverPasswordReset())
    }, [] /* empty array means run only when mounted */)
    
    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const payload = {
            mail: formData.get('email')
        }
        dispatch(recoverPassword(payload))
    }
    return (
        <TgForm onSubmit={handleSubmit}>
            <ImageBox src={goldLogo}
                width="100%" height="15rem">
            </ImageBox>
            <Typography color="primary" variant="subtitle1" paragraph align="center">
                PASSWORD RECOVERY
            </Typography>
            <FormControl fullWidth={true} margin="normal">
                <TextField id="recover-password-form-email"
                    name="email"
                    variant='outlined'
                    label="Your email address"
                    type="email"
                    required={true}
                    fullWidth={true}
                    color="primary"/>
            </FormControl>
            <Typography varian="body2" color="error">
                {authStatus && authStatus.recover && authStatus.recover.password && authStatus.recover.password.error &&
                    authStatus.recover.password.error
                }
            </Typography>
            <FormControl fullWidth={true} margin="normal">
                <Button
                    id="recover-password-form-submit"
                    type="submit"
                    variant="contained"
                    color="primary"
                    classes={{root: classes.noMargin}}
                >
                    CONTINUE
                </Button>
                {authStatus && authStatus.recover && authStatus.recover.password
                    && authStatus.recover.password.code === STATUSES.recover.password.recovering 
                    && <LinearProgress variant="query" color="secondary"/>}
            </FormControl>
        </TgForm>
    )
}
