import produce from 'immer';
import { STATUSES } from '../meta';

export {
    INITIAL_STATE,
    userStatusReducer
}

const INITIAL_STATE = {
    isIdentified: false,
    code: STATUSES.unidentified,
    message: 'unidentified',
    error: null,
    warning: null,
    // update flow metadata. Keep it separate from general user status
    update: {
        code: STATUSES.update.updated,
        message: null,
        error: null,
        warning: null
    },
    password: {
        code: STATUSES.password.confirmed,
        message: null,
        error: null,
        warning: null
    }
}

function userStatusReducer(state, action){
    state = state || INITIAL_STATE
    switch (action.type) {
        // FETCH USER
        case 'USER/DETAILS/ATTEMPTS/FETCH':
            return produceStatus(state, 'identifying')
        case 'USER/DETAILS/FAILED/FETCH':
            return produceStatus(state, 'failed', getUserError(action.payload))
        case 'USER/DETAILS/SUCCESS/FETCH':
            return produceStatus(state, 'identified')
        // UPDATE USER
        case 'USER/DETAILS/ATTEMPTS/UPDATE':
            return produceUpdateStatus(state, 'updating')
        case 'USER/DETAILS/FAILED/UPDATE':
            return produceUpdateStatus(state, 'failed', getUserError(action.payload))
        case 'USER/DETAILS/SUCCESS/UPDATE':
            return produceUpdateStatus(state, 'updated')
        // PASSWORD CHANGE
        case 'USER/DETAILS/ATTEMPTS/PASSWORD_CHANGE':
            return producePasswordStatus(state, 'updating')
        case 'USER/DETAILS/FAILED/PASSWORD_CHANGE':
            return producePasswordStatus(state, 'failed', getUserError(action.payload))
        case 'USER/DETAILS/SUCCESS/PASSWORD_CHANGE':
            return producePasswordStatus(state, 'updated')
        // UNSET USER
        case 'USER/DETAILS/UNSET':
            return produceStatus(state, 'unidentified')
        default:
            return state;
    }
}
function getUserError(error){
    return error.response && error.response.data
        ? error.response.data
        : error.message
}

const produceStatus = produce((draft, status, error, warning) => {
    draft.code = STATUSES[status];
    draft.message = status;
    draft.isIdentified = STATUSES[status] === STATUSES.identified
        ? true
        : false
    draft.error = error
        ? error
        : null
    draft.warning = warning
        ? warning
        : null
})

const produceUpdateStatus = produce((draft, status, error, warning) => {
    draft.update.code = STATUSES.update[status];
    draft.update.message = status;
    draft.update.error = error
        ? error
        : null
    draft.update.warning = warning
        ? warning
        : null
})

const producePasswordStatus = produce((draft, status, error, warning) => {
    draft.password.code = STATUSES.password[status];
    draft.password.message = status;
    draft.password.error = error
        ? error
        : null
    draft.password.warning = warning
        ? warning
        : null
})