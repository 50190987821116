import { Box, ButtonBase, Card, Paper, Typography } from '@material-ui/core'
import { useRouter } from '@uirouter/react'
import React, { useState } from 'react'
import { tgMakeStyles } from '../../lib/styles/TgMakeStyles'
import {COLORS} from '../../modules/theme/colors'
import { useStateUserDetails } from '../user/state/hooks/useStateUserDetails'

const useStyles = tgMakeStyles(theme => ({
    btnRoot: {
        // margin: theme.spacing()
    },
    card: {
        alignSelf: 'stretch',
        transition: 'all .3s',
        padding: theme.spacing(2),
        '&:hover': {
            color: 'white',
            backgroundColor: COLORS.get('gold').main
        }
    }
}))

export function CollectionCard({
    icon=null,
    title='title',
    id,
    ...props
}){

    const {info} = useStateUserDetails()
    const classes = useStyles()
    const router = useRouter()
    const navigateToCollection = e => {
        router.stateService.go(`root.hotel-console.collections.collection`, {id: id, hId: info.hotels[0].id})
    }
    return (
        <ButtonBase {...props}
            classes={{root: classes.btnRoot}}
            onClick={navigateToCollection}
        >
            <Paper elevation={4}
                classes={{root: classes.card}}
            >
                <Box 
                    width="7rem"
                    py={5}
                >
                    <Box display="flex" justifyContent="center">
                        {icon}
                    </Box>
                    <Typography>
                        {title}
                    </Typography>
                </Box>
            </Paper>
        </ButtonBase>
    )
}

export default CollectionCard 