import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import {useStyles} from './profileCss.classes'
import {formatDistanceToNow, max, parse} from 'date-fns'

export function PlaceOpenStatus({
    place, 
    showOperational=true, 
    showLastUpdate=true,
    color='error'
}){
    const _place = place
        ? place.place
        : null
    const _lastOpenHoursUpdateDate = max([
        parse(_place.lastUpdate,'dd.MM.yyyy HH:mm:ss', new Date(0)),
        parse(_place.lastOpeningHoursUpdate,'dd.MM.yyyy HH:mm:ss', new Date(0)),
    ])
    const typographies = useTypographyStyles()
    const classes = useStyles()
    return (
        <>
            {_place && _place.businessStatus === 'CLOSED_TEMPORARILY' &&
                <Box className={classes.box}>
                    <Typography
                        className={typographies.subtitle4}
                        color={color}
                    >
                        <b>Temporarily Closed</b>
                    </Typography>
                </Box>
            }
            {_place && _place.businessStatus === 'CLOSED_PERMANENTLY' && 
                <Box className={classes.box}>
                    <Typography
                        className={typographies.subtitle4}
                        color={color}
                    >
                        <b>Permanently Closed</b>
                    </Typography>
                </Box>
            }
            {_place && _place.businessStatus === 'OPERATIONAL' && showOperational &&
                <Box className={classes.box}>
                    <Typography
                        className={typographies.subtitle4}
                        color={color}
                    >
                        <b>Operational</b>
                    </Typography>
                </Box>
            }
            {showLastUpdate && (
                <Typography
                    align="center"
                    className={typographies.body2}
                >
                    Last Updated {formatDistanceToNow(_lastOpenHoursUpdateDate, {addSuffix: true})}
                </Typography>
            )}
        </>
    )
}
// 'd MMMM YYY'