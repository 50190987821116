import { Box, Button, Collapse, Container, FormControl, MenuItem, TextField, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useEffect, useState } from 'react';
import { dispatch } from 'slice';
import LanguageJson from '../../../../assets/json/Language.json';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { openModal } from '../../../dialogs/standard/state/actions';
import { TranslationInfo } from '../../../language/components/TranslationInfo';
import { setLanguage } from '../../../language/state/actions/translationActions';
import useStateTranslation from '../../../language/state/hooks/useStateTranslation';
import { useTypographyStyles } from '../../../theme/useTypography';
import Mixpanel from '../../../../lib/mixpanel'

const useStyle = tgMakeStyles((theme)=>({
    button : {
        width : '80%',
    },
    buttonRoot : {
        margin : 0,
        marginBottom : `${theme.spacing(2)}`
    },
    infoIcon : {
        verticalAlign : 'middle',
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))

export const LanguageSelectComp = (props) => {

    
    const {
        onApply,
        onContinue,
        onContinueText = "CONTINUE IN ENGLISH",
        selectOpen = false,
        ...others
    } = props;
    
    const typoClasses = useTypographyStyles();
    const classes = useStyle();
    const [isSelectOpen, setIsSelectOpen] = useState(selectOpen);
    const [translationState] = useStateTranslation();

    // set language and fetch translated texts.
    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // set langage to state.
        // This change is picked on DigitalMapGuestUI
        dispatch(setLanguage(formData.get('lang')))
        // TODO : after all the api call is done. Add api loading state to translationState 
        // execute call back
        Mixpanel('track')('click_apply_language_change', {
            selectedLanguage: formData.get('lang')
        })
        Mixpanel('people.set')({'language': formData.get('lang')})
        Mixpanel('register')({
            language: formData.get('lang')
        })
        onApply()
    }

    const handleOnContinue = () => {
        dispatch(setLanguage('en'))
        onContinue()
    }

    const buttonCommonProps = {
        classes : {
            label : typoClasses.subtitle2,
            root : classes.buttonRoot
        },
        color:'primary'
    }

    const onInfoClick = () => {
        dispatch(openModal({
            component : TranslationInfo
        }))
    }

    // re-render logic to render options element as selected 
    const [selectLoading, setSelectLoaging] = useState(false);
    useEffect(()=>{
        setSelectLoaging(true)
        setTimeout(()=> {
            setSelectLoaging(false)
        },500)
    },[translationState.language])

    return (
        <form onSubmit={handleSubmit}>
            <Box textAlign="center" m={2}>
                <Container fixed maxWidth={'md'}>
                    <Box mb={2}>
                        <Typography className={`${typoClasses.h5}`}>Map Language 
                            <span onClick={onInfoClick}>
                                <InfoOutlinedIcon className={`${classes.infoIcon}`} />
                            </span>
                        </Typography>
                    </Box>
                    <Button {...buttonCommonProps} className ={`${classes.button}`} variant="contained" onClick={handleOnContinue}> {onContinueText} </Button>
                    <Button {...buttonCommonProps} className ={`${classes.button}`} variant="outlined" onClick={() => { setIsSelectOpen(true) }}>TRANSLATE WITH GOOGLE</Button>
                    <Collapse in={isSelectOpen} mountOnEnter>
                        {!selectLoading && 
                            <LanguageSelect boxProps={{className:classes.button}} selectedVal={translationState.language}/>
                        }
                        <Box mt={3}>
                            <Button {...buttonCommonProps} type={'submit'} variant="contained">APPLY</Button>
                        </Box>
                    </Collapse>
                </Container>
            </Box >
        </form>
    );
}

export const LanguageSelect = ({boxProps,selectedVal, label}) => {

    const props = {
        items : LanguageJson,
        labelText : label ? label : 'Select Language',
        selectName : 'lang',
        boxProps : {
            ...boxProps,
            style:{
                margin:"auto"
            }
        },
        selectedVal : selectedVal !== 'en' ? selectedVal : ''
    }

    return (
        <TgSelect {...props}/>
    )
}

// TODO : Export as a common components
const useTgSelectStyle = tgMakeStyles((theme)=>({
    selectRoot : {
        borderColor: `${theme.palette.greyscale.grey_3}`
    },
    focused : {
        borderColor: `${theme.palette.greyscale.grey_3}`
    }
}))

export const TgSelect = ({
    labelText,
    items=[], 
    onChange = ()=>{},
    selectName,
    boxProps={},
    selectedVal,
    ...props
}) => {

    const classes = useTgSelectStyle()
    const typoClasses = useTypographyStyles();
    const [value, setValue] = useState(selectedVal ? selectedVal : '');

    const handleChange = event => {
        setValue(event.target.value);
        onChange(event.target.value);
    };

    return (
        <Box {...boxProps}>
            <FormControl variant="outlined" fullWidth={true}>
                <TextField
                    select
                    name={selectName}
                    label={labelText}
                    value={value}   
                    onChange={handleChange}
                    InputProps={{
                        classes : {
                            notchedOutline : classes.selectRoot,
                            focused : classes.focused,
                            input : typoClasses.body1
                        }
                    }}
                    variant="outlined">
                    {items.map((val,idx)=>{
                        // console.log(selectedVal, val[1], Boolean(selectedVal === val[1]))
                        return <MenuItem
                                    selected={Boolean(selectedVal === val[1])}
                                    key={`${val[1]}-${idx}`} 
                                    value={val[1]}>
                                    {val[0]}
                                </MenuItem>
                    })}
                </TextField>
            </FormControl>
        </Box>
    )
}


 