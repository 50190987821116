import React from 'react'
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSref } from '@uirouter/react';
import { useTypographyStyles } from '../../../theme/useTypography';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { TYPOGRAPHY_STYLES } from '../../../theme/typographies';
import {useJuicerFeed} from '../../hooks/useJuicerFeed'
import { PLANS_PLAIN_TEXT } from '../../../invitation/utils';
import { isPremium } from '../../utils';

const useStyles = tgMakeStyles(theme => ({
    linkText: {
        ...TYPOGRAPHY_STYLES.body3
    },
    noMargin: {margin: 0}
}))

export function QuickAccessLinks({business, ...props}){
    const bizProfileSref = useSref('root.business-console.profile', {basic: true})
    const socialMediaSref = useSref('root.business-console.profile', {social: true})
    const bizOperationSref = useSref('root.business-console.profile', {operation: true})
    const firstImpressionSref = useSref('root.business-console.profile', {impressions: true})
    const settingsSref = useSref('root.business-console.settings')
    
    const _feed = useJuicerFeed(business && business.feed ? business.feed.juicerId : null)
    const _validSource = _feed ? (_feed.sources.length > 0 && _feed.sources.filter(s => s.term === 'invalid-source').length === 0) : false
    const _noCover = business.place.images 
        && business.place.images.length === 0 
        && isPremium(business) // Digital maps and above plans
    
    const typo = useTypographyStyles()
    const classes = useStyles()

    return business 
        ? (
            <Box>
                <Typography color="primary"
                    className={`${typo.body4}`}
                >
                    <b>Quick Access</b>
                </Typography>
                <Box display='flex' justifyContent='center'
                    position='relative'>
                    <List component={Box} width='100%'>
                        {!(business.place.about && business.description) && 
                        <ListItem classes={{button: classes.noMargin}} disableGutters button divider
                            className={classes.linkText}
                            {...bizProfileSref}
                        >
                            <ListItemText
                                
                                primaryTypographyProps={{
                                    variant: 'inherit'
                                }}
                                primary='Complete my business profile'
                            />
                            <ListItemSecondaryAction {...bizProfileSref}>
                                <IconButton edge='end'>
                                    <ArrowForwardIosIcon color='inherit'/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>}

                        {!_validSource && 
                        <ListItem classes={{button: classes.noMargin}} disableGutters button divider
                            className={classes.linkText}
                            {...socialMediaSref}
                        >
                            <ListItemText 
                                
                                primaryTypographyProps={{
                                    variant: 'inherit'
                                }}
                                primary='Connect my social media'/>
                            <ListItemSecondaryAction {...socialMediaSref}>
                                <IconButton edge='end'>
                                    <ArrowForwardIosIcon color='inherit'/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>}
                        
                        {_noCover && 
                        <ListItem classes={{button: classes.noMargin}} disableGutters button divider
                            className={classes.linkText}
                            {...firstImpressionSref}
                        >
                            <ListItemText 
                                
                                primaryTypographyProps={{
                                    variant: 'inherit'
                                }}
                                primary='Design my first impression to guests'/>
                            <ListItemSecondaryAction {...firstImpressionSref}>
                                <IconButton edge='end'>
                                    <ArrowForwardIosIcon color='inherit'/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>}

                        <ListItem classes={{button: classes.noMargin}} disableGutters button divider
                            className={classes.linkText}
                            {...bizOperationSref}>
                            <ListItemText 
                                
                                primaryTypographyProps={{
                                    variant: 'inherit'
                                }}
                                primary='Update how my business operates'/>
                            <ListItemSecondaryAction {...bizOperationSref}>
                                <IconButton edge='end'>
                                    <ArrowForwardIosIcon color='inherit'/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem classes={{button: classes.noMargin}} disableGutters button divider
                            className={classes.linkText}
                            {...settingsSref}>
                            <ListItemText 
                                
                                primaryTypographyProps={{
                                    variant: 'inherit'
                                }}
                                primary='Manage my Circle membership'/>
                            <ListItemSecondaryAction {...settingsSref}>
                                <IconButton edge='end'>
                                    <ArrowForwardIosIcon color='inherit'/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        )
        : null
}

export default QuickAccessLinks