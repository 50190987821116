import Axios from "axios";
import cookies from 'cookies-js';

// create an instance of axios dedicated to querying TG backend
// this instance is used in all subdirectories
export const TG_API = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})
// initial setup for auth headers if cookies has them set already
// if no auth cookies, this will do nothing 
// and setAuthCookiesInHeaders will be called when the user logs in and after token have been set in applicaion cookies
setAuthCookiesInHeaders()

export function setAuthCookiesInHeaders(){
    // TG requests expect Authorization and PinAuthorization headers.
    // These headers values are set in browser cookies upon login.
    const bearerToken = cookies.get('bearerToken');
    const secondToken = cookies.get('secondToken');
    bearerToken && (TG_API.defaults.headers.common['Authorization'] = 'Bearer ' +bearerToken);
    secondToken && (TG_API.defaults.headers.common['PinAuthorization'] = secondToken);
}

// exports subfolders for convenience
export {login, logout} from "./authentication";