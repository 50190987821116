import React, { useEffect } from 'react'
import {useStateUserDetails} from '../state/hooks/useStateUserDetails';
import {useCurrentStateAndParams, useSref, useRouter} from '@uirouter/react';
import ErrorIcon from '@material-ui/icons/Error';
import { Tooltip, CircularProgress, Link, Box } from '@material-ui/core';
import { STATUSES } from '../state/meta';
import useStateAuthStatus from '../../authentication/state/hooks/useStateAuthStatus';
import {STATUSES as AUTH_STATUS} from '../../authentication/state/meta';
// import { doLogout } from '../../authentication/state/actions';
// import { dispatch } from 'slice';
// import { showNotification } from '../../notifications/state/actions';

/* 
    since this component is currently persisting across all routes
    it is also where side effects triggered by user details changes should be handled
*/
export default function UsernameLink(props) {
    const router = useRouter()
    const {BoxProps, ...forwardedProps} = props
    const {state} = useCurrentStateAndParams()
    const {info, status} = useStateUserDetails()
    const authStatus = useStateAuthStatus();
    const userSettingsSref = useSref("root.user.settings")
    const hotelUserSettingsSref = useSref("root.hotel-console.settings")
    const businessUserSettingsSref = useSref("root.business-console.settings")
    /* 
        this sideEffect is responsible for redirecting user depending on their role
    */
    useEffect(() => {
        // if user is authenticated and identified and not poiAdmin (ie: not a restaurant owner account)
        if(info && info.role && info.role !== 'poiAdmin'){
            // makes sure there is a hotel assigned to this user and redirect to the hotel standard map qr code
            if (info && info.hotels && info.hotels.length > 0){
                // automatically redirect to Home if currently on welcome page
                if(state.name === 'root.welcome' || router.stateService.includes('root.welcome.invited')){
                    router.stateService.go('root.hotel-console.maps.dashboard')
                }
            }
        } else if(info && info.role && info.role === 'poiAdmin'){
            if(!router.stateService.includes('root.business-console')){
                router.stateService.go('root.business-console.dashboard', {id: info.business[0].id})
                    // .then(console.log.bind(0, 'then'))
                    .catch(console.log.bind(0, 'catch'))
            }
        }
    }, [info])

    
    useEffect(()=>{
        // When user is unauthenticated, e.g failing to fetch user details with stored tokens 
        if (authStatus.code && authStatus.code === AUTH_STATUS.unauthenticated && !state.data.public ){
            router.stateService.go('root.welcome')
        }
    },[authStatus])

    return (
        <Box {...BoxProps}>
            {info && info.alias
            ? (
                // Link to user details
                <Link 
                    color='inherit'
                    {...forwardedProps}
                    {...router.stateService.includes('root.business-console') 
                        ? businessUserSettingsSref 
                        : router.stateService.includes('root.hotel-console')
                            ? hotelUserSettingsSref
                            : userSettingsSref
                    }
                >
                    {info.alias || info.name}
                </Link>
            )
            : status && status.error
                ? (
                    // an error happened
                    <Tooltip 
                        title={status.error} 
                        disableFocusListener
                    >
                        <ErrorIcon 
                            color="error" 
                            size="1.25em"
                        />
                    </Tooltip>
                )
                : status && status.code === STATUSES.identifying
                    ? (
                        // busy, show spinner
                        <CircularProgress size="1.25em"/>
                    )
                    : null
            }
        </Box>
    )
}
