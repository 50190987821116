import produce from "immer";

const initial_state = []

export function recentlyViewedPlaceReducer(state, action){
    if(!state) {state = initial_state}
    switch (action.type) {
        case 'RECENTLY/VIEWED/PLACES/PUSH':
            return produce(state, draft => {
                if(draft.length === 0){
                    draft.push(action.payload)
                    return draft
                } else if (draft.length > 0) {
                    const _IDS = state.map(x => x.place.googlePlaceId)
                    const _alreadyExists = _IDS.indexOf(action.payload.place.googlePlaceId)
                    if (_alreadyExists < 0) {
                        return [action.payload, ...draft]
                    } else if (_alreadyExists === 0) {
                        return draft
                    } else if (_alreadyExists > 0) {
                        const _new = [...draft]
                        _new.splice(_alreadyExists, 1)
                        return [action.payload, ..._new]
                    }
                }
                console.log('skipped all')
            })
        default:
            return state
    }
}