import { Subject } from "rxjs"
import produce from 'immer';

export const INITIAL_STATE = {
    open: false,
    subject: null,
    text:null,
    TextComponent:null,
    title:null,
    answerOptions:null,
    dialogProps:{}
}

export function confirmationReducer(state, action){
    switch (action.type) {
        case 'OPEN_CONFIRMATION_DIALOG':
        case 'CLOSE_CONFIRMATION_DIALOG':
            return produce(state,draft => {
                draft.open = action.payload.open
                draft.subject = action.payload.subject
                draft.text = action.payload.text
                draft.title = action.payload.title
                draft.answerOptions = action.payload.answerOptions
                draft.dialogProps = action.payload.dialogProps
                draft.TextComponent = action.payload.TextComponent
                draft.TitleComponent = action.payload.TitleComponent
            })

        default:
            return state;
    }
}

