import { Box, Fab } from '@material-ui/core';
import MapIcon from '@material-ui/icons/MapOutlined';
import React from 'react';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { COLORS } from '../../../theme/colors';

const useStyles = tgMakeStyles(theme => ({
    FabRoot: {
        boxShadow: 'none',
        backgroundColor: 'white',
        color: COLORS.get('gold').main,
        border: `2px solid ${COLORS.get('gold').main}`,
        '&:hover': {
            backgroundColor: COLORS.get('gold').main,
            color: 'white'
        }
    },
    icon : {
        marginRight : theme.spacing(1)
    }
}))

export const SeeMapOnFab = ({
    feature,
    map, 
    BoxProps,
    handleSeeOnMap, 
    ...forwardedProps
}) => {

    const classes = useStyles()

    return (
        <Box p={0} m={0} {...BoxProps}>
            <Fab 
                variant='extended'
                onClick={()=>{handleSeeOnMap(feature)}}
                color='inherit'
                className={classes.FabRoot}
                classes={{
                    label : classes.FabLabel
                }}
                {...forwardedProps}
            >
                <MapIcon className={classes.icon}/>
                See on Map
            </Fab>
        </Box>
    )
}