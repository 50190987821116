import React from 'react'
import { map } from 'rxjs/operators'

import {getInvitationData} from '../../http/tg/invitation'

import WelcomeFooter from '../../footer/components/WelcomeFooterNew'
// mobile
import MobileMembershipTour from '../components/onboarding/mobile/MembershipTour.Box'
import MobileCTA from '../components/onboarding/mobile/CTAContinue.Box'
import MobileMembershipPlans from '../components/onboarding/mobile/MembershipPlans.Box'
import MobileMemberBusinessDetailsForm from '../components/onboarding/mobile/MembershipBusinessDetails.form'
import MobileMembershipPlanReview from '../components/onboarding/mobile/MembershipPlanReview.Box'
import MobileMembershipOnboardingSuccess from '../components/onboarding/mobile/MembershipRegistrationSuccess.Box'
import MobileCTASubmitForm from '../components/onboarding/mobile/CTASubmitForm.Box'
import MobileCTASelectPlan from '../components/onboarding/mobile/CTASelectPlan.Box'
// desktop
import DesktopMembershipTour from '../components/onboarding/desktop/MembershipTour.Box'
import DesktopMembershipPlans from '../components/onboarding/desktop/MembershipPlans.Box'
import DesktopMemberBusinessDetailsForm from '../components/onboarding/desktop/MembershipBusinessDetails.form'
import DesktopMembershipPlanReview from '../components/onboarding/desktop/MembershipPlanReview.Box'
import DesktopMembershipOnboardingSuccess from '../components/onboarding/desktop/MembershipRegistrationSuccess.Box'
// tablet (between mobile and desktop screen widths)
import TabletCTA from '../components/onboarding/tablet/CTAContinue.Box'
import TabletCTASelectPlan from '../components/onboarding/tablet/CTASelectPlan.Box'
import TabletCTASubmitForm from '../components/onboarding/tablet/CTASubmitForm.Box'
import TabletMembershipTour from '../components/onboarding/tablet/MembershipTour.Box'
import TabletMembershipPlans from '../components/onboarding/tablet/MembershipPlans.Box'
import TabletMemberBusinessDetailsForm from '../components/onboarding/tablet/MembershipBusinessDetails.form'
import TabletMembershipPlanReview from '../components/onboarding/tablet/MembershipPlanReview.Box'
import TabletMembershipOnboardingSuccess from '../components/onboarding/tablet/MembershipRegistrationSuccess.Box'

import { DEFAULT_PLAN, NO_CODE } from '../utils'
import Breakpoints from '../../../lib/components/utils/Breakpoints'
import { Box } from '@material-ui/core'
import { dispatch } from 'slice'
import { openConfirmationDialog } from '../../dialogs/confirmation/states/actions'

const _invitationRoutes = [
    {
        name: 'root.welcome.invited',
        abstract: true,
        params: {
            code: {
                type: 'string',
                value: NO_CODE
                // value: 'test'
            }
        },
        resolve: [{
            token: 'invitation_group',
            deps: ['$stateParams', '$transition$'],
            resolveFn: ({code}, trans) => {
                if(code === NO_CODE){
                    console.log('no code')
                    trans.abort()
                    trans.router.stateService.go('root.welcome')
                }
                else {
                    // Promise.resolve('testgroup')
                    return getInvitationData({code})
                        .pipe(
                            map(r => r.data.group)
                        )
                        .toPromise()
                }
            }
        }],
        views : {
            'content-footer@root': {
                component: (props) => <Breakpoints 
                    medium={() => <Box pb="4.1875rem"></Box>}
                    large={() => <Box pb="4.1875rem"></Box>}
                    {...props} 
                />
            },
            'nav-bar@root': {
                component: () => null
            }
        }
    },
    {
        name: 'root.welcome.invited.tour',
        url:'/thecircle',
        data: {
            cta_next: 'root.welcome.invited.tour.plans',
            cta_btn: 'CONTINUE',
            cta_txt: 'STEP 2: SELECT YOUR MEMBERSHIP'
        },
        views: {
            'content@root': {
                component: (props) => <Breakpoints 
                    small={MobileMembershipTour} 
                    medium={TabletMembershipTour} 
                    large={DesktopMembershipTour} 
                    {...props} 
                />
            },
            'nav-bar@root': {
                component: (props) => <Breakpoints 
                    small={MobileCTA}
                    medium={TabletCTA}
                    {...props} 
                />
            }
        },
        onExit : (trans, state)=>{
            // Check if user transit to out of custom QR code page.
            if(trans.from().name === 'root.welcome.invited.tour.plans.business-details.review.success'){
                return true
            } else {
            return new Promise((resolve,reject)=>{
                dispatch(openConfirmationDialog({
                    title: 'LEAVING?',
                    text: 'If you leave this page, you may lose your progress. Are you sure you want to exit?',
                    answerOptions:[ 'Stay', 'Leave'],
                    onConfirm : ()=>{
                        return resolve();
                    },
                    onDismissed : ()=>{
                        // abort transition.
                        trans.abort();
                        return resolve();
                    }
                }))
            })
        }
        }
    },
    {
        name: 'root.welcome.invited.tour.plans',
        url:'/plans',
        data: {
            cta_btn: 'CONTINUE',
            cta_txt: 'STEP 3: COMPLETE YOUR BUSINESS DETAILS'
        },
        views: {
            'content@root': {
                component: (props) => <Breakpoints 
                    small={MobileMembershipPlans} 
                    medium={TabletMembershipPlans} 
                    large={DesktopMembershipPlans} 
                    {...props} 
                />
            },
            'nav-bar@root': {
                component: (props) => <Breakpoints 
                    small={MobileCTASelectPlan}
                    medium={TabletCTASelectPlan}
                    {...props}
                />
            }
        }
    },
    {
        name: 'root.welcome.invited.tour.plans.business-details',
        url:'/details',
        params: {
            selected_plan: {
                value: DEFAULT_PLAN
            }
        },
        data: {
            cta_next: 'root.welcome.invited.tour.plans.business-details.review',
            cta_btn: 'CONTINUE',
            cta_txt: 'REVIEW & SUBMIT'
        },
        views: {
            'content@root': {
                component: (props) => <Breakpoints 
                    small={MobileMemberBusinessDetailsForm} 
                    medium={TabletMemberBusinessDetailsForm} 
                    large={DesktopMemberBusinessDetailsForm} 
                    {...props} 
                />
            },
            'nav-bar@root': {
                component: () => null
            },
            'content-footer@root': {
                component: (props) => <Breakpoints
                    small={() => <Box pb="4.1875rem"></Box>}
                    medium={() => <Box pb="4.1875rem"></Box>}
                    large={() => <Box pb="4.1875rem"></Box>}
                    {...props}
                />
            }
        }
    },
    {
        name: 'root.welcome.invited.tour.plans.business-details.review',
        url:'/review',
        params: {
            business_name: {type: 'string', value: null},
            business_address: {type: 'string', value: null},
            primary_phone_number: {type: 'string', value: null},
            primary_email: {type: 'string', value: null},
            business_website: {type: 'string', value: null},
            google_profile_link: {type: 'string', value: null},
            admin_full_name: {type: 'string', value: null},
            admin_email: {type: 'string', value: null},
            billing_name: {type: 'string', value: null},
            billing_address: {type: 'string', value: null},

            // business_name: {type: 'string', value: 'biz name'},
            // business_address: {type: 'string', value: 'biz address'},
            // primary_phone_number: {type: 'string', value: '123123123'},
            // primary_email: {type: 'string', value: 'asd@prim'},
            // business_website: {type: 'string', value: null},
            // google_profile_link: {type: 'string', value: 'gglLink'},
            // admin_full_name: {type: 'string', value: 'Full Name'},
            // admin_email: {type: 'string', value: 'asd@admin'},
            // billing_name: {type: 'string', value: 'Bill Name'},
            // billing_address: {type: 'string', value: 'Bill Address'},

            vat_id: {type: 'string', value: null},
            agreed: {type: 'bool', value: false}
        },
        data: {
            cta_next: 'root.welcome.invited.tour.plans.business-details.review.success',
            cta_btn: 'SUBMIT',
            cta_txt: 'ALMOST DONE!'
        },
        views: {
            'content@root': {
                component: (props) => <Breakpoints 
                    small={MobileMembershipPlanReview} 
                    medium={TabletMembershipPlanReview} 
                    large={DesktopMembershipPlanReview} 
                    {...props} 
                />
            },
            'nav-bar@root': {
                component: (props) => <Breakpoints 
                    small={MobileCTASubmitForm} 
                    medium={TabletCTASubmitForm} 
                    {...props} 
                />
            },
            'content-footer@root': {
                component: (props) => <Breakpoints 
                    medium={() => <Box pb="4.1875rem"></Box>}
                    large={() => <Box pb="4.1875rem"></Box>}
                    {...props} 
                />
            }
        }
    },
    {
        name: 'root.welcome.invited.tour.plans.business-details.review.success',
        url:'/success',
        views: {
            'content@root': {
                component: (props) => <Breakpoints 
                    small={MobileMembershipOnboardingSuccess} 
                    medium={TabletMembershipOnboardingSuccess} 
                    large={DesktopMembershipOnboardingSuccess} 
                    {...props} 
                />
            },
            'content-footer@root': {
                component: (props) => <Breakpoints 
                    small={() => <WelcomeFooter fullScreen={true}/>} 
                    medium={() => <WelcomeFooter fullScreen={true}/>} 
                    large={() => <WelcomeFooter fullScreen={true} ContainerProps={{maxWidth: false}}/>} 
                    {...props} 
                />
            },
            'nav-bar@root': {
                component: () => null
            }
        }
    }
]

export default function invitationRoutes(router){
    return _invitationRoutes
};