import React from 'react';
import { lightTheme } from './modules/theme/cssTheme';
import { UIRouter, UIView } from '@uirouter/react';
import {router} from './routes';
import './lib/polyfills';
import NotificationsToastDiv from './modules/notifications/components/NotificationsToastDiv';
import StandardDialog from './modules/dialogs/standard/components/StandardDialog';
import { CookiesDisclaimerDrawer } from './modules/cookies/components/CookiesDisclaimerDrawer';
import {ConfirmationDialog} from './modules/dialogs/confirmation/components/ConfirmationDialog'
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import pkgJson from '../package.json'

console.log(`TasteGods v${pkgJson.version}`)

const App = () => {
  return ( 
    <div className="App">
      <UIRouter router={router}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                {/* root view, TG goes here */}
                <UIView />
                {/* tastegods modal, only one modal */}
                <StandardDialog />
                {/* toast notifications */}
                <NotificationsToastDiv />
                {/* drawer asking for consent for cookies usage (GDPR) */}
                <CookiesDisclaimerDrawer />
                {/* Confirmation dialog which can co-exist with Modal */}
                <ConfirmationDialog />
            </ThemeProvider>
          </StylesProvider>
      </UIRouter>
    </div>
   );
}

export default App;