import React, { useEffect } from 'react'
import Mixpanel from '../../../../lib/mixpanel'
// import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import base64Docs from '../../base64documents.json'
import { PdfArticle } from './PdfArticle'

export function AddendumBusiness01AArticle(props) {

    // because we do not want a url to point to this file, we get it as base64 from variable. And load it as pdf.
    const byteCharacters = atob(base64Docs['TG Addendum - Business - 01A']);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {type: 'application/pdf'});
    
    useEffect(() => {
        Mixpanel('track')("impression_addendum_01A", props);
    }, [])

    return <PdfArticle file={file} filename="TG Addendum - Business - 01A.pdf"/>
}