import React from 'react'
import { Container, Typography } from "@material-ui/core";
import { useTypographyStyles } from '../../../../theme/useTypography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OpenModalLink from '../../../../dialogs/standard/components/OpenStandardDialog.Link';

export function GoogleMyBusinessInfoDialog(props){
    const typo = useTypographyStyles()
    return (
        <Container maxWidth="xs">
            <Typography className={`${typo.body2}`} align="center">
            This data is connected to your Google profile. To update, please edit directly from your Google My Business account.<br/>
            <br/>
            Recent changes may take some time before automatically updating on TasteGods. Click "Check Google for updates" to refresh your profile.
            </Typography>
        </Container>   
    )
}

export function GoogleMyBusinessInfoDialogLink({trackingProps, ...forwardProps}){
    const typo = useTypographyStyles()
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle={(
                <Typography className={`${typo.subtitle5}`} color="primary">
                    GOOGLE MY BUSINESS
                </Typography>
            )}
            modalComponent={GoogleMyBusinessInfoDialog}
            modalComponentProps={trackingProps}>
                {forwardProps.children ? forwardProps.children : <HelpOutlineIcon />}
        </OpenModalLink>
    )
}