import produce from 'immer';

export const translationReducer = (state, action) => {
    switch (action.type) {
        case 'RECIEVE_TRANSLATION':
            return produce(state,draft => {
                draft.translation = action.payload.translation
            })
        case 'SET_LANGUAGE':
            return produce(state,draft => {
                draft.language = action.payload.language
            })
        case 'ATTEMPT_TRANSLATION':
        case 'COMPLETE_TRANSLATION':
            return produce(state,draft => {
                draft.loading = action.payload.loading
            })
        case 'ERROR_TRANSLATION':
            return produce(state,draft => {
                draft.error = action.payload.error
            })
        default:
            return state;
    }
}