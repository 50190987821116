import React, { useState } from "react";
import { Box, IconButton, TextField, Typography, Button, LinearProgress } from "@material-ui/core";
import PasswordInput from "../../../lib/components/inputs/PasswordInput";
import EditIcon from '@material-ui/icons/Edit';
import { updateUser, updateUserEmail, updateUserPassword } from "../state/actions";
import { dispatch } from "slice";
import { STATUSES } from "../state/meta";
import { useStateUserDetails } from "../state/hooks/useStateUserDetails";
import { tgMakeStyles } from "../../../lib/styles/TgMakeStyles";
import { TYPOGRAPHY_STYLES } from "../../theme/typographies";
import tracker from "../../../lib/mixpanel";

const useStyles = tgMakeStyles(theme => ({
    btn: {
        margin: 0
    },
    formCTA: {
        '& .MuiButton-label': {
            ...TYPOGRAPHY_STYLES.body1
        }
    },
    formControl: {
        marginBottom: theme.spacing(2)
    }
}))

export function UserDetailsForm({
    userInfo, 
    onCancel,
    ...props
}){

    // const typo = useTypographyStyles()
    const classes = useStyles()

    const {status} = useStateUserDetails()
    const [showPasswForm, setShowPasswForm] = useState(false)

    const handleUpdate = e => {
        e.preventDefault();
        const formData = new FormData(e.target); 
        // mail and password updates have different flows
        if(showPasswForm){
            const payload = {};
            payload.currentPassword = formData.get('current-password')
            payload.newPassword = formData.get('new-password')
            payload.verifyNewPassword = formData.get('verify-new-password')
            dispatch(updateUser({}))
            dispatch(updateUserPassword(payload))
        } else {
            const payload = {};
            // other properties can be changed as simple text update
            formData.get('email') !== userInfo.mail && (payload.email = formData.get('email')) 
            formData.get('name') !== userInfo.name && (payload.name = formData.get('name'))
            formData.get('job_title') !== userInfo.jobTitle && (payload.jobTitle = formData.get('job_title'))
            dispatch(updateUser(payload))
        }
    }

    const updateFormTracker = e => {
        if([
            "new-password",
            "verify-new-password",
            "current-password"
        ].includes(e.target.name)){
            // passwords should NEVER be tracked
            return
        } else {
            tracker('form')('business_settings_edit_admin_details_form', {[e.target.name]: e.target.value})
        }
    }
    return (
        <form 
            id="business-settings-edit-admin-details-form"
            onSubmit={handleUpdate}
            onChange={updateFormTracker}
        >
            {(( status && status.update.code === STATUSES.update.updating)
                || (status && status.password.code === STATUSES.password.updating)
            ) && 
                <LinearProgress variant="query" color="primary"/>
            }
            {/* name */}
            <TextField
                disabled={showPasswForm}
                className={`${classes.formControl}`}
                variant="outlined"
                label="Name"
                id="user-details-form-name"
                name="name"
                type="text"
                fullWidth={true}
                defaultValue={userInfo.name}
            />
            {/* job title */}
            <TextField
                disabled={showPasswForm}
                className={`${classes.formControl}`}
                variant="outlined"
                label="Job Title"
                id="user-details-form-job-title"
                name="job_title"
                type="text"
                fullWidth={true}
                defaultValue={userInfo.jobTitle}
            />
            {/* email */}
            <TextField
                disabled={showPasswForm}
                className={`${classes.formControl}`}
                variant="outlined"
                label="Email"
                id="user-details-form-email"
                name="email"
                type="email"
                fullWidth={true}
                defaultValue={userInfo.mail}
            />
            {/* password */}
            {showPasswForm
                ?(
                    <React.Fragment>
                        <Box mb={2}>
                            <Typography 
                                color="primary"
                                onClick={e => setShowPasswForm(false)}
                            >
                                Password
                            </Typography>
                        </Box>
                        
                        <PasswordInput
                            className={`${classes.formControl}`} 
                            fullWidth
                            required
                            label="New password - set"
                            variant="outlined"
                            id="user-password-form-newPassword"
                            name="new-password"
                        />
                        
                        <PasswordInput
                            className={`${classes.formControl}`}
                            fullWidth
                            required
                            label="New password - confirm"
                            variant="outlined"
                            id="user-password-form-verifyNewPassword"
                            name="verify-new-password" 
                        />
                        
                        <PasswordInput
                            className={`${classes.formControl}`}
                            fullWidth
                            required
                            label="Current password - confirm"
                            variant="outlined"
                            id="user-password-form-currentPassword"
                            name="current-password"
                        />
                    </React.Fragment>
                )
                :(
                    <React.Fragment>
                        <Box display="flex" alignItems="center">
                            <Box style={{flexGrow: 1}}>
                                <Typography>Password</Typography>
                            </Box>
                            <IconButton color="secondary" 
                                id="open-edit-user-password-form"
                                className={`${classes.btn} clickable`}
                                onClick={e => setShowPasswForm(true)}
                                color="inherit"
                            >
                                <EditIcon></EditIcon>
                            </IconButton>
                        </Box>
                        <Typography>***********</Typography>
                    </React.Fragment>
                )
            }
            <Box mt={2}>
                {status && (status.update.error || status.password.error) && 
                <Typography color="error" variant="body2" style={{textAlign: 'center'}}>
                    {status.update.error}
                    {status.password.error}
                </Typography>}
                <Box display="flex" justifyContent="center">
                    <Button 
                        variant="outlined" 
                        color="primary"
                        id="user-details-form-cancel"
                        className={`${classes.formCTA} clickable`}
                        onClick={onCancel}
                    >
                        CANCEL
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary"
                        id="user-details-form-save"
                        className={`${classes.formCTA} clickable`}
                        type="submit"
                    >
                        SAVE
                    </Button>
                </Box>
            </Box>
        </form>
    )
}