import { useState, useEffect } from 'react';
import { $lice } from 'slice';
import { notificationsRootReducer, INITIAL_STATE } from '../reducers';

const [notifications$] = $lice('tgnotifications', notificationsRootReducer, INITIAL_STATE)

export function useStateNotifications(){
    const [notif, setNotif] = useState(INITIAL_STATE);
    useEffect(() => {
        let sub = notifications$.subscribe(setNotif)
        return () => {
            sub.unsubscribe();
        }
    }, [])

    return notif
}