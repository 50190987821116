import React from 'react'
import { Box, Container, Link, Grid } from '@material-ui/core'
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { TermsOfServiceLink } from '../../legal/components/TermsOfServiceLink'
import { PrivacyPolicyLink } from '../../legal/components/PrivacyPolicyLink'
import { ImprintLink } from '../../legal/components/ImprintLink'
import LoginLink from '../../authentication/components/LoginLogout.Link'

const useContainerStyle = tgMakeStyles((theme) => ({
    container: {
        padding: '0',
        paddingLeft: props => props.fullScreen ? '0' : theme.spacing(8),
        backgroundColor: 'white'
    },
    grid: {
        backgroundColor: '#0E0E32',
        color: '#C6AD7C',
        borderRadius: props => `${props.fullScreen ? '0' : '4px'} 0 0 0`,
        padding: theme.spacing(2)
    },
    link: {
        color: '#C6AD7C'
    }
}))

export function WelcomeFooter(props) {
    const classes = useContainerStyle(props)
    return (
        <Box display="flex" justifyItems="center">
            <Container maxWidth="lg" className={classes.container} {...props.ContainerProps}>
                <Grid container justify="space-evenly" className={classes.grid}>
                    <Grid item xs={6} sm={4}
                        container justify="center">
                        <LoginLink className={classes.link}/>
                    </Grid>
                    <Grid item xs={6} sm={4}
                        container justify="center">
                        <Link href="mailto:hr@tastegods.com" 
                            id="footer-careers-link"
                            className={`${classes.link} clickable`} 
                        >
                            Careers
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={4}
                        container justify="center">
                        <TermsOfServiceLink 
                            className={`${classes.link} clickable`} 
                            id="footer-tou-link"
                            modalFullScreen={true}
                            trackingProps={{location: 'welcome_footer'}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}
                        container justify="center">
                        <Link href="mailto:contact@tastegods.com" 
                            id="footer-contact-us-link"
                            className={`${classes.link} clickable`} 
                        >
                            Contact Us
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={4}
                        container justify="center">
                        <ImprintLink 
                            id="footer-imprint-link"
                            className={`${classes.link} clickable`} 
                            trackingProps={{location: 'welcome_footer'}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}
                        container justify="center">
                        <PrivacyPolicyLink 
                            id="footer-priv-pol-link"
                            className={`${classes.link} clickable`} 
                            modalFullScreen={true}
                            trackingProps={{location: 'welcome_footer'}}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default WelcomeFooter