export const DAYS = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
export const DAYS_SHORT = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']
export const DEFAULT_OPEN_PERIOD = dayIdx =>  ({
    open: {
        day: dayIdx,
        time: '0800'
    },
    close: {
        day: dayIdx,
        time: '2300'
    }
})