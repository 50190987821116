import { useState, useEffect } from 'react';
import { INITIAL_STATE, rootModalReducer } from '../reducers';
import { $lice } from 'slice';

const [modal$] = $lice('tgmodal', rootModalReducer, INITIAL_STATE);

export function useStateModal(){
    const [modal, setModal] = useState(INITIAL_STATE)
    useEffect(() => {
        let sub = modal$.subscribe(setModal)
        return () => {
            sub.unsubscribe()
        };
    }, [])
    return modal
}