import React from 'react'
import { tgMakeStyles } from '../../lib/styles/TgMakeStyles'
import {dispatch} from 'slice'
import {openConfirmationDialog} from '../dialogs/confirmation/states/actions'
import { Typography } from '@material-ui/core';
import { TYPOGRAPHY_STYLES } from '../theme/typographies';
import pkgJson from '../../../package.json'
import { TermsOfServiceLink } from './components/TermsOfServiceLink';
import PrivacyPolicyLink from './components/PrivacyPolicyLink';
import { postUserAgreement } from '../http/tg/user';
import { mapTo } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AddendumBusiness01ALink } from './components/AddendumBusiness01ALink';


export {ImprintArticle} from './components/articles/ImprintArticle';
export {PrivacyPolicyArticle} from './components/articles/PrivacyPolicyArticle';
export {TermsOfServicesArticle} from './components/articles/TermsOfServicesArticle';


// This constant stores legal documents by name. Name is used as a unique key. minTgVersion is the minimum version of tg FE (package.json) for which this document applies to
export const LEGAL_DOCUMENTS = {
    'Terms of Use (ToU)': {
        minTgVersion: [1,0,0]
    },
    'Privacy Policy (PP)': {
        minTgVersion: [1,0,0]
    },
    'Addendum - Business - 01A': {
        minTgVersion: [1,0,3]
    }
}

export const useListSyleDecimal = tgMakeStyles({
    olDecimal: {
        listStyle: 'decimal inside'
    },
    olLatin: {
        listStyle: 'lower-latin inside'
    },
    liBlock: {
        display: 'list-item',
        paddingLeft: '0'
    },
    olDisc: {
        listStyle: 'disc inside'
    },
    olDiscOutside: {
        listStyle: 'disc outside',
        paddingInlineStart: '0.5rem',
        mmarginLeft: '0.5rem'
    }
})

export const useWordBreak = tgMakeStyles({
    wordBreak : {
        'overflow-wrap': 'break-word'
    }
})

export function hasAgreedDocument(documentName){
    const {minTgVersion} = LEGAL_DOCUMENTS[documentName]
    // agreement format agree:<documentname> = <userId|businessId>:<timestampnumber>
    const agreedVersion = localStorage.getItem(`agree:${documentName}`) || sessionStorage.getItem(`agree:${documentName}`)
    if(!agreedVersion) return false
    
    return compareToMinVersion(minTgVersion)(agreedVersion.split(','))
}

/**
 * 
 * @param {*} minVersion array. eg: [1,0,0] for version 1.0.0
 * @returns function that returns false if the given version is out of date when compared to minVersion
 */
const compareToMinVersion = minVersion => version => {
    const major = Number(version[0]) >= Number(minVersion[0])
    const medium = Number(version[1]) >= Number(minVersion[1])
    const minor = Number(version[2]) >= Number(minVersion[2])
    return major
            ? medium
                ? minor
                : false
            : false
}

export function openAgreementsConfirmationOverlay(listOfOutOfDateAgreements){
    if(listOfOutOfDateAgreements.length > 0){
        return new Promise((resolve,reject)=>{
            dispatch(openConfirmationDialog({
                TitleComponent: () => (
                    <Typography align="center" style={{...TYPOGRAPHY_STYLES.subtitle6, marginBottom: '1rem'}}>
                            UPDATES TO OUR TERMS
                    </Typography>
                ),
                TextComponent: () => <AgreementsPromptText agreements={listOfOutOfDateAgreements}/>,
                answerOptions:['AGREE'],
                onConfirm : ()=>{
                    listOfOutOfDateAgreements.forEach( agreement => {
                        localStorage.setItem(`agree:${agreement}`, [...pkgJson.version.split('.').map(str => Number(str)), Date.now()])
                    })
                    return resolve();
                }
            }))
        })
    } else {
        return Promise.resolve()
    }
}

export function AgreementsPromptText({agreements=[]}){
    function getLinkComponent(str){
        switch (str) {
            case 'Terms of Use (ToU)':
                return <TermsOfServiceLink />
            case 'Privacy Policy (PP)':
                return <PrivacyPolicyLink />
            case 'Addendum - Business - 01A':
                return <AddendumBusiness01ALink />
            default:
                return 'unknown'
        }
    }
    return agreements.length > 0 
        ? (
            <div style={{...TYPOGRAPHY_STYLES.body2, textAlign: 'center'}}>
                Please take the time to read the revised {agreements.map((str, i) => {return <span key={i}>{i === 0 ? '' : ' and'} {getLinkComponent(str)}</span>})}. To continue using TasteGods, you must confirm that you have read and accepted the terms.
            </div>
        )
        : <div>all agreements are up to date</div>
}

export function submitOutOfDateAgreements(){
    // this reducer takes an array of legal documents and returns an array of agreement promises for those documents that need to be agreed upon
    const agreementsPromises = Object.entries(LEGAL_DOCUMENTS).reduce((acc, [name]) => {
        const LSKey = `agree:${name}`
        const storageAgreement = localStorage.getItem(LSKey) // [1,0,0,timestamp,flag?]
        if(!storageAgreement) return acc
        
        const storedVersion = localStorage.getItem(LSKey).split(',').splice(0,3).join('.')

        return Number(storageAgreement.split(',')[4])
        // if already submitted (flag = 1)
            ? acc
            // else add a submission to accumulator array
            : [
                ...acc, 
                postUserAgreement(name, storedVersion).pipe(mapTo([LSKey, storageAgreement]))
            ]
    }, [])
    forkJoin(agreementsPromises).subscribe(storageAgreements => {
        storageAgreements.forEach(([key, storedAgreement]) => {
            // flag as submitted
            let flagged = storedAgreement.split(',')
            flagged[4] = 1
            localStorage.setItem(key, flagged)
        })
    })
    return 1
}