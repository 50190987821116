export const SUPPORTED_LANGUAGES = {
    'af': 'AFRIKAANS',
    'sq': 'ALBANIAN',
    'am': 'AMHARIC',
    'ar': 'ARABIC',
    'az': 'AZERBAIJANI',
    'eu': 'BASQUE',
    'be': 'BELARUSIAN',
    'bn': 'BENGALI',
    'bs': 'BOSNIAN',
    'bg': 'BULGARIAN',
    'my': 'BURMESE',
    'ca': 'CATALAN',
    'zh': 'CHINESE',
    'zh-CN': 'CHINESE (SIMPLIFIED)',
    'zh-HK': 'CHINESE (HONG KONG)',
    'zh-TW': 'CHINESE (TRADITIONAL)',
    'hr': 'CROATIAN',
    'cs': 'CZECH',
    'da': 'DANISH',
    'nl': 'DUTCH',
    'en': 'ENGLISH',
    'en-AU': 'ENGLISH (AUSTRALIAN)',
    'en-GB': 'ENGLISH (GREAT BRITAIN)',
    'et': 'ESTONIAN',
    'fa': 'FARSI',
    'fi': 'FINNISH',
    'fil': 'FILIPINO',
    'fr': 'FRENCH',
    'fr-CA': 'FRENCH (CANADA)',
    'gl': 'GALICIAN',
    'ka': 'GEORGIAN',
    'de': 'GERMAN',
    'el': 'GREEK',
    'gu': 'GUJARATI',
    'iw': 'HEBREW',
    'hi': 'HINDI',
    'hu': 'HUNGARIAN',
    'is': 'ICELANDIC',
    'id': 'INDONESIAN',
    'it': 'ITALIAN',
    'ja': 'JAPANESE',
    'kn': 'KANNADA',
    'kk': 'KAZAKH',
    'km': 'KHMER',
    'ko': 'KOREAN',
    'ky': 'KYRGYZ',
    'lo': 'LAO',
    'lv': 'LATVIAN',
    'lt': 'LITHUANIAN',
    'mk': 'MACEDONIAN',
    'ms': 'MALAY',
    'ml': 'MALAYALAM',
    'mr': 'MARATHI',
    'mn': 'MONGOLIAN',
    'ne': 'NEPALI',
    'no': 'NORWEGIAN',
    'pl': 'POLISH',
    'pt': 'PORTUGUESE',
    'pt-BR': 'PORTUGUESE (BRAZIL)',
    'pt-PT': 'PORTUGUESE (PORTUGAL)',
    'pa': 'PUNJABI',
    'ro': 'ROMANIAN',
    'ru': 'RUSSIAN',
    'sr': 'SERBIAN',
    'si': 'SINHALESE',
    'sk': 'SLOVAK',
    'sl': 'SLOVENIAN',
    'es': 'SPANISH',
    'es-419': 'SPANISH (LATIN AMERICA)',
    'sw': 'SWAHILI',
    'sv': 'SWEDISH',
    'ta': 'TAMIL',
    'te': 'TELUGU',
    'th': 'THAI',
    'tr': 'TURKISH',
    'uk': 'UKRAINIAN',
    'ur': 'URDU',
    'uz': 'UZBEK',
    'vi': 'VIETNAMESE',
    'zu': 'ZULU',
};

export const MAPS_LOADER_ERROR = {
    NETWORK_ERROR : "Network Error",
    AUTHENTICATION_ERROR : "SDK Authentication Error" 
}