import { Box, Button, Grid, Link, Typography, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles'
import { TYPOGRAPHY_STYLES } from '../../../theme/typographies'
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { useTypographyStyles } from '../../../theme/useTypography'
import {useJuicerFeed} from '../../hooks/useJuicerFeed'

const useStyles = tgMakeStyles(theme => ({
    btn: {
        margin: 0,
        '& .MuiButton-label': {
            ...TYPOGRAPHY_STYLES.body2    
        }
    }
}))

export function ManageBusinessSocialMedia({business, ...props}){
    const typo = useTypographyStyles()
    const classes = useStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))

    const {info} = useStateUserDetails()
    const _feed = useJuicerFeed((business && business.feed && business.feed.juicerId) ? business.feed.juicerId : null)
    const guideUrl = "https://www.notion.so/Connecting-a-social-media-account-with-your-TasteGods-Circle-profile-11a07fb0e09c4f509b1edc5196da11f8"

    return _feed && _feed.sources.length > 0
        ?(
            <Box mt={2} display="flex">
                <Box mr={2}>
                    <Button
                        id="manage-social-media"
                        component={Link}
                        target="_blank" 
                        href={`https://feeds.tastegods.com`}
                        variant="contained" 
                        color="secondary"
                        className={`${classes.btn} clickable`}
                    >
                        MANAGE
                    </Button>
                </Box>
                <Box>
                    {_feed.sources.map( (src, idx) => {
                        return (
                            <Box key={idx} display="flex" alignItems="center">
                                <Box style={{color: 'green'}} mr={1}>
                                    {src.term === "invalid-feed" ? <WarningIcon color="error"/> : <CheckCircleIcon color="inherit"/>}
                                </Box>
                                <Box>
                                    <Typography className={`${typo.body3}`}>
                                        <b>{src.source}</b>
                                    </Typography>
                                    <Typography className={`${typo.body2}`} style={{lineBreak: 'anywhere'}}>
                                        {`@${src.term}`}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        )
        : business && info
        ? (
            <Box mt={2} display="flex" flexDirection={isDesktop ? 'row' : 'column'}>
                <Box mr={2} mb={2}>
                    <Button
                        id="connect-social-media"
                        component={Link}
                        target="_blank" 
                        href={`https://feeds.tastegods.com`}
                        variant="contained" 
                        color="secondary"
                        className={`${classes.btn} clickable`}
                    >
                        CONNECT
                    </Button>
                </Box>
                <Box>
                    <Box mb={2}>
                        <Typography className={`${typo.body2}`}>
                            An email invitation was sent to <span className={`${typo.body3}`}>{info.mail}</span> to connect & manage your social media configuration on TasteGods.
                        </Typography>
                    </Box>
                    <Link href={`${guideUrl}`} target="_blank">Quick Setup Guide</Link>
                </Box>
            </Box>
        )
        : <Typography align="center">Loading...</Typography>
}