import React, { useEffect, useState } from 'react'
import { Box, Typography, Button, useMediaQuery } from '@material-ui/core'
import { dispatch } from 'slice';

import GoogleMapWithMarkerBox from '../../../../lib/google-map/components/GoogleMap/GoogleMapWithMarkerBox'

import { 
    // openModal, 
    closeModal 
} from '../../../dialogs/standard/state/actions';
// import { useStateUserHotels } from '../../../user/state/hooks/useStateUserHotels';
import { useTypographyStyles } from '../../../theme/useTypography';
import { addEditHotelMapPlace } from '../state/editHotelMapReducer';
import { getPlace } from '../../../http/tg/ldm-place';
import { CollapsableTextField } from '../../../../lib/components/inputs/CollapsableTextInput';


const BASE_NEW_HOTEL_PLACE = () => ({
    id: -1*Date.now(),
    tip: ""
})

export function AddHotelMapPlaceForm({
    name, 
    address, 
    lat, 
    lng, 
    googlePlaceId, 
    onSuccess
}){
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const typography = useTypographyStyles()

    const [placeData, setPlaceData] = useState()
    useEffect( function fetchPlaceData(){
        getPlace(googlePlaceId)
            .subscribe({
                next: res => {setPlaceData(res.data)},
                error: console.log
            })
    }, [])

    const handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        
        const newHotelPlace = {
            ...BASE_NEW_HOTEL_PLACE(),
            place: placeData,
            lat: placeData.place.lat,
            lng: placeData.place.lng,
            tip: formData.get('tip')
        }
        dispatch(addEditHotelMapPlace({
            ...newHotelPlace
        }))
        onSuccess(placeData)
    }
    return (
        <Box component={'form'}
            onSubmit={handleSubmit}
            p={0}
            position='relative'
        >
            {/* POI name */}
            <Typography
                variant='inherit' className={typography.h1}
                paragraph align='center'
            >
                {name}
            </Typography>
            {/* POI address */}
            <Typography 
                variant='inherit' className={typography.h6}
                paragraph align='center'
            >
                {address}
            </Typography>
            {/* Embedded Google map instance showing the POI location */}
            <GoogleMapWithMarkerBox
                BoxProps={{
                    height: '30vh',
                    // negtive margin is used to get the map to fill width beyond container padding
                    mx: isDesktop ? -8 : -4,
                    my: 4
                }}
                lat={lat} lng={lng}
            />
            {placeData && placeData.place && placeData.place.about && (
                <Box mb={4}>
                    <Typography className={`${typography.h3}`}>
                        ABOUT
                    </Typography>
                    <Typography>
                        {placeData.place.about}
                    </Typography>
                </Box>
            )}
            {/* <TgToggleTextBox
                boxProps={{mb: 4}}
                placeholder={`Share some tips about ${name} with your guest`}
                TextFieldProps={{
                    name: 'tip',
                    className: typography.body1
                }}
                headerText='YOUR TIP'
                toggleText={'SHARE TIPS'}
            /> */}
            {/* Collapsable input for hotel place tip */}
            <CollapsableTextField
                name="tip"
                label="Add More Information"
                subtext="i.e. details for a booking made, tips for a great experience"
                multiline={true}
                rows="5"
            />
            {/* Button ADD (next step) */}
            <Box textAlign='right'>
                <Button variant='contained' 
                    color='primary'
                    type='submit'
                    classes={{
                        label: typography.h3
                    }}
                >
                    ADD
                </Button>
            </Box>
        </Box>
    )
}

export default AddHotelMapPlaceForm