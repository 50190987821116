import React from 'react';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { Container, Grid, Box} from '@material-ui/core';

const resetRoutes = [
    {
        name: 'root.reset',
        abstract: true,
        data: {public: true}
    },
    {
        name: 'root.reset.password',
        url: '/reset?token',
        params: {
            token: {
                type: 'string',
                value: 'DUMMY_TOKEN'
            }
        },
        data: {public: true},
        views: {
            'content@root': {
                component: (props) => {
                    return (
                    <Container maxWidth="md">
                        <Grid container justify="center">
                            <Grid item>
                                <Box m={4}>
                                    <ResetPasswordForm {...props}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                )}
            }
        }  
    }
]

const authenticationRoutes = [
    ...resetRoutes
]

export default authenticationRoutes;
// const recoverRoutes = [
//     {
//         name: 'root.recover',
//         url: '/recover',
//         abstract: true
//     },
//     {
//         name: 'root.recover.password',
//         url: '/password',
//         views: {
//             'content@root': {
//                 component: RecoverPasswordForm
//             }
//         }   
//     },
//     {
//         name: 'root.recover.password.sent',
//         url: '/sent',
//         views: {
//             'content@root': {
//                 component: props => (
//                     <div>
//                         <h1>Almost done !</h1>
//                         <p>
//                             We have sent you a recovery email. Check your mailbox and follow the instructions.
//                         </p>
//                     </div>
//                 )
//             }
//         }   
//     }
// ]
