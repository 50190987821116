import React from 'react'
import { Snackbar } from '@material-ui/core'
import { useStateNotifications } from '../state/hooks/useStateNotifications'
import { dispatch } from 'slice'
import { closeNotification } from '../state/actions'

export default function NotificationsToastDiv() {
    const notification = useStateNotifications()
    const _duration = notification.duration === null
      ? null 
      : notification.duration || 6000
    const doCloseNotification = e => {
        dispatch(closeNotification())
    }
    return (
        <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={notification.open}
            autoHideDuration={_duration}
            onClose={doCloseNotification}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={notification.message}
            action={notification.action}
        />
    )
}
