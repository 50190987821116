import React, { useState } from 'react';
import {useRouter} from '@uirouter/react'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { Container, Box, Typography, IconButton, Tooltip, CircularProgress} from '@material-ui/core';

import QRCodeImg from '../../../../lib/qr-code/components/QRCode';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';

import {useStateUserHotels} from '../../../user/state/hooks/useStateUserHotels'
import CopyTextToClipboardTextField from '../../../../lib/components/buttons/CopyTextToClipboardTextField';
import {useTypographyStyles} from '../../../theme/useTypography'
import { COLORS } from '../../../theme/colors';
import { dispatch } from 'slice';
import { closeModal } from '../../../dialogs/standard/state/actions';
import { showNotification } from '../../../notifications/state/actions';
import { deleteHotelMap, postDuplicateMap } from '../../../http/tg/hotels';
import { EditMapTitleForm } from './EditMapTitle.form';
import InlineDialog from '../../../dialogs/standard/components/InlineStandardDialog';
import { openConfirmationDialog } from '../../../dialogs/confirmation/states/actions';
import DownloadQRCode from '../../../../lib/qr-code/components/DownloadQRCode';
import { format } from 'date-fns';
import { useHotelMapAsync } from '../../hooks';

const useStyles = tgMakeStyles(theme => ({
    sectionMargins: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    listItemSvgIcon: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    actionBtn: {
        margin: `0 ${theme.spacing(1)} 0 0`,
        backgroundColor: COLORS.get('gold').main,
        color: 'white',
        '&.grey': {
            backgroundColor: COLORS.get('grey')[3],
        },
        '&.gold:hover': {
            backgroundColor: COLORS.get('gold').dark
        }
    },
    placeOrderPin: {
        width: '2rem',
        height: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        color: 'white',
        backgroundColor: COLORS.get('grey')[3],
        marginRight: '1rem'
    },
    titleBox: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${COLORS.get('grey')[3]}`,
        borderRadius: theme.spacing(0.5),
        padding: '2.5rem 2rem'
    },
    title: {
        flexGrow: 1
    },
    editTitleBtn: {
        margin: 0,
        position: 'absolute',
        top: 0, right: 0
    },
    poiList: {
        columnWidth: '20rem'
    }
}))

function MapSummary({
    map, 
    onDelete,
    onChange,
    ...props
}){
    // if the hotel map is not including hotelPlace details, fetch hotel map by id
    const hasPlaces = map.hotelPlaces && map.hotelPlaces.length > 0 && !Number.isInteger(map.hotelPlaces[0])
    const _fetchedMap = useHotelMapAsync(hasPlaces ? 0 : map.id)
    const _map = hasPlaces
        ? map
        : _fetchedMap
    // console.log('_map', hasPlaces, _map)
    return _map
        ? (<MapSummaryDisplay {...{map: _map, onDelete, onChange, ...props}} />)
        : (
            <Box display="flex" minHeight="30vh">
                <CircularProgress
                    size={'4em'}
                    color="inherit"
                    style={{ margin: 'auto' }}
                />
            </Box>
        )
}

function MapSummaryDisplay({
    map, 
    onDelete,
    onChange,
    ...props
}){ 
    const hotels = useStateUserHotels()
    const _hotel = hotels && hotels.length 
        ? hotels[0]
        : null
    const _text = _hotel
        ? `${process.env.REACT_APP_TG_MAIL_REDIRECT_URL}/qrdmap/${_hotel.id}?method=console&customMapId=${map.id}`
        : null

    const classes = useStyles()
    const typo = useTypographyStyles()
    const router = useRouter()

    const creation_dt = new Date(Math.fround(map.created*1000))

    const [title, setTitle] = useState(map.title)
    const [dialog, setDialog] = useState({
        open: false,
        onClose: closeDialog,
        onSave: updateSummary
    })
    function closeDialog(e){
        setDialog(prev => ({...prev, open: false}))
    }
    function updateSummary(newtitle){
        onChange && onChange()
        setTitle(newtitle)
        closeDialog()
    }
    
    const editMapTitle = e => {
        setDialog({
            title: <Typography color="primary" className={`${typo.h3}`}>EDIT MAP TITLE</Typography>,
            component: EditMapTitleForm,
            props: {
                map: {
                    ...map,
                    title: title
                },
                onSave: updateSummary,
                onCancel: closeDialog
            },
            open: true,
            onClose: e => setDialog(prev => ({...prev, open: false}))
        })
    }
    const editMap = e => {
        // go to edit map page
        router.stateService.go('root.hotel-console.maps.map.edit', {
            customMapId: map.id
        })
        .then(res => {dispatch(closeModal())})
        .catch(res => {dispatch(showNotification({message: 'Unable to show this map, please try again later.'}))})
    }
    const duplicateMap = e => {
        postDuplicateMap(map.id).subscribe({
            next: res => {
                dispatch(showNotification({message: `"${map.title || "unnamed map"}" was successfully duplicated`}))
                onChange && onChange()
                dispatch(closeModal())
            },
            error: err => {}
        })
    }
    const deleteMap = e => {
        dispatch(openConfirmationDialog({
            title: 'DO YOU WANT TO DELETE?',
            text: 'This map will be permanently deleted. Guests will no longer be able to access it.',
            answerOptions:[ 'CANCEL', 'DELETE'],
            onConfirm : ()=>{
                deleteHotelMap(map.id).subscribe({
                    next: res => {
                        dispatch(showNotification({message: `"${map.title || "unnamed map"}" was permanently deleted`}))
                        dispatch(closeDialog())
                        dispatch(closeModal())
                        onDelete && onDelete(map.id)
                    },
                    error: err => {
                        dispatch(showNotification({message: 'Unable to delete this map, please try again later.'}))
                        dispatch(closeDialog())
                    }
                })
            },
            onDismissed : ()=>{
                dispatch(closeDialog())
            }
        }))   
    }


    return (
        <Box>
            <Container maxWidth='sm'>
                <Box p={2} mt={2} className={`${classes.titleBox}`}>
                    <Typography className={`${typo.subtitle4} ${classes.title}`} align="center">
                        {title || (_hotel
                            ? `${_hotel.name} - ${format(creation_dt, "dd MMMM yyyy")} - ${format(creation_dt, "HH:mm:ss")}`
                            : "no title")
                        }
                    </Typography>
                    <IconButton 
                        id="map-summary-open-edit-title"
                        className={`${classes.editTitleBtn} clickable`}
                        onClick={editMapTitle}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
            </Container>
            <Box>
                {/* QR Code */}
                <Container maxWidth='xs' className={classes.sectionMargins}>
                    <Box width={'14.375rem'} style={{margin: 'auto'}}>
                        {_text && <Box
                                display="flex"
                                marginBottom={2}
                                justify='center' alignItems='flex-end'
                                style={{position: 'relative'}}
                            >
                            <QRCodeImg
                                style={{
                                    border: `2px solid ${COLORS.get('grey')[3]}`,
                                    borderRadius: '10%',
                                    width: '100%',
                                    height: 'auto'
                                }} 
                                text={_text+'?method=console'}
                                colorLight={'#fff'}
                                colorDark={'#000'}
                            />
                            <DownloadQRCode text={_text+'?method=console'}
                                colorLight={'#fff'}
                                colorDark={'#000'}
                                style={{
                                    margin: 0,
                                    position: 'absolute',
                                    bottom: 0,
                                    right: '-3rem'
                                }}
                            />
                        </Box>}
                        {/* Copy to clipboard */}
                        {_text && <Box>
                            <CopyTextToClipboardTextField
                                color='primary'
                                IconButtonProps={{/* color: 'primary', */ variant: 'filled', edge: 'end'}}
                                text={_text+'?method=link'}>
                            </CopyTextToClipboardTextField>
                        </Box>}
                    </Box>
                </Container>
                {/* ACTION BUTTONS */}
                <Box display="flex" justifyContent="center" mb={4}>
                    <Tooltip title="Edit this map"
                        placement="left"
                    >
                        <IconButton className={`${classes.actionBtn} gold`}
                            onClick={editMap}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Duplicate this map"
                        placement="left"
                    >
                        <IconButton className={`${classes.actionBtn} grey`}
                            onClick={duplicateMap}
                        >
                            <LibraryAddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete this map"
                        placement="left"
                    >
                        <IconButton className={`${classes.actionBtn} grey`} onClick={deleteMap}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                {/* POIs list */}
                <Container maxWidth='lg'>
                    {map && map.hotelPlaces && map.hotelPlaces.length > 0
                        ? (<Box 
                            className={`${classes.poiList}`}
                        >
                            {map.hotelPlaces.map( (v, i) => {
                                return <HotelMapPlaceListItem
                                        key={i} 
                                        index={i}
                                        hotelPlace={v}
                                        ordered={map.ordered}
                                        mb={2}
                                    />
                                })
                            }
                        </Box>)
                        : (<Box display="flex" justifyContent="center">
                            This map is empty
                        </Box>)
                    }
                </Container>
            </Box>
            <InlineDialog modal={dialog}/>
        </Box>
    )
}

function HotelMapPlaceListItem({
    hotelPlace, 
    ordered,
    index,
    ...props
}){
    const typo = useTypographyStyles()
    const classes = useStyles()
    return (
        <Box display="flex" {...props} position="relative">
            {ordered && <Box className={`${classes.placeOrderPin}`} flexShrink="0">
                {index+1}
            </Box>}
            <Box style={{color: COLORS.get('grey')[3]}}>
                <Typography className={`${typo.subtitle6}`} color="inherit">
                    {hotelPlace.place.place.name}
                </Typography>
                <Typography className={`${typo.body2}`} color="inherit">
                    {hotelPlace.place.place.location}
                </Typography>
            </Box>
        </Box>
    )
}

export default MapSummary