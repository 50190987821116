import { useState, useEffect } from 'react';
import { map } from 'rxjs/operators';
import { $lice } from 'slice';
import { INITIAL_STATE, businessMembershipFormReducer } from '../reducers';
import {PLANS_PLAIN_TEXT} from '../../utils'
import tracker from '../../../../lib/mixpanel';

const [form$] = $lice('businessmembershipform', businessMembershipFormReducer, INITIAL_STATE)
// tracks form data in gtm dataLayer
form$
.pipe(
    map(state => ({
        selected_plan: PLANS_PLAIN_TEXT[state.selected_plan.value],
        business_name: state.business_name.value,
        business_address: state.business_address.value,
        primary_phone_number: state.primary_phone_number.value,
        primary_email: state.primary_email.value,
        business_website: state.business_website.value,
        google_profile_link: state.google_profile_link.value,
        admin_full_name: state.admin_full_name.value,
        admin_email: state.admin_email.value,
        billing_name: state.billing_name.value,
        billing_address: state.billing_address.value,
        vat_id: state.vat_id.value,
        agreed: state.agreed.value
    }))
)
.subscribe({
    next: formData => {
        tracker('form')('invited_new_membership_form', formData)
    },
    error: err => {console.log(err)}
})

export function useStateBusinessMembershipForm(){
    const [details, setDetails] = useState(INITIAL_STATE)
    useEffect(() => {
        let sub = form$.subscribe(function(n){
            setDetails(n)
        })
        return () => {sub.unsubscribe()}
    }, [])

    return details
}

export default useStateBusinessMembershipForm