import React, { useRef, useState } from 'react'
import { Box, Container } from '@material-ui/core'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles'
import LoopProgress from '../../../../lib/components/progress/LoopProgress'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const useStyles = tgMakeStyles(theme => ({
    pdfnav: {
        '& > *': {
            margin: '0 1rem 1rem 1rem'
        }
    },
    pdffile: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pdfsvg: {
        width: '100%',
        minWidth: '35rem',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        '& .react-pdf__Page__svg': {
            width: '100% !important',
            height: 'auto !important',
            zIndex: 2
        },
        '& .react-pdf__Page__svg svg': {
            width: '100% !important',
            height: 'auto'
        }
    }
}))

export function PdfArticle({file, downloadPdf=true, print=true, filename="tastegods-document.pdf", ...props}) {

    const _file = file instanceof Blob ?  window.URL.createObjectURL(file) : file
    const classes = useStyles()
    const [numPages, setNumPages] = useState(null);
    
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    
    const [pageNumber, setPageNumber] = useState(1);
    function stepPage(v){
        return e => {
            setPageNumber(prev => {
                console.log((prev + v <= 0 || prev + v > numPages) ? prev : prev + v)
                return (prev + v <= 0 || prev + v > numPages) ? prev : prev + v
            })
        }
    }

    return (
        <Container maxWidth="md">
            <Box display="flex" justifyContent="center" className={`${classes.pdfnav}`}>
                {/* <a onClick={stepPage(-1)}>{`<<`}</a><p>Page {pageNumber} of {numPages}</p><a onClick={stepPage(1)}>{`>>`}</a> */}
                <a href={_file} download={filename} type="application/pdf">pdf</a> | <a target="_blank" href={_file}>print</a>
            </Box>
            <Document
                loading={<LoopProgress 
                    color='primary' 
                    fontSize={'large'}
                />}
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                className={`${classes.pdffile}`}
                >
                    {numPages && Array(numPages).fill(0).map( (v, i) => <PdfPage 
                        key={i}
                        className={`${classes.pdfsvg}`}
                        pageNumber={i+1}
                    />)}
            </Document>
            {/* <Box display="flex" justifyContent="center" className={`${classes.pdfnav}`}>
                <a href={file} download>pdf</a> | <a target="_blank" href={file}>print</a>
            </Box> */}
        </Container>
    )
}

function PdfPage(props){
    const classes = useStyles()
    // used for preserveAspectRatio xMidYMin slice
    const _box = useRef()
    function onRenderSuccess(){
        if(_box.current) {
            _box.current.querySelector('svg').setAttribute('preserveAspectRatio', 'xMidYMin meet')
        }
    }
    return (
        <Page 
            {...props}
            loading={<LoopProgress 
                color='primary' 
                fontSize={'large'}
            />}
            className={`${classes.pdfsvg}`}
            inputRef={_box}
            renderMode="svg"
            onRenderSuccess={onRenderSuccess}
        />
    )
}