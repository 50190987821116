import React from 'react'
import { Container, Typography } from "@material-ui/core";
import { useTypographyStyles } from '../../../../theme/useTypography';

export function WhyEnglishInfoDialog(props){
    const typo = useTypographyStyles()
    return (
        <Container maxWidth="xs">
            <Typography className={`${typo.body2}`} align="center">
            TasteGods is currently only available in English.<br/>
            <br/>
            Our platform translates your profile from English to over 100 other languages for hotel guests & concierge.
            </Typography>
        </Container>   
    )
}