import { useMediaQuery, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ToolBarButton = ({mobile, desktop}) => {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    return (
        <React.Fragment>
            {isDesktop 
                ? desktop
                : mobile
            }
        </React.Fragment>
    );
}

ToolBarButton.propTypes = {
    mobile : PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element
    ]).isRequired,
    desktop : PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element
    ]).isRequired,
}

export default ToolBarButton




