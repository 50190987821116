import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import UsernameLink from '../../user/components/Username.Link';
import SearchIcon from '@material-ui/icons/Search';
import { useCurrentStateAndParams, useRouter, useSref } from '@uirouter/react';
import { useEffectRefreshAuthStatus } from '../../authentication/state/hooks/useEffectRefreshAuthStatus';
import { useEffectRefreshUserDetails } from '../../user/state/hooks/useEffectRefreshUserDetails';
import LoginLink from '../../authentication/components/LoginLogout.Link';
import { Box, Collapse, IconButton } from '@material-ui/core';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import TasteGodsBrand from './TasteGodsBrand.Typography';
import InvitationCodeLink from '../../invitation/components/InvitationCode.Link';
import { useStateUserDetails } from '../../user/state/hooks/useStateUserDetails';
import AutoCompleteBoxV2 from '../../../lib/components/utils/AutoCompleteBoxV2';
import { useStateAutocomplete } from '../../autocomplete/states/hooks/useStateAutocomplete';
import { useMediaQuery } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { COLORS } from '../../theme/colors';

const useStyles = tgMakeStyles(theme => ({
    appBarRoot: {
        boxShadow: '0px 4px 20px rgba(163,163,163,0.5)',
        backgroundColor: theme.palette.background.default
    },
    toolBarRoot: {
        padding: theme.spacing(2),
        flexWrap: 'wrap'
    },
    hoverCursor: {
        '&:hover': {cursor: 'pointer'}
    },
    mobSearchBtn: {
        margin: `0 ${theme.spacing(1)} 0 0`,
        backgroundColor: COLORS.get('gold').main,
        color: 'white'
    }
}))

export default function AppHeader(props){
    // use this hook to ensure component is rendered on all state changes so search bar only appears in the hotel-console
    useCurrentStateAndParams()
    const {info} = useStateUserDetails()
    const homeSref = useSref('root.welcome')
    const homeHSref = useSref('root.hotel-console.maps.dashboard')
    const homeBSref = useSref('root.business-console.dashboard')
    const classes = useStyles()
    const _homeSref = info && info.hotels
        ?  info.hotels.length > 0
            ? homeHSref
            : homeBSref
        : homeSref
    // Header is responsible for refreshingAuthStatus 
    // (this effect runs only once, checking if user is already authenticated in application cookies)
    useEffectRefreshAuthStatus()

    // sync with authentication status, this hook refresh user details when required
    useEffectRefreshUserDetails()

    const [autocomplete] = useStateAutocomplete();
    const router = useRouter()
    const onAutocompSelect = (...args) => {}
    const onInputChange = (...args) => {}
    const onInputEnter = (str) => {
        router.stateService.go('root.hotel-console.search-results', {q: str})
    }

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const [searchOpen, setSearchOpen] = useState(false)

    return (
        <AppBar
            color='default'
            classes={{
                root: classes.appBarRoot
            }}
            component='header'
            position="relative"
        >
            <Toolbar
                disableGutters={true}
                classes={{root: classes.toolBarRoot}}
            >
                {/* Tastegods text link */}
                <TasteGodsBrand 
                    {..._homeSref}
                    id="app-header-tastegods-home-link"
                    className='clickable'
                    BoxProps={{
                        m: 1
                    }}
                    classes={{root: classes.hoverCursor}}
                />
                
                {/* SPACER */}
                <Box 
                    className='flex-spacer' 
                    flexGrow={1}
                    display="flex"
                    justifyContent="center"
                >
                    {/* desktop hotel-console search bar */}
                    {router.stateService.includes('root.hotel-console') && isDesktop && (
                        <Box mx={7} maxWidth="62rem" flexGrow="1">
                            <AutoCompleteBoxV2
                                TextFieldProps={{
                                    variant: 'outlined',
                                    color: 'primary',
                                    fullWidth: true,
                                    autoFocus: false,
                                    placeholder: "search for a business"
                                }}
                                options={autocomplete.results}
                                OptionListComp={()=>null}
                                onOptionSelect={onAutocompSelect}
                                maxOptions={5}
                                onChange={(e, str) => onInputChange(str)}
                                loading={autocomplete.loading}
                                onSearch={onInputEnter}
                                HelpTextComp={()=>null}
                            />
                        </Box>
                    )}
                </Box>
                {/* invitation code */}
                <InvitationCodeLink 
                    id="header-invitation-link"
                    className='clickable'
                    BoxProps={{
                        m: 1
                    }} 
                >
                    Invitation Code
                </InvitationCodeLink>
                
                {/* mobile hotel console search button */}
                {router.stateService.includes('root.hotel-console') && !isDesktop && !searchOpen && (
                    <IconButton
                        onClick={e => {
                            e.stopPropagation()
                            setSearchOpen(true)
                        }}
                        className={`${classes.mobSearchBtn}`}
                    >
                        <SearchIcon />
                    </IconButton>
                )}
                {/* Login/Logout Link */}
                <LoginLink 
                    id="header-authentication-link"
                    className='clickable'
                    BoxProps={{m: 1}} 
                    classes={{root: classes.hoverCursor}}
                />
                
                {/* Username link */}
                <UsernameLink 
                    BoxProps={{m: 1, display: !isDesktop ? 'none' : 'initial'}}
                />

                {/* mobile hotel console search input */}
                {router.stateService.includes('root.hotel-console') && !isDesktop && (
                    <Box flexBasis="100%" flexShrink="0" flexGrow="1">
                        <Collapse 
                            in={searchOpen} 
                            timeout={{ enter: 200, exit: 200 }}
                        >
                            <ClickAwayListener 
                                mouseEvent="onMouseDown"
                                touchEvent="onTouchStart"
                                onClickAway={e => {
                                    e.stopPropagation()
                                    setSearchOpen(false)
                                }}
                            >
                                <Box>
                                    <AutoCompleteBoxV2
                                        TextFieldProps={{
                                            variant: 'outlined',
                                            color: 'primary',
                                            fullWidth: true,
                                            autoFocus: false,
                                            placeholder: "search for a business"
                                        }}
                                        options={autocomplete.results}
                                        OptionListComp={()=>null}
                                        onOptionSelect={onAutocompSelect}
                                        maxOptions={5}
                                        onChange={(e, str) => onInputChange(str)}
                                        loading={autocomplete.loading}
                                        onSearch={onInputEnter}
                                        HelpTextComp={()=>null}
                                    />
                                </Box>
                            </ClickAwayListener>
                        </Collapse>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}