import React from 'react'
import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles"
// import GmDigimapFeaturesListToggle from '../../../base/components/DigitalMapDrawerToggle.ButtonGroup'
import GmDigimapFeaturesListToggle from '../../../../maps/digital-map/base/components/DigitalMapDrawerToggle.ButtonGroup'
import ToolBarButton from '../../../../toolBar/components/ToolBarButton'

const useMobileBtnGroupStyles = tgMakeStyles(theme => ({
    grouped: {
        borderRadius: '0px',
        boxShadow: 'none'
    },
    root: {
        margin: 0,
        '& a.MuiButton-containedSizeLarge': {
            // hard-coded override for mobile layout where we want the toolbar to be taller than even size=large buttons
            padding: '9px 22px'
        }
    }
}))

export function getDigitalMapListToggle(map, hotel){
    const Btn = ({isMobile}) => {
        const classes = useMobileBtnGroupStyles()
        const _BoxProps = isMobile
            // on mobile, margins should be 0
            ? {
                m: 0
            }
            // on desktop, back to default margin
            : {}
        const _ForwardedButtonGroupProps = isMobile 
            // ButtonGroup for mobile must fit in the mobile toolbar
            ? {
                fullWidth: true,
                // size large uses the hard-coded padding override defined in useMobileBtnGroupStyles
                // result is 46px height buttons
                size: 'large',
                classes: {
                    root: classes.root,
                    grouped: classes.grouped
                }
            }
            : {
                fullWidth: true
            }
        return (
            <GmDigimapFeaturesListToggle
                hotel={hotel} 
                map={map}
                BoxProps={_BoxProps}
                {..._ForwardedButtonGroupProps}
            />
        )
    }
    return [
        props => {
            return (
                <ToolBarButton
                    mobile={(<Btn isMobile={true}/>)}
                    desktop={(<Btn />)}
                >
                </ToolBarButton>
            )
        }
    ]
}