import React from 'react'
import { Typography, Container, Grid } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function EmailSentConfirmationDiv({recipient}) {
    return (
        <Container>
            <Grid container>
                <Grid item xs={2} style={{textAlign: 'center'}}>
                    <CheckCircleIcon color="primary" style={{height: '3em', width: '3em'}}></CheckCircleIcon>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1">
                        Almost done ! Your email change will be effective only after you have confirmed your new email.
                        We have sent you a confirmation link at <b>{recipient}</b>.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}
