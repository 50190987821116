import { Box, Typography } from '@material-ui/core';
// import { useSrefActive } from '@uirouter/react';
import React from 'react';
import bannerBlackPng from '../../../assets/png/banner_black.png';
import { useTypographyStyles } from '../../theme/useTypography';
import { useTheme } from '@material-ui/styles';

// this is not a link anymore
export function TasteGodsCreditsLink(props){
    const {BoxProps, imgProps} = props
    const typography = useTypographyStyles()
    const theme = useTheme()
    return (
        <Box p={0} m={0} {...BoxProps}
            display='flex'
            justifyContent='center'
            alignItems='center'
        >
            {/* powered by TasteGods */}
            <Typography
                style={{margin: 0, marginBottom: theme.spacing(.5)}}
                className={typography.caption1}
            >
                powered by
            </Typography>
            <img src={bannerBlackPng}
                alt='logo'
                height='auto'
                width='60%'
                {...imgProps}
            />
        </Box>
    )
}