import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Input, Button, Select, MenuItem, Typography, LinearProgress } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useStateUserDetails } from '../state/hooks/useStateUserDetails';
import { TgForm } from '../../../lib/components/forms/DefaultTgForm';
import { parse } from 'date-fns';
import { dispatch } from 'slice';
import { updateUser, updateUserEmail, updateUserPassword } from '../state/actions';
import { STATUSES } from '../state/meta';
import PasswordInput from '../../../lib/components/inputs/PasswordInput';

// a collection of user properties edit controls
export default function EditUserDetailsForm({
    // hotel, currently not supported by the backend
    mail,
    alias,
    name,
    phone,
    title,
    birthdate,
    jobTitle,
    password
}) {
    const details = useStateUserDetails();

    const handleUpdate = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const payload = {};
        // mail and password updates have different flows
        if(mail) {
            payload.email = formData.get('email')
            dispatch(updateUserEmail(payload))
        }
        else if(password) {
            payload.currentPassword = formData.get('current-password')
            payload.newPassword = formData.get('new-password')
            payload.verifyNewPassword = formData.get('verify-new-password')
            dispatch(updateUserPassword(payload))
        }
        // other properties can be changed as simple text update
        else {
            name && (payload.name = formData.get('name'))
            alias && (payload.alias = formData.get('alias'))
            phone && (payload.phone = formData.get('phone'))
            title && (payload.gender = formData.get('gender'))
            birthdate && (payload.birthdate = formData.get('birthdate'))
            jobTitle && (payload.jobTitle = formData.get('jobTitle'))
            
            dispatch(updateUser(payload))
        }
    }

    const [birthDate, setBirthDate] = useState()
    const [gender, setGender] = useState('')
    // const [hotelData, setHotelData] = useState()
    useEffect(() => {
        setBirthDate(details && details.info && details.info.birthdate 
            ? parse(details.info.birthdate, 'dd.MM.yyyy', new Date())
            : void 0)
        setGender(details && details.info && details.info.gender
            ? details.info.gender
            : '')
        // setHotelData(details && details.info && details.info.hotels
        //     ? details.info.hotels[0].name
        //     : '')
    }, [details])

    return details && details.info && details.info.username
        ? (
            <TgForm onSubmit={handleUpdate}>
                {password &&
                <React.Fragment>
                    <FormControl fullWidth={true} margin="normal">
                        <InputLabel htmlFor="user-details-form-newPassword">
                            New password
                        </InputLabel>
                        <PasswordInput 
                            id="user-details-form-newPassword"
                            name="new-password"></PasswordInput>
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <InputLabel htmlFor="user-details-form-verifyNewPassword">
                            Confirm new password
                        </InputLabel>
                        <PasswordInput
                            id="user-details-form-verifyNewPassword"
                            name="verify-new-password"></PasswordInput>
                    </FormControl>
                    <FormControl fullWidth={true} margin="normal">
                        <InputLabel htmlFor="user-details-form-currentPassword">
                            Current password 
                        </InputLabel>
                        <PasswordInput
                            id="user-details-form-currentPassword"
                            name="current-password"></PasswordInput>
                    </FormControl>
                </React.Fragment>}
                {title && 
                <FormControl fullWidth={true} margin="normal">
                    <InputLabel htmlFor="user-details-form-gender">
                        Title
                    </InputLabel>
                    <Select
                        value={gender}
                        onChange={e => setGender(e.target.value)}
                        inputProps={{
                            name: 'gender',
                            id: 'user-details-form-gender',
                        }}
                    >
                        <MenuItem value={'mr'}>Mr</MenuItem>
                        <MenuItem value={'mrs'}>Mrs</MenuItem>
                        <MenuItem value={''}>None</MenuItem>
                    </Select>
                </FormControl>}
                {alias && 
                <FormControl fullWidth={true} margin="normal">
                    <InputLabel htmlFor="user-details-form-alias">
                        alias
                    </InputLabel>
                    <Input 
                        id="user-details-form-alias"
                        name="alias"
                        type="text"
                        // required={true}
                        fullWidth={true}
                        defaultValue={details.info.alias}
                        // aria-describedBy="" TODO? https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute
                        ></Input>
                </FormControl>}
                {name && 
                <FormControl fullWidth={true} margin="normal">
                    <InputLabel htmlFor="user-details-form-name">
                        Name
                    </InputLabel>
                    <Input 
                        id="user-details-form-name"
                        name="name"
                        type="text"
                        // required={true}
                        fullWidth={true}
                        defaultValue={details.info.name}
                        // aria-describedBy="" TODO? https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute
                        ></Input>
                </FormControl>}
                {mail &&
                <FormControl fullWidth={true} margin="normal">
                    <InputLabel htmlFor="user-details-form-email">
                        Email
                    </InputLabel>
                    <Input 
                        id="user-details-form-email"
                        name="email"
                        type="email"
                        // required={true}
                        fullWidth={true}
                        defaultValue={details.info.mail}
                        // aria-describedBy="" TODO? https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute
                        ></Input>
                </FormControl>}
                {birthdate && 
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        name="birthdate"
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        id="user-details-form-birthdate"
                        label="Date of birth"
                        value={birthDate}
                        onChange={setBirthDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        />
                </MuiPickersUtilsProvider>}
                {phone && 
                <FormControl fullWidth={true} margin="normal">
                    <InputLabel htmlFor="user-details-form-phone">
                        Phone number
                    </InputLabel>
                    <Input 
                        id="user-details-form-phone"
                        name="phone"
                        type="text"
                        // required={true}
                        fullWidth={true}
                        defaultValue={details.info.phone}
                        // aria-describedBy="" TODO? https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute
                        ></Input>
                </FormControl>}
                {jobTitle && 
                <FormControl fullWidth={true} margin="normal">
                    <InputLabel htmlFor="user-details-form-jobTitle">
                        Job title
                    </InputLabel>
                    <Input 
                        id="user-details-form-jobTitle"
                        name="jobTitle"
                        type="text"
                        // required={true}
                        fullWidth={true}
                        defaultValue={details.info.jobTitle}
                        // aria-describedBy="" TODO? https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute
                        ></Input>
                </FormControl>}
                {details.status && (details.status.update.error || details.status.password.error) && 
                <Typography color="error" variant="body2" style={{textAlign: 'center'}}>
                    {details.status.update.error}
                    {details.status.password.error}
                </Typography>}
                <FormControl fullWidth={true} margin="normal">
                    <Button
                        id="user-details-form-submit"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        // aria-describedBy="" TODO? https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute
                        >
                            UPDATE
                    </Button>
                    {(  (details && details.status.update.code === STATUSES.update.updating)
                        || (details && details.status.password.code === STATUSES.password.updating)
                    ) && 
                    <LinearProgress variant="query" color="primary"/>
                    }
                </FormControl>
            </TgForm>
        )
        : 'LOADING ...'
}
