import { tgMakeStyles } from "../../../../lib/styles/TgMakeStyles";
import {COLORS} from '../../../theme/colors'
import { TYPOGRAPHY_STYLES } from "../../../theme/typographies";

export const useStyles = tgMakeStyles((theme) => {
    return {
        container: {
            color: COLORS.get('grey')[4],
            padding: theme.spacing(2),
            '& img': {
                borderRadius: '1rem'
            }
        },
        containerMD: {
            color: COLORS.get('grey')[4],
            padding: theme.spacing(4),
            '& img': {
                borderRadius: '1rem'
            }
        },
        containerLG: {
            color: COLORS.get('grey')[4],
            padding: theme.spacing(2),
            '& img': {
                borderRadius: '1rem'
            }
        },
        containerCardMD: {
            color: COLORS.get('grey')[4],
            padding: theme.spacing(2),
            margin: theme.spacing(2),
            '& img': {
                borderRadius: '1rem'
            }
        },
        stepHeading: {
            // marginTop: '1rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&.noPadding': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            '&.noPaddingT': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            '&.noPadding .backBtn': {
                margin: 0,
                marginBottom: 0,
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0
            },
            '& > svg': {
                flexGrow: 0
            },
            '& > p': {
                flexGrow: 1
            },
            '& .backBtn': {
                marginLeft: 0
            }
        },
        mXL: {
            margin: '3rem 0 3rem 0',
        },
        mbXL: {
            marginBottom: '3rem'
        },
        mtXL: {
            marginTop: '3rem'
        },
        mL: {
            margin: '2.5rem 0 2.5rem 0',
        },
        mbL: {
            marginBottom: '2.5rem'
        },
        mtL: {
            marginTop: '2.5rem'
        },
        mM: {
            margin: '1.5rem 0 1.5rem 0',
        },
        mbM: {
            marginBottom: '1.5rem'
        },
        mtM: {
            marginTop: '1.5rem'
        },
        mS: {
            margin: '1rem 0 1rem 0',
        },
        mbS: {
            marginBottom: '1rem'
        },
        mtS: {
            marginTop: '1rem'
        },
        mtXS: {
            marginTop: '.5rem'
        },
        mXS: {
            margin: '.5rem 0 .5rem 0',
        },
        mbXS: {
            marginBottom: '.5rem'
        },
        mtXS: {
            marginTop: '.5rem'
        },
        mobileCTA: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderTop: `1px solid ${COLORS.get('grey')[2]}`,
            '& button, & .btn': {
                borderRadius: '1.5625rem',
                minWidth: '10rem',
                minHeight: '2.25rem',
                ...TYPOGRAPHY_STYLES.h4
            }
        },
        mobileCTAMD: {
            zIndex: 10,
            position: 'fixed',
            bottom: 0, left: 0,
            width: '100vw',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: `1px solid ${COLORS.get('grey')[2]}`,
            '& button, & .btn': {
                borderRadius: '1.5625rem',
                minWidth: '10rem',
                minHeight: '2.25rem',
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
                marginTop: theme.spacing(1),
                marginBottomt: theme.spacing(1),
                ...TYPOGRAPHY_STYLES.h4
            }
        },
        desktopCTA: {
            zIndex: 10,
            position: 'fixed',
            bottom: 0, left: 0,
            width: '100vw',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: `1px solid ${COLORS.get('grey')[2]}`,
            transition: 'all .3s',
            pointerEvents: 'none',
            opacity: 0,
            '&.on': {
                opacity: 1,
                pointerEvents: 'all'
            }
        },
        btn: {
            borderRadius: '1.5625rem',
            minWidth: '10rem',
            minHeight: '2.25rem',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(1),
            marginBottomt: theme.spacing(1),
            ...TYPOGRAPHY_STYLES.h4
        },
        shadow: {
            boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)"
        }
    }
})

export default useStyles