import React from 'react';
import { 
    pushStateLocationPlugin,
    servicesPlugin, 
    UIRouterReact
} from '@uirouter/react';
import welcomeRoutes from './modules/welcome/routes';
import hotelRoutes from './modules/hotel/routes'
import authenticationRoutes from './modules/authentication/routes'
import invitationRoutes from './modules/invitation/routes'
import AppHeader from './modules/header/components/AppHeader';
import userRoutes from './modules/user/routes';
import tracker from './lib/mixpanel';
import errorRoutes from './modules/error/routes'
import { isAuthenticated } from './modules/http/tg/authentication';
import AppLayout from './AppLayout';
import { PrivacyPolicyArticle, TermsOfServicesArticle } from './modules/legal';
import { businessRoutes } from './modules/business/routes';

// Here we keep it simple :
// * ROOT_ROUTE is abstract and should not be directly navigated to
// * defines the app skeleton, 3 named views : header, content and footer
// * finally fills them with default content. Module's routes should target the desired views
const ROOT_ROUTE = {
    name: 'root',
    abstract: true,
    data: {},
    onEnter: function(trans, state) {
        tracker('register')({
            platform: 'public',
            language: 'default'
        })
    },
    views: {
        '': {
            component: AppLayout
        },
        'header@root': {
            component: AppHeader
        },
        'content@root': {
            // Default content, I don't think there is a way for this to ever be displayed
            component: props => (<div>WELCOME TO TASTEGODS</div>)
        },
        'content-footer@root': {
            component: () => null
        }
    },
}
// This route is used when the backend wants to redirect to a page showing a specific message to the user
const MESSAGE_ROUTE = {
    name: 'root.message',
    url: '/display/message?msg&header',
    params: {
        msg: {
            type: 'string'
        },
        header: {
            type: 'string'
        }
    },
    data: {public: true},
    views: {
        'content@root': {
            component: ({$stateParams}) => {
                // console.log('$stateParams', $stateParams)
                return (
                    <div>
                        <h1>{window.decodeURIComponent($stateParams.header)}</h1>
                        <p>{window.decodeURIComponent($stateParams.msg)}</p>
                    </div>
                )
            }
        }
    }
}
const TOS_ROUTE = {
    name: 'root.tos',
    url: '/termsofuse',
    data: {public: true},
    views: {
        'content@root': {
            component: () => (<TermsOfServicesArticle />)
        }
    }
}
const PRIV_ROUTE = {
    name: 'root.privpolicy',
    url: '/privacypolicy',
    data: {public: true},
    views: {
        'content@root': {
            component: () => (<PrivacyPolicyArticle />)
        }
    }
}

const routerPlugins = [
    pushStateLocationPlugin, // required some server configutration to work as SPA
    // hashLocationPlugin, // temporary plugin that uses hash fragments for routing. doesn't require server config
    servicesPlugin
];

// Create a new instance of the Router
export const router = new UIRouterReact();
routerPlugins.forEach(plugin => {
    router.plugin(plugin);    
})

const states = [
    ROOT_ROUTE,
    MESSAGE_ROUTE,
    TOS_ROUTE,
    PRIV_ROUTE,
    ...authenticationRoutes,
    ...welcomeRoutes,
    ...invitationRoutes(router),
    ...hotelRoutes(router),
    ...businessRoutes,
    ...userRoutes,
    ...errorRoutes
];

// Register the initial (eagerly loaded) states
states.forEach(state => router.stateRegistry.register(state));
/* 
    ROUTER CONFIGURATION 
*/
// Initial route when navigating to tastegods.com.
// This rule is invoked only when user directly lands on tastegods.com.
// To avoide unnecessary 404 errors, when user transit to tastegods.com, e.g history back,
// state 'root.origin' exsits on welcome routes.
router.urlService.rules.initial({ state: 'root.welcome' });

// Redirect to 404 page if url doesn't exist 
router.urlService.rules.otherwise((matchValue, urlParts, router) => {
    // console.error('here comes error')
    // console.error(matchValue, urlParts, router)
    return {state : 'root.error',params:{tgErrorCode:"NOT_FOUND"}}
});

// catch unhandled errors on routes.
router.stateService.defaultErrorHandler(()=>{
    // console.log('error')
    return {state : 'root.error'}
})

/* 
    TRANSITION HOOKS
*/
// If authenticated, Home is not welcome page but rather the standard qr code page
// router.transitionService.onBefore({to: 'root.welcome'}, (trans) => {
//     if(isAuthenticated()){
//         return trans.router.stateService.target('root.hotel-console.maps.dashboard')
//     }
// })
// Checks only public routes can be access when not authenticated
// public route must have their stateDeclaration include data.public = true
// data is inherited by children state via prototypal inheritance :
// so if a child state needs to be auth only while one of its parent is public, 
// the child stateDeclaration must explicitely specify data.public = false
router.transitionService.onBefore({
    to: function(state) {
        return !state.data.public
    }
}, (trans) => {
        if(!isAuthenticated()){
            return trans.router.stateService.target('root.welcome')
        } else {
            return true
        }
    }
)

router.transitionService.onSuccess({to: 'root.welcome'}, trans => {
    tracker('register')({platform: 'public'})   
})

// router.transitionService.onSuccess({to: 'root.welcome.invited'}, trans => {
//     Mixpanel('register')({invited: 'invitation'})
// })

// trackConciergeConsole(router)
// trackGuestConsole(router)

// track page view on transition success
router.transitionService.onSuccess({}, trans => {
    // trigger pageview event
    var pageViewProps = {
        urlPath: router.locationService.path(),
        fromState: trans.from().name,
        toState: trans.to().name,
        toStateParams: trans.targetState().params()
    };
    tracker('track')("pageview", pageViewProps);
})

// Lazy load visualizer
// import('@uirouter/visualizer').then(module => router.plugin(module.Visualizer));