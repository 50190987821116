import axious from 'axios';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { TG_API } from '../';

// const BASE_URL = '/misc/search'
const BASE_URL = '/ldm/place/search'

const callSearchApi = (params,cancelTokenssrc) => {
    
    const req = {
        method: 'get',
        url: BASE_URL,
        params,
        cancelToken : cancelTokenssrc ? cancelTokenssrc.token : null
    }

    return from(TG_API(req))
}

// TODO : accept params below eventually.
// openNow .. return only open (if opening hours available)
// favorites .. return only favorites
// maxDistance=[FLOAT]
// minDistance=[FLOAT]
// openStart .. [dd.mm.yyyy HH:MM] (complete date) or [HH:MM] (time)
// openEnd .. like openStart, requires openStart
// openThroughout .. if specified, only results that are opened throughout the whole given period (openStart-openEnd) are returned
// applyOpenToSearch .. if specif ied, the open filters are applied to Google results, which requires a Google Place Details request for every Google result

export const searchPoiPerPage = ({ searchStr, page, per_page, center, hotelId, type }) => {

    const cancelTokenssrc = axious.CancelToken.source();

    const API$ = callSearchApi({   
        q: searchStr,
        search: true, // Google nearby search are added
        hotelId,
        center,
        metaData:true,
        page,
        per_page,
        type : type && type instanceof Array ? type.toString() : null,
        nocache: page === 1 ? true : void 0, // on every new request, search query should be rebuilt
        
    },cancelTokenssrc).pipe(
        map(res => ({
            metaData: {
                limit: res.data.limit,
                page: res.data.page,
                pages: res.data.pages,
                total: res.data.total,
            },
            results: res.data.data
        }))
    )

    return [API$,cancelTokenssrc]
}