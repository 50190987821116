import { dispatch } from 'slice';
import { retriveTranslationOfMap, setLanguageToLocal } from '../../../http/tg/translation';
import { TRANS_INITIAL_STATE } from '../hooks/useStateTranslation';

export const getTranslation = ({
    hotelId, 
    customMapId, 
    language = TRANS_INITIAL_STATE.language 
}) => {
    dispatch(attemptTranslationApi())
    const $subscribe = retriveTranslationOfMap({hotelId,customMapId,language})
    $subscribe.subscribe({
        next : (res)=>{
            dispatch(receiveTranslation(res.data))
        },
        complete : ()=> {
           dispatch(completeTranslation()) 
        } ,
        error : e => {
            dispatch(errorOnTranslation(e))
        }
    })
}

export const setLanguage = (lang) => {
    setLanguageToLocal(lang)

    return {
        type : 'SET_LANGUAGE',
        payload : {
            language : lang
        }
    }
}

const receiveTranslation = (res) => {
    return {
        type : 'RECIEVE_TRANSLATION',
        payload : {
            translation : res
        }
    }
}

export const attemptTranslationApi = () => {
    return {
        type: 'ATTEMPT_TRANSLATION',
        payload: {
            loading: true,
        }
    }
}

export const completeTranslation = () => {
    return {
        type: 'COMPLETE_TRANSLATION',
        payload: {
            loading: false,
        }
    }
}

export const errorOnTranslation = error => {
    return { 
        type: 'ERROR_TRANSLATION',
        payload : {
            error
        }
    }
}

export const updateStoredTranslatoin = () => {
    // TOOD : implement 
    // For MVP, we wouldn't do updating because there wouldn't be many cases 
    // where the same user access the same map after update on pois 
}

export const getStoredTranslationByPoiId = () => {
    // TODO : Waiting for API fixies.
    // https://app.asana.com/0/0/1166394463648537/f
}

