import { TG_API } from '../';
import { from } from 'rxjs';

const BASE_URL = '/misc';

export function sendEmail({subject, emailBody, files}){
    if(!subject || !emailBody) {throw new TypeError('subject and emailBody are required to send an email')}
    const reqConfig = {
        method: 'POST',
        url: BASE_URL+'/email',
        data: {
            subject,
            emailBody,
        }
    }
    if(files
        && files.name
        && files.type
        && (files.base64 || files.url)){
            reqConfig.params.files = files
    } else {
        reqConfig.data.files = []
    }
    return from(TG_API(reqConfig))
}