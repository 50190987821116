import { useState, useEffect } from 'react';
import { INITIAL_STATE, contextDrawerReducer } from '../reducers';
import { $lice } from 'slice';

const [drawers$] = $lice('tgContextDrawer', contextDrawerReducer, INITIAL_STATE);

export function useStateContextDrawer(){
    const [contextDrawer, setContextDrawer] = useState(INITIAL_STATE)
    useEffect(() => {
        let sub = drawers$.subscribe(setContextDrawer)
        return () => {
            sub.unsubscribe()
        };
    }, [])
    return contextDrawer
}