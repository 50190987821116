import {dispatch} from 'slice';
import { closeContextDrawer } from '../../../contextDrawer/state/actions'
import { CollectionsHome } from '../../../collections/CollectionsHome'
import { getNewBusinesses } from '../../../http/tg/business'
import { getUserPlaces } from '../../../http/tg/user'
import { showNotification } from '../../../notifications/state/actions'
import { catchError, map } from 'rxjs/operators'
import { throwError } from 'rxjs'
import { getHotelTeamPlaces } from '../../../http/tg/hotels'
import { Collection } from '../../../collections/Collection'

export const userCollectionsRoutes = [
    {
        name: 'root.hotel-console.collections',
        url: '/collections',
        onExit: function(){
            dispatch(closeContextDrawer())
        },
        views: {
            'content@root': CollectionsHome
        }
    },
    {
        name: 'root.hotel-console.collections.collection',
        url: '/:id',
        params: {
            id: {
                type: 'string'
            },
            hId: {
                type: 'string',
                value: '0'
            }
        },
        onExit: function(){
            dispatch(closeContextDrawer())
        },
        resolve: [{
            token: 'collection',
            deps: ['$stateParams', '$transition$'],
            resolveFn: (params, trans) => {
                if (params.id === 'new') {
                    return getNewBusinesses()
                        .pipe(
                            map(res => res.data),
                            catchError(err => {
                                dispatch(showNotification({message: 'Unable to fetch collection. Please try again later.'}))
                                return throwError(err)
                            })
                        ).toPromise()
                } else if (params.id === 'own') {
                    return getUserPlaces()
                        .pipe(
                            map(res => res.data),
                            catchError(err => {
                                dispatch(showNotification({message: 'Unable to fetch collection. Please try again later.'}))
                                return throwError(err)
                            })
                        ).toPromise()
                } else if (params.id === 'team') {
                    if(!params.hId) return throwError('invalid_hotel_id')
                    return getHotelTeamPlaces(params.hId)
                        .pipe(
                            map(res => res.data),
                            catchError(err => {
                                dispatch(showNotification({message: 'Unable to fetch collection. Please try again later.'}))
                                return throwError(err)
                            })
                        ).toPromise()
                } else {
                    return Promise.resolve('invalid_id')
                }
            }
        }],
        views: {
            'content@root': Collection
        }
    },
]

export default userCollectionsRoutes