import { useState, useEffect } from 'react';
import { INITIAL_STATE, rootToolBarReducer } from '../reducers';
import { $lice } from 'slice';

const [toolBar$] = $lice('tgtoolbar', rootToolBarReducer, INITIAL_STATE);

export function useStateToolBar(){
    const [toolBar, setToolBar] = useState(INITIAL_STATE)
    useEffect(() => {
        let sub = toolBar$.subscribe(setToolBar)
        return () => {
            sub.unsubscribe()
        };
    }, [])
    return toolBar
}