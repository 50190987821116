import { Container ,ListItem, Typography } from '@material-ui/core'
import React from 'react'
import OpenModalLink from '../../../../dialogs/standard/components/OpenStandardDialog.Link'
import { useListSyleDecimal } from '../../../../legal'
import { COLORS } from '../../../../theme/colors'
import { useTypographyStyles } from '../../../../theme/useTypography'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export function WhatGuestShouldKnowInfoDialog(props){
    const classes = useListSyleDecimal()
    return (
        <Container maxWidth="xs">
            <ol className={classes.olDiscOutside} component="ol" style={{transform: 'translateX(0.5rem)'}}>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        Are there requirements for guests to visit your business?
                    </Typography>
                </ListItem>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        Are there capacity or group size restrictions?
                    </Typography>
                </ListItem>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        What precautions is your business taking to ensure the safety of guests & staff?
                    </Typography>
                </ListItem>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        What should a guest know before leaving the hotel towards you?
                    </Typography>
                </ListItem>
            </ol>
        </Container>
    )
}

export function WhatGuestShouldKnowInfoDialogLink({trackingProps, ...forwardProps}){
    const typo = useTypographyStyles()
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle={(
                <Typography className={`${typo.subtitle5}`} color="primary">
                    WHAT A GUEST SHOULD KNOW <span style={{color: COLORS.get('blue')['main']}}>BEFORE VISITING</span>
                </Typography>
            )}
            modalComponent={WhatGuestShouldKnowInfoDialog}
            modalComponentProps={trackingProps}>
                {forwardProps.children ? forwardProps.children : <HelpOutlineIcon />}
        </OpenModalLink>
    )
}