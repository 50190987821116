import React from 'react'
import  OpenModalLink  from '../../dialogs/standard/components/OpenStandardDialog.Link'
import { TermsOfServicesArticle } from './articles/TermsOfServicesArticle'

export function TermsOfServiceLink({trackingProps, ...forwardProps}) {
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle="Terms of Use"
            modalComponent={TermsOfServicesArticle}
            modalComponentProps={trackingProps}>
                {forwardProps.children ? forwardProps.children : 'Terms Of Use'}
        </OpenModalLink>
    )
}
