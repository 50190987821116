import { useMediaQuery } from '@material-ui/core'
import React from 'react'

export function Breakpoints({
    small:S=()=>null,
    medium:M=()=>null, 
    large:L=()=>null, 
    ...props
}){
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))
    const isMedium = useMediaQuery(theme => theme.breakpoints.up('sm'))
    return isLarge 
        ? <L {...props}/> 
        : isMedium 
            ? <M {...props}/>
            : <S {...props}/>
}

export default Breakpoints