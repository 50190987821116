import produce from 'immer';
import authStatusReducer from './authStatus';

export {
    rootAuthReducer,
    INITIAL_STATE
}
const INITIAL_STATE = {
    status: null
}
function rootAuthReducer(state, action){
    return produce(state, draft => {
        draft['status'] = authStatusReducer(state['status'], action)
    })
}