import React from 'react'
import { Dialog, Fade, DialogTitle, DialogContent, Box, SvgIcon, Slide, IconButton } from '@material-ui/core'
import { useStateModal } from '../state/hooks/useStateModal';
import { dispatch } from 'slice';
import { closeModal } from '../state/actions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';

const useStyles = tgMakeStyles(theme => ({
    TitleRoot: {
        textAlign: 'center'
    },
    fadedDiv: {
        width: '100%', 
        height: '100%'
    }
}))

export default function ModalDiv() {
    const classes = useStyles()
    const modal = useStateModal();
    const Comp = modal.component;
    const doCloseModal = e => {
        dispatch(closeModal())
        modal.onClose && modal.onClose()
    }
    const content = (
        <React.Fragment>
            {/* absolutely positioned back and close buttons */}
            <Box 
                position='absolute'
                top='0' left='0' right='0'
                display="flex" 
                justifyContent="space-between" 
                alignItems="center"
            >
                {/* back arrow, only if onBack callback is provided */}
                {modal.onBack 
                    && (
                        <IconButton 
                            onClick={e => modal.onBack()}
                            style={{color: 'inherit'}}
                        >
                            <ArrowBackIcon/>
                        </IconButton>
                    )
                }
                <div></div>
                {/* close modal icon button */}
                <IconButton 
                    onClick={doCloseModal}
                    style={{
                        color: 'inherit',
                        marginTop: 0,
                        marginRight: 0
                    }}
                >
                    <SvgIcon>
                        <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
                    </SvgIcon>
                </IconButton>
            </Box>

            {/* Title and Content */}
            {modal.title && (
                <DialogTitle 
                    id="standard-dialog-title"
                    disableTypography
                    classes={{root: classes.TitleRoot}}
                >
                    {/* the modal title */}
                    {modal.title}
                </DialogTitle>
                
            )}

            <DialogContent id="standard-dialog-content">
                {/* content component */}
                {Comp && <Comp {...modal.props}></Comp>}
            </DialogContent>
        </React.Fragment>
    )
    return (
        <Dialog 
            scroll='paper'
            fullScreen={modal.fullScreen}
            open={modal.open}
            onClose={doCloseModal}
            {...modal.DialogProps}
        >
            {!modal.fullScreen 
                ? (
                    <Fade in={modal.open}>
                        {/* Fade requires an enclosing element */}
                        <div className={classes.fadedDiv}>
                            {content}
                        </div>
                    </Fade>)
                : (
                    <Slide direction="up" in={modal.open} mountOnEnter unmountOnExit>
                        <div className={classes.fadedDiv}>
                            {content}
                        </div>
                    </Slide>
                )
            }
        </Dialog>
    )
}
