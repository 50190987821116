import { useEffect, useState } from 'react';
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails';
import { searchByKeyword } from '../actions/poiSearchActions';
import useStatepoiSearch from './useStatePoiSearch';
import { INITIAL_PAGINATOR_META_PARAMS, INITIAL_SEARCH_CRITERIA } from '../meta/INITIAL_SEARCH_CRITERIA';

/**
 * This component encapsulaizes the logics for search API.
 * This component wrapps given child component and inject functions and states that are used for interactions with search API.
 */
export const useSearchPaginatorHooks = () => {
    const {info} = useStateUserDetails()
    const [poiSearch, dispatch] = useStatepoiSearch()
    const [searchCriteria, setSearchCriteria] = useState(INITIAL_SEARCH_CRITERIA)
    const [paginatorParams, setPaginatorParams] = useState(INITIAL_PAGINATOR_META_PARAMS)
    const [ready, setReady] = useState(false)
    const [cancelToken, setCancelToken] = useState(null);
    
    // Set hotelId asynchronously
    useEffect(() => {
        if (info && info.hotels) {
            setSearchCriteria({
                ...searchCriteria,
                hotelId: info.hotels[0].id
            })
        }
    }, [info])

    // poiSearch.cancelToken is renewd everytime API call is executed.
    useEffect(()=>{
        // If cancelToken is present, which means the previous http request is in progress,
        // execute http request cancelation.
        if (cancelToken) {
            cancelToken.cancel('cancel from hook')
        }
        setCancelToken(poiSearch.cancelToken);
    },[poiSearch.cancelToken])

    useEffect( () => {
        if(searchCriteria.hotelId || searchCriteria.center){
            setReady(true)
        }
        return () => {}
    }, [searchCriteria])

    useEffect(() => {
        if(!ready) return () => {}
        searchByKeyword({
            ...searchCriteria,
            ...paginatorParams,
        }, dispatch)
    }, [paginatorParams.page, searchCriteria.searchStr])

    const onSearchClick = (searchStr) => {
        if (!ready) {
            return void 0;
        }
        setSearchCriteria(prevState => {
            // when search str is changed, search query must be regarded as a new one.
            if (prevState.searchStr !== searchStr) {
                resetPage()
            }
            return {
                ...searchCriteria,
                searchStr: searchStr
            }
        })
    }

    const getNextPage = () => {
        if (!ready || !poiSearch.hasNextPage) return void 0;
        setPaginatorParams({
            ...paginatorParams,
            page: paginatorParams.page + 1
        })
    }

    const getPreviousPage = () => {
        if (!ready) return void 0;
        // if results are not fetched sequentially, results are not sorted collectlly and reset is required.
        setPaginatorParams({
            ...paginatorParams,
            page: paginatorParams.page - 1
        })
    }

    const getFirstPage = () => {
        if (!ready) return void 0;
        setPaginatorParams({
            ...paginatorParams,
            page: 1
        })
    }

    const getLastPage = () => {
        if (!ready) return void 0;
        setPaginatorParams({
            ...paginatorParams,
            page: poiSearch.metaData.pages
        })
    }

    const getPageByNumber = pageNum => {
        if (!ready) return void 0;
        setPaginatorParams({
            ...paginatorParams,
            page: pageNum
        })
    }

    const resetPage = () => {
        setPaginatorParams({
            ...INITIAL_PAGINATOR_META_PARAMS
        })
        dispatch({
            type : 'RESET_STATE'
        })
    }

    return {
        ready,
        poiSearch, 
        searchCriteria, 
        onSearchClick, 
        getNextPage, 
        getPreviousPage,
        getFirstPage,
        getLastPage,
        getPageByNumber
    }

}