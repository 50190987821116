import produce from 'immer';
import Mixpanel from '../../../../lib/mixpanel'

const INITIAL_STATE = {
    id:null,
    
    alias:null,
    birthdate:null,
    email:null,
    favorites:null,
    gender:null,
    image:null,
    jobTitle:null,
    mail:null,
    name:null,
    phone:null,
    
    association:null,
    hotels:null,
    business:null,
    
    preferences:null,
    role:null,
    status:null,
    teamAccount:null,
    user:null,
    username:null,
    wallet:null,
    workMail:null
}

export {
    INITIAL_STATE,
    userInfoReducer
}


function userInfoReducer(state, action){
    state = state || INITIAL_STATE
    switch (action.type) {
        case 'USER/DETAILS/INFO/ASSIGN':
            
            Mixpanel('identify')(action.payload.id)
            // console.log('assign people props', action.payload)
            Mixpanel('people.set')({
                alias: action.payload.alias,
                email: action.payload.mail,
                hotelID: action.payload.hotels.length > 0 ? action.payload.hotels[0].id : void 0,
                businessID: action.payload.business.length > 0 ? action.payload.business[0].id : void 0,
                username: action.payload.username,
                jobTitle: action.payload.jobTitle
                // tbc with other requried properties
            })
            Mixpanel('people.set_once')({'firstLoginDate': new Date()})
            delete action.payload.agreements
            return produceByAssignment(state, action.payload);
        case 'USER/DETAILS/FAVS/ASSIGN':
            return produce(state, draft => {
                draft.favorites = action.payload
            })
        case 'USER/DETAILS/UNSET':
            Mixpanel('reset')
            return produce(state, draft => {
                return INITIAL_STATE
            })
        default:
            return state;
    }
}

const produceByAssignment = produce((draft, payload) => {
    // basically does same as Object.assign(state, payload)
    Object.entries(payload).forEach(([key, value]) => {
        draft[key] = value;
    })
})