import React, { useReducer, useEffect, useState } from 'react'
import {TgForm} from '../../../lib/components/forms/DefaultTgForm';
import {Typography, Box, TextField, Grid, Button, LinearProgress} from '@material-ui/core';
import { sendEmail } from '../../http/tg/email';
import produce from 'immer';
import {dispatch as dispatchSlice} from 'slice'
import { openModal } from '../../dialogs/standard/state/actions';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import {tracker} from '../../../lib/mixpanel'
import InvitationCodeLink from '../../invitation/components/InvitationCode.Link';
import {COLORS} from '../../theme/colors'

const joinFormReducer = produce((draft ,action) => {
    switch (action.type) {
        case 'send/success':
            draft['status'] = 1;
            draft['message'] = null;
            break;
        case 'send/error':
            draft['status'] = 2;
            draft['message'] = action.payload;
            break;
        case 'send/attempts':
            draft['status'] = 3
            draft['message'] = null;
            break;
        default:
            return draft;
    }
})

const initialJoinFormState = {
    status: 0,
    message: null
}

const SuccessModal = props => (
    <Box mb={8}>
        <Box p={4}>
            <Typography variant="subtitle1"
                color="secondary"
                align="center">
                Thank you!
            </Typography>
        </Box>
        <Typography align="center">
            To tailor the experience of new hotel & business members, we currently have a waitlist. We will review your application and reach out shortly.
        </Typography>
    </Box>
)

const useStyles = tgMakeStyles(theme => ({
    root: {
        borderRadius: '1.75rem',
        border: '0.2rem solid',
        backgroundColor: '#0E0E32',
        '&:hover': {
            backgroundColor: 'white',
            borderColor: '#0E0E32'
        },
        '&:hover span': {
            color: '#0E0E32',
        }
    },
    text: {
        color: 'white',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}))

export function JoinTastegodsForm() {
    const styles = useStyles()
    const [joinForm ,dispatch] = useReducer(joinFormReducer, initialJoinFormState)
    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const payload = {
            memberType: formData.get('membertype'),
            businessName: formData.get('businessname'),
            city: formData.get('city'),
            country: formData.get('country'),
            firstName: formData.get('firstName'),
            lastName: formData.get('lastName'),
            title: formData.get('title'),
            email: formData.get('email'),
        }
        dispatch({type: 'send/attempts'})
        sendEmail({
            subject: `JOIN TASTEGODS : new request`,
            emailBody: [
                'JOIN THE CIRCLE FORM:\n',
                ...Object.entries(payload).map(([key, value]) => `${key} : ${value}`)
            ].join('\n')
        })
        .subscribe({
            error: err => dispatch({
                type: 'send/error',
                payload: 'An error occured while sending your request. Please try again.'
            }),
            complete: () => {
                dispatch({type: 'send/success'})
            }
        })
    }
    useEffect(() => {
        if(joinForm.status === 0){
            return
        } else if (joinForm.status === 1) {
            // send success
            dispatchSlice(openModal({
                title: ' ',
                component: SuccessModal
            }))
            // dispatchSlice(showNotification({
            //     message: 'You successfully requested to join TasteGods! Thank you!'
            // }))
        } else if (joinForm.status === 2) {
            // send error
            return
        } else if (joinForm.status === 3) {
            // send is busy
            return
        }
    }, [joinForm.status])

    const [memberType, setMemberType] = useState('As a Business Member')

    const updateFormTracker = e => {
        tracker('form')('new_membership_form', {[e.target.name]: e.target.value})
    }

    return (
        <TgForm onSubmit={handleSubmit} onChange={updateFormTracker}>
            <Box>
                {/* invitation code */}
                <InvitationCodeLink
                    color="inherit"
                    id="join-tastegods-form-invitation-link"
                    className='clickable'
                    BoxProps={{
                        m: 1,
                        marginBottom: 2,
                        color: COLORS.get('gold').main,
                        align: "center"
                    }} 
                >
                    ALLREADY HAVE AN INVITATION CODE?
                </InvitationCodeLink>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* Member type */}
                    <select 
                        id="membertype"
                        name="membertype"
                        style={{
                            width: '100%',
                            padding: '1rem'
                        }}
                        onChange={e => setMemberType(e.target.value)}
                    >
                        <option>As a Business Member</option>
                        <option>As a Hotel Member</option>
                    </select>
                </Grid>
                <Grid item xs={12}>
                    {/* City */}
                    <TextField id="join-form-business-name"
                        name="businessname"
                        label={memberType === 'As a Business Member' ? 'Business Name' : 'Hotel Name'}
                        type="text"
                        // variant="outlined"
                        required={true}
                        fullWidth={true}
                        color="secondary"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* City */}
                    <TextField id="join-form-city"
                        name="city"
                        label="City"
                        type="text"
                        // variant="outlined"
                        required={true}
                        fullWidth={true}
                        color="secondary"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* City */}
                    <TextField id="join-form-country"
                        name="country"
                        label="Country"
                        type="text"
                        // variant="outlined"
                        required={true}
                        fullWidth={true}
                        color="secondary"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* first name */}
                    <TextField id="join-form-first-name"
                        name="firstName"
                        label="First Name"
                        type="text"
                        // variant="outlined"
                        required={true}
                        fullWidth={true}
                        color="secondary"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* last name */}
                    <TextField id="join-form-last-name"
                        name="lastName"
                        label="Last Name"
                        type="text"
                        // variant="outlined"
                        required={true}
                        fullWidth={true}
                        color="secondary"/>
                </Grid>
                <Grid item xs={12}>
                    {/* title / job position */}
                    <TextField id="join-form-title"
                        name="title"
                        label="Your Job Title"
                        type="text"
                        // variant="outlined"
                        required={true}
                        fullWidth={true}
                        color="secondary"/>
                </Grid>
                <Grid item xs={12}>
                    {/* email */}
                    <TextField id="join-form-email"
                        name="email"
                        label="Your Email Address"
                        type="email"
                        // variant="outlined"
                        required={true}
                        fullWidth={true}
                        color="secondary"/>
                </Grid>
                <Grid item xs={12}>
                    <Box p={4}>
                        <Typography color="error" variant="body2" align="center">
                            {joinForm.message}
                        </Typography>
                        <Button 
                            id="join-form-submit"
                            className="clickable"
                            fullWidth
                            type="submit"
                            classes={{
                                root: styles.root,
                                text: styles.text
                            }}
                        >
                            APPLY
                        </Button>
                        {joinForm.status === 3 && <LinearProgress variant="query" color="secondary"/>}
                    </Box>
                </Grid>
            </Grid>
        </TgForm>
    )
}
