import React from 'react';
import { dispatch } from 'slice';
import ReactGoogleMapLoader from "react-google-maps-loader";
import googleMapStyle from '../../../../assets/json/googleMapStyle.json';
import { GoogleMapUI as GoogleMap } from './GoogleMap';
import { MAPS_LOADER_ERROR } from '../../utils/constants';
import { Box, Typography }  from '@material-ui/core';
import LoopProgress from '../../../../lib/components/progress/LoopProgress.jsx';
import {useTypographyStyles} from '../../../../modules/theme/useTypography'
import { showNotification } from '../../../../modules/notifications/state/actions';

/* 
    This component renders the digital map using the UI as defined in DigitalMapBaseUI
*/
export default function StandAloneGoogleMap({
    center={lat: 52.520008, lng: 13.404954}, //BERLIN, TBD=change to navigator.geolocation.getCurrentPosition
    render=(map)=>{console.log('TG_WARNING:No render function were provided for this Google Map:', map)}
}){
    const typography = useTypographyStyles()
    // hard coded options, should be coming from parent component
    const opts = {
        center: {
            lat: center.lat,
            lng: center.lng
        },
        zoom: 14,
        disableDefaultUI: true,
        styles : googleMapStyle,
        zoomControl: true,
    }
    const gglApiLoadParams = {
        v: 'weekly',
        libraries: 'places,geometry',
        // this should be coming from process.env ?
        key: 'AIzaSyDOfvxUtuaYceLgvuznXrm_XI3CPOUrlQM'
    }
    
    return (
        // ReactGoogleMapLoader is responsible for loading the google.maps library
        <ReactGoogleMapLoader
        className='ReactGoogleMapLoader'
            params={gglApiLoadParams}
            render={(googleMaps, error) => {
                // When it fails to fetch google map library file.
                if (
                    MAPS_LOADER_ERROR.NETWORK_ERROR === error ||  MAPS_LOADER_ERROR.AUTHENTICATION_ERROR === error) {
                        // router.stateService.go('root.hotel-map.error')
                        dispatch(showNotification({message: "Unable to load Google Map. Please try again later"}))
                } 
                
                return (
                    googleMaps && !error
                    ? (
                        // GoogleMapUI is responsible for creating a map instance, and displaying it. nothing more
                        <Box position='relative'
                            height='100%'>
                            <GoogleMap 
                                googleMaps={googleMaps}
                                options={opts}
                                // the render function is able to use window.google.maps and the created map instance
                                render={render}
                            />
                        </Box>
                    )
                    : (
                        <Box width='100%' height='100%' display='flex'>
                            <Box margin='auto' textAlign='center'>
                                <LoopProgress 
                                    color='primary' 
                                    fontSize={'large'}
                                />
                                <Typography
                                    color='primary' 
                                    className={typography.h5}
                                    gutterBottom={true}
                                >
                                    LOADING MAP
                                </Typography>
                            </Box>
                        </Box>
                    )
                )
            }}
        />
        )
}