import { TG_API } from '../';
import { from, Subject, Observable } from 'rxjs';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import pkgJson from '../../../../../package.json'

const BASE_URL = '/user';
const LDM_URL = '/ldm/user';

// GET USER DETAILS
const _getUserDetails = () => from(TG_API({method: 'GET',url: BASE_URL}))
const _$requestUserDetails$ = new Subject().pipe(
    debounceTime(1000)
)
export const getUserDetails$ = new Observable(subscriber => {
    _$requestUserDetails$.pipe(
        take(1),
        switchMap(x => _getUserDetails())
    ).subscribe(subscriber);
    _$requestUserDetails$.next('request user details please!');
})

export function putUserDetails(payload){
    const _KEYS = ["email","name","alias","phone","gender","birthdate","status","workMail","jobTitle"]
    const _payload = Object.entries(payload).filter(([key]) => _KEYS.indexOf(key) > -1)
    const reqConfig = {
        method: 'PUT',
        url: BASE_URL,
        data: _payload.reduce((acc, [key, value])=>{
            acc[key] = value;
            return acc
        }, Object.create(null))
    }
    return from(TG_API(reqConfig))
}

export function putUserPassword({
    currentPassword,
    newPassword,
    verifyNewPassword
}){
    const reqConfig = {
        method: 'PUT',
        url: BASE_URL+'/password',
        data: {
            currentPassword,
            newPassword,
            verifyNewPassword
        }
    }
    return from(TG_API(reqConfig))
}

export function postUserAgreement(documentName, tgVersion){
    const reqConfig = {
        method: 'POST',
        url: LDM_URL+'/agreement',
        data: {
            documentName: documentName,
            tastegodsVersion: tgVersion || pkgJson.version
        }
    }
    return from(TG_API(reqConfig))
}

export function getUserPlaces(){
    const reqConfig = {
        method: "GET",
        url: LDM_URL+`/place`
    }
    return from(TG_API(reqConfig))
}
export function getUserPlaceIDs(){
    const reqConfig = {
        method: "GET",
        url: LDM_URL+`/placeids`
    }
    return from(TG_API(reqConfig))
}
export function postUserPlace(placeId){
    const reqConfig = {
        method: "POST",
        url: LDM_URL+`/place/${placeId}`
    }
    return from(TG_API(reqConfig))
}
export function deleteUserPlace(placeId){
    const reqConfig = {
        method: "DELETE",
        url: LDM_URL+`/place/${placeId}`
    }
    return from(TG_API(reqConfig))
}