import {TG_CONSENT_KEY, TG_GUEST_CONSENT_KEY} from '../reducers'

export const guestConsentAgree = () => ({
    type: 'COOKIES/CONSENT/AGREE',
    payload : {
        cookieType : TG_GUEST_CONSENT_KEY
    }
})

export const guestConsentDisagree = () => ({
    type: 'COOKIES/CONSENT/DISAGREE',
    payload : {
        cookieType : TG_GUEST_CONSENT_KEY
    }
})

export const consentAgree = () => ({
    type: 'COOKIES/CONSENT/AGREE',
    payload : {
        cookieType : TG_CONSENT_KEY
    }
})

export const consentDisagree = () => ({
    type: 'COOKIES/CONSENT/DISAGREE',
    payload : {
        cookieType : TG_CONSENT_KEY
    }
})