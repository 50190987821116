import React from 'react'
import { SvgIcon } from "@material-ui/core";

export function EditSvgIcon(){
    return <SvgIcon overflow="visible"
        preserveAspectRatio="none" viewBox="0 0 24 24" width="28" height="28"
    >
        <g>
            <path 
                id="edit"
                d="M12,15.64h-0.62v-1h-1v-0.58l1.21-1.21l1.58,1.58L12,15.64L12,15.64z M16.57,8.49l-3.64,3.64  c-0.07,0.11-0.22,0.15-0.33,0.08c-0.11-0.07-0.15-0.22-0.08-0.33c0.02-0.03,0.05-0.06,0.08-0.08l3.6-3.64  c0.07-0.11,0.22-0.15,0.33-0.08c0.11,0.07,0.15,0.22,0.08,0.33c-0.02,0.03-0.05,0.06-0.08,0.08H16.57z M17.41,14.33  c0.01-0.14-0.08-0.26-0.21-0.3c-0.12-0.06-0.27-0.03-0.36,0.07l-0.67,0.67c-0.09,0.04-0.15,0.12-0.17,0.21v1.31  c0.01,0.91-0.72,1.67-1.63,1.69H5.71C4.79,18,4.02,17.27,4,16.35c0-0.01,0-0.03,0-0.04V7.65c0-0.92,0.75-1.67,1.67-1.67  c0.01,0,0.03,0,0.04,0h8.66c0.16,0,0.32,0.02,0.47,0.06c0.11,0.05,0.25,0.03,0.34-0.06l0.51-0.51c0.08-0.08,0.11-0.19,0.09-0.3  c-0.02-0.11-0.09-0.2-0.19-0.24c-0.38-0.18-0.8-0.26-1.22-0.26H5.71c-0.8-0.01-1.57,0.31-2.12,0.88C3.02,6.1,2.7,6.87,2.71,7.67  v8.66c-0.01,0.8,0.31,1.57,0.88,2.12c0.55,0.57,1.32,0.89,2.12,0.88h8.66c1.66,0,3-1.34,3-3v-2l0,0L17.41,14.33z M9.41,13.64v3h3  l7-7l-3-3L9.41,13.64z M21.29,7.32c0-0.27-0.1-0.52-0.29-0.71l-1.59-1.63c-0.39-0.39-1.02-0.39-1.41,0l-1,1l3,3l1-1  C21.18,7.8,21.28,7.57,21.29,7.32z"
                vectorEffect="non-scaling-stroke" />
        </g>
    </SvgIcon>
}
export default EditSvgIcon