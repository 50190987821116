import React from 'react'
import { Box, Container, Typography, useMediaQuery } from "@material-ui/core";
import halo from '../../../../assets/png/halo.png'
import ConfirmNoChangeCard from './ConfirmNoChange.card'
import QuickAccessLinks from './BusinessProfileQuickAccessLinks'
import { useStateAdminBusiness } from '../../state/hooks/useStateAdminBusiness';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { useTypographyStyles } from '../../../theme/useTypography';

const useStyles = tgMakeStyles(theme => ({
    section: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    nameSection: {
        '& img': {
            height: '6rem', 
            width: '6rem',
            marginBottom: theme.spacing(4)
        },
    }
}))

export function BusinessDashboard({$stateParams}){
    const classes = useStyles()
    const _business =  useStateAdminBusiness($stateParams.id)
    return _business
        ?(
            <Container maxWidth="xs">
                <BusinessName className={`${classes.section}`}>
                    {_business.place.name}
                </BusinessName>
                <ConfirmNoChangeCard business={_business} className={`${classes.section}`}/>
                <QuickAccessLinks business={_business} className={`${classes.section}`}/>
            </Container>
        )
        : <div>Loading ...</div>
}

function BusinessName(props){
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const classes = useStyles()
    const typo = useTypographyStyles()
    return (
        <Box {...props} display="flex"
            justifyContent="center" 
            flexDirection="column" 
            alignItems="center"
            className={`${props.className} ${classes.nameSection}`}
        >
            <img src={halo}
                className={classes.halo}
            />
            <Box px={'15%'}>
                <Typography 
                    className={`${isDesktop ? typo.subtitle1 : typo.h6}`} 
                    align="center"
                >
                    {props.children}
                </Typography>
            </Box>
        </Box>
    )
}