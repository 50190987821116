import { useRef,useEffect } from "react";

/**
 * Hook to store and retrive previous state value on render phase.
 * @param {any} stateVal 
 */
export function usePrevious(stateVal) {
    const ref = useRef();
    useEffect(() => {
      ref.current = stateVal;
    });
    return ref.current;
}