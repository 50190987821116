import { Box, Card, Container, Grid, Typography } from '@material-ui/core'
import React, { useRef } from 'react'
import { useTypographyStyles } from '../../../../../theme/useTypography'
import halo from '../../../../../../assets/png/halo.png'
import img01 from '../../../../../../assets/png/PP-Concierge_Network.png'
import img02 from '../../../../../../assets/png/PP-Business_profile-Patisserie+Bergamot.png'
import img03 from '../../../../../../assets/png/PP_Pin-Zoom.png'
import img04 from '../../../../../../assets/png/PP-event_card-NewYears.png'
import img05 from '../../../../../../assets/png/PP-Map_Hotel+Pin.png'
import img06 from '../../../../../../assets/png/PP-Hotel-Filters.png'
import img07 from '../../../../../../assets/png/PP-Hotel_TG-minicard.png'
import gif01 from '../../../../../../assets/gif/PP-Basic-final.gif'
import gif02 from '../../../../../../assets/gif/PP-Digital_Map-final.gif'
import svg01 from '../../../../../../assets/svg/icon-add_images.svg'
import svg02 from '../../../../../../assets/svg/icon-link_channels.svg'
import svg03 from '../../../../../../assets/svg/icon-share_story.svg'
import svg04 from '../../../../../../assets/svg/icon-calendar.svg'
import svg05 from '../../../../../../assets/svg/icon-envelope.svg'
import useOnBoardStyles from '../../onboarding.classes'
import useTourStyles from '../tour.classes'
import { COLORS } from '../../../../../theme/colors'
import DesktopCTAFooter from '../CTAContinue.Box'
import { isInViewport } from '../../../../../../lib/utils'

export function MembershipTour(props){
    
    const typo = useTypographyStyles()
    const obClasses = useOnBoardStyles()
    const tourClasses = useTourStyles()

    const stepHeaderRef = useRef()

    return (
        <>
            <Box>
                <Box 
                    component={Container} maxWidth="lg"
                    position="relative"
                    style={{minHeight: 'calc(100vh - 7.75rem)'}}
                    display="flex" alignItems="center"
                >
                    <img src={halo} className={tourClasses.halo1}/>
                    <img src={halo} className={tourClasses.halo2}/>
                    <Box>
                        <Typography
                            className={`
                                ${typo.h7}
                                ${obClasses.mbS}
                                ${tourClasses.welcomeCircle}`}
                            align="center"
                        >
                            WELCOME TO <b>TASTEGODS</b>
                        </Typography>
                        
                        <Typography 
                            className={`
                                ${typo.h2}
                                ${obClasses.mbL}`}
                            align="center"
                        >
                            <i>Home of <b>the Circle</b>: a digital platform connecting hotel concierges with local businesses</i>
                        </Typography>
                        
                        <Grid container>
                            <Grid item md={3}>
                                <Typography 
                                    className={`${typo.BODY1}`}
                                >
                                    Hotel concierges are the <b>ultimate insiders</b>; sharing recommendations and making bookings for <b>guests daily</b>.
                                </Typography>
                            </Grid>
                            <Grid item md={6}>
                                <img src={img01} 
                                    className={`
                                        ${obClasses.mL}
                                        ${tourClasses.networkImg}`}
                                />
                            </Grid>
                            <Grid item md={3} 
                                component={Box} 
                                display="flex" 
                                style={{alignItems: 'flex-end'}}
                            >
                                <Typography 
                                    className={`
                                        ${typo.BODY1}
                                        ${obClasses.mbM}`}
                                    align="right"
                                >
                                    Join <b>the Circle</b> to connect directly with concierge teams in your city: promote your business, share crucial information, and foster a long-term relationship.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                
                <Box 
                    ref={stepHeaderRef}
                    className={`${obClasses.stepHeading}`}
                    style={{marginTop: 0}}
                >
                    <Typography 
                        className={`${typo.BODY1}`}
                        align="center"
                    >
                        <b>STEP 1: TOUR THE TASTEGODS CIRCLE</b>
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        {`>>`}
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        STEP 2: SELECT YOUR MEMBERSHIP
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        {`>>`}
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        STEP 3: COMPLETE YOUR BUSINESS DETAILS
                    </Typography>
                </Box>
                <Box height="10vh"></Box>
                <Box component={Container}
                    maxWidth="lg"
                >
                    <Box className={`${obClasses.mXL}`}>
                        <Typography 
                            className={`
                                ${obClasses.mbS}
                                ${tourClasses.sectionHeading}`}
                            align="center"
                        >
                            YOUR PROFILE IN THE CIRCLE
                        </Typography>
                        <Typography
                            color="primary"
                            className={`
                                ${obClasses.mbL}
                                ${tourClasses.sectionSubtitle}`}
                            align="center"
                        >
                            All of the important details for a concierge, at a glance
                        </Typography>

                        <Box display="flex" justifyContent="center">
                            <Box
                                className={`${tourClasses.grid1}`}
                            >
                                <Box>
                                    <Typography 
                                        className={`
                                            ${tourClasses.title}
                                            ${obClasses.mbS}`}
                                        align="right"
                                    >
                                        Feed in existing social media content
                                    </Typography>
                                    <Typography 
                                        color="inherit"
                                        className={`
                                            ${tourClasses.paragraph}`}
                                        align="right"
                                    >
                                        Bring attention to your current marketing efforts and personality without doubling the work.
                                    </Typography>
                                </Box>
                                <Card elevation={0} 
                                    style={{borderRadius: '2rem', overflow: 'visible'}}
                                >
                                    <img 
                                        className={`${tourClasses.profileGif}`}
                                        src={gif01}
                                    />
                                </Card>
                                <Box>
                                    <Typography 
                                        className={`
                                            ${tourClasses.title}
                                            ${obClasses.mbS}`}
                                    >
                                        Highlight important information
                                    </Typography>
                                    <Typography 
                                        className={`
                                            ${tourClasses.paragraph}`}
                                    >
                                        Announce operational changes with ease; simply update your profile to show how & when your business is ready to welcome guests this week.
                                    </Typography>
                                </Box>
                                
                                <Card
                                    elevation={4}
                                    className={`${tourClasses.infoExample}`}
                                >
                                    <Typography
                                        className={`${obClasses.mbS}`}
                                    >
                                        Special brunch cocktail list designed especially for takeaway, stop by Sat-Sun from 11-18 to order/pickup & enjoy a walk by the Spree with drinks!
                                    </Typography>
                                    <Typography >
                                        <b>Last updated 2 days ago</b>
                                    </Typography>
                                </Card>
                                <Card
                                    elevation={4}
                                    className={`${tourClasses.infoExample}`}
                                >
                                    <Typography
                                        className={`${obClasses.mbS}`}
                                    >
                                        Special brunch cocktail list designed especially for takeaway, stop by Sat-Sun from 11-18 to order/pickup & enjoy a walk by the Spree with drinks!
                                    </Typography>
                                    <Typography >
                                        <b>Last updated 2 days ago</b>
                                    </Typography>
                                </Card>
                            </Box>
                        </Box>
                        <Box height="10vh"></Box>
                    </Box>

                    <Box className={`${obClasses.mXL}`}>

                        <Typography
                            className={`
                                ${obClasses.mbS}
                                ${tourClasses.sectionHeading}`}
                            align="center"
                        >
                            DIGITAL MAPS UPGRADE
                        </Typography>
                        <Typography
                            color="primary"
                            className={`
                                ${obClasses.mbL}
                                ${tourClasses.sectionSubtitle}`}
                            align="center"
                        >
                            Increase your visibility with guests when a concierge recommends you
                        </Typography>

                        <Box display="flex" justifyContent="center">
                            <Grid container spacing={6}
                                component={Container}
                                maxWidth="md"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        align="right"
                                        className={`
                                            ${tourClasses.title}
                                            ${obClasses.mbS}`}
                                    >
                                        Unlike outdated paper maps, digital maps have <span style={{color: COLORS.get('gold')['main']}}>zero</span> advertising clutter
                                    </Typography>
                                    <Typography
                                        align="right"
                                        className={`
                                            ${tourClasses.paragraph}
                                            ${obClasses.mbS}`}
                                    >
                                        Each business on the hotel's map is a genuine endorsement from a concierge.
                                    </Typography>
                                    <Typography
                                        align="right"
                                        className={`
                                            ${tourClasses.paragraph}`}
                                    >
                                        New maps are frequently created & tailored to each guest's request. Shared with guests at check in, the concierge desk or throughout their stay; word of mouth is now in digital form.
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                        <img 
                                            className={`${tourClasses.mmapGif}`}
                                            src={gif02}
                                        />
                                </Grid>

                                <Grid item xs={6}>
                                    <Box 
                                        className={`
                                            ${tourClasses.firstImpressionImgContainer}`}
                                    >
                                        <img src={img03}></img>
                                        <img src={img02} className={`${obClasses.shadow}`}></img>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}
                                    component={Box}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                >
                                    <Box>
                                        <Typography 
                                            className={`
                                                ${tourClasses.title}
                                                ${obClasses.mbS}`}
                                        >
                                            Craft your first impression
                                        </Typography>
                                        <Typography 
                                            className={`
                                                ${tourClasses.paragraph}
                                                ${obClasses.mbS}`}
                                        >
                                            When a concierge has added you to a map, capture the guest's interest with an introduction that sets you apart and showcases your unique specialties.
                                        </Typography>
                                    </Box>
                                    <Box position="relative">
                                        <Box 
                                            className={`
                                                ${tourClasses.firstImpressionIcons}`}
                                        >
                                            <Card component={Box}
                                                elevation={4}
                                            >
                                                <img src={svg02}></img>
                                                <Typography 
                                                    align="center"
                                                    className={`${typo.body3}`}
                                                >
                                                    Add<br/> Images
                                                </Typography>
                                            </Card>
                                            <Card component={Box}
                                                elevation={4}
                                            >
                                                <img src={svg01}></img>
                                                <Typography 
                                                    align="center"
                                                    className={`${typo.body3}`}
                                                >
                                                    Share your story
                                                </Typography>
                                            </Card>
                                            <Card component={Box}
                                                elevation={4}
                                            >
                                                <img style={{stroke: 'black'}} src={svg03}></img>
                                                <Typography 
                                                    align="center"
                                                    className={`${typo.body3}`}
                                                >
                                                    Link to your channels
                                                </Typography>
                                            </Card>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box height="10vh"></Box>
                    </Box>

                    <Box className={`${obClasses.mXL}`}>
                        <Typography
                            className={`
                                ${obClasses.mbS}
                                ${tourClasses.sectionHeading}`}
                            align="center"
                        >
                            <span style={{color: COLORS.get('gold')['main']}}>CIRCLE</span> UPGRADE
                        </Typography>
                        <Typography 
                            color="primary"
                            className={`
                                ${obClasses.mbL}
                                ${tourClasses.sectionSubtitle}`}
                            align="center"
                        >
                            Increase your visibility with concierges
                        </Typography>
                        <Box display="flex" justifyContent="center">
                            <Grid container
                                spacing={4}
                                component={Container}
                                maxWidth="md"
                            >
                                <Grid item md={6}>
                                    <Typography 
                                        className={`
                                            ${tourClasses.title}
                                            ${obClasses.mbS}
                                            blue2`}
                                        align="right"
                                    >
                                        Promote your events & news to concierges
                                    </Typography>
                                    <Typography 
                                        className={`
                                            ${tourClasses.paragraph}`}
                                        align="right"
                                    >
                                        Concierges are always looking for memorable experiences, even with last minute guest requests. Make sure your events are easy to find, share and book from the Circle.
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography
                                        className={`
                                            ${tourClasses.title}
                                            ${obClasses.mbS}`}
                                    >
                                        Build a professional network easily
                                    </Typography>
                                    <Typography
                                        className={`
                                            ${tourClasses.paragraph}`}
                                    >
                                        Connect directly with hotels in the Circle; concierges value first-hand knowledge, experience & relationships as powerful tools to expertly guide guests.
                                    </Typography>
                                </Grid>
                                <Grid item md={6} 
                                    className={`${tourClasses.grid2}`}
                                >
                                    <img src={svg04}></img>
                                    <img src={img04} className={`${obClasses.shadow}`}></img>
                                </Grid>
                                <Grid item md={6}>
                                    <Box 
                                        width="100%"
                                        className={`
                                            ${tourClasses.hotelImgContainer}`}
                                    >
                                        <img src={svg05}></img>
                                        <img src={img05}></img>
                                        <img src={img06} className={`${obClasses.shadow}`}></img>
                                        <img src={img07} className={`${obClasses.shadow}`}></img>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* CTA appears at bottom */}
            <DesktopCTAFooter {...props} 
                isVisible={()=>{
                    return !isInViewport(stepHeaderRef.current)
                }}
            />
        </>
    )
}

export default MembershipTour
