import React from 'react'
import { dispatch } from 'slice'
import { openModal } from '../state/actions'
import { Link, Box } from '@material-ui/core'

export function OpenModalLink(props) {
    // reusable component, accepts BoxProps to allow parent to style
    const {
        BoxProps,
        DialogProps,
        ...forwardedProps } = props

    // isolate custom feature props and define other props to forward to Link component
    const {
        modalTitle, 
        modalComponent, 
        modalComponentProps, 
        modalFullScreen, 
        callbackFn, 
        ...forwardedLinkProps } = forwardedProps
        
    const handleClickLink = e => {
        e.preventDefault()
        let modalConfig = {
            title: modalTitle,
            component: modalComponent,
            props: modalComponentProps,
            fullScreen: modalFullScreen,
            DialogProps: DialogProps
        }
        typeof props.callbackFn === "function" && callbackFn();
        modalConfig && modalConfig.component && dispatch(openModal(modalConfig))
    }
    
    return (
        <Box {...BoxProps}>
            <Link 
                {...forwardedLinkProps} 
                onClick={handleClickLink} 
            />
        </Box>
    )
}

export default OpenModalLink