import { Box, Button, IconButton, Paper, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles'
import { InsideCircleProfile } from '../../../explore/components/place-profile'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { TYPOGRAPHY_STYLES } from '../../../theme/typographies';
import { COLORS } from '../../../theme/colors';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PoiCardForGuestUi from '../../../hotel/guest-console/components/PoiCard';
import GoogleMapWithMarkerBox from '../../../../lib/google-map/components/GoogleMap/GoogleMapWithMarkerBox';

const useStyles = tgMakeStyles(theme => ({
    container: {
        backgroundColor: '#AEAEC2',
        padding: theme.spacing(2),
        minHeight: '100%',
        position: 'relative'
    },
    controls: {
        position: 'relative',
        marginBottom: theme.spacing(2),
        '& .back': {
            margin: 0,
            color: 'white',
            position: 'absolute',
            left: theme.spacing(-1), top: theme.spacing(-1)
        },
        '& .toggles': {
            backgroundColor: COLORS.get('grey')[2],
            borderRadius: '1.03125rem',
            overflow: 'hidden'
        },
        '& .toggle': {
            borderRadius: '1.03125rem',
            margin: 0,
            color: COLORS.get('grey')[3],
            backgroundColor: COLORS.get('grey')[2],
            '&.active': {
                backgroundColor: '#292952',
                color: 'white'
            },
            '& .MuiButton-label': {
                ...TYPOGRAPHY_STYLES.body4
            }
        }
    },
    content: {
        backgroundColor: 'white',
        padding: theme.spacing(2)
    }
}))

export function PreviewBusiness({
    hotelName,
    business,
    businessPruned,
    onClose,
    showBack=true,
    ...props
}){

    const classes = useStyles()
    const [showMap, setShowMap] = useState(false)
    const toggleMap = bool => e => {
        setShowMap(bool)
    }

    return (
        <Box className={`${classes.container}`}>
            {/* insert a google map to load google map library */}
            {/* <Box display="none">    
                <GoogleMapWithMarkerBox lat={0} lng={0}/>
            </Box> */}
            <Box 
                className={`${classes.controls}`}
                display="flex"
                justifyContent="center"
            >
                {showBack && <IconButton 
                    id="business-preview-close"
                    className={`back clickable`}
                    onClick={onClose}
                >
                    <ArrowBackIcon />
                </IconButton>}
                <Box 
                    display="flex"
                    className="toggles"
                >
                    <Button 
                        id="business-preview-show-circle"
                        className={`clickable toggle ${!showMap ? 'active' : ''}`}
                        onClick={toggleMap(false)}
                    >
                        {!showMap ? <VisibilityIcon style={{marginRight: '.5rem'}} /> : null}
                        <b>My Circle Profile</b>
                    </Button>
                    <Button 
                        id="business-preview-show-guests"
                        className={`clickable toggle ${showMap ? 'active' : ''}`}
                        onClick={toggleMap(true)}
                    >
                        {showMap ? <VisibilityIcon style={{marginRight: '.5rem'}} /> : null}
                        <b>What Guests See</b>
                    </Button>
                </Box>
            </Box>
            <Paper 
                elevation={0}
                className={`${classes.content}`}
            >
                {showMap
                    ? (<PreviewGuestCard business={businessPruned} hotelName={hotelName}/>)
                    : (<InsideCircleProfile place={business} showLinks={false}/>)
                }
            </Paper>
        </Box>
    )
}

function PreviewGuestCard({business, hotelName, ...props}){
    const theme = useTheme()
    const _place = business ? business.place : null
    const [feature, setFeature] = useState()
    useEffect(() => {
        if(!business) return ()=>{}

        const _feature = new window.google.maps.Data.Feature({
            properties: {
                id: business.id,
                order: 1,
                name: _place.name,
                address: _place.location,
                tipText: "",
                aboutText: "",
                googlePlaceId: _place.googlePlaceId,
                lat: _place.lat,
                lng: _place.lng,
                custom: true,
                hotelMap: 0,
                place: business
            }
        })
        setFeature(_feature)
        return ()=> {}
    }, [business])

    // Map icons
    const mapIcon = _place &&  _place.attributes && _place.attributes.mapIcon
        ? _place.attributes.mapIcon
        : 'default'

    const _circleIcon = {
        path: 0,
        strokeWeight: 0,
        scale: 18,
        // properties below change when Feature is highlighted and/or hovered
        fillOpacity: 1,
        fillColor: theme.palette.primary.main
    }
    const _pinIcon = {
        path: `M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z`,
        strokeColor: 'white',
        anchor: {x: 24, y: 42},
        // properties below change when Feature is highlighted and/or hovered
        fillOpacity: 1,
        fillColor: theme.palette.primary.main,
        scale: 1 ,
    }

    const ICONS_FONT_MAP = {
        bar: "\ue540",
        dining: "\ue561",
        cafe:  "\ue541",
        store: "\ue8d1",
        landmark: "\ue84f"
    }

    return (
        <Box>
            {_place && <Box>
                {/* Embedded Google map instance showing the place location */}
                <GoogleMapWithMarkerBox
                    BoxProps={{
                        height: '30vh',
                        // negtive margin is used to get the map to fill width beyond container padding
                        m: -2
                    }}
                    lat={_place.lat} lng={_place.lng}
                    markerOptions={{
                        label: mapIcon !== "default" 
                            ? {
                                text: ICONS_FONT_MAP[mapIcon],
                                fontFamily: "Material Icons",
                                color: "#ffffff",
                                fontSize: "18px",
                            }
                            : void 0,
                        icon: _circleIcon
                    }}
                />
            </Box>}
            <Box height="1.5rem"></Box>
            <Box>
                {feature && <PoiCardForGuestUi
                    BoxProps={{
                        borderRadius: '0.5rem',
                        overflow: 'hidden',
                        minHeight: '100%'
                    }}
                    isMobile={false}
                    isCompact={false}
                    isMapDisplay={true}
                    isCustom={true}
                    feature={feature}
                    translation={{}}
                    hotelName={hotelName}
                />}
            </Box>
        </Box>
    )
}