import React from 'react'
import { Typography } from '@material-ui/core'

export default function AboutText(props){

    const {trackingProps, ...forwardProps} = props

    return (
        <Typography 
            {...forwardProps}
            paragraph /* className={classes.aboutText} */
            style={{whiteSpace: 'pre-line'}}
        />
    )
}