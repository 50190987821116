import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useTypographyStyles } from '../../theme/useTypography';

export function PaginationBox({ 
    displayedPageNum, 
    totalPageNum, 
    displayPageLength = 5, 
    getFirstPage, 
    getPreviousPage, 
    getNextPage, 
    getLastPage,
    getPageByNumber,
    ...props
}){
    const typography = useTypographyStyles()

    let [startNum, endNum] = getStartAndEndNum(displayedPageNum, totalPageNum, displayPageLength);
    const pageArray = []
    pageArray.push(startNum)
    while (pageArray[pageArray.length - 1] < endNum) {
        startNum++
        pageArray.push(startNum)
    }

    return (
        <ButtonGroup 
            size='small' 
            variant="text" 
            color="secondary" 
            aria-label="pagination button group" 
            {...props}
        >
            {getFirstPage ? (
                <Button aria-label="firstPage" onClick={getFirstPage} disabled={displayedPageNum === 1}>
                    <FirstPageIcon />
                </Button>
            ) : null}
            {getPreviousPage ? (
                <Button aria-label="BeforePage" onClick={getPreviousPage} disabled={displayedPageNum === 1}>
                    <NavigateBeforeIcon />
                </Button>
            ) : null}
            {pageArray.map((pageNum, idx) => {
                return (
                    <Button key={idx} 
                        color={displayedPageNum === pageNum ? 'primary' : null}
                        onClick={()=>{getPageByNumber(pageNum)}}
                        disabled={displayedPageNum === pageNum}
                        classes={{
                            label: typography.subtitle2
                        }}
                        >{pageNum}</Button>
                    )
            })}
            {getNextPage ? (
                <Button aria-label="NextPage" onClick={getNextPage} disabled={displayedPageNum === endNum}>
                    <NavigateNextIcon />
                </Button>
            ) : null}
            {getLastPage ? (
                <Button aria-label="LastPage" onClick={getLastPage} disabled={displayedPageNum === endNum}>
                    <LastPageIcon />
                </Button>
            ) : null}
        </ButtonGroup>
    )
}

const getStartAndEndNum = (page, pagesLength, displayPageLength) => {
    // calicurate how much diffrences should exist from beginning number to display page and from display page to end number   
    let [diffBackward, diffFoward] = splitNumber(displayPageLength);
    let startNum = page - diffBackward;
    // if startNum is below zero, re-calcurate start number and diffFoward
    if (startNum < 1) {
        while (startNum < 1) {
            startNum++;
            diffFoward++;
        }
    }
    let endNum = page + diffFoward;
    // if endNumber exceeds pagesLength, floor num to pagesLength
    if (endNum > pagesLength) {
        endNum = pagesLength
    }
    // recalculate start number if difference between end to start is below displayPage length
    while (endNum - startNum < displayPageLength - 1 && startNum > 1) {
        startNum--
    }

    return [startNum, endNum]
}

function splitNumber(val) {
    const half = val / 2;
    // if number is odd
    if (half % 1 !== 0) {
        const rtnNum = Math.floor(half);
        return [rtnNum, rtnNum]
    } else {
        return [half - 1, half]
    }
}