import React, { useState, useEffect } from 'react'
// import Mixpanel from '../../../../../lib/mixpanel';
import { useStateGeoJsonOverlay } from '../../hooks';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { Drawer, Box } from '@material-ui/core';

export default function DigitalMapDrawer(props) {
    const {hotel, map, customMapId, ContentComponent} = props
    const {params} = useCurrentStateAndParams()
    const [features, setFeatures] = useState([])
    const files = useStateGeoJsonOverlay()
    
    useEffect(() => {
        if(files[0]){
            let _features = []
            files[0].gmData.forEach(feature => {
                _features.push(feature)
            })
            setFeatures(_features)
        }
    }, [files])
    
    const router = useRouter()

    const handleDrawerToggle = open => e => {
        if(typeof open === 'undefined'){
            router.stateService.go('.', {list: !params.list})
        } else {
            router.stateService.go('.', {list: open})
        }
    }

    return (
        <Drawer
            variant="temporary"
            anchor={'left'}
            open={params.list}
            onClose={handleDrawerToggle(false)}
            // props below mainly ensure the drawer is not full screen and does not cover the toolbar and tabbar
            ModalProps={{
                container: window.document.getElementById('content-container'), // attach drawer to content div (see AppLayout component)
                style: {position: 'absolute'}, // by default position = fixed
                BackdropProps: {position: 'absolute'}, // by default position = fixed
                hideBackdrop: true, 
                keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{ 
                style: { 
                    position: 'absolute',
                    width:"100%" 
                } 
            }} // by default position = fixed
            
        >
            <Box mb={6}
                paddingTop={4}
            >
                { ContentComponent && params.list
                    && <ContentComponent {...{
                            map,
                            hotel,
                            customMapId,
                            features
                        }}/>
                }   
            </Box>
        </Drawer>
    )
}
