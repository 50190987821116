import { Fab, Box } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import React from 'react';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import Mixpanel from '../../../../lib/mixpanel';

const useStyles = tgMakeStyles((theme) => ({
    FabRoot: {
        // margin: 0
    },
    iconMargin: {
        marginRight: theme.spacing(1)
    }
}));

export default function GoogleMapDirectionsFab({ lat, lng, googlePlaceId, name, BoxProps, ...forwardedProps }){

    const classes = useStyles();
    const openInGoogleMap = e => {  
        window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}&query_place_id=${googlePlaceId}`, "_blank")
        e.preventDefault();
        Mixpanel('track')('click_get_directions',{
            GooglePlaceID: googlePlaceId,
            poiName : name
        })
    }

    return (
        <Box p={0} m={0}
            {...BoxProps}
        >
            <Fab 
                variant='extended'
                onClick={openInGoogleMap}
                color='primary'
                className={classes.FabRoot}
                {...forwardedProps}
            >
                <DirectionsIcon className={classes.iconMargin}/>
                Get Directions
            </Fab>
        </Box>
    );
}