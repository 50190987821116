import { Link } from '@material-ui/core';
import React from 'react';
import Mixpanel from '../../../../lib/mixpanel';

export default function GoogleMapDirectionsLink({ 
    lat, 
    lng, 
    googlePlaceId, 
    name, 
    BoxProps, 
    ...forwardedProps 
}){
    const openInGoogleMap = e => {  
        window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}&query_place_id=${googlePlaceId}`, "_blank")
        e.preventDefault();
        Mixpanel('track')('click_get_directions',{
            GooglePlaceID: googlePlaceId,
            poiName : name
        })
    }

    return (
        <Link onClick={openInGoogleMap} {...forwardedProps}>
        </Link>
    );
}