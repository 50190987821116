import React, { useState } from 'react';
import { ClickAwayListener, Collapse, IconButton, TextField } from '@material-ui/core';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { COLORS } from '../../theme/colors';

const useStyles = tgMakeStyles(theme => ({
    sectionMargins: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    listItemSvgIcon: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    inputRoot: {
        paddingRight: 0
    },
    inputElem: {
        // these dimensions are hardcoded to match the height specs as well as ensuring the popover sticks to the bottom of the input element
        // using rem here. GOAL is >> input content height = search button height
        height: '1.1875rem', // '19px' = 1.1875rem
        paddingTop: '0.90625rem', // '14.5px' = 0.90625rem
        paddingBottom: '0.90625rem'
    },
    searchButton: {
        margin: 0,
        backgroundColor: COLORS.get('gold').main,
        borderRadius: 0,
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        color: 'white',
        '&:hover': {
            backgroundColor: COLORS.get('gold').dark
        }
    }
}))

export function WithCollapsableSearch({
    value, 
    TextFieldProps,
    onChange, 
    onReset,
    onSearch,
    ...props
}){

    const classes = useStyles()
    const [searchOpen, setSearchOpen] = useState(false)

    return (
        <Box display="flex" alignItems="center" flexWrap="wrap">
            {props.children}
            <Box flexGrow="1"></Box>
                
            <Box className="search-btn">
                <IconButton
                    onClick={e => {
                        e.stopPropagation()
                        setSearchOpen(true)
                    }}
                    style={{margin: 0}}
                >
                    <SearchIcon />
                </IconButton>
            </Box>
            <Box flexBasis="100%" flexShrink="0" flexGrow="1" 
                mt={searchOpen ? 1 : 0} style={{transition: 'all.3s'}} // fine tuning of open close animations
            >
                <Collapse
                    in={searchOpen} 
                    timeout={{ enter: 200, exit: 200 }}
                >
                    <ClickAwayListener
                        mouseEvent="onMouseDown"
                        touchEvent="onTouchStart"
                        onClickAway={e => {
                            e.stopPropagation()
                            setSearchOpen(false)
                        }}
                    >
                        <Box>
                            <TextField
                                type="text"
                                value={value}
                                variant="outlined"
                                placeholder={`search within list`}
                                fullWidth
                                InputProps={{
                                    classes: {
                                        adornedEnd: classes.inputRoot,
                                        input: classes.inputElem
                                    },
                                    endAdornment: (
                                        <Box display="flex">
                                            {value && (
                                                <IconButton style={{margin: 0}} onClick={onReset}>
                                                    <CloseIcon />
                                                </IconButton>
                                            )}
                                            <IconButton onClick={ onSearch || function(){} }
                                                classes={{
                                                    root: classes.searchButton
                                                }}
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </Box>
                                    )
                                }}
                                onChange={onChange}
                                {...TextFieldProps}
                            />
                        </Box>
                    </ClickAwayListener>
                </Collapse>
            </Box>
        </Box>
    )
}

export default WithCollapsableSearch