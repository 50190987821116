import { Box, Button, Card, Divider, Icon, IconButton, Typography } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import ClearIcon from '@material-ui/icons/Clear'
import { useRouter, useSref } from '@uirouter/react';
import useStateBusinessMembershipForm from '../../../state/hooks/useStateBusinessMembershipForm'
import { dispatch } from 'slice';
import useOBStyles from '../onboarding.classes'
import usePlansStyles from './plans.classes'
import usePlansTStyles from '../tablet/plans.classes'
import { COLORS } from '../../../../theme/colors';
import { PLANS, PLANS_PLAIN_TEXT } from '../../../utils';
import DesktopCTASelectPlan from './CTASelectPlan.Box'
import { isInViewport } from '../../../../../lib/utils'
import { useMediaQuery } from '@material-ui/core'
import PlanBer282 from './variants/MembershipPlans.Box'

export function MembershipPlans(props){
    // console.log('MembershipPlans', props)
    
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'))
    const router = useRouter()
    const previousSref = useSref('^')
    
    const form = useStateBusinessMembershipForm()
    const {
        selected_plan
    } = form
    
    const typo = useTypographyStyles()
    const obClasses = useOBStyles()
    const plansClasses = usePlansStyles()
    const plansClassesT = usePlansTStyles()

    const stepHeaderRef = useRef()

    const handleClickContinue = e => {
        router.stateService.go('root.welcome.invited.tour.plans.business-details', {selected_plan: selected_plan.value})
    }

    return String(props.$stateParams.code).toUpperCase() === 'BER282'
        ? (
            <PlanBer282 {...props} />
        )
        : (
        <>
            <Box>
                <Box 
                    ref={stepHeaderRef}
                    className={`${obClasses.stepHeading}`}
                >
                    <Box>
                        <IconButton 
                            id="invite-back-to-membership-tour"
                            {...previousSref}
                            className="clickable">
                            <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
                        </IconButton>
                    </Box>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        STEP 1: TOUR THE TASTEGODS CIRCLE
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        {`>>`}
                    </Typography>
                    <Typography 
                        className={`${typo.BODY1}`}
                        align="center"
                    >
                        <b>STEP 2: SELECT YOUR MEMBERSHIP</b>
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        {`>>`}
                    </Typography>
                    <Typography 
                        className={`${typo.body1}`}
                        style={{color: COLORS.get('grey')[3]}}
                        align="center"
                    >
                        STEP 3: COMPLETE YOUR BUSINESS DETAILS
                    </Typography>
                    <Button 
                        id="invite-header-confirm-plan-selection"
                        variant="contained"
                        color="primary"
                        className={`${obClasses.btn} clickable`}
                        onClick={handleClickContinue}
                    >
                        CONTINUE
                    </Button>
                </Box>
                <Box className={`${obClasses.container}`}>
                    <Card
                        component={Box}
                        elevation={4}
                        px={2}
                        py={4}
                        mt={2}
                    >
                        {/* <Typography
                            color="primary"
                            className={`
                                ${typo.subtitle6}
                                ${obClasses.mbL}`}
                            align="left"
                        >
                            START CONNECTING TODAY
                        </Typography> */}
                        {isLarge
                            ? (
                                <Box className={plansClasses.plansgrid}>
                                    <Card component={Box}
                                        elevation={4}
                                        className={`${plansClasses.planBox} free selected`}
                                    >
                                        <Typography
                                            className={`
                                                ${plansClasses.planName}`}
                                            align="center"
                                        >
                                            <b>BASIC MEMBERSHIP</b>
                                        </Typography>
                                        <Typography
                                            className={`
                                                ${plansClasses.planSubtext}
                                                ${typo.subtitle1}`}
                                            align="center"
                                        >
                                            <i>Important info, at a glance</i>
                                        </Typography>
                                    
                                        
                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Own & manage your profile in the Circle
                                            </Typography>
                                        </Box>

                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Your profile will show up when concierges search for your business
                                            </Typography>
                                        </Box>

                                        <Box 
                                            className={`${plansClasses.pitchTxt}`}
                                        >
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Integrate your feed from a social media channel
                                            </Typography>
                                        </Box>

                                        <Box className="spacer"></Box>

                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography
                                                className={`
                                                    ${typo.h1}`}
                                                style={{paddingRight: '.5rem'}}
                                            >
                                                10€
                                            </Typography>
                                            <Typography
                                                className={`${typo.body1}`}
                                            >
                                                / month<br/> + Setup Fee
                                            </Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Button variant="contained"
                                                className={`select_plan`}
                                            >
                                                <CheckRoundedIcon></CheckRoundedIcon>
                                                <Typography 
                                                    className={`${typo.h4}`}
                                                >
                                                    SELECTED
                                                </Typography>
                                            </Button>
                                        </Box>

                                    </Card>

                                    <SelectableMembershipPlan plan={1} selected={selected_plan.value === 1}>
                                        <Typography
                                            className={`
                                                ${plansClasses.planName}`}
                                            align="center"
                                        >
                                            <b>DIGITAL MAPS<br/>UPGRADE</b>
                                        </Typography>
                                        <Typography
                                            className={`
                                                ${plansClasses.planSubtext}
                                                ${typo.subtitle1}`}
                                            align="center"
                                        >
                                            <i>Optimise what guests see when concierges recommend you</i>
                                        </Typography>
                                        

                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Includes features from the Basic Membership
                                            </Typography>
                                        </Box>

                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Draw attention to your business on maps
                                            </Typography>
                                        </Box>

                                        <Box 
                                            className={`${plansClasses.pitchTxt}`}
                                        >
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Craft your first impression with more details about you
                                            </Typography>
                                        </Box>
                                        
                                        <Box className="spacer"></Box>

                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography
                                                className={`
                                                    ${typo.h1}`}
                                                style={{paddingRight: '.5rem'}}
                                            >
                                                35€
                                            </Typography>
                                            <Typography
                                                className={`${typo.body1}`}
                                            >
                                                / month<br/> + Setup Fee
                                            </Typography>
                                        </Box>
                                    </SelectableMembershipPlan>
                                    
                                    <SelectableMembershipPlan plan={2} selected={selected_plan.value === 2}>
                                        <Typography
                                            className={`
                                                ${plansClasses.planName}
                                                blue`}
                                            align="center"
                                        >
                                            <b>DIGITAL MAPS +<br/><span className="circleTxt">CIRCLE</span> UPGRADE</b>
                                        </Typography>
                                        <Typography
                                            className={`
                                                ${plansClasses.planSubtext}
                                                ${typo.subtitle1}`}
                                            align="center"
                                        >
                                            <i>Optimise how Circle concierges engage with your business</i>
                                        </Typography>
                                        

                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Includes features from the Basic Membership and Digital Maps Upgrade
                                            </Typography>
                                        </Box>

                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Promote news & events to concierges in the Circle
                                            </Typography>
                                        </Box>

                                        <Box 
                                            className={`${plansClasses.pitchTxt}`}
                                        >
                                            <CheckRoundedIcon />
                                            <Typography className={typo.body1}>
                                                Proactively reach out to hotel teams & concierges
                                            </Typography>
                                        </Box>
                                        
                                        <Box className="spacer"></Box>

                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography
                                                className={`
                                                    ${typo.h1}`}
                                                style={{paddingRight: '.5rem'}}
                                            >
                                                65€
                                            </Typography>
                                            <Typography
                                                className={`${typo.body1}`}
                                            >
                                                / month<br/> + Setup Fee
                                            </Typography>
                                        </Box>
                                    </SelectableMembershipPlan>

                                    <Card component={Box}
                                        elevation={4}
                                        className={`${plansClasses.planBox} disabled`}
                                    >
                                        <Typography
                                            className={`
                                                ${plansClasses.planName}`}
                                            align="center"
                                        >
                                            <b>INDUSTRY ICON UPGRADE</b>
                                        </Typography>
                                        <Typography
                                            className={`
                                                ${plansClasses.planSubtext}
                                                ${typo.subtitle1}`}
                                            align="center"
                                        >
                                            <i>The ultimate tool kit to establish & maintain your reputation in the Circle</i>
                                        </Typography>

                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Box>
                                                <Typography className={typo.body2}>
                                                    Includes features from the Basic Membership and Digital Maps + Circle Upgrade
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box className={`${plansClasses.pitchTxt}`}>
                                            <CheckRoundedIcon />
                                            <Box>
                                                <Typography className={typo.body1}><b>Custom Features</b></Typography>
                                                <Typography className={typo.body2}>
                                                    Industry-specific designs & tools to optimise your exposure & fully display your services
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box 
                                            className={`${plansClasses.pitchTxt}`}
                                        >
                                            <CheckRoundedIcon />
                                            <Box>
                                                <Typography className={typo.body1}><b>Strategic Growth</b></Typography>
                                                <Typography className={typo.body2}>
                                                    Expand your network in the Circle; establish your reputation as a "go to" for certain occasions & noteworthy experiences
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box className="spacer"></Box>
                                        <Typography align="center"
                                            className={`
                                                ${typo.h4}`}
                                            style={{margin: '.5rem'}}
                                        >
                                            SPECIAL INVITE ONLY
                                        </Typography>
                                    </Card>

                                    <Box
                                        className={`${plansClasses.summaryBox}`}
                                    >
                                        <Typography
                                            className={`header`}
                                            align="center"
                                        >
                                            <b>SUMMARY OF YOUR SELECTION</b>
                                        </Typography>
                                        {/* Show current selection */}
                                        <Box p={2} py={4}
                                            display="flex" justifyContent="space-between"
                                        >
                                            <Typography
                                                className={typo.subtitle4}
                                            >
                                                Basic Member<br/>
                                                <span className={`${typo.caption1}`}>1 year free membership</span>
                                            </Typography>
                                            <Typography
                                                className={typo.subtitle4}
                                            >
                                                0€
                                            </Typography>
                                        </Box>
                                        {selected_plan.value > 0 && (
                                            <Box p={2}
                                                display="flex" justifyContent="space-between"
                                            >
                                                <Typography style={{paddingRight: '2rem'}}
                                                    className={typo.subtitle4}
                                                >
                                                    {PLANS[selected_plan.value].name}
                                                </Typography>
                                                <Typography
                                                    className={typo.subtitle4}
                                                >
                                                    {`${PLANS[selected_plan.value].price}/month`}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box p={2}
                                            display="flex" justifyContent="space-between"
                                        >
                                            <Typography
                                                className={typo.subtitle4}
                                            >
                                                Setup Fee<br/>
                                                <span className={`${typo.caption1}`}>100€ fee waived</span>
                                            </Typography>
                                            <Typography
                                                className={typo.subtitle4}
                                            >
                                                0€
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                            : (
                                <Box className={plansClassesT.plansgrid}>
                                    <Card component={Box}
                                        elevation={4}
                                        className={`${plansClassesT.planBox} free selected`}
                                    >
                                        <Typography
                                            className={`
                                                ${typo.h5}
                                                ${obClasses.mS}`}
                                            align="center"
                                        >
                                            <b>BASIC<br/>MEMBERSHIP</b>
                                        </Typography>
                                        <Typography
                                            className={`${typo.body2}`}
                                            align="center"
                                        >
                                            <i>Important info, at a glance</i>
                                        </Typography>
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography
                                                className={`
                                                    ${typo.subtitle3}
                                                    ${obClasses.mM}`}
                                                style={{paddingRight: '.5rem'}}
                                            >
                                                10€
                                            </Typography>
                                            <Typography
                                                className={`${typo.body2}`}
                                            >
                                                / month + Setup Fee
                                            </Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Button variant="contained"
                                                className={`select_plan`}
                                            >
                                                <CheckRoundedIcon></CheckRoundedIcon>
                                                <Typography 
                                                    className={`${typo.h4}`}
                                                >
                                                    SELECTED
                                                </Typography>
                                            </Button>
                                        </Box>

                                    </Card>

                                    <SelectableMembershipPlan plan={1} selected={selected_plan.value === 1}>
                                        <Typography
                                            className={`
                                                ${typo.h5}
                                                ${obClasses.mS} blue`}
                                            align="center"
                                        >
                                            <b>DIGITAL MAPS<br/>UPGRADE</b>
                                        </Typography>
                                        <Typography
                                            className={`${typo.body2}`}
                                            align="center"
                                        >
                                            <i>Optimise what guests see when concierges recommend you</i>
                                        </Typography>
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography
                                                className={`
                                                    ${typo.subtitle3}
                                                    ${obClasses.mM}`}
                                                style={{paddingRight: '.5rem'}}
                                            >
                                                35€
                                            </Typography>
                                            <Typography
                                                className={`${typo.body2}`}
                                            >
                                                / month + Setup Fee
                                            </Typography>
                                        </Box>
                                    </SelectableMembershipPlan>
                                    
                                    <SelectableMembershipPlan plan={2} selected={selected_plan.value === 2}>
                                        <Typography
                                            className={`
                                                ${typo.h5}
                                                ${obClasses.mS} blue`}
                                            align="center"
                                        >
                                            <b><span className="circleTxt">CIRCLE</span> UPGRADE</b>
                                        </Typography>
                                        <Typography
                                            className={`${typo.body2}`}
                                            align="center"
                                        >
                                            <i>Optimise how Circle concierges<br/>engage with your business</i>
                                        </Typography>
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography
                                                className={`
                                                    ${typo.subtitle3}
                                                    ${obClasses.mM}`}
                                                style={{paddingRight: '.5rem'}}
                                            >
                                                65€
                                            </Typography>
                                            <Typography
                                                className={`${typo.body2}`}
                                            >
                                                / month + Setup Fee
                                            </Typography>
                                        </Box>
                                    </SelectableMembershipPlan>

                                    <Card component={Box}
                                        elevation={4}
                                        className={`${plansClassesT.planBox} disabled`}
                                    >
                                        <Typography
                                            className={`
                                                ${typo.h5}
                                                ${obClasses.mS}`}
                                            align="center"
                                        >
                                            <b>INDUSTRY ICON UPGRADE</b>
                                        </Typography>
                                        <Typography
                                            className={`${typo.body2}`}
                                            align="center"
                                        >
                                            <i>The ultimate tool kit to establish<br/> & maintain your reputation in<br/> the Circle</i>
                                        </Typography>
                                        <Typography
                                            className={`${typo.body2}`}
                                            align="center"
                                        >
                                            
                                        </Typography>
                                        <Typography align="center"
                                            className={`
                                                ${typo.h6}
                                                ${obClasses.mM}`}
                                        >
                                            SPECIAL INVITE ONLY
                                        </Typography>
                                    </Card>

                                    <Card component={Box}
                                        className={`${plansClassesT.summaryBox}`}
                                    >
                                        <Typography
                                            className={`${typo.h4} ${obClasses.mM}`}
                                            align="center"
                                        >
                                            SUMMARY OF YOUR SELECTION
                                        </Typography>
                                        {/* Show current selection */}
                                        <table className={`${plansClassesT.summaryTable}`}>
                                            <tbody>
                                                <tr className="header">
                                                    <td>
                                                        <Typography
                                                            className={typo.subtitle6}
                                                        >
                                                            Basic Member<br/>
                                                            <span className={`${typo.caption1}`}>1 year free membership</span>
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography
                                                            className={typo.subtitle6}
                                                        >
                                                            0€
                                                        </Typography>
                                                    </td>
                                                </tr>
                                                {selected_plan.value > 0 && (
                                                    <tr>
                                                        <td>
                                                            <Typography
                                                                className={typo.subtitle6}
                                                            >
                                                                {PLANS[selected_plan.value].name}
                                                            </Typography>
                                                        </td>
                                                        <td>
                                                            <Typography
                                                                className={typo.subtitle6}
                                                            >
                                                                {`${PLANS[selected_plan.value].price}/month`}
                                                            </Typography>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td>
                                                        <Typography
                                                            className={typo.subtitle6}
                                                        >
                                                            Setup Fee<br/>
                                                            <span className={`${typo.caption1}`}>100€ fee waived</span>
                                                        </Typography>
                                                    </td>
                                                    <td>
                                                        <Typography
                                                            className={typo.subtitle6}
                                                        >
                                                            0€
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Card>
                                </Box>
                            )
                        }
                    </Card>
                </Box>
            </Box>
            {/* CTA appears at bottom */}
            <DesktopCTASelectPlan {...props} 
                isVisible={()=>{
                    return !isInViewport(stepHeaderRef.current)
                }}
            />
        </>
    )
}

function SelectableMembershipPlan({children, plan, selected, ...props}){

    const plansClasses = usePlansStyles()
    const typo = useTypographyStyles()

    const handleTogglePlan = plan => e => {
        // console.log(plan)
        dispatch({
            type: 'BUSINESS/INVITE/FORM/SET',
            payload: {
                key: 'selected_plan',
                value: selected ? 0 : plan
            }
        })
    }

    const [hover, setHover] = useState(false)
    return (
        <Card 
            component={Box}
            elevation={4}
            className={`${plansClasses.planBox} ${selected ? 'selected' : ''}`}
            {...props}
        >
            {children}
            <Box display="flex" justifyContent="center">
                <Button
                    id={`invite-select-plan-${PLANS_PLAIN_TEXT[plan]}`}
                    onClick={handleTogglePlan(plan)} 
                    variant="contained"
                    className={`select_plan clickable`}
                    onMouseEnter={e => {setHover(true)}}
                    onMouseLeave={e => {setHover(false)}}
                >
                    {selected && hover 
                        ? <ClearIcon /> 
                        : selected 
                            ? <CheckRoundedIcon></CheckRoundedIcon> 
                            : null}
                    <Typography 
                        className={`${typo.h4}`}
                    >
                        {/* {selected ? 'SELECTED' : 'ADD'} */}
                        {selected ? hover ? 'REMOVE' : 'SELECTED' : 'ADD'}
                    </Typography>
                </Button>
            </Box>
        </Card>
    )
}

export default MembershipPlans