import produce from 'immer';
import { STATUSES } from '../meta';

export default authStatusReducer;
const INITIAL_STATE = {
    isAuthenticated: false,
    code: STATUSES.unauthenticated,
    message: 'unauthenticated',
    error: null,
    warning: null,
    recover: {
        password: {
            code: STATUSES.recover.password.idle,
            message: 'idle',
            error: null,
            warning: null,
        }
    },
    reset: {
        password: {
            code: STATUSES.reset.password.idle,
            message: 'idle',
            error: null,
            warning: null,
        }
    }
}

function authStatusReducer(state, action){
    // rootReducer initialise status prop as null.
    // this reducer is responsible for setting up the initial state on first action
    // this makes the state more predictable and should allow us to dodge a few bugs.
    state = state || INITIAL_STATE;
    switch (action.type) {
        case 'AUTH/REFRESH/STATUS':
            return action.payload.isAuthenticated
                ? produceStatus('authenticated')(state)
                : produceStatus('unauthenticated')(state)
        // LOGIN
        case 'AUTH/ATTEMPTS/LOGIN':
            return produceStatus('authenticating')(state)
        case 'AUTH/SUCCESS/LOGIN':
            return produceStatus('authenticated')(state)
        case 'AUTH/FAILED/LOGIN':
            return produceStatus('unauthenticated', 'login failed')(state)
        // LOGOUT
        case 'AUTH/ATTEMPTS/LOGOUT':
            return produceStatus('unauthenticating')(state)
        case 'AUTH/SUCCESS/LOGOUT':
            return produceStatus('unauthenticated')(state)
        case 'AUTH/FAILED/LOGOUT':
            return produceStatus('unauthenticated', null, 'an error occured during logout')(state)
        // RECOVER PASSWORD
        case 'AUTH/ATTEMPTS/RECOVER/PASSWORD':
            return produceRecoverPasswordStatus(state, 'recovering')
        case 'AUTH/FAILED/RECOVER/PASSWORD':
            return produceRecoverPasswordStatus(state, 'failed', action.payload.message)
        case 'AUTH/SUCCESS/RECOVER/PASSWORD':
            return produceRecoverPasswordStatus(state, 'recovered')
        case 'AUTH/RESET/RECOVER/PASSWORD':
            return produceRecoverPasswordStatus(state, 'idle')
        // RESET PASSWORD
        case 'AUTH/ATTEMPTS/RESET/PASSWORD':
            return produceResetPasswordStatus(state, 'resetting')
        case 'AUTH/FAILED/RESET/PASSWORD':
            return produceResetPasswordStatus(state, 'failed', action.payload.message)
        case 'AUTH/SUCCESS/RESET/PASSWORD':
            return produceResetPasswordStatus(state, 'reset')
        case 'AUTH/RESET/RESET/PASSWORD':
            return produceResetPasswordStatus(state, 'idle')
        default:
            return state;
    }
}

// returns a producer function that takes in the old state and spits out the new state
// ie: produceStatus('unauthenticated')(state)
function produceStatus(status, error, warning){
    const producerFn = produce(draft => {
        draft.code = STATUSES[status];
        draft.message = status;
        draft.isAuthenticated = STATUSES[status] === STATUSES.authenticated
            ? true
            : false
        draft.error = error
            ? error
            : null
        draft.warning = warning
            ? warning
            : null
    })
    return producerFn
}

const produceRecoverPasswordStatus = produce((draft, status, error, warning)=> {
    draft.recover.password.code = STATUSES.recover.password[status];
        draft.recover.password.message = status;
        draft.recover.password.error = error
            ? error
            : null
        draft.recover.password.warning = warning
            ? warning
            : null
})

const produceResetPasswordStatus = produce((draft, status, error, warning)=> {
    draft.reset.password.code = STATUSES.reset.password[status];
        draft.reset.password.message = status;
        draft.reset.password.error = error
            ? error
            : null
        draft.reset.password.warning = warning
            ? warning
            : null
})