import produce from "immer";
import { useEffect, useState } from "react";
import { $lice } from "slice";

export function useStateAdminBusiness(id){
    const [biz, setBiz] = useState()
    useEffect(() => {
        const [biz$] = $lice('tgadminbusiness')
        let sub = biz$.path(id).subscribe({
            next: biz => {
                if(biz && biz.visibility){
                    let bizWithPrunedData = produce(biz, draft => {
                        draft.pruned = {...biz}
                        let pruned = draft.pruned
                        pruned.place = {...biz.place}
                        delete pruned.pruned
                        delete pruned.visibility
                        if(biz.visibility){
                            !biz.visibility.website && (pruned.place.website = "")
                            !biz.visibility.social && (pruned.feed = null)
                            !biz.visibility.hours && (pruned.place.openingHours = null)
                            !biz.visibility.about && (pruned.place.about = "")
                        }
                    })
                    setBiz(bizWithPrunedData)
                }
            },
            error: console.log.bind(0, 'biz in hook error'),
            complete: console.log.bind(0, 'biz in hook complete'),
        }) 

        return () => {sub.unsubscribe()}
    } , [id])

    return biz
}