import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useTypographyStyles } from '../../theme/useTypography'

export default function TasteGodsBrand(props) {
    const typography = useTypographyStyles()
    const {BoxProps, classes, ...forwardedProps} = props
    return (
        // reusable component exposing BoxProps for parent to style spacing and classes to style Typography
        // classes should superseed className so component defaults can be overriden from outside
        <Box {...BoxProps}>
            <Typography
                classes={classes}
                variant='inherit'
                {...forwardedProps}
                className={`${forwardedProps.className || ''} ${typography.h3}`}
            >
                TASTEGODS
            </Typography>
        </Box>
    )
}