import { useEffect } from 'react';
import { dispatch } from 'slice';
import { refreshAuthStatus } from '../actions';

export function useEffectRefreshAuthStatus(dependency){
    useEffect(() => {
        // console.log('useEffectRefreshAuthStatus')
        // dispatch action to check current auth status
        dispatch(refreshAuthStatus())
    }, [dependency])
}