import { Box, Divider, List, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import React from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import SearchResultListItem from './SearchResult.ListItem'

const isClaimedBusiness = (bool) => (poi) => {
    return Boolean(poi.plan) === bool
}

export default function SearchResultsList({
    searchResult,
    onSelect
}){
    const typography = useTypographyStyles()
    const theme = useTheme()
    return (
        <Box>
            <Box display="flex" px={2}>
                <Box flexGrow={1}>
                    <Typography variant="inherit" component='div' className={typography.subtitle1}>Search Results</Typography>
                    <Typography variant="inherit" className={typography.body2}>
                        Showing {
                                searchResult.metaData.page === searchResult.metaData.pages ? 
                                searchResult.metaData.total : 
                                searchResult.metaData.limit * searchResult.metaData.page
                            } of {searchResult.metaData.total} businesses
                    </Typography>
                </Box>
            </Box>
            <List 
                component="nav" 
                style={{padding: theme.spacing(2)}}

            >
                <Box mb={3}>
                    {searchResult.pageResult.filter(isClaimedBusiness(true)).map((v, idx) => (
                        <SearchResultListItem
                            key={idx}
                            poi={v}
                            onSelect={onSelect}
                        />
                    ))}
                </Box>
                <Box>
                    <Typography className={typography.body1}>Outside of the Circle</Typography>
                    <Divider style={{margin: '1rem 0 1rem 0'}}/>
                    {searchResult.pageResult.filter(isClaimedBusiness(false)).map((v, idx) => (
                        <SearchResultListItem
                            key={idx}
                            poi={v}
                            onSelect={onSelect}
                        />
                    ))}
                </Box>
            </List>
        </Box>
    )
}