import React from 'react'
import { Box, Card, ListItem, Typography } from "@material-ui/core"
import { useTypographyStyles } from "../../../theme/useTypography"
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CircleMemberLabel } from '../../../hotel/hotel-console/components/EditHotelMapPlaces';

const CLOSED_STATUSES = ['CLOSED_TEMPORARILY', 'CLOSED_PERMANENTLY']

const noMarginCss = {
    margin: 0,
    border: 'none',
    borderRadius: '0.5rem'
}

const useStyles = tgMakeStyles(theme => ({
    liButton: {
        ...noMarginCss,
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    }
}))

export default function SearchResultListItem({ 
    poi, 
    onSelect 
}){
    const _place = poi ? poi.place : null
    const classes = useStyles()
    const typography = useTypographyStyles()
    const address = _place.location
    
    return (
        <ListItem
            component={poi.plan ? Card : 'div'}
            elevation={4}
            onClick={() => { onSelect(poi) }}
            button
            disableGutters={true}
            divider={true}
            classes={{
                button: classes.liButton
            }}
        >
            <Box width="100%" pl={2}>
                <CircleMemberLabel place={poi} />
                <Box display={'flex'} width="100%" alignItems="center">
                    <Typography
                        variant="inherit"
                        component='div'
                        className={poi.plan ? typography.subtitle6 : typography.subtitle4}
                        style={{flexGrow: 1}}
                    >
                        {_place.name}
                    </Typography>
                    {!!poi.plan && <ChevronRightIcon style={{flex: 'none'}}/>}
                </Box>
                <Box display={'flex'} width="100%" alignItems="center" height="1.5rem">
                    <Typography
                        variant="body1"
                    >
                        {address}
                    </Typography>
                </Box>
                {/* if permanently closed */}
                { CLOSED_STATUSES.indexOf(_place.businessStatus) !== -1 &&
                    <Box display={'flex'} width="100%" alignItems="center" height="1.5rem">
                            <Typography
                                className={typography.subtitle4}
                                color="error"
                            >
                                {`${_place.businessStatus === 'CLOSED_PERMANENTLY' ? 'Permanently' : 'Temporarily'} closed`}
                            </Typography>
                    </Box>
                }
            </Box>
        </ListItem>
    )
}