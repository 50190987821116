import { Box, Button, Dialog, DialogContent, Link, Typography, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React, { useEffect, useState, useRef } from 'react';
import { dispatch } from 'slice';
import desktopBerlinSvg from '../../../assets/svg/city/Berlin-desktop-illustration.svg';
import mobileBerlinSvg from '../../../assets/svg/city/Berlin-mobile-illustration.svg';
import ImageBox from '../../../lib/components/images/ImageBox';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { usePrevious } from '../../../lib/utils/hooks';
import Mixpanel from '../../../lib/mixpanel'
import { useTypographyStyles } from '../../../modules/theme/useTypography';
import { useStateGuestCookiesConsent } from '../../cookies/state/hooks/useStateCookiesConsent';
import { openModal } from '../../dialogs/standard/state/actions';
import HotelLogoImg from '../../hotel/components/HotelLogoImg';
import { PrivacyPolicyArticle } from '../../legal/components/articles/PrivacyPolicyArticle';
import { TermsOfServicesArticle } from '../../legal/components/articles/TermsOfServicesArticle';
import { guestConsentAgree } from '../state/actions';
import { useSwipeable } from 'react-swipeable';
import { LanguageSelectComp } from '../../hotel/guest-console/components/LanguageSelectComp';

const useStyle = tgMakeStyles((theme)=>({
    backButton : {
        position:'absolute',
        top:0,
        left:0
    },
    // for the first slide, default style of dialog should be applied to wrapper 
    dialogPaper:{
        padding:({isFirst, isMobile})  => {
            return isFirst && !isMobile ? 0 : null
        }
    },
    wrapperBox:({isFirst,isMobile}) => {
        return isFirst && !isMobile ?  {
            ...theme.overrides.MuiDialog.paper,
            flex : 5.5
        } : null
    },
    dialogContainer : {
        zIndex :  `${theme.zIndex.modal - 1 } !important`
    },
}))

export function GuestCookiesDisclaimerModal(props) {

    const {hotelData} = props;
    //Check if a user has already agreed legal stuffs.
    const hasAgreed = useStateGuestCookiesConsent();
    // dialog open
    const [open, setOpen] = useState(true)
    // active content index
    const [activeStepIdx, setActiveStepIdx] = useState(0);

    // Prevent the modal to be closed when user clicked cookie consent for the first time but 
    // a user is still in the process of welcome modal
    const isInProgress = useRef(false)
    useEffect(()=>{
        if (activeStepIdx > 0){
            isInProgress.current = true
        }
        return () => {}
    },[activeStepIdx])
    // contents to be injected  
    const contents = [
        <WelcomePage hotelData={hotelData}/>,
        <ConsentPage />,
        <SetLanguagePage />
    ]
    // Change styling based on user window size.
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))
    const classes = useStyle({isFirst : activeStepIdx === 0, isMobile})
    
    //set up slide in animation 
    // store previous active index to detect if next page is going forward or back.
    const prevActiveIdx = usePrevious(activeStepIdx)
    const [aniClassNam, setAniClassNam] = useState(null)
    const aniFactory = (fn) => (val) => {
        setAniClassNam(null)
        setTimeout(()=>{
            fn(val);
        },100)
    }

    const navigateToNext = aniFactory(()=>{setActiveStepIdx(prevActiveStep => prevActiveStep + 1)})
    const navigateBack = aniFactory(()=>{
            // prevent to go back more than 0
            setActiveStepIdx(prevActiveStep => {
                return prevActiveStep === 0 ? 0 : activeStepIdx - 1;
            })
        })
    const navigateByIndex = aniFactory((idx)=>{setActiveStepIdx(idx)})

    useEffect(()=>{
        if (activeStepIdx === contents.length){
            setOpen(false)
        }

        if (activeStepIdx === 0 && !prevActiveIdx) {
            setAniClassNam(null)
        } else if (activeStepIdx > prevActiveIdx) {
            setAniClassNam('slide-in-right')
        } else if (activeStepIdx < prevActiveIdx) {
            setAniClassNam('slide-in-left')
        }
        return () => {}
    },[activeStepIdx,prevActiveIdx,contents.length])

    // Set up swipe handlers
    // get react swipeable props from custom hook
    const _react_swipeable_config_object = {
        delta: 10,                           // min distance(px) before a swipe starts
        preventDefaultTouchmoveEvent: true   // preventDefault on touchmove, required to prevent window refresh when swiping down
    }
    const swipeHandlers = useSwipeable({
        onSwipedRight: navigateBack,
        onSwipedLeft: navigateToNext,
        ..._react_swipeable_config_object
    })
    // swipe should be available only on mobile
    const swipeProps = isMobile ? swipeHandlers : {}

    return (
        <>
            { (!hasAgreed || isInProgress.current) &&
                <Dialog
                    open={open}
                    disableBackdropClick={true}
                    classes={{  
                        paper: `${aniClassNam} ${classes.dialogPaper}`,
                        root : classes.dialogContainer
                    }}
                    fullWidth={activeStepIdx === 0}
                    maxWidth={activeStepIdx === 0 && !isMobile ? 'lg' : 'sm'}
                >
                    <Box display="flex" {...swipeProps}>
                        <Box className={`${classes.box} ${classes.wrapperBox}`}>
                            {/* Back button  */}
                            {activeStepIdx !== 0 &&
                                <IconButton
                                onClick={navigateBack}
                                className={classes.backButton}
                                color="primary">
                                    <ArrowBackIcon />
                                </IconButton>
                            }
                            <DialogContent>
                                <Box>
                                    {contents.map((Comp, idx) => {
                                        if (activeStepIdx !== idx) return null;
                                        return (
                                            <Box key={idx}>
                                                {React.cloneElement(Comp, { navigateToNext, navigateBack })}
                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Box>
                                    <ClickableSteppers
                                        onClickFn={navigateByIndex}
                                        steps={contents.length}
                                        activeStep={activeStepIdx}
                                        />
                                </Box>
                            </DialogContent>
                        </Box>
                        {/* Berlin city image for desktop  */}
                        {activeStepIdx === 0 && !isMobile &&
                            <Box flex={4.5}>
                                <ImageBox src={desktopBerlinSvg} style={{
                                    width: 'auto',
                                    height: '100%',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }} />
                            </Box>
                        }
                    </ Box>
                </Dialog>
            }
        </>
    )
}

const useWelcomeStyle = tgMakeStyles((theme)=>({
    mb : {
        marginBottom: theme.spacing(2),
    },
    greyColor : {
        color : theme.palette.greyscale.grey_4
    }
}))

const WelcomePage = (props) => {

    useEffect( () => {
        Mixpanel('track')('impression_welcome')
        return () => {}
    }, [])

    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))
    const classes = useWelcomeStyle()
    const {navigateToNext, hotelData} = props
    
    const welcomeParagraphs = hotelData 
        ? hotelData.welcomeMessage
            ? hotelData.welcomeMessage.split('\n')
            : undefined
        : undefined

    const typoClasses = useTypographyStyles()
    const hotelImgStyle = {
        height: 'auto', maxWidth : '120px'
    }
    const imgStyle = {
        height: isMobile ? '50px' : '100px', width : 'auto'
    }

    return (
        <Box textAlign="center">
            {hotelData ? (
                <HotelLogoImg hotel={hotelData} style={isMobile ? imgStyle : hotelImgStyle} className={`${classes.mb}`}/>
            ):(
                <Box style={hotelImgStyle} className={`${classes.mb}`}/>
            )}
            {isMobile && 
                <ImageBox src={mobileBerlinSvg} className={`${classes.mb}`} style={imgStyle}/>
            }
            <Typography 
                className={`${classes.mb} ${classes.greyColor} ${typoClasses.h5}`}
            >
                {welcomeParagraphs 
                    ? welcomeParagraphs.slice(0,1) || 'We guide you through our favorite spots around Berlin'
                    : 'We guide you through our favorite spots around Berlin'

                }
            </Typography>
            <Typography 
                className={`${classes.mb} ${classes.greyColor} ${isMobile ? typoClasses.body1 : typoClasses.h6}`}
            >
                {welcomeParagraphs
                    ? welcomeParagraphs.slice(1).join(' ') || ''
                    : 'Visit us at the concierge desk for more personalised recommendations and assistance with bookings.'

                }
            </Typography>
            <Button
                endIcon={<NavigateNextIcon />}
                onClick={navigateToNext}
                color="primary"
                className={`${classes.mb}`}
                classes = {{
                    label : typoClasses.h4
                }}
            >
                CONTINUE
            </Button>
        </Box>
    )
}

const useConsentStyle = tgMakeStyles((theme)=>({
    link : {
        textDecoration: 'underline'
    },
    mb : {
        marginBottom: theme.spacing(2)
    },
    dialogRoot : {
        zIndex : theme.zIndex.modal + 1
    },
    greyColor : {
        color : theme.palette.greyscale.grey_4
    }
}))



const ConsentPage = props => {
    const classes = useConsentStyle();
    const typoClasses = useTypographyStyles()
    const {navigateToNext, ...others} = props
    const preventDefault = (callbackFn) => (e) => {
        e.preventDefault();
        callbackFn()
    }
    const classOverRide = {
        root : classes.dialogRoot
    }

    const openPrivacyPolicy = () => {
        // Add DialogProps and z-index 
        dispatch(openModal({
            title: 'Privacy Policy',
            component: PrivacyPolicyArticle,
            props: {location: 'cookies_disclaimer'},
            DialogProps : {
                classes : classOverRide
            },
        }))
    }
    const openLearnMore = () => {
        dispatch(openModal({
            title: 'Terms Of Service',
            component: TermsOfServicesArticle,
            props: {location: 'cookies_disclaimer'},
            DialogProps : {
                classes : classOverRide
            }
        }))
    }

    const onOkClick = () => {
        navigateToNext();
        Mixpanel('track')('agree_cookies_disclaimer')
        dispatch(guestConsentAgree());
    }

    return (
        <Box textAlign="center">
            <Typography color='primary' className={`${classes.mb} ${typoClasses.h1}`}>COOKIES &amp; YOU</Typography>
            <Typography className={`${classes.mb} ${typoClasses.greyColor} ${typoClasses.body2}`}>
                We use cookies and other tools to analyse site traffic and offer you the best experience. By continuing to use this website, 
                you consent to the use of cookies and our <Link className={`${classes.link} ${typoClasses.body2}`} href="#" onClick={preventDefault(openPrivacyPolicy)} color="inherit">privacy policy</Link>
            </Typography>
            <Box className={`${classes.mb} ${typoClasses.h1}`}>
                <Button  variant="contained" color='primary' onClick={onOkClick}> OK </Button>
            </Box>
            <Box className={`${classes.mb} ${typoClasses.subtitle5}`}>
                <Link className={classes.link} href="#" onClick={preventDefault(openLearnMore)} color="inherit">
                    LEARN MORE
                </Link>
            </Box>
        </Box>
    )
}

const SetLanguagePage = props => {
    const {navigateToNext} = props;
    const compProps = {
        onContinue : navigateToNext,
        onApply : navigateToNext
    }
    return <LanguageSelectComp {...compProps}/>
}

const useSteppersStyle = tgMakeStyles((theme)=>({
    dot : {
        width:theme.spacing(1.5),
        height: theme.spacing(1.5),
        margin: `${theme.spacing(0)} ${theme.spacing(1)}`,
        borderRadius: ' 50%',
        backgroundColor: ' rgba(0, 0, 0, 0.26)',
        '&:hover' : {
            cursor: 'pointer'
        }
    },
    dotActive : {
        backgroundColor: theme.palette.primary.main
    },
    dotWrapper : {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    }
}))

// Almost identical copy of MobileStepper comp but with on click function.
const ClickableSteppers = (props) => {
    const classes = useSteppersStyle();
    const {steps,activeStep,onClickFn,boxProps={}} = props;
    return (
        <Box className={`${classes.dotWrapper}`} {...boxProps}>
            {new Array(steps).fill(null).map((val,idx)=>{
                return (
                    <div onClick={()=>{onClickFn(idx)}} className={`${classes.dot} ${idx === activeStep && classes.dotActive}`} key={idx}/>
                )
            })}
        </Box>
    )
}