import React from 'react'
import  OpenModalLink  from '../../dialogs/standard/components/OpenStandardDialog.Link'
import { PrivacyPolicyArticle } from './articles/PrivacyPolicyArticle'

export function PrivacyPolicyLink({trackingProps, ...forwardProps}) {
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle="Privacy Policy" 
            modalComponent={PrivacyPolicyArticle}
            modalComponentProps={trackingProps}
        >
            {forwardProps.children ? forwardProps.children : 'Privacy Policy'}
        </OpenModalLink>
    )
}

export default PrivacyPolicyLink