import { fromEvent, merge } from "rxjs";
import mapshaper from 'mapshaper';

/* 
    This module contains functions to read and optionnally convert files data :
    - conversion to geo related format is done with mapshaper (ie: csv to geoJson)
    - for less specific conversion, FileReader is wrapped to be used with Promise or Observable (https://developer.mozilla.org/en-US/docs/Web/API/FileReader)
*/

/**
 * 
 * @param {string} format the desired output format. see mapshaper doc for supported formats
 * @param {string} csvTextData csv data string
 * @param {string} filename optional filename, may be useful to help debugging
 */
function csvToXAsync(format, csvTextData, filename = 'csvfile'+Date.now()){
        const input = {
            [filename]: csvTextData
        }
        const cmd = `
            -i "${filename}" 
            -points x=lng y=lat
            -o format=${format}
        `
        mapshaper.gui = true;
        return mapshaper.applyCommands(cmd, input)
}

export const csvToGeojsonAsync = csvToXAsync.bind(0, 'geojson')

/**
 * 
 * @param {string} format the desired output format. see mapshaper doc for supported formats
 * @param {string} jsonData can be JSON string or plain javascript object / array of object
 * @param {string} filename optional filename, may be useful to help debugging
 */
function jsonToXAsync(format, jsonData, filename = 'jsonfile'+Date.now()){
    const input = {
        [filename]: jsonData
    }
    const cmd = `
        -i "${filename}" 
        -points x=lng y=lat
        -o format=${format}
    `
    mapshaper.gui = true;
    return mapshaper.applyCommands(cmd, input)
}
export const jsonToGeojsonAsync = jsonToXAsync.bind(0, 'geojson')

/**
 * Wrap FileReader method in Promise for convenience
 * @param {string} method the FileReader method to use (see FileReader doc for a list of available methods)
 * @param {*} File a File instance
 */
function readAsXAsync(method, File){
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        try {
            // resolve on load
            reader.onload = loadEvent => {
                resolve(loadEvent.target.result)
            }
            reader.onabort = abortEvent => {reject(abortEvent)}
            reader.onerror = errorEvent => {reject(errorEvent)}
            // load file
            reader[method](File)
        } catch (error) {
            reject(error)
        }
    })
}
export const readAsTextAsync = readAsXAsync.bind(0, 'readAsText')

/**
 * Wrap FileReader methods and provide observables for FileReader events
 * @param {string} method the FileReader method to use (see FileReader doc for a list of available methods)
 * @param {*} File a File instance
 */
export function RxReadAsX(method, File){
    // console.log('RxReadAsX', method)
    const reader = new FileReader();
    // create observables
    const onload = fromEvent(reader, 'load')
    const onabort = fromEvent(reader, 'abort')
    const onerror = fromEvent(reader, 'error')
    const onloadstart = fromEvent(reader, 'loadstart')
    const onloadend = fromEvent(reader, 'loadend')
    const onprogress = fromEvent(reader, 'progress')
    const onALL = merge(
        onload,
        onabort,
        onerror,
        onloadstart,
        onloadend,
        onprogress
    )
    // load File
    reader[method](File);
    return {
        onALL,
        onload,
        onabort,
        onerror,
        onloadstart,
        onloadend,
        onprogress
    }
}