import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles";
import { COLORS } from "../../../../theme/colors";
import { TYPOGRAPHY_STYLES } from "../../../../theme/typographies";

export const useStyles = tgMakeStyles(theme => {
    return {
        plansgrid: {
            // backgroundColor: COLORS.get('grey')[1],
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gridtemplateRows: 'max-content',
            columnGap: theme.spacing(2),
            '& button': {
                borderRadius: '1.5625rem',
                minWidth: '10rem',
                minHeight: '2.25rem',
                ...TYPOGRAPHY_STYLES.h4
            },
            '& button.select_plan': {
                color: 'white',
                backgroundColor: '#292952',
            }
        },
        planBox: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            boxShadow: `2px 2px 30px ${COLORS.get('grey')[4]}`,
            borderRadius: '1rem',
            transition: 'all .3s',
            '& .spacer': {
                flexGrow: 1,
                minHeight: '1rem'
            },
            // blue etc. deals with the text for circle upgrade
            '& .blue': {
                color: '#292952'
            },
            '& .blue .circleTxt': {
                color: COLORS.get('gold').main
            },
            '&.selected .blue .circleTxt': {
                color: '#292952'
            },
            // free deals with the basic plan
            '&.free.selected': {
                backgroundColor: '#292952',
                color: 'white'
            },
            // generic classes for selectable plans
            '&.selected': {
                backgroundColor: COLORS.get('gold').main,
                color: '#292952'
            },
            '&:hover': {
                boxShadow: `2px 2px 30px #292952`
            },
            '&.selected button': {
                backgroundColor: 'white',
                color: COLORS.get('grey')[4]
            },
            // disabled icon upgrade
            '&.disabled': {
                color: COLORS.get('grey')[3]
            }
        },

        planName: {
            ...TYPOGRAPHY_STYLES.BODY1,
            minHeight: '7rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        planSubtext: {
            borderBottom: '2px solid grey',
            marginTop: '1rem',
            minHeight: '5rem'
        },
        pitchTxt: {
            // minHeight: '7rem',
            marginTop: '1rem',
            display: 'flex',
            '& > *:nth-child(1)': {margin: '0 1rem 1rem 0'}
        },

        summaryBox: {
            display: 'flex',
            flexDirection: 'column',
            border: '2px solid #292952',
            borderRadius: '1rem',
            overflow: 'hidden',
            '& > .header': {
                ...TYPOGRAPHY_STYLES.BODY1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#292952',
                color: 'white',
                padding: theme.spacing(2),
                minHeight: '8rem'
            },
            '& > div': {
                color: COLORS.get('grey')[4]
            }
        }
    }
})

export default useStyles