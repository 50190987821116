import { UserDetailsRoot } from '../../../user/routes'

export const hotelConsoleSettingsRoutes = [
    {
        name: 'root.hotel-console.settings',
        url: '/settings',
        views: {
            'content@root': {
                component: UserDetailsRoot
            }
        }
    }
]

export default hotelConsoleSettingsRoutes