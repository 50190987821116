import { useState, useEffect } from 'react';
import { $lice } from 'slice';
import { rootAuthReducer, INITIAL_STATE as initialAuthState } from '../reducers';

// define tgauth slice
const [auth$] = $lice('tgauth', rootAuthReducer, initialAuthState)

export default function useStateAuthStatus(){
    // set initial state, always unauthenticated
    const [status, setStatus] = useState(initialAuthState);
    useEffect(
        // Side effect code, analog to componentWillMount
        () => {
            // subscribe to auth slice
            let sub = auth$.path('status').subscribe(setStatus)
            // cleanup. Analog to componentWillUnmount
            return () => {sub.unsubscribe()}
        }, 
        // Dependencies array.
        []
    )
    // the hook returns state 
    return status
}