import { max, parse } from "date-fns";
import { PLANS_PLAIN_TEXT } from "../../invitation/utils";

export function getBusinessLastUpdate(biz, dtFormatStr="dd.MM.yyyy HH:mm:ss"){
    return max([
        parse(biz.lastUpdate, dtFormatStr, new Date()),
        parse(biz.place.lastProfileUpdate, dtFormatStr, new Date()),
        parse(biz.place.lastOpeningHoursUpdate, dtFormatStr, new Date())
    ])
}

export function isPremium(business){
    return business
    ? PLANS_PLAIN_TEXT.indexOf(business.plan) > 0
    : false
}