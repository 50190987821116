import { useEffect, useState } from 'react';

export const useTranslationHook = ({
    translation,
    tipText,
    aboutText,
    id
}) => {
    
    const [showTrans, setShowTrans] = useState(translation && translation.length > 0)

    const [texts, setTexts] = useState({})

    useEffect(()=> {
        if (showTrans && translation.length > 0) {
            const target = translation.find((translatedPOI)=>{
                return translatedPOI.googlePlaceId === id
            })
            setTexts({
                tipText : target ? target.tip : tipText,
                aboutText : target ? target.place.place.about : aboutText
            })
        } else {
            setTexts({
                tipText : tipText,
                aboutText : aboutText
            })
        }
    },[showTrans,translation,tipText,aboutText,id])

    return {texts, showTrans, setShowTrans}
}