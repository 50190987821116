import { Container ,ListItem, Typography } from '@material-ui/core'
import React from 'react'
import OpenModalLink from '../../../../dialogs/standard/components/OpenStandardDialog.Link'
import { useListSyleDecimal } from '../../../../legal'
import { COLORS } from '../../../../theme/colors'
import { useTypographyStyles } from '../../../../theme/useTypography'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export function WhatConciergeWantToKnowInfoDialog(props){
    const classes = useListSyleDecimal()
    return (
        <Container maxWidth="xs">
            <ol className={classes.olDiscOutside} component="ol" style={{transform: 'translateX(0.5rem)'}}>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        What kind of business are you?
                    </Typography>
                </ListItem>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        What are you best known for?
                    </Typography>
                </ListItem>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        What is the ambience like?
                    </Typography>
                </ListItem>
                <ListItem className={classes.liBlock} style={{paddingBottom: 0}}>
                    <Typography variant="body2" display="inline">
                        What is the history behind your business?
                    </Typography>
                </ListItem>
            </ol>
        </Container>
    )
}

export function WhatConciergeWantToKnowInfoDialogLink({trackingProps, ...forwardProps}){
    const typo = useTypographyStyles()
    return (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle={(
                <Typography className={`${typo.subtitle5}`} color="primary">
                    WHAT CONCIERGES & GUESTS WANT TO KNOW <span style={{color: COLORS.get('blue')['main']}}>ABOUT YOU</span>
                </Typography>
            )}
            modalComponent={WhatConciergeWantToKnowInfoDialog}
            modalComponentProps={trackingProps}>
                {forwardProps.children ? forwardProps.children : <HelpOutlineIcon />}
        </OpenModalLink>
    )
}