import { putUserDetails, getUserDetails$, putUserPassword } from "../../../http/tg/user";
import {dispatch} from 'slice';
import { doLogout } from "../../../authentication/state/actions";
import { closeModal, openModal } from "../../../dialogs/standard/state/actions";
import EmailSentConfirmationDiv from "../../components/EmailSentConfirmationDiv";
import { showNotification } from '../../../notifications/state/actions';

const attemptFetch = () => ({type: 'USER/DETAILS/ATTEMPTS/FETCH'})
const fetchUserSuccess = () => ({type: 'USER/DETAILS/SUCCESS/FETCH'})
const fetchUserFailed = (error) => {
    return function(){
        dispatch(doLogout())
        dispatch({
            type: 'USER/DETAILS/FAILED/FETCH',
            payload: error
        })
    }
}

const assignUserInfo = (userInfo) => ({
    type: 'USER/DETAILS/INFO/ASSIGN',
    payload: userInfo
})
export const assignUserFavs = (favIDs) => ({
    type: 'USER/DETAILS/FAVS/ASSIGN',
    payload: favIDs
})

export const setUserDetails = () => () => {
    dispatch(attemptFetch())
    return getUserDetails$.subscribe({
        next: res => {dispatch(assignUserInfo(res.data))},
        error: error => {dispatch(fetchUserFailed(error))},
        complete: () => {dispatch(fetchUserSuccess())}
    })
};

const attemptUpdate = () => ({type: 'USER/DETAILS/ATTEMPTS/UPDATE'})
const updateUserFailed = (error) => ({
    type: 'USER/DETAILS/FAILED/UPDATE',
    payload: error
})
const updateUserSuccess = () => ({type: 'USER/DETAILS/SUCCESS/UPDATE'})

export const updateUser = (payload) => () => {
    dispatch(attemptUpdate())
    return putUserDetails(payload).subscribe({
        next: res => {dispatch(assignUserInfo(res.data))},
        error: error => {dispatch(updateUserFailed(error))},
        complete: () => {
            const msg = payload.email ? `Your changes have been saved and a verification email has been sent to ${payload.email}` : 'Your changes have been saved'
            dispatch(showNotification({message: msg}))
            dispatch(updateUserSuccess())
            dispatch(closeModal())
        }
    })
}

export const updateUserEmail = (payload) => () => {
    dispatch(attemptUpdate())
    return putUserDetails(payload).subscribe({
        next: res => {dispatch(assignUserInfo(res.data))},
        error: error => {dispatch(updateUserFailed(error))},
        complete: () => {
            dispatch(openModal({
                title: 'Confirm your new email address',
                component: EmailSentConfirmationDiv,
                props: {
                    recipient: payload.email
                }
            }))
            dispatch(updateUserSuccess())
        }
    })
}

export const updateUserPassword = (payload) => () => {
    dispatch(attemptPasswordUpdate())
    return putUserPassword(payload).subscribe({
        error: err => {dispatch(passwordUpdateFailed(err))},
        complete: () => {
            dispatch(showNotification({message: 'Your password has been changed'}))
            dispatch(passwordUpdateSuccess())
            dispatch(closeModal())
        }
    })
}

const attemptPasswordUpdate = () => ({type: 'USER/DETAILS/ATTEMPTS/PASSWORD_CHANGE'})
const passwordUpdateSuccess = () => ({type: 'USER/DETAILS/SUCCESS/PASSWORD_CHANGE'})
const passwordUpdateFailed = e => ({
    type: 'USER/DETAILS/FAILED/PASSWORD_CHANGE',
    payload: e
})

export const unsetUserDetails = () => ({type: 'USER/DETAILS/UNSET'})