import React, { useEffect } from 'react'
import {TgForm} from '../../../lib/components/forms/DefaultTgForm'
import { Typography, FormControl, TextField, Button, LinearProgress } from '@material-ui/core'
import goldLogo from '../../../assets/png/logo_gold_with_text.png';
import useStateAuthStatus from '../state/hooks/useStateAuthStatus';
import { STATUSES } from '../state/meta';
import { dispatch } from 'slice';
import { resetPasswordReset, resetPassword } from '../state/actions';
import ImageBox from '../../../lib/components/images/ImageBox';
import {tgMakeStyles} from '../../../lib/styles/TgMakeStyles'
import { useRouter } from '@uirouter/react';

const useStyles = tgMakeStyles(theme => ({
    submitButton: {
        marginBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px',
    }
}))

export function ResetPasswordForm({$stateParams}) {
    const router = useRouter()
    const classes = useStyles()
    const authStatus = useStateAuthStatus()
    // go to welcome page when status = 'reset'
    useEffect(() => {
        if(authStatus && authStatus.reset && authStatus.reset.password
            && authStatus.reset.password.code === STATUSES.reset.password.reset){
            router.stateService.go('root.welcome')
        }
        return () => {}
    }, [authStatus])

    // on component dismount, do reset state 
    // Notice that the effect only returns the cleanup function
    useEffect(() => function resetStateOnDismount(){
        dispatch(resetPasswordReset())
    }, [] /* empty array means run only when mounted */)
    
    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const payload = {
            token: $stateParams.token,
            newPassword: formData.get('newPassword'),
            confirmPassword: formData.get('confirmPassword')
        }
        dispatch(resetPassword(payload))
    }
    return (
        <TgForm onSubmit={handleSubmit}>
            <ImageBox src={goldLogo}
                width="100%" height="15rem">
            </ImageBox>
            <Typography color="primary" variant="subtitle1" paragraph align="center">
                PASSWORD RESET
            </Typography>
            <FormControl fullWidth={true} margin="normal">
                <TextField id="reset-password-form-new-password"
                    name="newPassword"
                    label="Enter new password"
                    type="password"
                    required={true}
                    fullWidth={true}
                    color="primary"/>
            </FormControl>
            <FormControl fullWidth={true} margin="normal">
                <TextField id="reset-password-form-confirm-password"
                    name="confirmPassword"
                    label="Confirm new password"
                    type="password"
                    required={true}
                    fullWidth={true}
                    color="primary"/>
            </FormControl>
            <Typography varian="body2" color="error">
                {authStatus && authStatus.reset && authStatus.reset.password && authStatus.reset.password.error &&
                    authStatus.reset.password.error
                }
            </Typography>
            <FormControl fullWidth={true} margin="normal">
                <Button
                    classes={{root: classes.submitButton}}
                    id="reset-password-form-submit"
                    type="submit"
                    variant="contained"
                    color="primary">
                    CONTINUE
                </Button>
                {authStatus && authStatus.reset && authStatus.reset.password
                    && authStatus.reset.password.code === STATUSES.reset.password.resetting 
                    && <LinearProgress variant="query" color="secondary"/>}
            </FormControl>
        </TgForm>
    )
}
