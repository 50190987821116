import React, { useState } from 'react'
import { Box, Button, Link, TextField, Typography } from "@material-ui/core"
import { putHotelMap } from '../../../http/tg/hotels'
import { dispatch } from 'slice'
import { showNotification } from '../../../notifications/state/actions'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles'
import { COLORS } from '../../../theme/colors'

const useStyles = tgMakeStyles(theme => ({
    clearBtn: {
        '&:hover': {
            color: COLORS.get('gold').main
        }
    }
}))

export function EditMapTitleForm({
    map, 
    onSave,
    onCancel,
    ...props
}){

    const classes = useStyles()
    const [title, setTitle] = useState(map.title)
    const submitForm = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        putHotelMap({
            id: map.id,
            title: formData.get('new-title')
        }).subscribe({
            next: res => {
                onSave(res.data.title)
            },
            error: err => {
                console.log(err)
                dispatch(showNotification({message: `Unable to set new map title. Please try again later`}))
            }
        })
    }

    return (
        <Box component="form" onSubmit={submitForm}>
            <Typography align="center">
                This is for your team only; guests will not see this.
            </Typography>
            <Box my={2}>     
                <TextField
                    variant="outlined"
                    fullWidth
                    label="Map Title"
                    id="new-title"
                    name="new-title"
                    type="text"
                    value={title}
                    onChange={e => {setTitle(e.target.value)}}
                    placeholder="This map has no title yet"
                    InputProps={{
                        endAdornment: title
                         ? <Link 
                                id="clear-map-title"
                                className={`${classes.clearBtn} clickable`}
                                color="inherit" 
                                onClick={e => {setTitle("")}}
                            >
                                clear
                        </Link>
                         : null
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center">
                <Button 
                    onClick={onCancel}
                    variant="outlined"
                    color="primary"
                >
                    cancel
                </Button>
                <Button 
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    save
                </Button>
            </Box>
        </Box>
    )
}