import { useEffect } from "react";

export function useHotelMarker({
    map,
    hotel
}){
    // add hotel marker(logo) and pin (for exact location)
    useEffect(function placeHotelMarker(){
        const hotelMarkerOptions = {
            map: map,
            position: {
                lat: hotel.lat,
                lng: hotel.lng
            },
            icon: {
                url: process.env.REACT_APP_API_BASE_URL.replace('api', '')+hotel.mapLogo,
                scaledSize: new window.google.maps.Size(196, 100),
                // Adjust anchor so that pin doesn't overrap icon image 
                anchor : new window.google.maps.Point(98,110),
            },
            clickable: false
        }
        const hotelPinOptions = {
            map: map,
            position: {
                lat: hotel.lat,
                lng: hotel.lng
            },
            clickable: false,
            icon: (function(fillColor, strokeColor, scale){
                var circleSymbol = {
                    path: 0, // path: window.google.maps.SymbolPath.CIRCLE,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    strokeColor: strokeColor || '#ffffff',
                    fillOpacity: 1,
                    fillColor: fillColor || '#c6ad7c',
                    scale: scale || 4
                }
                return circleSymbol;
            })('black')
        }
        /* const hotelMarker =  */new window.google.maps.Marker(hotelMarkerOptions)
        /* const hotelPin =  */new window.google.maps.Marker(hotelPinOptions)
    }, [hotel, map])
}