import React from 'react';
import { Box, Typography,Paper } from '@material-ui/core';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import TextsmsIcon from '@material-ui/icons/Textsms';
import {COLORS} from '../../../theme/colors'

const useStyles = tgMakeStyles((theme) => ({
    PaperRoot: {
        backgroundColor: COLORS.get('grey')[2],
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        boxShadow: 'none'
    }
}));

const TipBox = (props) => {
    const {children, BoxProps, trackingProps, hotelName} = props
    const classes = useStyles();

    return (
        <>
            { children 
                ? (
                    <Box p={0} m={0} {...BoxProps}>
                        <Box position='relative' pt={2.5}>
                            <Box 
                                position='absolute'
                                top={0}
                                width='100%'
                                display='flex'
                                justifyContent='center'
                            >
                                <Box
                                    // display='inline-block' 
                                    display='flex'
                                    justifyContent='center'
                                    alignItems="center"
                                    p={1}
                                    borderRadius='50%'
                                    height="2.5rem"
                                    width="2.5rem"
                                    bgcolor={COLORS.get('grey')[3]}
                                    color="white"
                                >
                                    <TextsmsIcon color='inherit'/>
                                    {/* <SvgIcon viewBox="0 0 352 512" 
                                        style={{height: '24px', width: '24px'}}
                                        color='primary'>
                                        <path fill="currentColor" 
                                            d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z" />
                                    </SvgIcon> */}
                                </Box>
                            </Box>
                            <Paper classes={{root: classes.PaperRoot}}>
                                {hotelName && <Typography paragraph align="center" style={{marginTop: "1rem"}}>
                                    From {hotelName}
                                </Typography>}
                                <Typography paragraph
                                    style={{whiteSpace: 'pre-line'}}
                                >
                                    <i>{children}</i>
                                </Typography>
                            </Paper>
                        </Box>
                    </Box>
                )
                : null
            }
        </>
    )
}

export default TipBox;