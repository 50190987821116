import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles'
import { TYPOGRAPHY_STYLES } from './typographies';
import { COLORS } from './colors';

const DEFAULT_SPACING_FUNCTION = factor => `${0.5 * factor}rem`
const DEFAULT_MUI_THEME = createMuiTheme()

// Set all the variant classes to have line-height of 1.15;
const typoSetting = [
        // 'root',
        'body2',
        'body1',
        'caption',
        // 'button',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'overline'
    ].reduce((acum,val,idx)=>{
        return {
            ...acum,
            [val]:{lineHeight:1.15}
        }
    },{})

let _lightTheme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        type: 'light',
        primary: {
            main: COLORS.get('gold').main,
            light: COLORS.get('gold').light,
            dark: COLORS.get('gold').dark,
            inactive: COLORS.get('gold').inactive,
            contrastText: COLORS.get('gold').contrastText
        },
        secondary: {
            main: COLORS.get('blue').main,
            light: COLORS.get('blue').light,
            dark: COLORS.get('blue').dark,
            inactive: COLORS.get('blue').inactive,
            contrastText: COLORS.get('blue').contrastText
        },
        text: {
            primary: COLORS.get('grey')[4]
        },
        background: {
            // paper: COLORS.get('whiteTaintedRed'),
            // default: COLORS.get('whiteTaintedRed')
            paper: '#fff',
            default: '#fff'
        },
        // NOTE : 
        // Custom named palette is not fully functional.
        // We need to inject theme in makeStyle or useStyle hook to use it.
        // https://github.com/mui-org/material-ui/issues/14185
        greyscale: {
            grey_1: COLORS.get('grey')[1],
            grey_2: COLORS.get('grey')[2],
            grey_3: COLORS.get('grey')[3],
            grey_4: COLORS.get('grey')[4],
            grey_5: COLORS.get('grey')[5],
        }
    },
    typography: {
        // Tell Material-UI what's the font-size on the html element is. in px
        htmlFontSize: 16,
        fontWeightRegular: 400,
        fontFamily: 'Lato',
        subtitle1: {
            fontSize: '1.125rem'
        }
    },
    zIndex: {
        autoComplete : 1300
    },
    // using increment of 0.5rem.
    spacing: DEFAULT_SPACING_FUNCTION,
    overrides: {
        // standardize Dialog layout to match TG rules
        MuiDialog: {
            paper: {
                padding: DEFAULT_SPACING_FUNCTION(8),
                margin: DEFAULT_SPACING_FUNCTION(1),
                [DEFAULT_MUI_THEME.breakpoints.down('sm')]: {
                    padding: DEFAULT_SPACING_FUNCTION(4)
                }
            }
        },
        MuiDialogTitle: {
            root: {
                marginBottom: DEFAULT_SPACING_FUNCTION(2),
                padding: 0,
                ...TYPOGRAPHY_STYLES.h5,
                // minHeight is used to ensure that when the Dialog has no Title, 
                // the close and back buttons do not overlap DialogContent. 
                // This would happen because close and back are absolutely positioned
                // and end up in dialog paper padding BUT are bigger than that padding
                minHeight: DEFAULT_SPACING_FUNCTION(4),
            }
        },
        MuiDialogContent: {
            root: {
                padding: 0,
                // override overflowY: auto
                overflowY: 'initial'
            }
        },
        MuiDialogContentText: {
            root: {
                padding: 0,
                // override overflowY: auto
                overflowY: 'initial'
            }
        },
        MuiDialogActions: {
            root: {
                marginTop: DEFAULT_SPACING_FUNCTION(2),
                padding: 0
            }
        },
        MuiLink: {
            root: {
                // override to body1 because link can inherit <body> fontSize = 14px.
                // <body> fontSize = 14px is coming from CssBaseline component that applies body2 to <body>
                fontSize: TYPOGRAPHY_STYLES.body1.fontSize
            }
        },
        MuiTypography: {
            paragraph: {
                marginTop: DEFAULT_SPACING_FUNCTION(1),
                marginRight: DEFAULT_SPACING_FUNCTION(1),
                marginBottom: DEFAULT_SPACING_FUNCTION(1),
                marginLeft: DEFAULT_SPACING_FUNCTION(1)
            },
            root : {
                lineHeight : 1.15
            },
            ...typoSetting
        },
        MuiFab: {
            label: {
                ...TYPOGRAPHY_STYLES.subtitle3
            },
            secondary: {
                "&:hover": {
                    // by default Mui theme applies dark to background color on hover
                    backgroundColor: COLORS.get('blue').light
                }
            }
        },
        MuiButtonBase: {
            root: {
                margin: DEFAULT_SPACING_FUNCTION(1)
            }
        },
        MuiButton: {
            label: {
                ...TYPOGRAPHY_STYLES.h3
            },
            containedSecondary: {
                "&:hover": {
                    // by default Mui theme applies dark to background color on hover
                    backgroundColor: COLORS.get('blue').light
                }
            }
        },
        MuiButtonGroup: {
            root: {
                margin: DEFAULT_SPACING_FUNCTION(1)
            },
            grouped: {
                // reset MuiButtonBase override since we do not want Buttons in ButtonGroup to have margins (default Mui theme behaviour)
                margin: 'initial'
            }
        }
    }
})
_lightTheme = responsiveFontSizes(_lightTheme);
// console.log('theme = ', _lightTheme)
export const lightTheme = _lightTheme;