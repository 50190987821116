import React, { useEffect, useState } from 'react';
import { CircularProgress, Collapse, Container, Fab, Tab, Tabs, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {useTypographyStyles} from '../../../theme/useTypography'
import {TYPOGRAPHY_STYLES} from '../../../theme/typographies'
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails';
import { format, set } from 'date-fns';
import { Box } from '@material-ui/core';
import { getHotelMaps, getHotelNamedMaps, postNewHotelMap } from '../../../http/tg/hotels';
import MapsList from './Maps.List';
import WithCollapsableSearch from '../../../search/components/WithCollapsableSearch';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import { dispatch } from 'slice';
import { showNotification } from '../../../notifications/state/actions'

import { useRouter } from '@uirouter/react';

const useStyles = tgMakeStyles(theme => ({
    fab: {},
    desktopGrid: {
        display: 'grid',
        gridTemplateColumns: `repeat(2, calc(50% - ${theme.spacing(1)}))`,
        gridTemplateRows: 'max-content',
        gridGap: theme.spacing(2),
    },
    tab: {
        textTransform: 'none',
        ...TYPOGRAPHY_STYLES.subtitle1
    }
}))

export default function MapsDashboard ({
    ...props
}) {
    const router = useRouter()
    const typo = useTypographyStyles()
    const {info} = useStateUserDetails()
    const _greeting = `${getGreeting()}, ${info && info.firstName ? info.firstName : info && info.name ? info.name : ''}`
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('md'))


    // const _tabs = ['All Maps', 'Titled Maps']
    const _tabs = ['Recent Maps', 'Titled Maps']
    const [lists, setLists] = useState([])
    const [dataRefreshTrigger, setDataRefreshTrigger] = useState(true)
    const [fetching0, setFetching0] = useState(false)
    const [fetching1, setFetching1] = useState(false)

    useEffect(() => {
        setFetching0(true)
        setFetching1(true)
        let subs = [
            getHotelMaps({returnPlaces: true, per_page: 15}).subscribe({
                next: res => {setLists(prev => [res.data, prev[1]])},
                error: err => {setFetching0(false)},
                complete: () => {setFetching0(false)}
            }),
            getHotelNamedMaps({returnPlaces: true, per_page: 15}).subscribe({
                next: res => {setLists(prev => [prev[0], res.data])},
                error: err => {setFetching1(false)},
                complete: () => {setFetching1(false)}
            })
        ]
        return () => {
            subs.forEach( s => s.unsubscribe())
        }
    }, [dataRefreshTrigger])
    
    const theme = useTheme()

    const createNewMap = e => {
        postNewHotelMap().subscribe({
            next: res => {
                router.stateService.go('root.hotel-console.maps.map.edit', {
                    customMapId: res.data.id
                })
            },
            error: err => {
                dispatch(showNotification({message: 'Oops, unable to create a new map, please try again later'}))
            }
        })
    }

    return (
    <Container maxWidth="lg">
        <Box my={2} display="flex" alignItems="center" >
            <Box style={{flexGrow: 1}}>
                <Typography className={`${typo.h5}`}>
                    {_greeting}
                </Typography>
                <Typography className={`${typo.body2}`} style={{marginTop: theme.spacing(1)}}>
                    {format(new Date(), 'EEEE, dd MMMM yyyy')}
                </Typography>
            </Box>
            <Fab onClick={createNewMap}
                variant={isDesktop ? 'extended' : 'round'} 
                color="primary"
            >
                {isDesktop ? '+ NEW MAP' : <AddIcon />}
            </Fab>
        </Box>
        {isLarge 
            ? <DesktopLayout 
                    {...{
                        tabNames: _tabs,
                        tabContents: lists,
                        fetchings: [fetching0,fetching1],
                        onMapDelete: (mapId) => {setDataRefreshTrigger(prev => !prev)},
                        onMapChange: (map) => {setDataRefreshTrigger(prev => !prev)},
                        onSearch: term => e => {
                            setFetching1(true)
                            getHotelNamedMaps({returnPlaces: true, q: term}).subscribe({
                                next: res => {setLists(prev => [prev[0], res.data])},
                                error: err => {setFetching1(false)},
                                complete: () => {setFetching1(false)}
                            })
                        }
                    }}
            />
            : <MobileLayout 
                    {...{
                        tabNames: _tabs,
                        tabContents: lists,
                        fetching0,
                        fetching1,
                        onMapDelete: (mapId) => {setDataRefreshTrigger(prev => !prev)},
                        onMapChange: (map) => {setDataRefreshTrigger(prev => !prev)},
                        onSearch: term => e => {
                            setFetching1(true)
                            getHotelNamedMaps({returnPlaces: true, q: term}).subscribe({
                                next: res => {setLists(prev => [prev[0], res.data])},
                                error: err => {setFetching1(false)},
                                complete: () => {setFetching1(false)}
                            })
                        }
                    }}
            />
        }
    </Container>
)}

function getGreeting(dt = new Date()){
    const boundaries = [ // times of the day in ascending order with matching greeting
        [set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}), 'Hi'], // 00:00
        [set(new Date(), {hours: 4, minutes: 0, seconds: 0, milliseconds: 0}), 'Good morning'], // 04:00
        [set(new Date(), {hours: 12, minutes: 0, seconds: 0, milliseconds: 0}), 'Good afternoon'], // 12:00
        [set(new Date(), {hours: 18, minutes: 0, seconds: 0, milliseconds: 0}), 'Good evening'] // 18:00
    ]
    return boundaries.reduce(
        (acc, v) => v[0] < dt ? v[1] : acc,
        'Hello'
    )
}

function MobileLayout({
    tabNames,
    tabContents,
    onMapDelete,
    onMapChange,
    onSearch,
    fetching0,
    fetching1
}){

    const classes = useStyles()
    const [tabIdx, setTabIdx] = useState(0)
    const [qTerm, setQTerm] = useState('')
    const filterByTerm = e => {
        setQTerm(e.target.value)
    }
    
    return <Box>
        <WithCollapsableSearch 
            value={qTerm}
            onChange={filterByTerm}
            TextFieldProps={{placeholder:`Search for Titled Maps by Name`}}
            onReset={tabIdx === 0
                    ? e=>setQTerm('')
                    : e=>{
                        setQTerm('')
                        onSearch('')()
                    }
            }
            onSearch={tabIdx === 0
                ? function(){}
                : onSearch(qTerm)
            }
        >
            {/* TABS */}
            <Tabs 
                value={tabIdx} 
                onChange={(e,nIdx)=> {
                    setQTerm('')
                    setTabIdx(nIdx)
                }}
                indicatorColor="primary"
            >
                {tabNames.map( (v,i) => (
                    <Tab key={i} 
                        value={i} label={v}
                        classes={{root: classes.tab}}
                    />
                ))}
            </Tabs>
        </WithCollapsableSearch>
        <Box pt={2}>
            <Collapse in={tabIdx === 0}>
                {/* {tabContents[0]  */}
                {!fetching0 
                    ?   (<MapsList 
                            maps={tabContents[0]} 
                            searchTerm={qTerm}
                            onMapDelete={onMapDelete}
                            onMapChange={onMapChange}
                        />)
                    :   (<Box display="flex" justifyContent="center" height="30vh" alignItems="center">
                            <CircularProgress />
                        </Box>)
                }
            </Collapse>
            <Collapse in={tabIdx === 1}>
                {!fetching1
                    ?(<MapsList maps={tabContents[1]} searchTerm={qTerm}/>)
                    :(<Box display="flex" justifyContent="center" height="30vh" alignItems="center">
                        <CircularProgress />
                    </Box>)
                }
            </Collapse>
        </Box>
    </Box>
}

function DesktopLayout({
    tabNames,
    tabContents,
    onMapDelete,
    onMapChange,
    onSearch,
    fetchings
}){

    // const [tabIdx, setTabIdx] = useState(0)
    const [qTerm, setQTerm] = useState(['',''])
    const filterByTerm = idx => e => {
        let nval = e.target.value
        setQTerm(prev => {
            let n = [...prev]
            n[idx] = nval
            return n
        })
    }
    const resetQterm = idx => e => {
        setQTerm(prev => {
            let n = [...prev]
            n[idx] = ''
            return n
        })
    }
    const classes = useStyles()
    const typo = useTypographyStyles()

    return <Box className={`${classes.desktopGrid}`}>
        {tabNames.map( (name, idx) => {
            return (
                <Box key={idx}>
                    {idx === 0 
                        ? (
                        <Box height="3rem" display="flex" alignItems="center">
                            <Typography className={`${typo.subtitle1}`}>
                                {name}
                            </Typography>
                        </Box>
                        )
                        : (
                            <WithCollapsableSearch 
                                value={qTerm[idx]}
                                onChange={filterByTerm(idx)}
                                TextFieldProps={{placeholder:`Search for Titled Maps by Name`}}
                                onReset={resetQterm(idx)}
                                onSearch={onSearch(qTerm)}
                            >
                                <Typography className={`${typo.subtitle1}`}>
                                    {name}
                                </Typography>
                            </WithCollapsableSearch>
                        )
                    }
                    <Box pt={2}>
                        {!fetchings[idx]
                            ?   (<MapsList 
                                    maps={tabContents[idx]} 
                                    searchTerm={qTerm[idx]}
                                    onMapDelete={onMapDelete}
                                    onMapChange={onMapChange}
                                />)
                            :   (<Box display="flex" justifyContent="center" height="30vh" alignItems="center">
                                    <CircularProgress />
                                </Box>)
                        }
                    </Box>
                </Box>
            )
        })}
    </Box>
}

/* 
const handleSubmit = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    // POST customPOI
    saveHotelDigimapCustomPOI({
        hotelId: hotels[0].id,
        mapId: customMapId,
        about: formData.get('poiAbout'),
        tip: formData.get('poiTip'),
        googlePlaceId: googlePlaceId
    })
        .subscribe(
            res => {
                dispatch(openModal({
                    title: '',
                    component: p => (<POIAddedSuccessConfirmation name={name}/>)
                }))
                onSuccess(res)
            },
            error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    if(error.response.data && error.response.data.message){
                        if(error.response.data.message.includes('GooglePlaceId already exists')){
                            dispatch(showNotification({
                                message: `${name} already exists in your Custom Map`
                            }))
                        }
                    }
                    } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error);
                    }
                    console.log(error.config);
            },
            () => console.log('complete')
        )
}
 */