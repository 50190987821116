import { Link } from '@material-ui/core'
import React from 'react'
import { map } from 'rxjs/operators'
import { dispatch } from 'slice'
import { postRefreshBusiness } from '../../../http/tg/business'
import { showNotification } from '../../../notifications/state/actions'
import { setAdminBusinessPlaceFieldData } from '../../state/actions'

export function CheckGoogleUpdatesLink({businessId, ...props}){

    const refreshData = bizID => e => {
        postRefreshBusiness(bizID)
            .pipe(
                map(res => res.data)
            )
            .subscribe({
                next: data => {
                    console.log(data)
                    dispatch(setAdminBusinessPlaceFieldData(bizID, 'location', data.place.location))
                    dispatch(setAdminBusinessPlaceFieldData(bizID, 'openingHours', data.place.openingHours))
                },
                error: err => {},
                complete: () => {dispatch(showNotification({message: 'Your profile has been updated. Changes are now live for concierges.'}))}
            })
    }

    return <Link
        className="clickable"
        color="primary"
        {...props}
        onClick={refreshData(businessId)}
    >
        Check Google for updates
    </Link>
}

export default CheckGoogleUpdatesLink