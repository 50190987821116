import React, { useEffect } from 'react'
import { useMediaQuery } from '@material-ui/core'
import MobileLayout from './modules/layout/MobileLayout'
import DesktopLayout from './modules/layout/DesktopLayout'
import Mixpanel from './lib/mixpanel'

export default function AppLayout() {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))
    useEffect( () => {
        Mixpanel('register')({mobile: isMobile})
    }, [isMobile])
    return (
        <React.Fragment>
            {isMobile
                ? <MobileLayout />
                : <DesktopLayout />
            }
        </React.Fragment>
    )
}