import { Box, Button, Container, Typography } from '@material-ui/core'
import { useSref } from '@uirouter/react'
import React, { useEffect, useState } from 'react'
import { fromEvent } from 'rxjs'
import { distinct } from 'rxjs/operators'
import { useTypographyStyles } from '../../../../theme/useTypography'
import useOBStyles from '../onboarding.classes'

export function DesktopCTA({isVisible, ...props}){
    // console.log('DesktopCTA', props)

    const [visible, setVisible] = useState(false)
    useEffect(() => {
        let sub = fromEvent(document.querySelector('#content-scroll'), 'scroll')
            .pipe(distinct())
            .subscribe(e => {
                setVisible(isVisible())
            })

        return () => {sub.unsubscribe()}
    }, [])

    const {$state$} = props
    const classes = useOBStyles()
    const typo = useTypographyStyles()
    const plansSref = useSref($state$.data.cta_next)
    return  <Box
                className={`${classes.desktopCTA} ${visible ? 'on' : ''}`}
            >
                <Typography className={typo.subtitle6} align="center">
                    {$state$.data.cta_txt}
                </Typography>
                <Button {...plansSref}
                    id="invite-continue-to-membership-plans"
                    className={`${classes.btn} clickable`}
                    color="primary"
                    variant="contained"
                >
                    {$state$.data.cta_btn}
                </Button>
            </Box>
}

export default DesktopCTA