import React, { useEffect } from 'react';
import {Drawer, Container, useMediaQuery, Fab} from '@material-ui/core';
import {useStateContextDrawer} from '../state/hooks/useStateContextDrawer';
import {closeContextDrawer} from '../state/actions'
import CloseIcon from '@material-ui/icons/Close';
// import IconButton from '@material-ui/core/IconButton';
import {dispatch } from 'slice';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';

const useStyles = tgMakeStyles(theme => ({
	wrapper: {
		position:'relative'
    },
    button : {
        position:"absolute", right:0,top:0,
        boxShadow: 'none'
    },
    paper : ({isDesktop}) => {
        const BASE_PAPER_STYLE = { 
            boxShadow: theme.shadows[10],
            // Notes : 
            // * css width makes sure the drawer is not adjust to content width
            // * using isDesktop is overkill since this component should not be mounted when mobile browsing
            // * ideally we'd like to use breakpoints api but width values do not fit what we want (xs = 0 and sm = 600)
            width: isDesktop ? 444 : 'initial'
        }
        return BASE_PAPER_STYLE;
    },
    // Based on DESKTOP MAP VIEW POI CARD specification.
    // and assumption that this padding should be applied to any injected components here 
    'container-root' : ({noPadding}) => ({
        padding : theme.spacing(noPadding ? 0 : 5.5)
    })
}))

const ContextDrawer = () => {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const contextDrawer = useStateContextDrawer()
    const {
        open,
        component,
        onCloseFn,
    } = contextDrawer
    const onCloseIconClick = () => {
        dispatch(closeContextDrawer())
    }
    const classes = useStyles({
        isDesktop,
        noPadding: contextDrawer.noPadding
    })

    // execute callback when drawer is closed.
    // NOTE : Use onClose property when material ui is updated to v4.9.0
    useEffect(()=>{
        if (!open){
            onCloseFn && onCloseFn()
        }
    // Set dependency lil bit 'loose' so that when whatever changes in contextDrawer can trigger onCloseFn.
    },[contextDrawer])

    const InjectedComp = component
    const _drawerContainer = window.document.getElementById('content-container')
    
    return (
        <Drawer 
            classes={{
                paper : classes.paper
            }}
            anchor='right'
            ModalProps={{
                container: _drawerContainer, // attach drawer to content div (see AppLayout component)
                style: {position: 'absolute'}, // by default position = fixed
                BackdropProps: {
                    // by default position = fixed
                    position: 'absolute'
                }, 
                hideBackdrop: true
            }}
            PaperProps={{ 
                style: { 
                    position: 'absolute', // by default position = fixed
                } 
            }}
            open={open}
            variant='persistent'>
                {contextDrawer && component ?
                    (   
                        <Container maxWidth="xs"
                            classes={{
                                root : classes['container-root']
                            }}>
                                <Fab type="button" 
                                    onClick={onCloseIconClick} 
                                    aria-label="reset" 
                                    className={classes.button}
                                    color="inherit"
                                >
                                    <CloseIcon />
                                </Fab>
                                {/* <IconButton type="button" 
                                    onClick={onCloseIconClick} 
                                    aria-label="reset" 
                                    className={classes.button}
                                    color="primary"
                                >
                                    <CloseIcon />
                                </IconButton> */}
                                <InjectedComp />
                        </Container>
                    )
                : null}
        </Drawer>
    );
}
 
export default ContextDrawer;