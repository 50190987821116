import React, {useState, useCallback} from 'react'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';

const useStyles = tgMakeStyles({
    root : {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
    }
})

export function GoogleMapUI({
    googleMaps,
    options,
    render,
    ...props
}){
    const classes = useStyles()
    const [_map, set_map] = useState(null);
    const div = useCallback(
        node => {
            // if node is defined, instanciate a new map for that node
            node && (set_map(new googleMaps.Map(node, options)))
        },
        [googleMaps.Map, options],
    )
    return (
        // the div containing the map 
        <div ref={div} className={classes.root}>
            {/* and a render function to run with the created google map instance (_map)*/}
            {_map && render(_map)}
        </div>
    )
}