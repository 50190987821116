import React from 'react'
import { hasAgreedDocument, openAgreementsConfirmationOverlay, submitOutOfDateAgreements } from '../../legal'
import { getBusiness, getBusinessPropertyVisibility } from '../../http/tg/business'
import { map, tap } from 'rxjs/operators'
import { $lice, dispatch } from 'slice'

import Breakpoints from '../../../lib/components/utils/Breakpoints'
import {BottomNavBar} from '../../navigation/components/business/MobileNavBar.BottomNavigation'
import {DesktopNavBar} from '../../navigation/components/business/DesktopNavBar'
import { adminBusinessDataReducer } from '../state/reducers'
import { BusinessDashboard } from '../components/dashboard/BusinessDashboard'
import EditBusinessProfileForm from '../components/profile/EditBusinessProfile.form'
import BusinessAdminSettings from '../components/settings/BusinessAdminSettings'
import { openConfirmationDialog } from '../../dialogs/confirmation/states/actions'
import { closeModal } from '../../dialogs/standard/state/actions'
import { setAdminBusinessData } from '../state/actions'
import WelcomeFooter from '../../footer/components/WelcomeFooterNew'
import { concat, forkJoin } from 'rxjs'

function getOutOfDateBusinessAgreements(){
    const tou = hasAgreedDocument('Terms of Use (ToU)')
    const pp = hasAgreedDocument('Privacy Policy (PP)')
    const a01A = hasAgreedDocument('Addendum - Business - 01A')
    const _agreements = []
    !tou && _agreements.push('Terms of Use (ToU)')
    !pp && _agreements.push('Privacy Policy (PP)')
    !a01A && _agreements.push('Addendum - Business - 01A')
    return _agreements
}

export const businessRoutes = [
    {
        name: 'root.business-console',
        url: '/business/:id',
        params: {
            id: {
                value: null
            }
        },
        abstract: true,
        onEnter: (t, s) => {
            const _outOfDate = getOutOfDateBusinessAgreements()
            return openAgreementsConfirmationOverlay(_outOfDate)
                .then(() => submitOutOfDateAgreements())
        },
        resolve: [
            {
                token: 'business',
                deps: ['$stateParams', '$transition$'],
                resolveFn: (params) => {
                    if(!params.id) return Promise.reject()
                    // getVisibility
                    return forkJoin([
                        getBusiness(params.id).pipe(
                            map(res => res.data)
                        ),
                        getBusinessPropertyVisibility(params.id).pipe(
                            map(res => res.data)
                        )
                    ]).pipe(
                        map(([biz,viz]) => {
                            function getPropViz(propName, viz){
                                if(!viz || viz.length === 0) return true // props are visible by default
                                
                                return !!viz[viz.findIndex(({property}) => property === propName)].visible
                            }
                            // setup data slice with initial values form API
                            $lice('tgadminbusiness', adminBusinessDataReducer, {
                                [biz.id]: {
                                    visibility: {
                                        website: getPropViz('website', viz),
                                        social: getPropViz('feed', viz),
                                        hours: getPropViz('openingHours', viz),
                                        about: getPropViz('about', viz)   
                                    },
                                    ...biz}
                            })
                            return biz
                        }),
                    ).toPromise()
                }
            }
        ],
        views: {
            'nav-bar@root': (props) => <Breakpoints 
                small={() => <BottomNavBar {...props}/>}
                medium={() => <DesktopNavBar {...props} />}
                large={() => <DesktopNavBar {...props} />}
            />
        }
    },
    {
        name: 'root.business-console.dashboard',
        url:'/home',
        views: {
            'content@root': {
                component: BusinessDashboard
            }
        }
    },
    {
        name: 'root.business-console.profile',
        url:'/profile',
        params: {
            saved: {
                value: true,
                dynamic: true
            },
            basic: {
                value: false,
                dynamic: true
            },
            operation: {
                value: false,
                dynamic: true
            },
            social: {
                value: false,
                dynamic: true
            },
            impressions: {
                value: false,
                dynamic: true
            }
        },
        views: {
            'content@root': {
                component: EditBusinessProfileForm
            }
        },
        onExit : (trans)=>{
            return new Promise((resolve,reject)=>{
                if(trans.params('from').saved){
                    return resolve()   
                } else {
                    dispatch(openConfirmationDialog({
                        title: 'LEAVING?',
                        text: 'You will lose any unsaved changes to your profile.',
                        answerOptions:[ 'Cancel', 'Leave'],
                        onConfirm : ()=>{
                            dispatch(closeModal())
                            dispatch(setAdminBusinessData(trans.injector().get('business')))
                            return resolve();
                        },
                        onDismissed : ()=>{
                            // abort transition.
                            trans.abort();
                            return resolve();
                        }
                    }))   
                }
            })
        }
    },
    {
        name: 'root.business-console.settings',
        url:'/settings',
        params: {
            upgrade: {
                value: false,
                dynamic: true
            }
        },
        views: {
            'content@root': {
                component: BusinessAdminSettings
            },
            'content-footer@root': {
                component: () => <WelcomeFooter fullScreen/>
            }
        }
    }
]