import React from 'react';
import { dispatch } from 'slice';

import DigitalMap from "../../../maps/digital-map/DigitalMap";
import {ErrorPageDiv} from '../../../error/components/ErrorPageDiv'
import Mixpanel from '../../../../lib/mixpanel';
import DesktopNavBar from '../../../navigation/components/guest/DesktopNavBar'
import MobileNavBar from '../../../navigation/components/guest/MobileNavBar.BottomNavigation'
import DigitalMapDrawer from '../../../maps/digital-map/base/components/DigitalMapDrawer';
import {GuestCookiesDisclaimerModal} from '../../../cookies/components/GuestCookiesDisclaimerModal'
import {Settings} from '../../../settings/components/Settings'
import { closeContextDrawer } from '../../../contextDrawer/state/actions';
import { getHotelById } from '../../../http/tg/hotels';
import { useMediaQuery } from '@material-ui/core';

import DigitalMapGuestUI from '../components/DigitalMapGuestUI';
import GmFeaturesAsList from '../components/GmFeaturesAsList';
import {GuestMapHomeComp} from '../components/GuestMapHomeComp'
import StandAloneGoogleMap from '../../../../lib/google-map/components/GoogleMap/StandaloneGoogleMap';

export const GUESTMAP_PARENT_STATE_NAME = 'root.hotel-map';

export default function guestRoutes(router) {
    trackGuestConsole(router)
    return [
        {
            // this url is shared via QR code
            name: 'root.qr-map',
            url: '/qrdmap/:hotelId?method&customMapId',
            params: {
                hotelId: {type: 'int'},
                customMapId: {type: 'int', value: 0},
                method : {type:'string'}
            },
            data: {public: true},
            // and it immediately redirects to a more branded and readable url 
            redirectTo: trans => {
                // here we get the hotel data, 
                // in the future, we could have an endpoint that returns a map data (with associated hotelName) if given a hotelId
                return getHotelById(trans.params().hotelId)
                    .toPromise()
                    .then(res => {
                        const hotel = res.data;
                        return {
                            state: 'root.hotel-map.map',
                            params: {
                                hid: hotel.id,
                                cid: trans.params().customMapId,
                                // only keep letters and digits. to create a readable url
                                hotelname: hotel.name.replace(/[^\w\d]/gi,''),
                                hotelData: hotel
                            }
                        }
                    })
                    .catch(err => {
                        trans.abort()
                        // and transition to an error page
                        trans.router.stateService.go('root.hotel-map.error',{error: err})
                    })
                    .finally(() => {
                        Mixpanel('register')({map_method: trans.params().method ? trans.params().method : 'print'})
                    })
            }
        },
        {   // root.hotel-map
            name: GUESTMAP_PARENT_STATE_NAME,
            url: '/hotel/:hotelname?:hid&:cid',
            abstract: true,
            data: {public: true},
            views: {
                'header@root': p => null,
                'content-footer@root': p => null,
                'nav-bar@root': GuestNavbar
            },
            params: {
                hotelData: null,
                hotelname: {
                    type: 'string'  
                },
                hid: {type: 'int'}, // hotelId can be used to fetch hotel info and the standard POIs if hotelData is empty
                cid: { // custom map id can be used to fetch the custom POI
                    type: 'int',
                    value: 0,
                    squash: true
                }
            },
            resolve: [{
                token: 'hotel',
                deps: ['$stateParams', '$transition$'],
                resolveFn: ({hotelData, hid}, trans) => {
                    if(hotelData){return hotelData}
                    else {
                        return getHotelById(hid)
                        .toPromise()
                        .then(res => {
                            const hotel = res.data;
                            return hotel
                        })
                        .catch(err => {
                            trans.abort()
                            // and transition to an error page
                            trans.router.stateService.go('root.hotel-map.error',{error: err})
                        })
                    }
                }
            }]
        },
        {   // root.hotel-map.home
            name: `${GUESTMAP_PARENT_STATE_NAME}.home`,
            url: '/home',
            views: {
                'content@root': props => (
                    <>
                        <GuestMapHomeComp {...props}/>
                        <GuestCookiesDisclaimerModal hotelData={props.hotel}/>
                    </>
                )
            }
        },
        {   // root.hotel-map.map
            // this state can be shared as a URL (copy paste)
            // this is the url the guests will see, it includes the hotel name ;) 
            name: `${GUESTMAP_PARENT_STATE_NAME}.map`,
            url: '/map?list',
            params: {
                list: {
                    type: 'bool',
                    value: false,
                    dynamic: true,
                    squash: true,
                    inherit: true
                }
            },
            data: {public: true},
            onExit: function() {
                dispatch(closeContextDrawer())
            },
            views: {
                'content@root': {
                    component: HotelGuestMap
                }
            }
        },
        {   // root.hotel-map.home
            name: `${GUESTMAP_PARENT_STATE_NAME}.setting`,
            url: '/setting',
            views: {
                'content@root': props => (
                    <>
                        <Settings {...props}/>
                        <GuestCookiesDisclaimerModal hotelData={props.hotel}/>
                    </>
                )
            }
        },
        {   // root.hotel-map.error
            // this state displays an error message to the user attempting to navigate to a digital map
            name: `${GUESTMAP_PARENT_STATE_NAME}.error`,
            url: '/error',
            params: {
                error:null
            },
            data: {public: true},
            views: {
                'content@root': {
                    component: ({$stateParams}) => {
                        let errorObj = {}
                        if($stateParams.error && $stateParams.error instanceof Error) {
                            errorObj = {
                                dispErrorCode : ($stateParams.error.response ? $stateParams.error.response.status : null)
                            }
                        } 
    
                        return (<ErrorPageDiv tgErrorCode="DIGITAL_MAP_ERROR" errorObj={errorObj}/>)
                    }
                }
            }
        }
    ]
}

function HotelGuestMap({hotel, $stateParams}){
    return (
        <StandAloneGoogleMap
            center={hotel}
            render={(googleMapInstance) => {
                const DmapProps = {
                    map: googleMapInstance, 
                    hotel, 
                    hotelMapId: $stateParams.cid
                }

                const ListProps = {
                    map: googleMapInstance, 
                    hotel, 
                    hotelMapId: $stateParams.cid
                }
                return (
                    <>
                        <GuestCookiesDisclaimerModal hotelData={hotel}/>
                        <DigitalMapGuestUI {...DmapProps} />
                        <DigitalMapDrawer {...ListProps}
                            ContentComponent={GmFeaturesAsList}
                        />
                    </>
                )
            }}
        />
    )
}

export function trackGuestConsole(router){
    // register guest map super props
    router.transitionService.onEnter(
        {entering: 'root.hotel-map'}, 
        trans => {
            Mixpanel('register')({
                platform: 'guest',
                map_type: trans.params().cid ? 'custom' : 'standard',
                hotel_id: trans.params().hid
            })
            trans.params().cid 
                ? Mixpanel('register')({map_id: trans.params().cid})
                : Mixpanel('unregister')('map_id')
            // if entering this state without hotelData, it means we are not coming from qr-map. So the method of sharing is unknown
            if(trans.params().hotelData === null){
                Mixpanel('register')({map_method: 'unknown'})
            }
        }
    )

    // register map vs grid super prop
    router.transitionService.onEnter(
        {entering: 'root.hotel-map.map'},
        trans => {
            // console.log('enter', trans.params())
            Mixpanel('register')({poi_view: trans.params().list ? 'grid' : 'map'});
        }
    )
    router.transitionService.onRetain(
        {retained: 'root.hotel-map.map'},
        trans => {
            // console.log('retain', trans.params())
            Mixpanel('register')({poi_view: trans.params().list ? 'grid' : 'map'});
        }
    )

    // clear super prop on exit
    router.transitionService.onExit(
        {exiting: 'root.hotel-map'},
        () => {
            console.log('exit map')
            Mixpanel('unregister')('poi_view');
            Mixpanel('unregister')('map_type');
            Mixpanel('unregister')('map_id');
            Mixpanel('unregister')('map_method');
            Mixpanel('unregister')('hotel_id');
        }
    )
}

function GuestNavbar(props){
    const {hotel} = props
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    return (
        <>
            { isDesktop
                ? <DesktopNavBar hotel={hotel}/>
                : <MobileNavBar />
            }
        </>
    )
}