export const setContextDrawer = ({
    component,
    onCloseFn, 
    variant=null, 
    fullScreen=false,
    noPadding=false
}) => {
    return {
        type : 'CONTEXT_DRAWER_SET_COMPONENT',
        payload : {
            component,
            open : true,
            onCloseFn,
            variant,
            fullScreen,
            noPadding
        }
    }
} 
export const unsetContextDrawer = () => {
    return {
        type : 'CONTEXT_DRAWER_UNSET_COMPONENT',
        // note : payload is unnecessary here ?
        payload : {
            component : null,
        }
    }
} 
export const openContextDrawer = () => {
    return {
        type : 'CONTEXT_DRAWER_OPEN',
        payload : {
            open : true
        }
    }
} 
export const closeContextDrawer = () => {
    return {
        type : 'CONTEXT_DRAWER_CLOSE',
        payload : {
            open : false
        }
    }
} 