import {dispatch} from 'slice';
import BusinessProfilePageAsync from '../../../explore/components/place-profile/BusinessProfilePageAsync'
import { closeContextDrawer } from '../../../contextDrawer/state/actions'
import SearchResultsPage from '../../../explore/components/SearchResultsPage';

export const hotelConsoleSearchRoutes = [
    {
        name: 'root.hotel-console.search-results',
        url: '/search?q',
        params: {
            q: {
                type: 'query',
                dynamic: true
            }
        },
        onExit: function(){
            dispatch(closeContextDrawer())
        },
        views: {
            'content@root': SearchResultsPage
        }
    },
    {
        name: 'root.hotel-console.search-results.business',
        url: '/business/:id',
        params: {
            id: {
                type: 'string'
            }
        },
        views: {
            'content@root': BusinessProfilePageAsync
        }
    }
]

export default hotelConsoleSearchRoutes