import {useState, useEffect} from 'react';
import { $lice } from 'slice';
import { cookiesRootReducer, INITIAL_STATE, TG_CONSENT_KEY, TG_GUEST_CONSENT_KEY } from '../reducers';

const [cookiesConsent$] = $lice('tgcookiesconsent', cookiesRootReducer, INITIAL_STATE)

export const useStateCookiesConsent = () => {
    const [hasAgreed, setHasAgreed] = useState(0)
    useEffect(() => {
        let sub = cookiesConsent$.path('consent').subscribe(c => setHasAgreed(c[TG_CONSENT_KEY]))
        return () => {
            sub.unsubscribe()
        }
    }, [])
    return hasAgreed
}

export const useStateGuestCookiesConsent = () => {
    const [hasAgreed, setHasAgreed] = useState(0)
    useEffect(() => {
        let sub = cookiesConsent$.path('consent').subscribe(c => setHasAgreed(c[TG_GUEST_CONSENT_KEY]))
        return () => {
            sub.unsubscribe()
        }
    }, [])
    return hasAgreed
}