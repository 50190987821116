import { useState } from 'react'
import { useEffect } from 'react'
import { getHotelMapById, getPublicHotelMapById } from '../../http/tg/hotels'
import { from, of } from 'rxjs'
import { switchMap, map as mapOperator, map } from 'rxjs/operators'
import { jsonToGeojsonAsync } from '../../../lib/files'

export function useHotelMapAsync(
    hotelMapID,
    options
){
    // const hotelMap = useHotelMapById
    const [hotelMap, setHotelMap] = useState(null)
    useEffect( () => {
        if(!hotelMapID) return ()=>{}
        let hotelMap$ = getHotelMapById(hotelMapID, options)
        let sub = hotelMap$
            .pipe(
                map(res => res.data)
            )
            .subscribe({
                next: hmap => {setHotelMap(hmap)},
                error: err => {console.log('error fetching map', err)}
            })
        
        return () => {sub.unsubscribe()}
    }, [hotelMapID, options])

    return hotelMap
}

export function usePublicHotelMapAsync(
    hotelMapID, 
    hotelID,
    translate
){
    // const hotelMap = useHotelMapById
    const [hotelMap, setHotelMap] = useState(null)
    useEffect( () => {
        // if(!hotelMapID || !hotelID) return ()=>{}
        if(!hotelID) return ()=>{}
        let hotelMap$ = getPublicHotelMapById(hotelMapID, hotelID, translate)
        let sub = hotelMap$
            .pipe(
                map(res => res.data)
            )
            .subscribe({
                next: hmap => {setHotelMap(hmap)},
                error: err => {console.log('error fetching map', err)}
            })
        
        return () => {sub.unsubscribe()}
    }, [hotelMapID, hotelID, translate])

    return hotelMap
}

export function useHotelPlacesToGeojson(p){
    const [geojson, setGeojson] = useState()
    useEffect( () => {
        if(!p) return () => {}
        if(p.length === 0){
            setGeojson({})
            return () => {}
        }
        
        let sub = of(p)
            .pipe(
                switchMap(json => from(jsonToGeojsonAsync(json)).pipe(
                    // mapshaper returns an object = {[fileName] : <geoJson data>}
                    mapOperator(geojson => ({
                        // some mapping shenanigans
                        name: Object.entries(geojson)[0][0],
                        object: JSON.parse(Object.entries(geojson)[0][1].toString())
                    }))
                ))
            ).subscribe({
                next: geojson => {setGeojson(geojson)},
                error: err => {console.log(err)}
            })
        return () => {sub.unsubscribe()}
    }, [p])

    return geojson
}