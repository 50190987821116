import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { TG_API } from '../'
import axios from 'axios'

const BASE_URL = '/misc/autocomplete'
const LDM_BASE_URL = '/ldm/place/autocomplete'

const callAutocompleteAPI = (params,cancelTokensrc) => {

    const req = {
        method: 'get',
        url: LDM_BASE_URL,
        params,
        cancelToken : cancelTokensrc ? cancelTokensrc.token : null
    }

    return from(TG_API(req))
}


export const autocomplete = ({ searchStr, page, per_page=10, center, hotelId, type }) => {

    // console.table('autocomplete', { searchStr, page, per_page, center, hotelId, type })
    const cancelTokensrc = axios.CancelToken.source();

    if (searchStr.length === 0){
        return [
            from(Promise.resolve({
                results:[]
            })),
            cancelTokensrc
        ]
    }

    const API$ = callAutocompleteAPI({
        q: searchStr,
        hotelId,
        center,
        page,
        per_page,
        // type : type && type instanceof Array ? type.toString() : null
    },cancelTokensrc).pipe(
        map(res => {
            return {
                results: res.data
            }
        })
    )

    return [API$,cancelTokensrc]
}