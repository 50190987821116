export function tracker(methodPath){
    try {
        switch (methodPath) {
            case 'register':
                return registerSuper
            case 'unregister':
                return unregisterSuper
            case 'reset':
                return reset
            case 'people.set':
                return registerUser
            case 'people.set_once':
                // return console.log.bind(0, 'people')
                return ()=>{}
            case 'identify':
                return identify
            case 'track':
                return trackEvent
            case 'form':
                return dlvFormData
             default:
                // return console.log.bind(0, 'default')
                return ()=>{}
        }   
    } catch (error) {
        console.log(error)
    }
}
export default tracker

function dlvFormData(formId, formData){
    window.dataLayer =  window.dataLayer || []
    let _message = {
        [`tg.forms.${formId}`]: {
            last_updated: (new Date()).toLocaleString(),
            ...formData
        }
    }
    window.dataLayer.push(_message)
}

function registerSuper(props){
    
    window.dataLayer =  window.dataLayer || []
    let _message = {}
    for(let key in props){
        _message[`tg.super.${key}`] = props[key]
    }
    window.dataLayer.push(_message)
}
function unregisterSuper(key){
    window.dataLayer =  window.dataLayer || []
    window.dataLayer.push({[`tg.super.${key}`]: undefined})
}

function reset(){
    window.dataLayer =  window.dataLayer || []
    window.dataLayer.push({'tg.super': {}})
    window.dataLayer.push({'tg.user': {}})
}

function trackEvent(eventName, props){
    window.dataLayer =  window.dataLayer || []
    let _eventMessage = {
        event: `tg.${eventName}`,
        'tg.props': props
    }
    window.dataLayer.push(_eventMessage)
}

function identify(userID){
    window.dataLayer =  window.dataLayer || []
    window.dataLayer.push({[`tg.user.user_id`]: userID})
    window.dataLayer.push({
        event: 'tg.identify',
        [`tg.user.user_id`]: userID
    })
}

function registerUser(props){
    window.dataLayer =  window.dataLayer || []
    let _message = {}
    for(let key in props){
        _message[`tg.user.${key}`] = props[key]
    }
    window.dataLayer.push(_message)
}