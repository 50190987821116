export function addGeoJsonPolygonsByURL(layerName, url, DataConstructorOptions){
    return {
        type: 'MAP/OVERLAYS/GEOJSON/ADD',
        payload: {
            name: layerName,
            url: url,
            DataOptions: DataConstructorOptions
        }
    }
}

export function seedGeoJsonDataWith(layerName, geoJsonObject, DataConstructorOptions, source){
    return {
        type: 'MAP/OVERLAYS/GEOJSON/SEED',
        payload: {
            name: layerName,
            geojson: geoJsonObject,
            DataOptions: DataConstructorOptions,
            source
        }
    }
}

export function addGeoJsonCollection(layerName, geoJsonObject, DataConstructorOptions, source){
    return {
        type: 'MAP/OVERLAYS/GEOJSON/ADD',
        payload: {
            name: layerName,
            geojson: geoJsonObject,
            DataOptions: DataConstructorOptions,
            source
        }
    }
}

/**
 * Reflect values of sourceObj on feature.
 * @param {String} layerName // name of the data layer
 * @param {Any} featureId // id of target feature 
 * @param {Object} sourceObj // source object which should be reflected on feature properties 
 */
export function updateGeoJsonOverlay(layerName,featureId,sourceObj){
    return {
        type: 'MAP/OVERLAYS/GEOJSON/UPDATE',
        payload: {
            layerName,
            featureId,
            sourceObj
        }
    }
}

export function toggleGeoJsonOverlay(idx){
    return {
        type: 'MAP/OVERLAYS/GEOJSON/TOGGLE',
        payload: {
            index: idx
        }
    }
}

export function assignHighlightedFeature(FeatureInstance){
    return {
        type: 'HIGHLIGHTED/FEATURE/ASSIGN',
        payload: FeatureInstance
    }
}

export function unassignHighlightedFeature(){
    return {
        type: 'HIGHLIGHTED/FEATURE/UNASSIGN',
    }
}

export function removeFilesFromMap(){
    return {
        type: 'MAP/OVERLAYS/GEOJSON/RESET',
    }
}

// export function apiAddFeatures(layerName, gmFeatures, DataConstructorOptions){
//     return {
//         type: 'MAP/OVERLAYS/API/ADD',
//         payload: {
//             name: layerName,
//             gmFeatures,
//             DataOptions: DataConstructorOptions
//         }
//     }
// }

// export function apiToggle(idx){
//     return {
//         type: 'MAP/OVERLAYS/API/TOGGLE',
//         payload: {
//             index: idx
//         }
//     }
// }
// export function compareRemoveAll(){
//     return {
//         type: 'COMPARE/REMOVE/ALL'
//     }
// }
// export function compareAddFeature(FeatureInstance){
//     return {
//         type: 'COMPARE/ADD/ITEM',
//         payload: FeatureInstance
//     }
// }
// export function apiAddRestaurants(){
//     return () => {
//         getRAsFeatures().subscribe(
//             features => {dispatch(apiAddFeatures(
//                 'restaurants',
//                 features,
//                 {style: {icon: getCircleIconStyle('green')}}
//             ))}
//         )
//     }
// }
// export function apiAddLandmarks(){
//     return () => {
//         getLAsFeatures().subscribe(
//             features => {dispatch(apiAddFeatures(
//                 'landmarks',
//                 features,
//                 {style: {icon: getCircleIconStyle('orange')}}
//             ))}
//         )
//     }
// }
// export function apiAddServices(){
//     return () => {
//         getSAsFeatures().subscribe(
//             features => {dispatch(apiAddFeatures(
//                 'services',
//                 features,
//                 {style: {icon: getCircleIconStyle('blue')}}
//             ))}
//         )
//     }
// }