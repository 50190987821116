import { Box, Button, Collapse, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { useTypographyStyles } from '../../../modules/theme/useTypography';
import { useEffect } from 'react';
import { tgMakeStyles } from '../../styles/TgMakeStyles';

const useStyles = tgMakeStyles(theme => ({
    subtext: {
        paddingLeft: theme.spacing(5)
    }
}))

export const TgTextBox = ({
    boxProps,
    TextFieldProps,
    text,
    placeholder,
    headerText,
    onChange,
    textHeaderProps
}) => {
    return (
        <Box {...boxProps}>
            <Box pb={1}>
                <Typography
                    align='left'
                    {...textHeaderProps} >{headerText}</Typography>
            </Box>
            <TextField
                defaultValue={text}
                value={text}
                onChange={e=>{onChange && onChange(e.currentTarget.value)}}
                placeholder={placeholder}
                type='text'
                multiline
                fullWidth
                variant='outlined'
                color='primary'
                {...TextFieldProps}/>
        </Box>
    )
}

export const CollapsableTextField = ({
    defaultValue,
    label,
    subtext,
    ...props
}) => {

    const classes = useStyles()
    const typo = useTypographyStyles();
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        // Update open to true only for the first time 
        if (!open && props.text && props.text.length > 0) setOpen(true)
    },[props.text])

    return (
        <>
            <Collapse in={Boolean(defaultValue || open)}>
                <Box mb={1}>
                    <Typography className={`${typo.h3}`} style={{marginBottom: '0.25rem'}}>
                        {label}
                    </Typography>
                    {subtext && <Typography className={`${typo.body2}`}>
                        <i>{subtext}</i>
                    </Typography>}
                </Box>
                <TextField
                    variant="outlined"
                    fullWidth={true}
                    {...props}
                    defaultValue={defaultValue}
                />
            </Collapse>
            <Collapse in={!(defaultValue || open)}>
                <Box textAlign="left">
                    <Button
                        startIcon={<AddIcon color='primary' />}
                        onClick={()=>{setOpen(true)}}
                        classes={{
                            label: `${typo.h3}`
                        }}
                         style={{
                             marginBottom: '0.25rem',
                             marginLeft: 0,
                             marginRight: 0,
                             paddingLeft: 0,
                             textAlign: 'left'
                        }}
                    >
                            {label}
                    </Button>
                    {subtext && <Typography className={`${typo.body2} ${classes.subtext}`}>
                        <i>{subtext}</i>
                    </Typography>}
                </Box>
            </Collapse>
        </>
    )
}