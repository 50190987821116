import React, { useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useRouter } from '@uirouter/react'

import Mixpanel from '../../../../lib/mixpanel'

import { openFeatureInContextDrawer } from '../../../maps/digital-map/listeners/openFeatureInContextDrawer'
import { useGeoJsonListeners } from '../../../maps/digital-map/hooks/useEffectSetGeoJsonListeners'
import { useHotelMarker } from '../../../maps/digital-map/base/hooks/useHotelMarker'

import { useEditHotelMapToolbar } from '../hooks/useConciergeToolbar';
import { doHoverFeature } from '../../../maps/digital-map/listeners/doHoverFeature'
import { undoHoverFeature } from '../../../maps/digital-map/listeners/undoHoverFeature'
import { useEffect } from 'react'
import { useEditHotelMap } from '../hooks/useEditHotelMap'
import { useGeojsonToGoogleMapData } from '../../../maps/digital-map/base/hooks/useGeojsonToGoogleMapData'
import { useTheme } from '@material-ui/styles'
import EditHotelMapPlaceForm from './EditHotelMapPlaceForm'
import { dispatch } from 'slice'
import { editHotelPlace } from '../state/editHotelMapReducer'
import { closeContextDrawer } from '../../../contextDrawer/state/actions'
import { useHotelMapAsync, useHotelPlacesToGeojson } from '../../hooks'
import { PLANS_PLAIN_TEXT } from '../../../invitation/utils'
import { isPremium } from '../../../business/utils'

/**
 * rendered component that uses google map instance and digital map metadata to implement
 * UI logic relevant to concierges only
 */
export function EditHotelMapGoogleMapUI({
    map,
    hotel,
    customMapId
}) {

    const router = useRouter()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const theme = useTheme()

    const hotelMap = useHotelMapAsync(customMapId, hotel.id)
    
    const [editHotelMapState] = useEditHotelMap(hotelMap)
    // console.log('editHotelMapState', editHotelMapState)
    const _hotelPlaces = editHotelMapState && editHotelMapState.tgt
        ? editHotelMapState.tgt.hotelPlaces
        : null
    const geojson = useHotelPlacesToGeojson(_hotelPlaces)

    useGeojsonToGoogleMapData(geojson, {
        map,
        styleFeatureFn: styleFeatureFunction(editHotelMapState && editHotelMapState.tgt && editHotelMapState.tgt.ordered, theme),
        // MuiTheme: theme
    })
    // hotel console map uses the tool bar :
    // primary action is AddPOI by default
    // secondary action is the toggle between grid and list
    useEditHotelMapToolbar({
        map,
        customMapId,
        isDesktop,
        router,
        hotel
    })

    useGeoJsonListeners({
        map,
        listeners: {
            mouseover: doHoverFeature,
            mouseout: undoHoverFeature,
            click: onClickPin()
        }
    })
    
    // add hotel marker(logo) and pin (for exact location)
    useHotelMarker({
        map,
        hotel
    })

    return null
}
export default EditHotelMapGoogleMapUI

function styleFeatureFunction(isOrderedMap, theme){
    const ICONS_FONT_MAP = {
        bar: "\ue540",
        dining: "\ue561",
        cafe:  "\ue541",
        store: "\ue8d1",
        landmark: "\ue84f"
    }
    return function(feature){
        // the styling function used when instantiating the google.maps.Data object /layer
        const isHighlighted = feature.getProperty('highlighted');
        const isHovered = feature.getProperty('hovered');
        const business = feature.getProperty('place')
        const _isPremium = isPremium(business)
            
        const place = feature.getProperty('place').place
        const mapIcon = _isPremium 
            ? place.attributes && place.attributes.mapIcon
                ? place.attributes.mapIcon
                : 'default'
            : 'default'
        // console.log(mapIcon)

        const _circleIcon = {
            path: 0, // path: window.google.maps.SymbolPath.CIRCLE,
            strokeWeight: 0,
            scale: !isHighlighted ? 18 : 22,
            // properties below change when Feature is highlighted and/or hovered
            fillOpacity: 1,
            fillColor: !isHighlighted && !isHovered
                // default
                ? theme.palette.primary.main
                // highlighted OR hovered
                : isHovered
                    ? theme.palette.primary.dark
                    : 'black'
        }
        const _circleIconSmall = {
            ..._circleIcon,
            scale: isHovered 
                ? 12
                : !isHighlighted 
                    ? 10 
                    : 13,
        }
        return {
            title: feature.getProperty('name'),
            label: isOrderedMap 
                ? {
                    text: feature.getProperty('order'),
                    color: 'white',
                    fontSize: '1rem',
                    fontWeight: '700'
                } 
                // : void 0,
                : mapIcon !== "default" 
                    ? {
                        text: ICONS_FONT_MAP[mapIcon],
                        fontFamily: "Material Icons",
                        color: "#ffffff",
                        fontSize: "18px",
                    }
                    : void 0,
            icon: isOrderedMap
                ? _circleIcon
                : mapIcon === "default"
                    ? _circleIconSmall
                    : _circleIcon,
            zIndex: isHovered 
                ? 3
                : isHighlighted
                    ? 2
                    : 1
        }
    }
}

function onClickPin(){
    return (onFeatureClickEvent,feature) => {

        // track click event 
        if (onFeatureClickEvent) onFeatureClickEvent.stop();
        const _feature = onFeatureClickEvent ? onFeatureClickEvent.feature : feature
        const eventProps = {
            GooglePlaceID: _feature.getProperty('googlePlaceId'),
            poiName: _feature.getProperty('name')
        }
        Mixpanel('track')('click_poi', eventProps)

        // show feature details in card
        openFeatureInContextDrawer({
            Component: EditGMFeatureAsHotelPlace,
            fullScreen: true,
            noContainerPadding: false
        })(onFeatureClickEvent,feature)
    }
}

function EditGMFeatureAsHotelPlace({
    feature,
    onDrawerClose
}){
    // const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));

    const [hotelPlace, setHotelPlace] = useState()
    useEffect( () => {
        feature.toGeoJson(geojson => {
            setHotelPlace(geojson.properties)
        })
        return ()=>{}
    }, [feature])

    const onSuccessEdit = (editedHotelPlace) => {
        dispatch(editHotelPlace(editedHotelPlace))
        onDrawerClose()
        dispatch(closeContextDrawer())
    }
    
    return (
        <EditHotelMapPlaceForm
            hotelPlace={hotelPlace}
            onContinue={onSuccessEdit}
        />
    )
}