import React from 'react'
import LoginForm from './LoginForm';
import useStateAuthStatus from '../state/hooks/useStateAuthStatus';
import { dispatch } from 'slice';
import { doLogout } from '../state/actions';
import { Link, useMediaQuery, Box } from '@material-ui/core';
import  OpenModalLink  from '../../dialogs/standard/components/OpenStandardDialog.Link';
import { useTypographyStyles } from '../../theme/useTypography';

export default function LoginLogoutLink(props) {
    const {BoxProps, ...forwardedProps} = props
    const typography = useTypographyStyles()
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const handleLogout = e => {
        dispatch(doLogout())
    }
    const {isAuthenticated} = useStateAuthStatus()
    return (
        <Box {...BoxProps}>
            {isAuthenticated
            ? (
                <Link {...forwardedProps}
                    id={`${forwardedProps.id || 'authentication-link'}-logout`}
                    color='inherit'
                    onClick={handleLogout}
                    className={`${forwardedProps.className || ''} ${typography.body1} clickable`}
                >
                    Log Out
                </Link>  
            )
            : (
                <OpenModalLink {...forwardedProps}
                    id={`${forwardedProps.id || 'authentication-link'}-login`}
                    modalTitle="Login to TasteGods"
                    modalFullScreen={isDesktop ? false : true}
                    modalComponent={LoginForm}
                    color='inherit'
                    className={`${forwardedProps.className || ''} ${typography.body1} clickable`}
                >
                    Log In
                </OpenModalLink>
            )}
        </Box>
    )
}
