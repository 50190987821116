import React from 'react'
import WelcomeDiv from "../components/WelcomeDiv";
import { WelcomeFooter } from '../../footer/components/WelcomeFooterNew';

const welcomeRoutes = [
    {
        name: 'root.welcome',
        url: '/welcome',
        data: {public: true},
        views: {
            'content': { // no need to be explicit (content@root) since root is state's parent (root.welcome)
                component: WelcomeDiv
            },
            'content-footer@root': { // custom footer for welcome page
                component: () => <WelcomeFooter fullScreen={true}/>
            },
            'nav-bar@root': {
                component: () => null
            }
        }
    },
    // State to redirect to welcome state to avoide 404 error 
    {
        name: 'root.origin',
        url: '/',
        redirectTo: () => "root.welcome"
    }
]

export default welcomeRoutes;