import { useState, useEffect } from 'react';
import { $lice } from 'slice';
import { INITIAL_STATE as initialUserState, userRootReducer } from '../reducers';

export {
    useStateUserHotels
}

const [user$] = $lice('tguser', userRootReducer, initialUserState)
function useStateUserHotels(){
    const [hotels, setHotels] = useState([])
    useEffect(() => {
        let sub = user$.path('details.info.hotels').subscribe(function(n){
            setHotels(n)
        })
        return () => {sub.unsubscribe()}
    }, [])

    return hotels
}