import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles";
import { TYPOGRAPHY_STYLES } from "../../../../theme/typographies";

export const useStyles = tgMakeStyles(theme => {
    return {
        section: {
            marginBottom: '5rem'
        },
        welcomeCircle: {
            color: '#0E0E32'
        },
        networkImg: {
            width: '100%',
            height: 'auto',
            padding: '0 1rem 0 1rem'
        },
        grid1: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: '0% 20% repeat(2, auto) 0%',
            gridTemplateRows: '15vw repeat(5, auto)',
            gridGap: '.5rem',
            '& > *:nth-child(1)': {
                gridArea: '1/3/span 2/span 2'
            },
            '& > *:nth-child(2)': {
                gridArea: '2/2/span 3/span 2',
                // zIndex: 1
            },
            '& > *:nth-child(3)': {
                gridArea: '4/3/span 2/span 2'
            },
            '& > *:nth-child(4)': {
                gridArea: '6/3/span 1/span 2'
            },
            '& > *:nth-child(6)': {
                gridArea: '2/3/-1/-1'
            }
        },
        blue1: {
            backgroundColor: '#292952',
            width: '50vw',
            height: '50vw',
            borderRadius: '50%'
        },
        profileGif: {
            width: '50vw',
            height: 'auto',
            backgroundColor: 'white',
            padding: '.5rem',
            borderRadius: '2.3rem !important'
        },
        infoExample: {
            borderRadius: '1rem',
            width: '100%',
            width: 'calc(100% - .5rem)',
            padding: '1rem',
            textAlign: 'center',
            '& > *': {
                ...TYPOGRAPHY_STYLES.body2
            }
        },
        
        firstImpressionImgContainer: {
            display: 'grid',
            gridTemplateColumns: '35% 23% 42%',
            gridTemplateRows: 'repeat(3, auto)',
            padding: '0 1rem 0 1rem',
            '& > img': {
                width: '100%',
                height: 'auto'
            },
            '& img:nth-child(1)': {
                gridArea: '3 / 1 / span 2 / span 2',
                border: '1px solid grey',
                borderRadius: '50%'
            },
            '& img:nth-child(2)': {
                gridArea: '1 / 2 / span 3 / -1'
            }
        },
        firstImpressionIcons: {
            '& > *': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '6.5rem',
                height: '6.5rem',
                color: 'white',
                backgroundColor: '#292952',
                padding: '1rem .5rem 1rem .5rem',
                borderRadius: '1rem'
            }
        },
        eventsImgContainer: {
            padding: '0 1rem 0 1rem',
            overflow: 'visible',
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: '1fr 15% min-content',
            gridTemplateRows: 'auto auto 5vh',
            '& > div:nth-child(1)': {
                gridArea: '2 / 1 / span 1 / span 2'
            },
            '& > div:nth-child(1) > img': {
                width: '100%',
                height: '100%'
            },
            '& > img:nth-child(2)': {
                gridArea: '1 / 2 / -1 / -1',
                width: '50vw',
                height: 'auto'
            }
        },
        hotelImgContainer:  {
            padding: '0 1rem 0 1rem',
            overflow: 'visible',
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: '40% 20% 20% 20%',
            gridTemplateRows: 'repeat(4, auto)',
            rowGap: '.5rem',
            '& > img': {
                width: '100%',
                height: 'auto'
            },
            '& > img:nth-child(1)': {
                gridArea: '1 / 3 / span 1 / span 2',
                position: 'relative',
                top: '-2rem'
            },
            '& > img:nth-child(2)': {
                gridArea: '1 / 1 / span 3 / span 3',
                zIndex: 2
            },
            '& > img:nth-child(3)': {
                gridArea: '3 / 2 / span 2 / -1',
                zIndex: 3
            },
            '& > img:nth-child(4)': {
                gridArea: '2 / 2 / span 1 / -1',
                zIndex: 3
            },
            
        }
    }
})

export default useStyles