import { useState, useEffect } from 'react';
import { INITIAL_STATE, confirmationReducer } from '../reducers';
import { $lice } from 'slice';

const [confirmation$] = $lice('confirmationDialog', confirmationReducer, INITIAL_STATE);

export function useStateConfirmation(){
    const [confirmation, setConfirmation] = useState(INITIAL_STATE)
    useEffect(() => {
        let sub = confirmation$.subscribe(setConfirmation)
        return () => {
            sub.unsubscribe()
        };
    }, [])
    return confirmation
}