import axious from 'axios';
import { searchPoiPerPage } from '../../../http/tg/poiSearch';

// By injecting dispatch from templete files, we can encupselize the actions logic in one place and 
// use states to be in non-singleton fashion.
export const searchByKeyword = ({ searchStr, page, per_page, hotelId }, dispatch) => {
    const [api$, cancelToken] = searchPoiPerPage({ searchStr, page, per_page, hotelId });
    // set cancelToken in the state. Cancelation is managed in hooks.
    dispatch(attemptSearchByKeyword(cancelToken))

    api$
    .subscribe({
        next: (res) => {
            dispatch(receiveSearchByKeyword(res))
        },
        complete: () => {
            dispatch(completeGetSearchByKeyword())
        },
        error: (e) => {
            // ignore if the thrown error is cancel
            if (!axious.isCancel(e)){
                dispatch(errorOnGetSearchByKeyword(e))
                dispatch(completeGetSearchByKeyword())
            }
        }
    })
}

export const attemptSearchByKeyword = cancelToken => {
    return {
        type: 'ATTEMPT_SEARCH_BY_KEYWORD',
        payload: {
            loading: true,
            cancelToken
        }
    }
}

export const errorOnGetSearchByKeyword = error => {
    return { 
        type: 'ERROR_ON_SEARCH_BY_KEYWORD',
        payload : {
            error
        }
    }
}

export const receiveSearchByKeyword = ({ results, metaData }) => {
    return {
        type: 'RECEIVE_SEARCH_BY_KEYWORD_RESULTS',
        payload: {
            results,
            metaData
        }
    }
}

export const completeGetSearchByKeyword = () => {
    return {
        type: 'COMPLETE_SEARCH_BY_KEYWORD',
        payload: {
            loading: false
        }
    }
}