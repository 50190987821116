import React, { useEffect } from 'react'
import Mixpanel from '../../../../lib/mixpanel'
// import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import PPpdf from '../../../../assets/pdf/legal/TG Privacy Policy.pdf'
import { PdfArticle } from './PdfArticle'

export function PrivacyPolicyArticle(props) {

    useEffect(() => {
        Mixpanel('track')("impression_privacy", props);
    }, [])

    return <PdfArticle file={PPpdf} filename="TG Privacy Policy.pdf"/>
}
