import React from 'react'
import { Box } from '@material-ui/core'
import { UIView } from '@uirouter/react'
import MobileToolBarBox from '../toolBar/components/MobileToolBarBox'
import MobileContextDrawer from '../contextDrawer/components/MobileContextDrawer'
// import { useCallback } from 'react'
// import { useState } from 'react'

export default function MobileLayout() {
    // const contentScrollRef = useCallback(node => setContentScrollEl(node), [])
    // const [contentScrollEl, setContentScrollEl] = useState(null)
    return (
        // app root container, lays out children vertically so content is in between header and toolBar/tabBar
        // note zIndex values place content behind header and toolBar, useful to display mobile FAB on top of content bottom edge
        <Box 
            display='flex' 
            flexDirection='column'
            position='fixed'
            width='100%'
            height='100%'
        >
            {/* header does not grow inside flex container */}
            <Box flex='none'
                zIndex={2}
            >
                <UIView name="header"/>
            </Box>
            {/* content area, grows to fill screen height between header and toolbar */}
            <Box flexGrow='1' 
                zIndex={1}
                width='100%'
                position='relative'
                overflow='auto'
                height="100%"
                // id is used to target content container to open drawer sized to content
                id='content-container'
            >
                <Box 
                    id='content-scroll'
                    height='100%'
                >
                    <UIView name="content"/>
                    <UIView name="content-footer"/>
                </Box>
            </Box>
            {/* toolBar/tabBar container, does not grow, should always be on the bottom edge of the screen */}
            <Box flex='none' 
                zIndex={2}
                position='relative'
            >
                <MobileToolBarBox />
                <UIView name="nav-bar"/>
            </Box>
            <MobileContextDrawer />
        </Box>
    )
}
