import produce from 'immer';

// hard coded list of highlight "modes". The value is picked up by the style hook to amend the drawer styling
const DRAWER_VARIANT_VALUES = [
    'blueBorderLeft',
    'blueBorderTop'
]

export const INITIAL_STATE = {
    open: false,
    component: null,
    // if true, drawer is bordered top. used to differentiate 
    variant: null,
    onCloseFn:()=>{},
    fullScreen: false,
    noPadding: false
}

export const contextDrawerReducer = (state, action) => {
    switch (action.type) {
        case 'CONTEXT_DRAWER_UNSET_COMPONENT':
            return produce(state, draft => {
                draft.component = null;
                draft.onCloseFn = ()=>{}
                draft.variant = null
                draft.fullScreen = false
                draft.noPadding = false
            })
        case 'CONTEXT_DRAWER_SET_COMPONENT':
            return produce(state, draft => {
                draft.fullScreen = action.payload.fullScreen
                draft.component = action.payload.component;
                draft.open = true;
                draft.onCloseFn = action.payload.onCloseFn
                draft.noPadding = !!action.payload.noPadding
                if(DRAWER_VARIANT_VALUES.indexOf[action.payload.variant] != -1){
                    draft.variant = action.payload.variant
                } else {
                    draft.variant = null
                }
            })
        case 'CONTEXT_DRAWER_OPEN':
        case 'CONTEXT_DRAWER_CLOSE':
            // open / close depending on the action type
            // no need for paylaod if the action always does the exact same thing
            // this reduces the chances for things to fail
            return produce(state, draft => {
                draft.open = action.type.split('_')[2] === 'OPEN'
                    ? true
                    : false
                draft.component = null
            })
        default:
            return state
    }
}

