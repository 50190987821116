import { from, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import cookies from 'cookies-js';
import {TG_API, setAuthCookiesInHeaders} from '../'
/* 
PORT=3030
// Dev Enviroment
API_BASE_URL=https://conciergemade.vielzulangedomain.de/api
// API_BASE_URL=https://kiosk.conciergemade.com/api
// Producition Enviroment
// API_BASE_URL=https://backend.tastegods.com/api
GOOGLE_MAPS_API_KEY=AIzaSyDOfvxUtuaYceLgvuznXrm_XI3CPOUrlQM
GOOGLE_STATIC_MAPS_API_KEY=AIzaSyDOfvxUtuaYceLgvuznXrm_XI3CPOUrlQM
NODE_ENV=development
MIXPANEL_TOKEN=fc183e8d9f5953d1c3f21c4b32b3972c
*/

const BASE_URL = '/authentication'

export function login({username, password}){
    function tokenSerializer(string){
        return string.split(';')
    }
    const reqConfig = {
        method: 'POST',
        url: BASE_URL+'/login',
        data: {
            username: username,
            password: password
        },
        transformResponse: [tokenSerializer]
    }
    return from(TG_API(reqConfig)).pipe(
        // sets token headers for all requests to TG backend
        tap(res => {
            // console.log('logged in, res = ', res);
            const [bearerToken, secondToken] = res.data;
            // NOTE : As we obsolete, 
            cookies.set('bearerToken', bearerToken);
            cookies.set('secondToken', secondToken);
            setAuthCookiesInHeaders();
        })
    )
}

export function logout(){
    const reqConfig = {
        method: 'GET',
        url: BASE_URL+'/logout'
    }
    return from(TG_API(reqConfig)).pipe(
        tap(unauthenticate)
    )
}

export function unauthenticate(){
    // expire cookies
    cookies.expire('bearerToken');
    cookies.expire('secondToken');
    // unset headers on TG axios instance
    delete TG_API.defaults.headers.common['Authorization'] 
    delete TG_API.defaults.headers.common['PinAuthorization']
}

export function recoverPassword({mail}){
    const reqConfig = {
        method: 'POST',
        url: BASE_URL+'/recover',
        data: {
            mail,
            frontendDomain : process.env.REACT_APP_TG_MAIL_REDIRECT_URL + '/'
        }
    }
    return from(TG_API(reqConfig))
}

export function resetPassword({token, newPassword, confirmPassword}){
    const reqConfig = {
        method: 'POST',
        url: BASE_URL+'/reset',
        data: {
            token,
            newPassword,
            verifyNewPassword: confirmPassword
        }
    }
    return from(TG_API(reqConfig))
}

export function isAuthenticated(){
    // being authenticated auth headers are set
    return (TG_API.defaults.headers.common['Authorization'] 
        &&  TG_API.defaults.headers.common['PinAuthorization'])
            ? true
            : false
}
export function isAuthenticatedAsync(){
    return of(isAuthenticated())
}