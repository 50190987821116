import { Box, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useSearchPaginatorHooks } from '../../search/state/hooks/useSearchPaginatorHooks';
import { useTypographyStyles } from '../../theme/useTypography'
import SearchResultsList from './search-results/SearchResults.List';
import { PaginationBox } from '../../search/components/PaginationBox.ButtonGroup'
import { dispatch } from 'slice';
import { setContextDrawer } from '../../contextDrawer/state/actions';
import { InsideCircleProfile, OutsideCircleProfile } from './place-profile';
import { useStateUserDetails } from '../../user/state/hooks/useStateUserDetails';
import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useStateRecentlyViewed } from '../../collections/state/useStateRecentlyViewed';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getPlace } from '../../http/tg/ldm-place'

export function SearchResultsPage(props) {

    const {
        ready,
        poiSearch,
        searchCriteria,
        onSearchClick,
        getNextPage,
        getPreviousPage,
        getFirstPage,
        getLastPage,
        getPageByNumber
    } = useSearchPaginatorHooks()
    const { params } = useCurrentStateAndParams()
    const { info } = useStateUserDetails()

    useEffect(() => {
        // console.log('can do search ?', params.q && info && info.hotels, info)
        if (ready && params.q && info && info.hotels) {
            onSearchClick(decodeURIComponent(params.q))
        }
        return () => { }
    }, [info, params.q, ready])

    const typography = useTypographyStyles()

    const { touch } = useStateRecentlyViewed()
    const selectSearchResult = (poi) => {
        if(poi.plan){
            // business memebers are always up to date in search results
            const drawerProps = {
                component: () => <InsideCircleProfile place={poi} />,
                onCloseFn: () => { },
                fullScreen: true
            }
            dispatch(setContextDrawer(drawerProps))
        } else {
            getPlace(poi.place.googlePlaceId).subscribe({
                next: res => {
                    // non business members might be out of date
                    const drawerProps = {
                        component: () => <OutsideCircleProfile place={res.data} />,
                        onCloseFn: () => { },
                        fullScreen: true
                    }
                    dispatch(setContextDrawer(drawerProps))
                },
                error: err => console.log(err)
            })
        }
        touch(poi)
    }

    const router = useRouter()
    const backToPreviousState = e => {
        const prevState = props.$transition$.from()
        const prevParams = props.$transition$.params('from')
        prevState.name && router.stateService.go(prevState.name, prevParams)
            .catch(err => {
                console.log('error back navigation', err)
            })
    }

    return (
        <Grid container>
            <Grid item xs={12} md={6}>

                <Box
                    mt={1}
                    display="flex"
                    flexDirection="column"
                >
                    <Box>
                        <IconButton onClick={backToPreviousState}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Box>
                    {poiSearch && poiSearch.loading
                        // loading
                        ? (
                            <CircularProgress
                                size={'4em'}
                                color="inherit"
                                style={{ margin: 'auto' }}
                            />
                        )
                        // search has completed
                        : poiSearch.pageResult && poiSearch.pageResult.length > 0 && !poiSearch.loading
                            // some search results
                            ? (<SearchResultsList
                                searchResult={poiSearch}
                                onSelect={selectSearchResult}
                            />)
                            // search returned no results
                            : poiSearch.pageResult.length === 0 && searchCriteria.searchStr && !poiSearch.loading
                                ? (
                                    <Box flexGrow={1} px={2}>
                                        <Typography color='inherit' component='div' className={typography.subtitle1}>Search Results</Typography>
                                        <Typography color='inherit' className={typography.body2} >No Results</Typography>
                                    </Box>
                                )
                                // nothing to display
                                : null
                    }
                </Box>

                {/* PAGINATION */}
                {poiSearch.pageResult.length > 0
                    // display pagination if multiple pages
                    && (
                        <Box
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <PaginationBox
                                displayedPageNum={poiSearch.metaData.page}
                                totalPageNum={poiSearch.metaData.pages}
                                getFirstPage={getFirstPage}
                                getPreviousPage={getPreviousPage}
                                getNextPage={getNextPage}
                                getLastPage={getLastPage}
                                getPageByNumber={getPageByNumber}
                            />
                        </Box>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default SearchResultsPage