import produce from 'immer';
import {gglLayer} from '.';
/* 
state is an array with each
element = {
        active: bool,
        name: string,
        gmData: google.maps.Data instance
}
*/
export function GeoJsonDataOverlayReducer(state, action){
    switch (action.type) {
        case 'MAP/OVERLAYS/GEOJSON/RESET':
            // removeLayers(state)
            return []
        case 'MAP/OVERLAYS/GEOJSON/SEED':
            // first remove existing layers from map
            removeOverlay(state)
            return produce(state, draft => {
                const gmData = new window.google.maps.Data(action.payload.DataOptions)
                // load data into Data instance
                action.payload.url 
                    ? gmData.loadGeoJson(action.payload.url)
                    : gmData.addGeoJson(action.payload.geojson)
                // gglLayer is only there to be where validation happens if necessary
                return [gglLayer(true, action.payload.name, gmData, action.payload.source)]
            })
        case 'MAP/OVERLAYS/GEOJSON/ADD':
            return produce(state, draft => {
                const gmData = new window.google.maps.Data(action.payload.DataOptions)
                action.payload.url 
                    ? gmData.loadGeoJson(action.payload.url)
                    : gmData.addGeoJson(action.payload.geojson)
                draft.push(gglLayer(true, action.payload.name, gmData, action.payload.source))
            })
        case 'MAP/OVERLAYS/GEOJSON/TOGGLE':
            return produce(state, draft => {
                draft[action.payload.index].active = !draft[action.payload.index].active
            })
        case 'MAP/OVERLAYS/GEOJSON/UPDATE':
            return produce(state, draft => {

                // find a geojson overlay by name
                const targetOverlay = draft.filter((val)=>{
                    return val.name === action.payload.layerName
                });

                // replace specified feature property with source object.
                targetOverlay[0].gmData.forEach((_feature)=>{
                    // find destination 
                    if (_feature.getProperty('id') === action.payload.featureId){
                        // override propary only when key exists on both destination and source.
                        const keys = Object.keys(action.payload.sourceObj);
                        _feature.forEachProperty((val,propNam)=>{
                            if (keys.indexOf(propNam) >= 0){
                                _feature.setProperty(propNam,action.payload.sourceObj[propNam])
                            }
                        })   
                    }
                })
            })
        default:
            return state;
    }
}

const removeOverlay = state => {
    state && state.length > 0 && state.forEach(overlay => {
        overlay.gmData.setMap(null)
    })
}

export default GeoJsonDataOverlayReducer