import { useEffect, useState } from "react"
import { $lice } from "../../../lib/slice"
import { recentlyViewedPlaceReducer } from "./recentlyViewedReducer"

const [places$, dispatch] = $lice('tgrecentlyviewedplaces', recentlyViewedPlaceReducer, [])
export function useStateRecentlyViewed(){
    const [state, setState] = useState([])
    useEffect( () => {
        let sub = places$.subscribe({
            next: state => {
                setState(state)
            },
            error: console.log.bind(0, 'error in tgrecentlyviewedplaces slice subscription')
        })
        return () => {sub.unsubscribe()}
    }, [])

    return {
        places: state,
        touch: function(place){
            dispatch({
                type: 'RECENTLY/VIEWED/PLACES/PUSH',
                payload: place
            })
        }
    }
}