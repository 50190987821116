export const RESULT_TYPE = {
    PLACE: 'place',
    PLACE_LANDMARK: 'placeLandmark',
    PLACE_RESTAURANT: 'placeRestaurant',
    PLACE_SERVICE: 'placeService',
    VENUE: 'venue',
    LANDMARK: 'landmark',
    SERVICE: 'service',
}

export const isGooglePlace = type => {
    return Boolean((type === RESULT_TYPE.PLACE) || (type === RESULT_TYPE.PLACE_LANDMARK) || (type === RESULT_TYPE.PLACE_RESTAURANT) || (type === RESULT_TYPE.PLACE_SERVICE));
}

/**
 * Wrapper for String.localeCompare to be used as string sort comparison function.
 * It contains basic options for comparing special charactors like 'a' and 'ä'
 * @param {String} refStr 
 * @param {String} comStr 
 */
export const defaultLocaleCompare = (refStr,comStr) => {
    try {
        // Use localeCompare in order to language sensitive comparison of special charactors. e.g 'a' vs 'ä'
        return refStr.localeCompare(comStr, 
        'en',
        { 
            sensitivity: 'base' // base of the charactor only should matter. e.g 'a' = 'ä' 
        })
    } catch (error) {
        // String type error shouldn't be fatal error. just return 1 
        return 0
    }
}

export function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function toImageURL(url){
    const _URL = new URL(url, process.env.REACT_APP_API_BASE_URL.replace('api', ''))
    return _URL.href
}

export function setClipboard(text) {
    var type = "text/plain";
    var blob = new Blob([text], { type });
    var data = [new window.ClipboardItem({ [type]: blob })];

    return navigator.clipboard.write(data)
}