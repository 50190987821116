import React, { useEffect } from "react"
import { dispatch } from "slice"

import { useToolBar } from "../../../toolBar/state/hooks/useToolBar"
import ToolBarButton from "../../../toolBar/components/ToolBarButton"
import { unsetContextDrawer, closeContextDrawer } from "../../../contextDrawer/state/actions"

import { getDigitalMapListToggle } from "./button-getters/getDigitalMapListToggle"
import { getAddHotelMapPlaceCTA } from "./button-getters/getAddHotelMapPlaceButton"
import { useEditHotelMap } from "./useEditHotelMap"
import { Fab } from "@material-ui/core"

function SaveHotelMapButton(){
    const [editHotelMap, {save}] = useEditHotelMap()
    
    return (
        <Fab color='secondary' 
            onClick={e=>save(editHotelMap)}
            variant='extended'
            style={{padding: '0 3rem', flexGrow: 1}}
        >
            SAVE
        </Fab>
    )
}

export function useEditHotelMapToolbar({
    map,
    hotel,
    customMapId,
    isDesktop,
    router
}){
    const _toolBar = useToolBar()
    useEffect( function placeToolBarButtons(){
        // on render, place toolbar buttons
        _toolBar.setPrimary(getAddHotelMapPlaceCTA(map))
        _toolBar.setSecondary(getDigitalMapListToggle(map, hotel))

        if(customMapId && isDesktop){
            _toolBar.addPrimary(
                props => {
                    return (
                        <ToolBarButton
                            // TODO: save editHotelMap
                            desktop={<SaveHotelMapButton/>}
                            // the QR code button is not in toolBar on mobile
                            mobile={<></>}
                        />
                    )
                }
            ) 
        }
        return function teardownToolBar(){
            // remove toolbar controls 
            _toolBar.clear()
            // remove ggl map control
            map.controls[window.google.maps.ControlPosition.TOP_CENTER] = []
            // teardown ContextDrawer on unmount
            // NOTE : not too sure why I did this here. This may be useless.
            dispatch(unsetContextDrawer())
            dispatch(closeContextDrawer())
        }
    }, [customMapId, isDesktop])
}