export const openModal = ({
    title,
    component,
    props,
    fullScreen,
    onBack,
    DialogProps,
    onClose
}) => ({
    type: 'MODAL/OPEN',
    payload: {
        title,
        component,
        props,
        fullScreen,
        onBack,
        DialogProps,
        onClose
    }
})

export const closeModal = () => ({type: 'MODAL/CLOSE'})