import produce from "immer";

export const INITIAL_STATE = {
    selected_plan: {value: 0},
    submit: {status: 0},
    business_name: {value: '', required: true, label: 'Business Name'},
    business_address: {value: '', required: true, label: 'Business Address'},
    primary_phone_number: {value: '', label: 'Business Primary Phone Number'},
    primary_email: {value: '', required: true, label: 'Business Primary Email'},
    business_website: {value: '', label: 'Business Website'},
    google_profile_link: {value: '', required: true, label: 'Google Business Profile Link'},
    admin_full_name: {value: '', required: true, label: 'Full Name of Admin User'},
    admin_email: {value: '', required: true, label: 'Admin Email'},
    billing_name: {value: '', required: true, label: 'Billing Name / Company'},
    billing_address: {value: '', required: true, label: 'Billing Address'},
    // business_name: {value: 'biz name', required: false, label: 'Business Name'},
    // business_address: {value: 'biz address', required: false, label: 'Business Address'},
    // primary_phone_number: {value: '123123123', label: 'Business Primary Phone Number'},
    // primary_email: {value: 'asd@prim', required: false, label: 'Business Primary Email'},
    // business_website: {value: 'website.com', label: 'Business Website'},
    // google_profile_link: {value: 'gglLink', required: false, label: 'Google Business Profile Link'},
    // admin_full_name: {value: 'Full Name', required: false, label: 'Full Name of Admin User'},
    // admin_email: {value: 'asd@admin', required: false, label: 'Admin Email'},
    // billing_name: {value: 'Bill Name', required: false, label: 'Billing Name / Company'},
    // billing_address: {value: 'Bill Address', required: false, label: 'Billing Address'},
    vat_id: {value: '', label: 'VAT ID'},
    agreed: {value: false, required: true, label: 'I agree to the Terms & Conditions and Privacy Policy*'}
}

export const businessMembershipFormReducer = function(state, action){
    state = state || INITIAL_STATE
    switch (action.type) {
        case 'BUSINESS/INVITE/FORM/SET':
            return setFormFieldValueProducer(state, action.payload)
        
        case 'BUSINESS/INVITE/FORM/SUBMIT/SUCCESS':
            return INITIAL_STATE
            
        case 'BUSINESS/INVITE/FORM/SUBMIT/ERROR':
            return produce(draft => {
                draft.submit['status'] = 2;
                draft.submit['message'] = action.payload;
            })(state)
            
        case 'BUSINESS/INVITE/FORM/SUBMIT/START':
            return produce(draft => {
                draft.submit['status'] = 3
                draft.submit['message'] = null;
            })(state)
            
    
        default:
            return state;
    }
}

const setFormFieldValueProducer = produce((draft, payload)=> {
    const {key, value} = payload
    draft[key].value = value
})