import React from 'react';
import { Typography, Card, ListItem } from '@material-ui/core';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';
import {useTypographyStyles} from '../../../theme/useTypography'
import { format } from 'date-fns';
import { Box } from '@material-ui/core';
import { COLORS } from '../../../theme/colors';
import { compareDesc } from 'date-fns/esm';
import { openModal } from '../../../dialogs/standard/state/actions';
import { dispatch } from 'slice';
import MapSummary from './MapSummary';
import { useStateUserHotels } from '../../../user/state/hooks/useStateUserHotels';
import { useMediaQuery } from '@material-ui/core';

const noMarginCss = {
    margin: 0,
    border: 'none',
    borderRadius: '0.5rem'
}
const useItemStyles = tgMakeStyles(theme => ({
    liButton: {
        ...noMarginCss,
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),

    }
}))

export function MapListItem({
    map, 
    defaultTitle = "",
    onDelete,
    onChange,
    ...props
}){

    const typo = useTypographyStyles()
    const classes = useItemStyles()
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))

    const hotels = useStateUserHotels()
    const _hotel = hotels && hotels.length
        ? hotels[0]
        : null
    const creation_dt = new Date(Math.fround(map.created*1000))
    const update_dt = new Date(Math.fround(map.lastUpdate*1000))
    const _defaultTitle = _hotel
        ? defaultTitle
            ? defaultTitle
            : `${_hotel.name} - ${format(creation_dt, "dd MMMM yyyy")} - ${format(creation_dt, "HH:mm:ss")}`
        : defaultTitle
    const _title = map.title || _defaultTitle
    const _dateStr = compareDesc(update_dt, creation_dt) === -1
        ? `Last edited ${format(update_dt, "dd MMMM yyyy")}`
        : `Created ${format(creation_dt, "dd MMMM yyyy")}`

    const onSelect = map => e => {
        dispatch(openModal({
            component: MapSummary,
            DialogProps: {
                maxWidth: 'md',
                fullWidth: true,
                fullScreen: isMobile
            },
            props: {
                map,
                onDelete: onDelete,
                onChange: onChange
            }
        }))
    }

    return (
        <ListItem
            component={Card}
            elevation={4}
            onClick={onSelect(map)}
            button
            disableGutters={true}
            divider={true}
            style={{color: map.title ? 'inherit' : COLORS.get('grey')[3]}}
            classes={{
                button: classes.liButton
            }}
        >
            <Box>
                <Typography className={`${typo.subtitle4}`}>
                    {_title}
                </Typography>
                <Typography className={`${typo.body1}`}>
                    {_dateStr}
                </Typography>
            </Box>
        </ListItem>
    )
}

export default MapListItem