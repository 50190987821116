export const STATUSES = Object.freeze({
    unauthenticated: 1,
    authenticating: 2,
    authenticated: 3,
    unauthenticating: 4,
    recover: {
        password: {
            idle: 10,
            recovering: 20,
            recovered: 30,
            failed: 40
        }
    },
    reset: {
        password: {
            idle: 100,
            resetting: 200,
            reset: 300,
            failed: 400
        }
    }
})