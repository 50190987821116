import produce from "immer";
import { dispatch } from "slice";
import { openConfirmationDialog } from "../../../dialogs/confirmation/states/actions";
import { closeModal } from "../../../dialogs/standard/state/actions";
import { showNotification } from "../../../notifications/state/actions";

export const INITIAL_EDIT_MAP_STATE = {
    pristine: true,
    src: null, // used to rollback to pre-change state
    tgt: null, // consumed by all components that show the edited hotel map
    // props below are used onSave to send all the changes to the BE
    updateHotelMap: null,
    newHotelPlaces: [],
    deleteHotelPlaces: [],
    updateHotelPlaces: [],
    refreshToggle: false
}
function touch(state){
    state.pristine = false
}

export function editHotelMapReducer(state, action) {
    if (!state) return INITIAL_EDIT_MAP_STATE
    switch (action.type) {
        case 'EDIT/HOTELMAP/SEED':
            // set hotel map in state
            return produce(state, draft => {
                draft.src = action.payload
                draft.tgt = action.payload
                // when seeding, no need to set the order
                // updatePlacesOrder(draft)
            })
        case 'EDIT/HOTELMAP/ASSIGN':
            return produce(state, draft => {
                touch(draft)
                draft.tgt = {
                    ...state.tgt,
                    ...action.payload
                }
            })
        case 'EDIT/HOTELMAP/RESET/SORT':
            // re-order hotel places in alphabetical order
            return produce(state, draft => {
                touch(draft)
                draft.tgt.hotelPlaces.sort(hotelPlacesAlphabetically)
                updatePlacesOrder(draft)
            })
        case 'EDIT/HOTELMAP/TOGGLE/ORDERED':
            return produce(state, draft => {
                touch(draft)
                draft.tgt.ordered = !draft.tgt.ordered
            })
        case 'EDIT/HOTELMAP/TOGGLE/SORTED':
            return produce(state, draft => {
                touch(draft)
                draft.tgt.alphabeticallyOrdered = !draft.tgt.alphabeticallyOrdered
            })
        case 'EDIT/HOTELMAP/PLACES/ADD':
            if (action.payload.__force || !placeExistsAlready(state, action.payload)) {
                return produce(state, draft => {
                    touch(draft)
                    draft.newHotelPlaces.push(action.payload)

                    if(state.tgt.alphabeticallyOrdered){
                        // find where new place should be in an alphabetically ordered list
                        let arr = [...state.tgt.hotelPlaces, action.payload].sort(hotelPlacesAlphabetically)
                        let position = arr.findIndex( elem => elem === action.payload)
                        draft.tgt.hotelPlaces.splice(position, 0, action.payload)
                    } else {
                        // or simply push in array if hotel map is orderd = true
                        draft.tgt.hotelPlaces.push(action.payload)
                    }
                    updatePlacesOrder(draft)
                })
            } else {
                dispatch(openConfirmationDialog({
                    title: 'DO YOU WANT TO ADD THIS AGAIN?',
                    text: `${action.payload.place.place.name} already exists on the map`,
                    answerOptions:[ 'Cancel', 'Add'],
                    onConfirm : ()=>{
                        dispatch(addEditHotelMapPlace({
                            ...action.payload,
                            __force: true
                        }))
                        dispatch(closeModal())
                        return Promise.resolve();
                    },
                    onDismissed : ()=>{
                        dispatch()
                        return Promise.resolve();
                    }
                }))
                // dispatch(showNotification({ message: `${action.payload.place.place.name} is already in your map` }))
            }
        case 'EDIT/HOTELMAP/PLACES/DELETE':
            // payload = idx in draft.tgt.hotelPlaces
            return produce(state, draft => {
                touch(draft)
                let hPlace = state.tgt.hotelPlaces[action.payload]
                if (isNewHotelPlace(hPlace)) {
                    removeFrom(hPlace, draft.newHotelPlaces)
                } else {
                    draft.deleteHotelPlaces.push(hPlace)
                }
                // remove from state.tgt.hotelPlaces
                draft.tgt.hotelPlaces.splice(action.payload, 1)
                updatePlacesOrder(draft)
                dispatch(showNotification({message: `${hPlace.place.place.name} was removed from this map`}))
            })
        case 'EDIT/HOTELMAP/RESET':
            // use src to reset the map. action.payload optionnally can be an object that gets merged into state
            return produce(state, draft => {
                return {
                    // the order matters here
                    ...INITIAL_EDIT_MAP_STATE,
                    src: draft.src,
                    tgt: draft.src,
                    ...action.payload
                }
            })
        case 'EDIT/HOTELMAP/PLACES/MOVE':
            return produce( state, draft => {
                touch(draft)
                // action.payload = {from: int, to: int}
                let movedhp = draft.tgt.hotelPlaces.splice(action.payload.from, 1)[0]
                draft.tgt.hotelPlaces.splice(action.payload.to, 0, movedhp)
                updatePlacesOrder(draft)
            })
        case 'EDIT/HOTELMAP/PLACE/ASSIGN':
            return produce(state, draft => {
                touch(draft)
                let hp = action.payload
                const newPlaceIdx = draft.newHotelPlaces.findIndex(elem => sameHotelPlaceId(hp, elem))
                const updateIdx = draft.updateHotelPlaces.findIndex(elem => sameHotelPlaceId(hp, elem))
                const tgtIdx = draft.tgt.hotelPlaces.findIndex(elem => sameHotelPlaceId(hp, elem))

                // update in tgt.hotelPlaces
                draft.tgt.hotelPlaces[tgtIdx] = {
                    ...state.tgt.hotelPlaces[tgtIdx],
                    ...hp
                }

                // if it is a new place, update as new place
                if (newPlaceIdx != -1) {
                    draft.newHotelPlaces[newPlaceIdx] = {
                        ...state.newHotelPlaces[newPlaceIdx],
                        ...hp
                    }
                    // else update as an update of existing hotelPlace
                } else {
                    // either add to updates array or add to array
                    if (updateIdx === -1) {
                        draft.updateHotelPlaces.push(hp)
                    } else {
                        draft.updateHotelPlaces[updateIdx] = {
                            ...state.updateHotelPlaces[updateIdx],
                            ...hp
                        }
                    }
                }
            })
        case 'EDIT/HOTELMAP/MAKE/PRISTINE':
            return produce(state, draft => {
                draft.pristine = true
                draft.updateHotelMap = null
                draft.newHotelPlaces = []
                draft.deleteHotelPlaces = []
                draft.updateHotelPlaces = []
            })
        
        case 'EDIT/HOTELMAP/CLEAR':
            return produce(state, draft => {
                return {
                    // the order matters here
                    ...INITIAL_EDIT_MAP_STATE,
                    ...action.payload
                }
            })

        default:
            return state;
    }
}

export function seedEditHotelMap(hotelMap) {
    return {
        type: 'EDIT/HOTELMAP/SEED',
        payload: hotelMap
    }
}

export function addEditHotelMapPlace(place, force=false) {
    return {
        type: 'EDIT/HOTELMAP/PLACES/ADD',
        payload: place
    }
}

export function updateHotelMap({
    ordered,
    title
}) {
    return {
        type: 'EDIT/HOTELMAP/ASSIGN',
        payload: {
            ordered,
            title
        }
    }
}

export function resetHotelMap() {
    return {
        type: 'EDIT/HOTELMAP/RESET'
    }
}
export function clearHotelMap() {
    return {
        type: 'EDIT/HOTELMAP/CLEAR'
    }
}

export function editHotelPlace(hotelPlace) {
    return {
        type: 'EDIT/HOTELMAP/PLACE/ASSIGN',
        payload: hotelPlace
    }
}

export function deleteHotelPlace(idx) {
    return {
        type: 'EDIT/HOTELMAP/PLACES/DELETE',
        payload: idx
    }
}

export function toggleOrderedMap() {
    return {
        type: 'EDIT/HOTELMAP/TOGGLE/ORDERED'
    }
}
export function toggleSortedMap() {
    return {
        type: 'EDIT/HOTELMAP/TOGGLE/SORTED'
    }
}
export function resetHotelPlacesOrder(){
    return {
        type: 'EDIT/HOTELMAP/RESET/SORT'
    }
}
export function moveHotelPlace(from, to){
    return {
        type: 'EDIT/HOTELMAP/PLACES/MOVE',
        payload: {
            from ,to
        }
    }
}

export function sameGooglePlaceId(hp1, hp2) {
    return hp1.place.place.googlePlaceId === hp2.place.place.googlePlaceId
}
export function sameHotelPlaceId(hp1, hp2) {
    return hp1.id === hp2.id
}
function removeFrom(xhp, hpArr) {
    const idx = hpArr.findIndex(elem => sameGooglePlaceId(elem, xhp))
    hpArr.splice(idx, 1)
}
function isNewHotelPlace(hp) {
    return hp.id < 0 // places not persisted have negative IDs (not very explicit but easy)
}
function placeExistsAlready(state, newPlace) {
    const alreadyExists = state.tgt.hotelPlaces.find(hp => hp.place.place.googlePlaceId === newPlace.place.place.googlePlaceId)
    return alreadyExists
}
function updatePlacesOrder(editHotelMapState) {

    // This function is used to set the correct order value everywhere it is necessary so next save also saves the correct place order
    const updatePlaceOrder = editHotelMapState => (hp, idx) => {
        // for each hotel place, 
        if (isNewHotelPlace(hp)) {
            // 1. set order in newPlaces array if new place.
            let newPlaceIdx = editHotelMapState.newHotelPlaces.findIndex(elem => sameGooglePlaceId(hp, elem))
            editHotelMapState.newHotelPlaces[newPlaceIdx].order = idx+1
        } else {
            // 2. else set order in updatePlace
            let updateIdx = editHotelMapState.updateHotelPlaces.findIndex(elem => sameGooglePlaceId(hp, elem))
            if (updateIdx === -1) {
                // console.log('not in update')
                editHotelMapState.updateHotelPlaces.push({
                    ...hp,
                    order: idx + 1
                })
            } else {
                editHotelMapState.updateHotelPlaces[updateIdx].order = idx + 1
            }
        }
        // 3. set order in tgt.hotelPlaces
        hp.order = idx + 1
    }

    editHotelMapState.tgt.hotelPlaces
        .forEach(updatePlaceOrder(editHotelMapState))
}
function hotelPlacesAlphabetically(a, b) {
    const nameA = a.place.place.name.toLowerCase()
    const nameB = b.place.place.name.toLowerCase()
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}