import React from 'react'
import ReactGoogleMapLoader from "react-google-maps-loader";
import { Box, Typography } from '@material-ui/core'
import { GoogleMapUI } from './GoogleMap'
import googleMapStyle from '../../../../assets/json/googleMapStyle.json'
import { useTypographyStyles } from '../../../../modules/theme/useTypography'
import LoopProgress from '../../../components/progress/LoopProgress';
import { COLORS } from '../../../../modules/theme/colors';


/**
 * 
 * this component does not load google maps library, 
 * window.google.maps must already exist for the component to show a map
 */
export default function GoogleMapWithMarkerBox({
    BoxProps,
    lat,
    lng,
    markerOptions
}){
    const typography = useTypographyStyles()
    const gglApiLoadParams = {
        v: 'weekly',
        libraries: 'places,geometry',
        // this should be coming from process.env ?
        key: 'AIzaSyDOfvxUtuaYceLgvuznXrm_XI3CPOUrlQM'
    }
    const opts = {
        center: {
            lat: lat,
            lng: lng
        },
        zoom: 14,
        // disableDefaultUI: true,
        fullscreenControl: true,
        mapTypeControl: false,
        rotateControl: false,
        streetViewControl: false,
        scaleControl: true,
        gestureHandling: 'cooperative',
        styles : googleMapStyle
    }
    return (
        <Box position='relative' height='30vh'  {...BoxProps} >
            {window.google && window.google.maps
                ? (
                <GoogleMapUI 
                    googleMaps={window.google.maps}
                    options={opts}
                    render={map => {
                        const poiMarkerOptions = {
                            map: map,
                            position: {
                                lat: lat,
                                lng: lng
                            },
                            icon: {
                                path: `M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z`,
                                strokeColor: 'white',
                                anchor: new window.google.maps.Point(24, 42),
                                fillOpacity: 1,
                                fillColor: COLORS.get('gold').main,
                                scale: 1.15,
                            },
                            clickable: false,
                            ...markerOptions
                        }
                        new window.google.maps.Marker(poiMarkerOptions)
                    }}/>
                )
                : (
                    <ReactGoogleMapLoader
                        className='ReactGoogleMapLoader'
                        params={gglApiLoadParams}
                        render={(googleMaps, error) => {
                            return (
                                googleMaps && !error
                                ? (
                                    <GoogleMapUI 
                                        googleMaps={googleMaps}
                                        options={opts}
                                        render={map => {
                                            const poiMarkerOptions = {
                                                map: map,
                                                position: {
                                                    lat: lat,
                                                    lng: lng
                                                },
                                                icon: {
                                                    path: `M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z`,
                                                    fillOpacity: 0.8,
                                                    strokeColor: 'white',
                                                    anchor: new window.google.maps.Point(24, 42),
                                                    // properties below change when Feature is highlighted
                                                    fillColor: /* !isHighlighted ? '#c6ad7c' : */ '#11113f',
                                                    scale: /* !isHighlighted ? 1 : */ 1.15,
                                                },
                                                clickable: false
                                            }
                                            new window.google.maps.Marker(poiMarkerOptions)
                                    }}/>
                                )
                                : (
                                    <Box width='100%' height='100%' display='flex'>
                                        <Box margin='auto' textAlign='center'>
                                            <LoopProgress 
                                                color='primary' 
                                                fontSize={'large'}
                                            />
                                            <Typography
                                                color='primary' 
                                                className={typography.h5}
                                                gutterBottom={true}
                                            >
                                                LOADING MAP
                                            </Typography>
                                        </Box>
                                    </Box>
                                )
                            )
                        }}>

                    </ReactGoogleMapLoader>  
                )
            }
        </Box>
    )
}