import React from 'react'
import { AddendumBusiness01ALink } from '../../../legal/components/AddendumBusiness01ALink';

export function AddendumByInvitationCodeLink({code}){
    switch (code) {
        case 'ber282':
        case 'ber286':
        case 'ber288':
            return <span>and <AddendumBusiness01ALink /></span>
    
        default:
            return null;
    }
}