import { Box, Button, Typography } from '@material-ui/core'
import { useRouter } from '@uirouter/react'
import React, { useEffect, useState } from 'react'
import { fromEvent } from 'rxjs'
import { distinct } from 'rxjs/operators'
import { useTypographyStyles } from '../../../../theme/useTypography'
import useStateBusinessMembershipForm from '../../../state/hooks/useStateBusinessMembershipForm'
import useOBStyles from '../onboarding.classes'

export function DesktopCTASelectPlan({isVisible, ...props}){
    // console.log('DesktopCTASelectPlan', props)
    
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        let sub = fromEvent(document.querySelector('#content-scroll'), 'scroll')
            .pipe(distinct())
            .subscribe(e => {
                setVisible(isVisible())
            })

        return () => {sub.unsubscribe()}
    }, [])

    const {$state$} = props
    const typo = useTypographyStyles()
    const classes = useOBStyles()
    const router = useRouter()
    const {selected_plan} = useStateBusinessMembershipForm()

    const handleSubmitPlanSelection = e => {
        router.stateService.go('root.welcome.invited.tour.plans.business-details', {selected_plan: selected_plan.value})
    }
    return (
        <Box
            className={`${classes.desktopCTA} ${visible ? 'on' : ''}`}
        >
            <Typography className={typo.subtitle6} align="center">
                {$state$.data.cta_txt}
            </Typography>
            <Button 
                id="invite-confirm-plan-selection"
                onClick={handleSubmitPlanSelection}
                className={`${classes.btn} clickable`}
                color="primary"
                variant="contained"
            >
                {$state$.data.cta_btn}
            </Button>
        </Box>
    )
}

export default DesktopCTASelectPlan