import { Box, Button, Typography, Checkbox, FormControlLabel, CircularProgress, TextField, Collapse, RadioGroup, Radio } from '@material-ui/core';
import React, { useRef, useState } from 'react'
import { useTypographyStyles } from '../../../theme/useTypography';
import { showNotification } from '../../../notifications/state/actions';
import { dispatch } from 'slice';
import { getHotelToPlaceDirections } from '../../../http/tg/hotels';
// import { closeModal, openModal } from '../../../dialogs/standard/state/actions';
import { LanguageSelect, TgSelect } from '../../../hotel/guest-console/components/LanguageSelectComp';
import { CollapsableTextField } from '../../../../lib/components/inputs/CollapsableTextInput';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useStateUserDetails } from '../../../user/state/hooks/useStateUserDetails';
import { switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';

export function PrintDirectionsForm({
    hotelId,
    place,
    info,
    ...props
}) {
    let formRef = useRef()
    const { info: _user } = useStateUserDetails()
    const hotel = _user && _user.hotels[0]
    const typography = useTypographyStyles()

    const transportationModes = [
        ['Driving', 'driving'],
        ['Walking', 'walking'],
        ['Bicycling', 'bicycling'],
        ['Transit', 'transit'],
    ]

    const [checkboxes, setCheckboxes] = useState({
        map: true,
        directions: true,
        booking: false
    })
    const handleCheckboxChange = (tgt) => e => {
        const v = e.target.checked
        setCheckboxes(prev => ({
            ...prev,
            [tgt]: v
        }))
    }

    // const [collapsed, setCollapsed] = useState(true)
    const [busyPrint, setBusyPrint] = useState(false)
    const [busyMail, setBusyMail] = useState(false)

    const shareDirections = (hotelId, placeId, mode="mail") => e => {
        e.preventDefault()
        const formData = new FormData(formRef.current)
        const _recipientEmail = formData.get('printout-recipient-email')
        if(mode === "mail" && !_recipientEmail){
            dispatch(showNotification({ message: `Recipient email address is required. Please try again.` }))
            return
        }
        const _hideElement = Object
            .entries(checkboxes)
            .filter(v => !v[1])
            .map(v => v[0])
            .toString()

        const _info = formData.get('info')
        const _emailName = formData.get('email-name')
        const _title = _emailName
            ? `${_emailName}`
            : 'Guest'
        const _booking = formData.get('booking')

        mode === "mail" && setBusyMail(true)
        mode === "print" && setBusyPrint(true)
        getHotelToPlaceDirections({
            hotelId,
            place: placeId,
            language: formData.get('lang') || 'en',
            mode: formData.get('mode') || 'walking',
            hideElement: _hideElement,
            extraInfo: checkboxes.booking
                ? (`${_info ? `${_info}\n\n` : ''} BOOKING DETAILS:\n${_booking}`)
                : (`${_info ? _info : ''}`),
            email: ( mode === "mail" && _recipientEmail )
                ? {
                    recipient: _recipientEmail,
                    subject: `Your directions to ${place.name}`,
                    body: getEmailBody(checkboxes.booking ? _booking : void 0, hotel, _user.mail, place.name, null, _title)
                }
                : void 0
        }).pipe(
            switchMap(res => {
                if(mode === "mail") {
                    return from(res.data.text())
                } else {
                    return of(res)
                }
            }),
            tap(txt => {
                if(mode === "mail" && txt.toLowerCase() !== 'ok'){
                    throw new Error(txt)
                }
            })
        ).subscribe({
            next: mode === "mail"
                ? notifyEmailSent(_recipientEmail)
                : openPrintout,
            error: err => {
                console.log(err)
                mode === "mail" && setBusyMail(false)
                mode === "print" && setBusyPrint(false)
                dispatch(showNotification({ message: `Oops! ${err.toString().substring(0, 144)}, please try again later.` }))
            }
        })

        function openPrintout(res) {
            setBusyPrint(false)
            // dispatch(closeModal())
            var blob = new Blob([res.data], { type: "application/pdf" });
            let x = window.open(URL.createObjectURL(blob), "_blank")
        }
        function notifyEmailSent (recipient) {
            return res => {
                setBusyMail(false)
                // dispatch(closeModal())
                dispatch(showNotification({ message: `Success! Directions to ${place.name} sent to ${recipient}` }))
            }
        }
    }

    const onPrint = e => {
        shareDirections(hotelId, place.id, "print")(e)
    }
    const onSendEmail = e => {
        shareDirections(hotelId, place.id, "mail")(e)
    }

    return (
        <form onSubmit={onPrint} ref={formRef}>

            <Box my={2} mt={3}>
                <Typography className={typography.h3}>
                    SETTINGS
                </Typography>
            </Box>
            <Box>
                <FormControlLabel
                    value="top"
                    control={
                        <Checkbox
                            checked={checkboxes.map}
                            color="primary"
                            onChange={handleCheckboxChange('map')}
                            inputProps={{
                                name: 'show-map'
                            }}
                        />
                    }
                    label="Show map"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="top"
                    control={
                        <Checkbox
                            checked={checkboxes.directions}
                            color="primary"
                            onChange={handleCheckboxChange('directions')}
                            inputProps={{
                                name: 'show-directions'
                            }}

                        />
                    }
                    label="Show step by step directions"
                    labelPlacement="end"
                />
                <LanguageSelect
                    selectedVal="de"
                    label="Select step by step directions language"
                />
            </Box>
            <Box my={2}>
                <TgSelect
                    items={transportationModes}
                    selectName="mode"
                    selectedVal="walking"
                    labelText="Select transportation mode"
                />
            </Box>
            <Box mb={2}>
                <CollapsableTextField
                    name="info"
                    label="Add More Information"
                    subtext="i.e. give tips for a great experience"
                    multiline={true}
                    rows="5"
                    defaultValue={info}
                />
                <FormControlLabel
                    value="top"
                    control={
                        <Checkbox
                            checked={checkboxes.booking}
                            color="primary"
                            onChange={handleCheckboxChange('booking')}
                            inputProps={{
                                name: 'has-booking'
                            }}
                        />
                    }
                    label="Include booking information"
                    labelPlacement="end"
                />
                <Collapse in={checkboxes.booking}>
                    <TextField id="booking"
                        name="booking"
                        type="text"
                        label="Booking informations"
                        variant="outlined"
                        multiline={true}
                        rows="7"
                        fullWidth
                        placeholder=''
                        defaultValue={getBookingTemplate(place, _user)}
                    />
                </Collapse>
            </Box>

            <Box my={2} mb={4}>
                <Typography className={typography.h3}>
                    SHARE
                </Typography>
            </Box>
            <Box>
                <Box mb={2}>
                    {/* <FormControlLabel 
                        label='SEND TO : '
                        labelPlacement='start'
                        control={(
                            <RadioGroup aria-label="title" name="title" defaultValue={'Mr.'} row={true} >
                                <FormControlLabel value="Mr." control={<Radio color="primary" />} label={'Mr.'} />
                                <FormControlLabel value="Mrs." control={<Radio color="primary" />} label={'Mrs.'} />
                            </RadioGroup>
                        )}
                    /> */}
                    <TextField id="email-name"
                        name="email-name"
                        type="text"
                        label="Recipient Name"
                        variant="outlined"
                        fullWidth
                        placeholder='Mrs. Smith'
                        defaultValue={''}
                    />
                </Box>
                <TextField id="printout-recipient-email"
                    name="printout-recipient-email"
                    type="email"
                    label="Recipient Email"
                    variant="outlined"
                    fullWidth
                    placeholder='example@mail.com'
                    InputProps={{
                        endAdornment: <>
                            <SendPrintoutButton
                                busy={busyMail}
                                // variant='contained'
                                color='primary'
                                classes={{
                                    label: typography.h3
                                }}
                                style={{margin: 0, paddingLeft: '1rem'}}
                                onClick={onSendEmail}
                            />
                        </>
                    }}
                />
            </Box>
            <Box  display="flex" justifyContent="flex-end">
                {!busyPrint
                    ? (<Button variant='contained'
                        color='primary'
                        type='submit'
                        style={{marginRight: 0}}
                        classes={{
                            label: typography.h3
                        }}
                    >
                        PRINT
                    </Button>)
                    : <CircularProgress size="2rem" style={{margin: '1rem'}}/>
                }
            </Box>
            <Typography color="inherit" className={typography.caption1}>
                <i>Your Directions printout will open in a new tab. Ensure your browser does not block new tabs and popup windows.</i>
            </Typography>
        </form>
    )
}

export default PrintDirectionsForm;

function getEmailBody(_booking, _hotel, _email, _place, _phoneNumber, _title="Guest"){
    const _baseImgUrl = process.env.REACT_APP_API_BASE_URL.substring(0, process.env.REACT_APP_API_BASE_URL.length -4)
    return `
        <p>
            Dear ${_title},<br/><br/>
            Please find your directions ${_place && `to ${_place} `}in the attached PDF document.
        <p/>
        ${_booking ? `
        <p>
            We are pleased to advise the following reservation has been confirmed on your behalf:
        </p>
        <p style="white-space: pre;">${_booking}</p>
        <p>
            Should you wish to amend or cancel this reservation, please do not hesitate to contact the Concierge Desk.
        </p>
        <p>
            We wish you a most pleasant dinner.
        </p>`
        : ''
        }
        <p>
            Sincerely yours,<br/><br/>
            ${_hotel.name}<br/>
            ${_phoneNumber ? `${_phoneNumber}<br/>` : ``}
            ${_email}
        </p>
        ${_hotel.logo.url && _hotel.logo && _hotel.logo.url 
            ? `<div style="display: flex;"><img style="width: auto; max-height: 4rem;" src=${_baseImgUrl+_hotel.logo.url}></div>`
            : ''
        }
    `
}
// - Address: ${_place.location}
function getBookingTemplate(_place){
    return (
`    - Date / Time:
    - Persons:
    - Restaurant: ${_place.name}
    - Phone: ${_place.internationalPhoneNumber}
    - Confirmed by:
    - Dress code:
`
    )}

function SendPrintoutButton({
    busy,
    ...props
}) {
    return !busy
        ? (
            <Button {...props}>
                SEND
            </Button>
        )
        : (
            <CircularProgress size="2rem"/>
        )
}
function ToggleEmailForm({
    // open=false,
    ...props
}) {
    return <Button {...props}>
        <SettingsIcon />
        {/* {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} */}
    </Button>
}