import produce from 'immer';

export const poiSearchReducer = (state, action) => {
    // console.log('userFavReducer : ', { state, action })
    switch (action.type) {
        case 'RECEIVE_SEARCH_BY_KEYWORD_RESULTS':
            return produce(state,draft => {
                const prev = action.payload.metaData.page > 1 ? draft.results : [];
                if (action.payload.metaData.page === (state.metaData.page + 1)){
                    draft.results = [
                        ...prev,
                        ...action.payload.results
                    ]
                } else {
                    draft.results = []
                }
                draft.pageResult = action.payload.results;
                draft.metaData = {
                    ...action.payload.metaData
                }
                draft.hasNextPage = !(action.payload.metaData.pages === action.payload.metaData.page)
            })
        case 'ATTEMPT_SEARCH_BY_KEYWORD':
            return produce(state, draft => {
                draft.loading = action.payload.loading;
                draft.cancelToken = action.payload.cancelToken;
            })
        case 'COMPLETE_SEARCH_BY_KEYWORD':
            return produce(state, draft => {
                draft.loading = action.payload.loading
                draft.cancelToken = null
            })
        case 'ERROR_ON_SEARCH_BY_KEYWORD':
            return produce(state, draft => {
                draft.error = action.payload.error
            })
        case 'RESET_STATE':
            return produce(state, draft => {
                draft.hasNextPage = true;
            })
        default:
            return state;
    }
}