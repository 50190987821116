import React from 'react'
import { Box, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"
import { from, throwError } from "rxjs"
import { concatMap, mergeMap, toArray } from "rxjs/operators"
import { $lice, dispatch } from "slice"
import { openConfirmationDialog } from "../../../dialogs/confirmation/states/actions"
import { closeModal } from "../../../dialogs/standard/state/actions"
import { deleteHotelMapPlace, postHotelMapPlace, postUpdateHotelMapPlaces, putHotelMap } from "../../../http/tg/hotels"
import { showNotification } from "../../../notifications/state/actions"
import { editHotelMapReducer, INITIAL_EDIT_MAP_STATE, moveHotelPlace, resetHotelMap, resetHotelPlacesOrder, seedEditHotelMap, toggleOrderedMap, toggleSort, toggleSortedMap, updateHotelMap } from "../state/editHotelMapReducer"

// set up edit hotel map's slice 

export function useEditHotelMap(hotelMap){
    
    const [editHotelMap, setEditHotelMap] = useState(hotelMap)
    
    useEffect(function setupEditHotelMapSlice(){
        $lice('edithotelmap', editHotelMapReducer, INITIAL_EDIT_MAP_STATE)
        hotelMap && dispatch(seedEditHotelMap(hotelMap))

        return () => {}
    }, [hotelMap])

    useEffect( () => {
        const [state$] = $lice('edithotelmap')
        let sub = state$
            .subscribe({
                next: state => {
                    // console.log('new state', state)
                    setEditHotelMap(state)
                },
                error: err => {console.log(err)}
            })
        return () => {sub.unsubscribe()}
    }, [])
    
    return [editHotelMap, {
        save: apiSave,
        cancel: apiCancel,
        updateMap: apiUpdateMap,
        toggleOrderedMap: apiToggleOrderedMap,
        toggleSortedMap: apiToggleSortedMap,
        resetPlacesOrder: apiResetPlacesOrder,
        moveHotelPlace: apiMoveHotelPlace
    }]
}

/* 
    src: null,
    tgt: null,
    // props below are used onSave to send all the changes to the BE
    updateHotelMap: null,
    newHotelPlaces: [],
    deleteHotelPlaces: [],
    updateHotelPlaces: [],
    refreshToggle: false
*/
// concat(
//     of('first').pipe(delay(20000)),
//     of('second'),
//     of('third')
// ).subscribe({next: console.log.bind(0, 'concat')})

export function saveEditHotelMapAsync(editHotelMapState){
    // console.log(editHotelMapState)
    if(!editHotelMapState) return throwError('editHotelMap is undefined')

    return from(editHotelMapState.deleteHotelPlaces)
            .pipe(
                deletePlace,
                toArray()
            ).pipe(
                concatMap( x => from(editHotelMapState.newHotelPlaces)
                    .pipe(
                        postNewPlace(editHotelMapState),
                        toArray()
                    ))
            ).pipe(
                concatMap(x => postUpdateHotelMapPlaces(editHotelMapState.tgt.id, editHotelMapState.tgt.hotelPlaces))
            ).pipe(
                concatMap(x => putHotelMap(editHotelMapState.tgt))
            )
    // return concat(
    //     from(editHotelMapState.deleteHotelPlaces)
    //         .pipe(
    //             deletePlace,
    //             delay(5000)
    //         ),
    //     from(editHotelMapState.newHotelPlaces)
    //         .pipe(
    //             postNewPlace(editHotelMapState)
    //         ),
    //     postUpdateHotelMapPlaces(editHotelMapState.tgt.id, editHotelMapState.tgt.hotelPlaces),
    //     putHotelMap(editHotelMapState.tgt)
    // )
}

const deletePlace = hotelPlace$ => hotelPlace$
    .pipe(
        mergeMap( hotelPlace => {
            return deleteHotelMapPlace(hotelPlace.id, hotelPlace.hotelMap)
        })
    )

const postNewPlace = editHotelMap => newPlaces$ => {
    return newPlaces$.pipe(
        mergeMap(hp => {
            // console.log('hp =', hp.place.place.googlePlaceId)
            return postHotelMapPlace(hp, {
                hotelId: editHotelMap.tgt.hotel,
                hotelMapId: editHotelMap.tgt.id,
                googlePlaceId: hp.place.place.googlePlaceId
            })
        })
    )
}

function apiSave(editHotelMap){
    // console.log('save', editHotelMap)
    dispatch(openConfirmationDialog({
        title: 'READY TO UPDATE YOUR MAP?',
        text: <Box>
            <Typography style={{marginBottom: '1rem'}}>
                Your changes will be applied and automatically shown to guests who use this map already
            </Typography>
            <Typography>
                The QR code and URL link will not change.
            </Typography>
        </Box>,
        answerOptions:[ 'Cancel', 'Update'],
        onConfirm : ()=>{
            saveEditHotelMapAsync(editHotelMap)
                .subscribe({
                    next: res => {
                        dispatch({type: 'EDIT/HOTELMAP/MAKE/PRISTINE'})
                    },
                    error: err => {dispatch(showNotification({message: `We were unable to save All your edits, Please try again later.`}))},
                    complete: () => {
                        dispatch(closeModal())
                        dispatch(showNotification({message: 'Your edits have been saved'}))
                    }
                })
        },
        onDismissed : ()=>{
            dispatch(closeModal())
        }
    }))   
}
function apiUpdateMap(updates){
    dispatch(updateHotelMap(updates))
}
function apiCancel(){
    dispatch(resetHotelMap())
}
function apiToggleOrderedMap(){
    dispatch(toggleOrderedMap())
}
function apiToggleSortedMap(){
    dispatch(toggleSortedMap())
}
function apiResetPlacesOrder(){
    dispatch(resetHotelPlacesOrder())
}
function apiMoveHotelPlace(from, to){
    dispatch(moveHotelPlace(from, to))
}