import { useReducer, useEffect, useState } from 'react';
import {autocompleteReducers} from '../reducers/autocompleteReducers'

export const INITIAL_STATE = {
    loading: false,
    results: [],
    error : null,
    cancelToken : null
}

export const useStateAutocomplete = () => {

    const [autocomplete, dispatch] = useReducer(
        // reducer
        autocompleteReducers,
        // initial state
        INITIAL_STATE
    )

    const [cancelToken, setCancelToken] = useState(null)

    // cancelToken is renewd everytime API call is executed.
    useEffect(()=>{
        // If cancelToken is present, which means the previous http request is still in progress,
        // execute http request cancelation.
        if (cancelToken) {
            cancelToken.cancel('cancel from hook')
        }
        setCancelToken(autocomplete.cancelToken);
    },[autocomplete.cancelToken])

    return [autocomplete, dispatch];
}