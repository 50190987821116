import { Box, Button, Card, IconButton, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import ClearIcon from '@material-ui/icons/Clear'
import { useRouter, useSref } from '@uirouter/react';
import useStateBusinessMembershipForm from '../../../state/hooks/useStateBusinessMembershipForm'
import { dispatch } from 'slice';
import useOBStyles from '../onboarding.classes'
import usePlansStyles from './plans.classes'
import { COLORS } from '../../../../theme/colors';
import { PLANS, PLANS_PLAIN_TEXT } from '../../../utils';

export function MembershipPlans(props){
    // console.log('MembershipPlans', props)
    
    const router = useRouter()
    const previousSref = useSref('^')
    
    const form = useStateBusinessMembershipForm()
    const {
        selected_plan
    } = form
    
    const typo = useTypographyStyles()
    const obClasses = useOBStyles()
    const plansClasses = usePlansStyles()

    return (
        <Box
            className={`${obClasses.container}`}
        >
            <Box className={`${obClasses.stepHeading} noPaddingT`}>    
                <IconButton 
                    id="invite-back-to-membership-tour"
                    {...previousSref} 
                    className="backBtn clickable"
                >
                    <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
                </IconButton>
                <Typography 
                    className={`${typo.body1}`}
                    style={{color: COLORS.get('grey')[3]}}
                    align="center"
                >
                    STEP 1
                </Typography>
                <Typography 
                    className={`${typo.body1}`}
                    style={{color: COLORS.get('grey')[3]}}
                    align="center"
                >
                    {`>>`}
                </Typography>
                <Typography 
                    className={`${typo.BODY1}`}
                    align="center"
                >
                    <b>STEP 2: SELECT YOUR MEMBERSHIP</b>
                </Typography>
                <Typography 
                    className={`${typo.body1}`}
                    style={{color: COLORS.get('grey')[3]}}
                    align="center"
                >
                    {`>>`}
                </Typography>
                <Typography 
                    className={`${typo.body1}`}
                    style={{color: COLORS.get('grey')[3]}}
                    align="center"
                >
                    {`STEP 3`}
                </Typography>
            </Box>
            <Card
                component={Box}
                elevation={4}
                px={2}
                py={4}
                mt={2}
            >
                {/* <Typography
                    color="primary"
                    className={`
                        ${typo.subtitle6}
                        ${obClasses.mbL}`}
                    align="center"
                >
                    START CONNECTING TODAY
                </Typography> */}
                <Box className={plansClasses.plansgrid}>

                    <Card component={Box}
                        elevation={4}
                        className={`${plansClasses.planBox} free selected`}
                    >
                        <Typography
                            className={`
                                ${typo.h5}
                                ${obClasses.mS}`}
                            align="center"
                        >
                            <b>BASIC<br/>MEMBERSHIP</b>
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                            align="center"
                        >
                            <i>Important info, at a glance</i>
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography
                                className={`
                                    ${typo.subtitle3}
                                    ${obClasses.mM}`}
                                style={{paddingRight: '.5rem'}}
                            >
                                10€
                            </Typography>
                            <Typography
                                className={`${typo.body2}`}
                            >
                                / month + Setup Fee
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button variant="contained"
                                className={`select_plan`}
                            >
                                <CheckRoundedIcon></CheckRoundedIcon>
                                <Typography 
                                    className={`${typo.h4}`}
                                >
                                    SELECTED
                                </Typography>
                            </Button>
                        </Box>

                    </Card>

                    <SelectableMembershipPlan plan={1} selected={selected_plan.value === 1}>
                        <Typography
                            className={`
                                ${typo.h5}
                                ${obClasses.mS} blue`}
                            align="center"
                        >
                            <b>DIGITAL MAPS<br/>UPGRADE</b>
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                            align="center"
                        >
                            <i>Optimise what guests see when<br/>concierges recommend you</i>
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography
                                className={`
                                    ${typo.subtitle3}
                                    ${obClasses.mM}`}
                                style={{paddingRight: '.5rem'}}
                            >
                                35€
                            </Typography>
                            <Typography
                                className={`${typo.body2}`}
                            >
                                / month + Setup Fee
                            </Typography>
                        </Box>
                    </SelectableMembershipPlan>
                    
                    <SelectableMembershipPlan plan={2} selected={selected_plan.value === 2}>
                        <Typography
                            className={`
                                ${typo.h5}
                                ${obClasses.mS} blue`}
                            align="center"
                        >
                            <b>DIGITAL MAPS + <br/><span className="circleTxt">CIRCLE</span> UPGRADE</b>
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                            align="center"
                        >
                            <i>Optimise how Circle concierges<br/>engage with your business</i>
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography
                                className={`
                                    ${typo.subtitle3}
                                    ${obClasses.mM}`}
                                style={{paddingRight: '.5rem'}}
                            >
                                65€
                            </Typography>
                            <Typography
                                className={`${typo.body2}`}
                            >
                                / month + Setup Fee
                            </Typography>
                        </Box>
                    </SelectableMembershipPlan>

                    <Card component={Box}
                        elevation={4}
                        className={`${plansClasses.planBox} disabled`}
                    >
                        <Typography
                            className={`
                                ${typo.h5}
                                ${obClasses.mS}`}
                            align="center"
                        >
                            <b>INDUSTRY ICON UPGRADE</b>
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                            align="center"
                        >
                            <i>The ultimate tool kit to establish<br/> & maintain your reputation in<br/> the Circle</i>
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                            align="center"
                        >
                            
                        </Typography>
                        <Typography align="center"
                            className={`
                                ${typo.h6}
                                ${obClasses.mM}`}
                        >
                            SPECIAL INVITE ONLY
                        </Typography>
                    </Card>

                    <Card component={Box}
                        className={`${plansClasses.summaryBox}`}
                    >
                        <Typography
                            className={`${typo.h4} ${obClasses.mM}`}
                            align="center"
                        >
                            SUMMARY OF YOUR SELECTION
                        </Typography>
                        {/* Show current selection */}
                        <table className={`${plansClasses.summaryTable}`}>
                            <tbody>
                                <tr className="header">
                                    <td>
                                        <Typography
                                            className={typo.subtitle6}
                                        >
                                            Basic Member<br/>
                                            <span className={`${typo.caption1}`}>One year</span>
                                        </Typography>
                                    </td>
                                    <td>
                                        <Typography
                                            className={typo.subtitle6}
                                        >
                                            FREE
                                        </Typography>
                                    </td>
                                </tr>
                                {selected_plan.value > 0 && (
                                    <tr>
                                        <td>
                                            <Typography
                                                className={typo.subtitle6}
                                            >
                                                {PLANS[selected_plan.value].name}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography
                                                className={typo.subtitle6}
                                            >
                                                {`${PLANS[selected_plan.value].price}/month`}
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                                {selected_plan.value > 0 && (
                                    <tr>
                                        <td>
                                            <Typography
                                                className={typo.subtitle6}
                                            >
                                                Setup Fee
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography
                                                className={typo.subtitle6}
                                            >
                                                200€
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </Card>
                </Box>
            </Card>
        </Box>
    )
}

function SelectableMembershipPlan({children, plan, selected, ...props}){

    const plansClasses = usePlansStyles()
    const typo = useTypographyStyles()

    const handleTogglePlan = plan => e => {
        dispatch({
            type: 'BUSINESS/INVITE/FORM/SET',
            payload: {
                key: 'selected_plan',
                value: selected ? 0 : plan
            }
        })
    }

    const [hover, setHover] = useState(false)

    return (
        <Card 
            component={Box}
            elevation={4}
            className={`${plansClasses.planBox} ${selected ? 'selected' : ''}`}
            {...props}
        >
            {children}
            <Box display="flex" justifyContent="center">
                <Button 
                    id={`invite-select-plan-${PLANS_PLAIN_TEXT[plan]}`}
                    onClick={handleTogglePlan(plan)} 
                    variant="contained"
                    className={`select_plan clickable`}
                    onMouseEnter={e => {setHover(true)}}
                    onMouseLeave={e => {setHover(false)}}
                >
                    {selected && hover 
                        ? <ClearIcon /> 
                        : selected 
                            ? <CheckRoundedIcon></CheckRoundedIcon> 
                            : null}
                    <Typography 
                        className={`${typo.h4}`}
                    >
                        {/* {selected ? 'SELECTED' : 'ADD'} */}
                        {selected ? hover ? 'REMOVE' : 'SELECTED' : 'ADD'}
                    </Typography>
                </Button>
            </Box>
        </Card>
    )
}

export default MembershipPlans