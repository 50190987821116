import React, {useState} from 'react';
import {InputAdornment, IconButton, TextField} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function PasswordInput(props){
    const [showPassword, setShowPassword] = useState(false)
    return (
    <TextField 
        {...props}
        type={showPassword? 'text' : 'password'}
        InputProps={{
            endAdornment: 
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={e => {setShowPassword(!showPassword)}}
                    onMouseDown={e => {e.preventDefault()}}
                    >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
        }}
    ></TextField>)
}