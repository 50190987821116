import { Box, Button, ButtonGroup } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import MapIcon from '@material-ui/icons/MapOutlined';
import { useCurrentStateAndParams, useSrefActiveExact } from '@uirouter/react';
import React, { useEffect } from 'react';
import Mixpanel from '../../../../../lib/mixpanel';
import { tgMakeStyles } from '../../../../../lib/styles/TgMakeStyles';
import { useTypographyStyles } from '../../../../theme/useTypography';

const useStyles = tgMakeStyles(theme => ({
    buttonGroupRoot: {
        borderRadius: '0px',
        // because this is a reusable compnent :
        // set 0 margin to override theme default (=1). 
        // The defafult margin is provided by the enclosing Box component instead and is override-able with BoxProps
        margin: 0,
        '& a': {
            backgroundColor: theme.palette.primary.inactive
        },
        '& a.active': {
            backgroundColor: theme.palette.primary.main
        }
    }
}))

export default function DigitalMapDrawerToggle(props){
    const {hotel, map, BoxProps, ...forwardedProps} = props
    const classes = useStyles()
    const typography = useTypographyStyles()
    
    const {state} = useCurrentStateAndParams()

    const srefActiveList = useSrefActiveExact(state.name, {list: true}, 'active')
    const srefActiveMap = useSrefActiveExact(state.name, {list: false}, 'active')
    return (
        <Box m={1} p={0} {...BoxProps}>
            <ButtonGroup
                className={classes.buttonGroupRoot}
                {...forwardedProps}
            >
                <Button
                    color={'primary'}
                    variant='contained'
                    startIcon={<MapIcon />}
                    classes={{
                        label: typography.subtitle5
                    }}

                    {...srefActiveMap}
                >
                    Map
                </Button>
                <Button
                    color={'primary'}
                    variant='contained'
                    endIcon={<ListIcon />}
                    classes={{
                        label: typography.subtitle5
                    }}

                    {...srefActiveList}
                >
                    List
                </Button>
            </ButtonGroup>
        </Box>
    )
}