import { Box } from '@material-ui/core';
import React from 'react';
import { tgMakeStyles } from '../../../../../../lib/styles/TgMakeStyles';

const useFluidStyles = tgMakeStyles(theme => ({
    container: {
        display: "flex",
        flexFlow: "row wrap",
        '& > div' : {
            [theme.breakpoints.up('sm')]: {
                "&:nth-child(1)": {
                    width:'50%',
                    order: 1
                },
                "&:nth-child(2)": {
                    width:'50%',
                    order: 2
                },
            },
            [theme.breakpoints.down('sm')]: {
                width:'100%',
            },
        },
        
    },
}))

export const FluidBox = ({children}) => {
    const classes = useFluidStyles();
    return (
        <Box className={classes.container}>
            {children && React.Children.map(children,(Child)=>(
                <Box>
                    {Child}
                </Box>
            ))}
        </Box>
    );
}

const useFeatureGridDisplayStyles = tgMakeStyles(theme => ({
    box : {
        margin : `${theme.spacing(2)} ${theme.spacing(4)}`
    }
}))

export const FeatureGridDisplay = (props) => {
    const {featureCategories} = props;
    const classes = useFeatureGridDisplayStyles();
    return (
        <FluidBox>
            {featureCategories.map((category,idx)=>{
                if(idx>0) return null;
                return <Box key={idx} className={classes.box}>
                    <category.headerNode gutterBottom={true}/>
                    <category.listsComp />
                </Box>
            })}
        </FluidBox>
    )
}