import { TG_API } from '../';
import { from } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

const BASE_URL = '/ldm/business'

export function getBusiness(id){
    const reqConfig = {
        method: 'GET',
        url: BASE_URL+'/api/'+id
    }
    return from(TG_API(reqConfig))
}
export function postRefreshBusiness(id){
    const reqConfig = {
        method: 'POST',
        url: `${BASE_URL}/${id}/refresh`
    }
    return from(TG_API(reqConfig))
}
export function putBusiness(id, payload){
    const reqConfig = {
        method: 'PUT',
        url: BASE_URL+'/api/'+id,
        data: payload
    }
    return from(TG_API(reqConfig))
}
export function getNewBusinesses(){
    const reqConfig = {
        method: 'GET',
        url: BASE_URL+'/recent'
    }
    return from(TG_API(reqConfig))
}

export function getBusinessPropertyVisibility(id){
    const reqConfig = {
        method: 'GET',
        url: `${BASE_URL}/${id}/propertyVisibility`
    }
    return from(TG_API(reqConfig))
}
export function postBusinessPropertyVisibility(id, {domain, property, visible}){
    const reqConfig = {
        method: 'POST',
        url: `${BASE_URL}/${id}/propertyVisibility`,
        data: {
            domain, property, visible
        }
    }
    return from(TG_API(reqConfig))
}

export function postBusinessPlaceAttribute(id, {key, value, replace=true}){
    const reqConfig = {
        method: 'POST',
        url: `${BASE_URL}/${id}/attributes`,
        data: {
            key, value, replace
        }
    }
    return from(TG_API(reqConfig))
}
export function deleteBusinessPlaceAttribute(id, {key, value}){
    const reqConfig = {
        method: 'DELETE',
        url: `${BASE_URL}/${id}/attributes`,
        data: {
            key, value
        }
    }
    return from(TG_API(reqConfig))
}

export function uploadBusinessImageCover(id, formData){
    // /ldm/business/[ID]/images/[IMAGE ID]
    const upReqConfig = {
        method: 'POST',
        url: `${BASE_URL}/${id}/imageUpload`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    const imageIsCoverReqConfig = imageId => ({
        method: 'PUT',
        url: `${BASE_URL}/${id}/images/${imageId}`,
        data: {
            action: "prioritize"
        }
    })

    return from(TG_API(upReqConfig))
        .pipe(
            take(1),
            map(res => res.data),
            tap(console.log.bind(0, 'uploadBusinessImageCover')),
            concatMap( image => from(TG_API(imageIsCoverReqConfig(image.id))))
        )
}

export function deleteBusinessImage(bizId, {id: imageId}){
    const reqConfig = {
        method: 'DELETE',
        url: `${BASE_URL}/${bizId}/images/${imageId}`
    }
    return from(TG_API(reqConfig))
}