import { Subject } from "rxjs"
import { dispatch } from 'slice'
import { INITIAL_STATE } from '../reducers/index'

/**
 * Display confirmation dialog, which can co-exist with other modals.
 * text : Text
 * TextComponent : component to be injected into the place of text.
 * title : titile 
 * answerOptions : array of answers for confirmation. 
 * onConfirm : Callback executed after an confirmation. If answers are provided more than 2, callback is executed with index of chosen answer.
 * dialogProps : dialogProps to be applied to Dialog component
 * onDismissed : Callback executed when user dismiss the confirmation
 */
export const openConfirmationDialog = ({ 
    text, 
    TextComponent,
    title,
    TitleComponent,
    answerOptions, 
    onConfirm = ()=>{}, 
    dialogProps,
    onDismissed = ()=>{} 
}) => {
    const subject = new Subject();

    dispatch(setUpDialog({ text, title, answerOptions, subject, dialogProps,TextComponent, TitleComponent }))

    subject.subscribe({
        next: (isConfirmed) => {
            isConfirmed ? onConfirm() : onDismissed()
        },
        complete: () => {
            dispatch(closeDialog());
        },
        error: console.error
    })
}

const setUpDialog = ({ text, title, answerOptions, onConfirm, subject, dialogProps,TextComponent, TitleComponent }) => {
    return {
        type: 'OPEN_CONFIRMATION_DIALOG',
        payload: {
            text, title, answerOptions, onConfirm, subject, dialogProps,TextComponent, TitleComponent,
            open: true
        }
    }
}

export const closeDialog = () => {
    return {
        type: 'CLOSE_CONFIRMATION_DIALOG',
        payload: {
            ...INITIAL_STATE
        }
    }
}





