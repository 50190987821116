import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles";
import { TYPOGRAPHY_STYLES } from "../../../../theme/typographies";

export const useStyles = tgMakeStyles(theme => {
    return {
        welcomeCircle: {
            color: '#0E0E32'
        },

        halo1: {
            position: 'absolute',
            bottom: 0, left: 0,
            transform: 'translate(-50%, -15%)'
        },
        halo2: {
            position: 'absolute',
            top: 0, right: 0,
            transform: 'translate(50%, -50%)'
        },
        networkImg: {
            width: '100%',
            height: 'auto',
            padding: '0 1rem 0 1rem'
        },

        sectionHeading: {
            ...TYPOGRAPHY_STYLES.h0
        },
        sectionSubtitle: {
            ...TYPOGRAPHY_STYLES.h6
        },
        title: {
            ...TYPOGRAPHY_STYLES.h4,
            textTransform: 'none'
        },
        paragraph: {
            ...TYPOGRAPHY_STYLES.h8
        },


        grid1: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: 'minmax(auto, 20rem) auto minmax(auto, 20rem)',
            gridTemplateRows: '1.5rem repeat(4, auto)',
            gridGap: '1rem',
            '& > *:nth-child(1)': {
                gridArea: '1/1/span 3/span 1'
            },
            '& > *:nth-child(2)': {
                gridArea: '2/2/span 4/span 1',
                zIndex: 1
            },
            '& > *:nth-child(3)': {
                gridArea: '1/3/span 2/span 1'
            },
            '& > *:nth-child(4)': {
                gridArea: '3/3/span 1/span 1'
            },
            '& > *:nth-child(5)': {
                gridArea: '4/3/span 1/span 1'
            }
        },
        profileGif: {
            padding: '.5rem',
            width: 'auto',
            height: '65vh',
            borderRadius: '1rem'
        },
        infoExample: {
            borderRadius: '1rem',
            width: '100%',
            padding: '1rem',
            textAlign: 'center',
            '& > *': {
                ...TYPOGRAPHY_STYLES.body1
            }
        },


        firstImpressionImgContainer: {
            display: 'grid',
            gridTemplateColumns: '40% repeat(4, auto)',
            gridTemplateRows: 'repeat(3, auto)',
            '& > img': {
                width: '100%',
                height: 'auto',
                borderRadius: '1rem'
            },
            '& img:nth-child(1)': {
                gridArea: '3 / 1 / span 2 / span 3',
                border: '1px solid grey',
                borderRadius: '50%'
            },
            '& img:nth-child(2)': {
                gridArea: '1 / 2 / span 3 / -1'
            }
        },
        firstImpressionIcons: {
            display: 'inline-grid',
            gridTemplateColumns: 'repeat(3, auto)',
            columnGap: '1rem',
            width: 'auto',
            '& > *': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '6.5rem',
                height: '6.5rem',
                color: 'white',
                backgroundColor: '#292952',
                padding: '1rem .5rem 1rem .5rem',
                borderRadius: '1rem'
            }
        },
        
        grid2: {
            overflow: 'visible',
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            gridTemplateRows: 'auto auto 5vh',
            '& img': {
                borderRadius: '1rem'
            },
            '& > *:nth-child(1)': {
                gridArea: '2 / 1 / span 1 / span 2',
                height: 'auto',
                width: '100%',
                paddingBottom: '1rem'
            },
            '& > *:nth-child(2)': {
                gridArea: '1 / 2 / -1 / -1',
                width: 'auto',
                height: '60vh',
                zIndex: 1
            }
        },
        hotelImgContainer:  {
            overflow: 'hidden',
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: '2% 38% 14% 23% 23%',
            gridTemplateRows: 'auto repeat(5, auto)',
            rowGap: '1rem',
            '& > img': {
                width: '100%',
                height: 'auto',
                borderRadius: '1rem'
            },
            '& > img:nth-child(1)': { //
                gridArea: '1 / 1 / span 3 / span 2',
            },
            '& > img:nth-child(2)': { // map
                gridArea: '3 / 2 / -1 / 5',
                height: '48vh',
                width: 'auto'
            },
            '& > img:nth-child(3)': {
                gridArea: '2 / 4 / span 2 / -1'
            },
            '& > img:nth-child(4)': {
                gridArea: '4 / 4 / span 1 / -1'
            },
            
        }
            
        // }
    }
})

export default useStyles