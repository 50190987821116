import React from 'react'
import { Box } from '@material-ui/core'

export function ImageBox(props) {
    // const _bg = props.background || {}
    const {background = {}, style, ...others} = props
    return props.src 
        ? (
            <Box {...others} style={{
                backgroundImage: `url("${props.src}")`,
                backgroundPosition: background.position || 'center center',
                backgroundRepeat: background.repeat || 'no-repeat',
                backgroundSize: background.size || 'contain',
                backgroundOrigin: background.origin || 'initial',
                backgroundClip: background.clip || 'initial',
                ...style
            }}>
            </Box>
        )
        : null
}

export default ImageBox