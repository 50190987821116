import { Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import React, { useState } from 'react';
import { tgMakeStyles } from '../../../../../../lib/styles/TgMakeStyles';
import Mixpanel from '../../../../../../lib/mixpanel'

const useExpansionPanelStyles = tgMakeStyles(theme => ({
    root : {
        boxShadow : theme.shadows[3]
    },
    iconButtonRoot : {
        padding: 0,
        margin : 0
    }
}))

// Component to display feature for desktop view.
export const ExpansionPanelItem = ({
    isMobile = false, 
    children, 
    expandIcon,
    summaryText, 
    feature
}) => {

    const [expanded, setExpanded] = useState(false)
    const handleExpandCard = ()=>{
        !expanded && Mixpanel('track')('click_poi', {
            GooglePlaceID: feature.getProperty('googlePlaceId'),
            poiName: feature.getProperty('name'),
        })
        setExpanded(!expanded)
    }
    
    const classes = useExpansionPanelStyles()
    
    const expantionProps = {
        isMobile
    }
    const IconButtonProps = {
        classes : {
            root : classes.iconButtonRoot
        },
        edge : false,
    }

    return (
        // Extension panel here is diplayed as a part of PoiInfocard and it needs to be styled diffrently 
        <ExpansionPanel 
            expanded={expanded}
            classes={{
                root : classes.root
            }}>
            <TgExpansionPanelSummary
                onClick={handleExpandCard}
                expandIcon={expandIcon}
                IconButtonProps={IconButtonProps}
                {...expantionProps}>
                    <Box width="100%" mr={2}>
                        {React.cloneElement(summaryText, { expanded })}
                    </Box>
            </TgExpansionPanelSummary>
            <TgExpansionPanelDetails {...expantionProps}>
                {expanded && children}
            </TgExpansionPanelDetails>
        </ExpansionPanel>
    )
}

const useExpansionPanelSummaryStyle = tgMakeStyles(theme => ({
    root : {
        padding : ({isMobile})=>`${theme.spacing(2)} ${theme.spacing(isMobile ? 2 : 5.5)}`,
        margin : 0,
    },
    content : {
        margin : 0,
        flex: 1,
        width:'100%',
        minWidth: 0
    },
    expanded : {
        margin : '0 !important',
        paddingBottom : '0 !important' 
    },
    expanded : ({isMobile}) => {
        let style = isMobile ? 
        {
            flex: 'initial !important',
            minWidth: 'initial !important'
        } : {}

        return {
            margin : '0 !important',
            paddingBottom : '0 !important',
            ...style
        }
    }
}))

const TgExpansionPanelSummary = ({children,isMobile, ...others}) => {

    const classes = useExpansionPanelSummaryStyle({isMobile});
    return (
        <ExpansionPanelSummary classes={{
            root : classes.root,
            content : classes.content,
            expanded : classes.expanded,
        }} 
        {...{...others}} >
            {children}
        </ExpansionPanelSummary>
    )
    
}

const useExpansionPanelDetailsStyle = tgMakeStyles(theme => ({
    root : {
        padding : ({isMobile})=>`${theme.spacing(isMobile ? 2 : 5.5)}`,
        paddingTop : '0 !important',
        display:'block'
    },
}))

const TgExpansionPanelDetails = ({
    children,
    isMobile, 
    ...others
}) => {
    const classes =  useExpansionPanelDetailsStyle({isMobile})

    return (    
        <ExpansionPanelDetails classes={{
            root : classes.root,
        }} 
        {...others}>
            {children}
        </ExpansionPanelDetails>
    )
}
