import React from 'react'
import { dispatch } from "slice";
import { closeContextDrawer, setContextDrawer } from "../../../contextDrawer/state/actions";


export function openFeatureInContextDrawer({
    Component, 
    props,
    fullScreen,
    noContainerPadding = false
}){
    /**
     * This function could be triggered through google.maps.event.trigger
     * In this case, the function could be called with onFeatureClickEvent = null , feature = feature.
     * 
     * Notes : calling trigger like below would do the same thing without the need for different function params :
     * > window.google.maps.event.trigger(gmData, 'click', {feature})
     */
    return (onFeatureClickEvent,feature) => {
        if (onFeatureClickEvent) onFeatureClickEvent.stop();
        dispatch(closeContextDrawer())
        const _feature = onFeatureClickEvent ? onFeatureClickEvent.feature : feature
        // Set pin highlighted.
        _feature.setProperty('highlighted', true)
        // function to be called when drawer is closed 
        const onCloseFn = ()=>{
            // Toggle pin highlighted.
            _feature.setProperty('highlighted', false)
        }
    
        const drawerProps = {
            component : (_props)=>(
                    <Component 
                        {..._props} 
                        {...props} 
                        feature={_feature} 
                        onDrawerClose={onCloseFn}
                    />
                ),
            onCloseFn,
            fullScreen: fullScreen,
            noPadding: noContainerPadding
        }
    
        dispatch(setContextDrawer(drawerProps))
    }
}