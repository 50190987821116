import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { dispatch } from 'slice';
import { showNotification } from './modules/notifications/state/actions';
import CloseNotificationButton from './modules/notifications/components/CloseNotificationButton';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const SWConfig = {
    onUpdate: (registration) => {
        try {
            navigator && navigator.vibrate(30)
        } catch (error) {
            void 0
        }
        dispatch(showNotification({
            message: 'A new version of TasteGods is available! Close all open TasteGods windows & then re-open a new one... voilà, enjoy our latest features!',
            duration: null,
            action: [
                (<CloseNotificationButton />)
            ]
        }))
    }
}
serviceWorker.register(SWConfig);
// serviceWorker.unregister();
