import React from 'react'
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles'
import useStateAuthStatus from '../../authentication/state/hooks/useStateAuthStatus'
import OpenModalLink from '../../dialogs/standard/components/OpenStandardDialog.Link'
import { useTypographyStyles } from '../../theme/useTypography'
import InvitationCodeForm from './InvitationCode.form'

const useStyles = tgMakeStyles(theme => ({
    paper: {
        backgroundColor: 'rgba(41,41,59,0.95)',
        color: 'white',
        paddingTop: '4rem',
        '& .MuiDialogContent-root': {
            height: '100%',
            width: '100%'
        }
    }
}))

export function InvitationCodeLink(forwardProps){
    
    const typography = useTypographyStyles()
    const classes = useStyles()
    const {isAuthenticated} = useStateAuthStatus()

    return !isAuthenticated
        ? (
            <OpenModalLink
                {...forwardProps}
                modalComponent={InvitationCodeForm}
                modalComponentProps={{x: 'bisous'}}
                color='inherit'
                className={`${forwardProps.className || ''} ${typography.body1}`}
                modalFullScreen={true}
                DialogProps={{
                    hideBackdrop: true,
                    classes: {
                        paper: classes.paper
                    }
                }}
            >
            </OpenModalLink>
        )
        : null
}

export default InvitationCodeLink