export const TYPOGRAPHY_STYLES = {
    h0: {
        fontSize: '2.75rem',
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    h7: {
        fontSize: '2.75rem',
        fontWeight: 300,
        textTransform: 'uppercase'
    },
    h1: {
        fontSize: '1.75rem',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    h2: {
        fontSize: '1.625rem',
        fontWeight: 400,
        textTransform: 'none'
    },
    h3: {
        fontSize: '1.375rem',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    h4: {
        fontSize: '1.375rem',
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    h5: {
        fontSize: '1.375rem',
        fontWeight: 700,
        textTransform: 'none'
    },
    h6: {
        fontSize: '1.25rem',
        fontWeight: 400,
        textTransform: 'none'
    },
    h8: {
        fontSize: '1.25rem',
        fontWeight: 300,
        textTransform: 'none'
    },
    subtitle1: {
        fontSize: '1.125rem',
        fontWeight: 700,
        textTransform: 'none'
    },
    subtitle2: {
        fontSize: '1.125rem',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    subtitle3: {
        fontSize: '1.125rem',
        textTransform: 'uppercase',
        fontWeight: 700
    },
    subtitle4: {
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'none'
    },
    subtitle5: {
        fontSize: '1rem',
        fontWeight: 400,
        textTransform: 'uppercase'
    },
    subtitle6: {
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    body1: {
        fontSize: '1rem',
        fontWeight: 400,
        textTransform: 'none'
    },
    body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        textTransform: 'none'
    },
    body3: {
        fontSize: '0.875rem',
        fontWeight: 700,
        textTransform: 'none'
    },
    body4: {
        fontSize: '0.75rem',
        fontWeight: 400,
        textTransform: 'none'
    },
    body5: {
        fontSize: '1.625rem',
        fontWeight: 300,
        textTransform: 'none'
    },
    caption1: {
        fontSize: '0.625rem',
        fontWeight: 400,
        textTransform: 'none'
    },
    BODY1: {
        fontSize: '1.375rem',
        fontWeight: 300,
        textTransform: 'none'
    },
}