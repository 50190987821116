import { Box, Button, Card, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import svg01 from '../../../../../assets/svg/icon-diamond.svg'
import { useRouter, useSref } from '@uirouter/react';
import useStateBusinessMembershipForm from '../../../state/hooks/useStateBusinessMembershipForm'
import { dispatch } from 'slice';
import useOBStyles from '../onboarding.classes'
import usePlansStyles from './plans.classes'
// import { COLORS } from '../../../../theme/colors';
import { PLANS, PLANS_PLAIN_TEXT } from '../../../utils';

export function MembershipPlans(props){
    
    const router = useRouter()
    const previousSref = useSref('^')
    
    const form = useStateBusinessMembershipForm()
    const {
        selected_plan
    } = form
    
    const typo = useTypographyStyles()
    const obClasses = useOBStyles()
    const plansClasses = usePlansStyles()
    

    // const handleClickContinue = e => {
    //     router.stateService.go('root.welcome.invited.tour.plans.business_details', {selected_plan: selected_plan.value})
    // }

    return (
        <Box className={`${obClasses.container}`}>
            <Box 
                className={`${obClasses.stepHeading} noPadding`}
            >
                <IconButton 
                    id="invite-back-to-membership-tour"
                    {...previousSref} 
                    className="backBtn clickable"
                >
                    <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
                </IconButton>
                <Typography 
                    className={typo.subtitle6} 
                    align="center"
                >
                    STEP 2: SELECT YOUR MEMBERSHIP
                </Typography>
            </Box>
            {/* <Typography
                color="primary"
                className={`
                    ${typo.subtitle6}
                    ${obClasses.mL}`}
                align="center"
            >
                START CONNECTING TODAY
            </Typography> */}
            <Box className={`
                ${plansClasses.plansgrid}
                ${obClasses.mtS}`}
            >

                <Card component={Box}
                    elevation={4}
                    className={`${plansClasses.planBox} free selected`}
                >
                    <Typography
                        className={`
                            ${typo.h5}
                            ${obClasses.mbS}`}
                    >
                        BASIC MEMBERSHIP
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Typography
                            className={`${typo.subtitle3}`}
                            style={{paddingRight: '.5rem'}}
                        >
                            10€
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                        >
                            / month + Setup Fee
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained"
                            className={`select_plan`}
                        >
                            <CheckRoundedIcon></CheckRoundedIcon>
                            <Typography 
                                className={`${typo.h4}`}
                            >
                                SELECTED
                            </Typography>
                        </Button>
                    </Box>

                </Card>

                <SelectableMembershipPlan plan={1} selected={selected_plan.value === 1}>
                    <Typography
                        className={`
                            ${typo.h5}
                            ${obClasses.mbS}`}
                    >
                        DIGITAL MAPS UPGRADE
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Typography
                            className={`${typo.subtitle3}`}
                            style={{paddingRight: '.5rem'}}
                        >
                            35€
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                        >
                            / month + Setup Fee
                        </Typography>
                    </Box>
                </SelectableMembershipPlan>
                
                <SelectableMembershipPlan plan={2} selected={selected_plan.value === 2}>
                    <Typography
                        className={`
                            ${typo.h5}
                            ${obClasses.mbS}`}
                            align="center"
                    >
                        DIGITAL MAPS + <span className="circleTxt">CIRCLE</span> UPGRADE
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Typography
                            className={`${typo.subtitle3}`}
                            style={{paddingRight: '.5rem'}}
                        >
                            65€
                        </Typography>
                        <Typography
                            className={`${typo.body2}`}
                        >
                            / month + Setup Fee
                        </Typography>
                    </Box>
                </SelectableMembershipPlan>

                <Card component={Box}
                    elevation={4}
                    className={`${plansClasses.planBox} disabled`}
                >
                    <img src={svg01}></img>
                    <Typography
                        className={`
                            ${typo.h5}
                            ${obClasses.mbS}`}
                        align="center"
                    >
                        INDUSTRY ICON UPGRADE
                    </Typography>
                    <Typography 
                        className={`
                            ${typo.body2}
                            ${obClasses.mbS}`}
                        align="center"
                    >
                        <i>The ultimate tool kit to establish & maintain your reputation in the Circle</i>
                    </Typography>
                    <Typography align="center"
                    >
                        SPECIAL INVITE ONLY
                    </Typography>
                </Card>

                <Card component={Box}
                    className={`${plansClasses.summaryBox}`}
                >
                    <Typography
                        className={typo.h4}
                        align="center"
                    >
                        SUMMARY OF YOUR SELECTION
                    </Typography>
                    {/* Show current selection */}
                    <Box p={2}
                        display="flex" justifyContent="space-between"
                    >
                        <Typography
                            className={typo.subtitle6}
                        >
                            Basic Member<br/>
                            <span className={`${typo.caption1}`}>1 year free membership</span>
                        </Typography>
                        <Typography
                            className={typo.subtitle6}
                        >
                            0€
                        </Typography>
                    </Box>
                    {selected_plan.value > 0 && (
                        <Box p={2}
                            display="flex" justifyContent="space-between"
                        >
                            <Typography
                                className={typo.subtitle6}
                            >
                                {PLANS[selected_plan.value].name}
                            </Typography>
                            <Typography
                                className={typo.subtitle6}
                            >
                                {`${PLANS[selected_plan.value].price}/month`}
                            </Typography>
                        </Box>
                    )}
                    <Box p={2}
                        display="flex" justifyContent="space-between"
                    >
                        <Typography
                            className={typo.subtitle6}
                        >
                            Setup Fee<br/>
                            <span className={`${typo.caption1}`}>100€ fee waived</span>
                        </Typography>
                        <Typography
                            className={typo.subtitle6}
                        >
                            0€
                        </Typography>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

function SelectableMembershipPlan({children, plan, selected, ...props}){

    const plansClasses = usePlansStyles()
    const typo = useTypographyStyles()

    const handleTogglePlan = plan => e => {
        dispatch({
            type: 'BUSINESS/INVITE/FORM/SET',
            payload: {
                key: 'selected_plan',
                value: selected ? 0 : plan
            }
        })
    }

    return (
        <Card 
            component={Box}
            elevation={4}
            className={`${plansClasses.planBox} ${selected ? 'selected' : ''}`}
            {...props}
        >
            {children}
            <Box display="flex" justifyContent="flex-end">
                <Button 
                    id={`invite-select-plan-${PLANS_PLAIN_TEXT[plan]}`}
                    onClick={handleTogglePlan(plan)} 
                    variant="contained"
                    className={`select_plan clickable`}
                >
                    {selected && <CheckRoundedIcon></CheckRoundedIcon>}
                    <Typography 
                        className={`${typo.h4}`}
                    >
                        {selected ? 'SELECTED' : 'ADD'}
                    </Typography>
                </Button>
            </Box>
        </Card>
    )
}

export default MembershipPlans