import { TG_API } from '../';
import { from } from 'rxjs';

const BASE_URL = '/ldm/place'

export function getPlace(id){
    const reqConfig = {
        method: 'GET',
        url: BASE_URL+'/'+id
    }
    return from(TG_API(reqConfig))
}