import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useStateConfirmation } from '../states/hooks/useStateConfirmation';
import {useTypographyStyles} from '../../../theme/useTypography'
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';

const useConfirmationDialogStyles = tgMakeStyles(theme => ({
    TitleRoot: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    ActionsRoot: {
        justifyContent: 'center'
    },
    ContentTextRoot: {
        margin: theme.spacing(1),
        textAlign: 'center'
    }
}))

export const ConfirmationDialog = () => {
    const typography = useTypographyStyles()
    const classes = useConfirmationDialogStyles()
    const confirmation = useStateConfirmation();

    // for questions which has has more than 2 answers
    const onOptionsClick = (idx) => {
        confirmation.subject.next(idx)
        confirmation.subject.complete()
    }

    // for questions which is yes or no
    const answerYesNoQuestion = (bool) => { 
        confirmation.subject.next(bool);
        confirmation.subject.complete()
    }

    return (
        <>
        {confirmation.open 
            ? (
                <Dialog 
                    classes={{
                        paper: classes.DialogPaper
                    }}
                    open={confirmation.open}
                    {...confirmation.dialogProps}
                >
                    {confirmation.TitleComponent
                        ? <confirmation.TitleComponent />
                        :  confirmation.title
                            ? (
                                <DialogTitle 
                                    disableTypography={true}
                                    classes={{root: classes.TitleRoot}}
                                    className={typography.h1}
                                >
                                    {confirmation.title}
                                </DialogTitle>
                            )
                            : null
                    }
                    <DialogContent>
                        {confirmation.TextComponent 
                            ? <confirmation.TextComponent /> 
                            : confirmation.text 
                                ? (
                                    <DialogContentText
                                        component={'div'}
                                        classes={{
                                            root: classes.ContentTextRoot
                                        }}
                                        className={typography.h6}
                                    >
                                        {confirmation.text}
                                    </DialogContentText>
                                )
                                : null
                        }
                    </DialogContent>
                    <DialogActions
                        classes={{
                            root: classes.ActionsRoot
                        }}
                    >
                        {/* When question has several answers  */}
                        {confirmation.answerOptions.length > 2 
                            ? confirmation.answerOptions.map((val, idx)=>(
                                <Button 
                                    id={`confirm-dialog-${val}`}
                                    className="clickable"
                                    onClick={()=>{onOptionsClick(idx)}}
                                    key={idx}
                                    variant="outlined"
                                    color="primary"
                                    classes={{
                                        label: typography.h3
                                    }}>
                                    {val}
                                </Button>
                            ))
                            // yes/no-ish question.
                            : confirmation.answerOptions.length === 2
                                ? confirmation.answerOptions.map((val, idx)=>(
                                    <Button 
                                        id={`confirm-dialog-${val}`}
                                        className="clickable"
                                        onClick={()=>{answerYesNoQuestion(idx)}}
                                        key={idx}
                                        variant={idx ? "contained" : "outlined" }
                                        color="primary"
                                        classes={{
                                            label: typography.h3
                                        }}>
                                        {val}
                                    </Button>
                                ))
                                : <Button 
                                    id={`confirm-dialog-${confirmation.answerOptions[0]}`}
                                    className="clickable"
                                    onClick={()=>{answerYesNoQuestion(1)}}
                                    variant={"contained"}
                                    color="secondary"
                                    classes={{
                                        label: typography.h3
                                    }}>
                                    {confirmation.answerOptions[0]}
                                </Button>
                        }
                    </DialogActions>
                </Dialog>
            )
            // confirmation.open = false
            : null
        }
        </>
    )
}
