import React from 'react'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { dispatch } from 'slice';
import { closeNotification } from '../state/actions';

export default function CloseNotificationButton() {
    return (
        <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={e => {dispatch(closeNotification())}}
        >
            <CloseIcon />
        </IconButton>
    )
}
