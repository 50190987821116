import { 
    Box, 
    // Button, 
    Grid, 
    Link, 
    Typography, 
    // useMediaQuery 
} from '@material-ui/core';
import React, { Children } from 'react';
// import { dispatch } from 'slice';
import mobileBerlinSvg from '../../../../assets/svg/city/Berlin-mobile-illustration.svg';
import ImageBox from '../../../../lib/components/images/ImageBox';
import { tgMakeStyles } from '../../../../lib/styles/TgMakeStyles';

// import { openModal } from '../../../dialogs/standard/state/actions';
// import { ImprintArticle, PrivacyPolicyArticle, TermsOfServicesArticle } from '../../../legal';
import { TYPOGRAPHY_STYLES } from "../../../theme/typographies";
import { useTypographyStyles } from '../../../theme/useTypography';

import { ContentWrapper } from '../../../maps/digital-map/common/components/ContentWrapper';

import GoogleMapDirectionsFab from './GoogleMapDirectionsFab';

export const GuestMapHomeComp = ({hotel}) => {

    return (
        <ContentWrapper hotel={hotel}>
            <HomeGrid>
                <InfoCardBox cardTitle={'Welcome to Berlin'}>
                    <WelcomeContent welcomeMessage={hotel.welcomeMessage}/>
                </InfoCardBox>
                <InfoCardBox cardTitle={'Contact Us'}>
                    <HotelInfoContent hotelData={hotel} />
                </InfoCardBox>
            </HomeGrid>
        </ContentWrapper>
    );
}

const useInfoCardStyle = tgMakeStyles((theme)=>({
    // Create pesudo min-margin using padding 
    wrapBox : {
        margin: `${theme.spacing(2)} auto`,
        padding : `0px ${theme.spacing(2)}`,
    },
    cardBox : {
        borderRadius:' 8px',
        boxShadow: theme.shadows[10],
        padding : theme.spacing(4),
        maxWidth: '350px',
        margin: `0 auto`,
        // margin: `${theme.spacing(2)} auto`
    },
    cardTitle : {
        textAlign: 'center',
        marginBottom:theme.spacing(2),
        color:theme.palette.primary.main,
        ...TYPOGRAPHY_STYLES.h5
    }
}))

const InfoCardBox = ({cardTitle, children}) => {

    const classes = useInfoCardStyle()

    return (
        <div className={`${classes.wrapBox}`}>
            <Box className={`${classes.cardBox}`}>
                <Typography className={`${classes.cardTitle}`}>{cardTitle}</Typography>
                {children} 
            </Box>
        </div>
    )
}

const useHomeGridStyle = tgMakeStyles((theme)=>({
    container : {
        padding : `0px ${theme.spacing(2)}`
    }
}))

const HomeGrid = ({children, ...others}) => {
    const classes = useHomeGridStyle()
    return (
        <Grid container className={classes.container}>
            {Children.map(children, (child,idx)=>(
                <Grid item
                    xs={12}
                    md={6} 
                    lg={3} 
                    key={idx}>
                        {child} 
                </Grid>
            ))}
        </Grid>
    )
}

const useWelcomeStyle = tgMakeStyles((theme)=>({
    mb : {
        marginBottom: theme.spacing(2)
    },
    greyColor : {
        color : theme.palette.greyscale.grey_4
    }
}))

export const WelcomeContent = ({welcomeMessage}) => {
    
    const classes = useWelcomeStyle()
    const typoClasses = useTypographyStyles()
    const imgStyle = {
        width: 'auto', height: '100px'
    }

    return (
        <Box textAlign="center">
            <ImageBox 
                src={mobileBerlinSvg} 
                className={`${classes.mb}`} 
                style={imgStyle}
            />
            <Typography 
                className={`${classes.mb} ${typoClasses.body2}`}
            >
                {welcomeMessage || 'We guide you through our favorite spots around Berlin. Visit us at the concierge desk for more personalised recommendations and assistance with bookings.'}
            </Typography>
        </Box>
    )
}

const useHotelInfoStyle = tgMakeStyles((theme)=>({
    mb : {
        marginBottom: theme.spacing(4)
    },
    FabLabel : {
        ...TYPOGRAPHY_STYLES.body2
    },
}))

const HotelInfoContent = ({hotelData}) => {
    const classes = useHotelInfoStyle()
    const typoClasses = useTypographyStyles()
    const directionsProps = {
        lat: hotelData.lat,
        lng: hotelData.lng,
        googlePlaceId: hotelData.googlePlaceId,
        name: hotelData.name,
        BoxProps : {
            className : `${classes.mb}`
        },
        classes : {
            label : classes.FabLabel
        },
        style : {
            boxShadow : 'none'
        }
    }
    return (
        <Box textAlign="center">
            <Typography className={`${typoClasses.h5}`} paragraph>{hotelData.name}</Typography>
            <Typography className={`${classes.mb} ${typoClasses.body1}`}>{hotelData.streetAddress}{`${hotelData.zip ? ', '+hotelData.zip :null }`}</Typography>
            <GoogleMapDirectionsFab {...directionsProps}/>
            {/* hotel number */}
            {hotelData.internationalPhoneNumber && 
                <Link href={`tel:${hotelData.internationalPhoneNumber}`} color='inherit' className={`${classes.mb} ${typoClasses.body1}`}>
                    {hotelData.internationalPhoneNumber}
                </Link>
            }
        </Box>
    )
}

// const useHomeGridFooterStyle = tgMakeStyles((theme)=>({
//     button : {
//         ...TYPOGRAPHY_STYLES.subtitle2,
//         margin : 0,
//         padding : `0 ${theme.spacing(1)}`
//     }
// }))

// const HomeGridFooter = ({boxProps, ...others}) => {
//     const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))
//     const classes = useHomeGridFooterStyle()

//     const openPrivacyPolicy = () => {
//         dispatch(openModal({
//             title: 'Privacy Policy',
//             component: PrivacyPolicyArticle
//         }))
//     }
//     const openTermsOfService = () => {
//         dispatch(openModal({
//             title: 'Terms Of Service',
//             component: TermsOfServicesArticle
//         }))
//     }
//     const openImprint = () => {
//         dispatch(openModal({
//             title: 'Imprint',
//             component: ImprintArticle
//         }))
//     }

//     return (
//         <Box display='flex' flexDirection={`${isMobile ? 'column' : 'initial'}`} {...boxProps}>
//             <Box flex={1} textAlign='center'>
//                 <Button onClick={openImprint} className={classes.button} >IMPRINT</Button>
//             </Box>
//             <Box flex={1} textAlign='center'>
//                 <Button onClick={openPrivacyPolicy} className={classes.button}>PRIVACY POLICY</Button>
//             </Box>
//             <Box flex={1} textAlign='center'>
//                 <Button onClick={openTermsOfService} className={classes.button}>IMPRESSUM</Button>
//             </Box>
//         </Box>
//     )
// }