import { Box, Button, Container, Drawer, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useOnStateChanged, useRouter } from '@uirouter/react';
import React, { useEffect, useState } from 'react';
import { dispatch } from 'slice';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { PrivacyPolicyLink } from '../../legal/components/PrivacyPolicyLink';
import { TermsOfServiceLink } from '../../legal/components/TermsOfServiceLink';
import { GUESTMAP_PARENT_STATE_NAME } from '../../hotel/guest-console/routes/guestRoutes';
import { consentAgree } from '../state/actions';
import { useStateCookiesConsent } from '../state/hooks/useStateCookiesConsent';
import Mixpanel from '../../../lib/mixpanel'

const useStyle = tgMakeStyles(theme => ({
    underlined: {
        textDecoration: 'underline'
    },
    container: {
        paddingRight: '0',
        paddingLeft: '0'
    }
}))

export function CookiesDisclaimerDrawer() {

    // Check if current state is related to hotel guest map.
    // Until a transition finishes, stateService.current returns 'empty' state.
    // We need to check current state asynchronously everytime state changes.
    // REF : https://github.com/angular-ui/ui-router/issues/1627
    const route = useRouter()
    // Set true as default so that the drawer is not displayed initially
    const [isGuestRoutes, setIsGuestRoutes] = useState(route.stateService.includes(`**.${GUESTMAP_PARENT_STATE_NAME}.**`))
    useOnStateChanged(() => {
        setIsGuestRoutes(route.stateService.includes(`**.${GUESTMAP_PARENT_STATE_NAME}.**`))
    })

    const classes = useStyle()
    const hasAgreed = useStateCookiesConsent()
    const handleAgreement = e => {
        Mixpanel('track')('agree_cookies_disclaimer')
        dispatch(consentAgree())
    }

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'))
    // show this drawers only when outside of hotel guest map routes 

    return !hasAgreed && !isGuestRoutes
        ? (
            <Drawer variant="persistent"
                anchor={'bottom'}
                open={true}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}>
                    <Container maxWidth="md" className={classes.container}>
                        <Box p={isDesktop ? 4 : 2}>
                            <Grid container justify="space-around" wrap="nowrap"
                                spacing={2}>
                                <Grid item>
                                    <Typography color="secondary" paragraph>
                                        <b>COOKIES ON TASTEGODS</b>
                                    </Typography>
                                    <Typography variant="body2">
                                        We use cookies to analyse site traffic and offer you the best experience. By continuing to use this website, you consent to the use of cookies and our <PrivacyPolicyLink className={classes.underlined} modalFullScreen={true} trackingProps={{location: 'cookies_disclaimer'}}/>.
                                    </Typography>
                                </Grid>
                                <Box flexGrow={0} display="flex">
                                    <Grid container direction="column" 
                                        justify="space-evenly"
                                        alignContent="center">
                                        <Button color="secondary" variant="contained"
                                            onClick={handleAgreement}>
                                            AGREE
                                        </Button>
                                        <TermsOfServiceLink 
                                            color="primary" 
                                            modalFullScreen={true}
                                            trackingProps={{location: 'cookies_disclaimer'}}
                                        >
                                            <Typography align="center" noWrap>LEARN MORE</Typography>
                                        </TermsOfServiceLink>

                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                    </Container>
            </Drawer>
        )
        : null
}
