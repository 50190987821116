import React from 'react';
// import {useSref} from '@uirouter/react'
// import AddBoxIcon from '@material-ui/icons/AddBox';
// import CreateIcon from '@material-ui/icons/Create';
// import HistoryIcon from '@material-ui/icons/History';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { List} from '@material-ui/core';
import { Box } from '@material-ui/core';
import MapListItem from './Map.ListItem';

export function MapsList({
    maps, 
    searchTerm = "",
    onMapDelete,
    onMapChange,
     ...props
}){
    const _term = RegExp(searchTerm, 'i')
    const _maps = maps
        ? maps.filter( m => {
            return m.title.match(_term)
        })
        : null

    return maps
        ? (
            <List>
                {_maps.length > 0 
                    ? _maps.map( (map, i) => (
                        <MapListItem key={i}
                            map={map}
                            onDelete={onMapDelete}
                            onChange={onMapChange}
                        />   
                    ))
                    : (
                        <Box display="flex" justifyContent="center">
                            No Maps 
                        </Box>
                    )
                }
            </List>
        )
        : (
            <Box display="flex" justifyContent="center">
                No Maps
            </Box>
        )
}

export default MapsList