import React, { useEffect, useCallback, useState } from 'react'
import { Box, TextField, IconButton, MenuItem, Popover, MenuList, ListItemText, Button, Divider} from '@material-ui/core'
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fromEvent } from 'rxjs';
import { debounceTime, tap, map } from 'rxjs/operators';
import { useRef } from 'react';

// Style sheet for autocomplete input and option list
const useAutoCompleteStyles = tgMakeStyles(theme => ({
    clearSearch: {
        margin: '0px',
        marginRight: '0.5rem',
        transform: 'rotate(45deg)'
    },
    searchButton: {
        height: '3rem',
        width: '3rem',
        minWidth: 'initial',
        padding: '0.75rem',
        margin: '0px',
        marginRight: '-0.875rem',
        boxShadow: 'none',
        borderRadius: '0px 4px 4px 0px'
    },
    inputElem: {
        // these dimensions are hardcoded to match the height specs as well as ensuring the popover sticks to the bottom of the input element
        // using rem here. GOAL is >> input content height = search button height
        height: '1.1875rem', // '19px' = 1.1875rem
        paddingTop: '0.90625rem', // '14.5px' = 0.90625rem
        paddingBottom: '0.90625rem'
    },
    PopoverPaperRoot: {
        padding: theme.spacing(2)
    },
    // reset Mui Menu styles
    MenuListroot: {
        // Remove focus outline which pops up when auto complete suggestion is displayed.
        outline: "none",
        padding: '0px',
        margin: '0px'
    },
    // reset Mui Menu Item styles
    MenuItemRoot: {
        margin: '0px',
        padding: '0px',
        minHeight: 'initial'
    }
}));

export default function AutoCompleteBoxV2({
    options,
    maxOptions,
    OptionListComp,
    onOptionSelect,
    onChange, 
    onSearch,
    loading,
    HelpTextComp,
    BoxProps,
    debounceMs=250,
    TextFieldProps={}
}) {
    /* 
        AUTOCOMPLETE FLOW LOGIC
    */
    // when actually searching : press Enter key or click Search Icon
    const handleSubmit = e => {
        e.preventDefault()
        // on submit, autocomplete options should not be displayed
        setHideOptions(true)
        onSearch(inputStr)
    }
    // On selecting an options
    const handleOptionSelect = data => e => {
        onOptionSelect(data)
    }

    /* 
        AUTOCOMPLETE UX/UI SETUP
    */
    // inputEl is the input element, required to anchor the options Popover menu
    const [inputEl, setInputEl] = useState(null)
    // the string inside input element
    const [inputStr, setInputStr] = useState('')
    // used to make sure option does not display when search has been initiated
    const [hideOptions, setHideOptions] = useState(false)
    
    // SETUP debounce input change
    // callback used to grab the input element and register an event listener using rxjs so it is easy to debounceTime
    const inputRef = useCallback(node => {
        if(node){
            fromEvent(node, 'input').pipe(
                tap(e => {
                    // autocomplete is about to fire, make sure options can be displayed
                    setHideOptions(false)
                }),
                // some healthy debounceTime to not trigger on change hook too often
                debounceTime(debounceMs),
                tap(e => {
                    // assign inputEl so options popover can anchor onto it
                    setInputEl(e.target)
                }),
                // on change is called with event and input string
                map(e => [e, e.target.value])
            ).subscribe(params => {
                onChange(...params)
            })
        }   
    }, [])

    const classes = useAutoCompleteStyles();

    // SETUP autocomplete hardcoded props
    // parse TextFieldProps : ensuring some props are not overwritten
    const _TextFieldProps = {
            ...TextFieldProps,
            onChange: e => setInputStr(e.target.value),
            value: inputStr,
            type: 'text',
            name: 'autocompleteInput',
            // input ref is required to register a debounced input change listener
            inputRef: inputRef,
            InputProps: {
                ...TextFieldProps.InputProps,
                endAdornment: (
                    <React.Fragment>
                        { (loading || inputStr)
                            && <IconButton 
                                    classes={{
                                        root: classes.clearSearch
                                    }}
                                    color='primary'
                                    onClick={function clearInput(e){
                                        !loading && setInputStr('')
                                    }}
                                >
                                    {loading 
                                        ? <CircularProgress size={'1em'} color="inherit" /> 
                                        : inputStr && <AddCircleIcon color="inherit" />
                                    }
                                </IconButton>
                        }
                        <Button
                            classes={{
                                root: classes.searchButton
                            }}
                            type='submit'
                            variant='contained'
                            color='primary'
                        >
                            <SearchIcon />
                        </Button>
                    </React.Fragment>
                )
            },
            inputProps: {
                ...TextFieldProps.inputProps,
                className: classes.inputElem
            }
        }

    // when options changes. open or close the options dropdown
    const [optionsOpen, setOptionsOpen] = useState(false)
    useEffect(function handleOptionsChange(){
        if(options && options.length > 0){
            setOptionsOpen(true)
        } else {
            setOptionsOpen(false)
        }
    }, [options])
    
    // optionsOpen = false to close options
    const handleClose = () => {
        setOptionsOpen(false)
    };
    
    // Popover allows autoFocus, good for arrow up down navigation
    // BUT text input must be focused back if any other key is pressed
    const handleListKeyDown = e => {
        e.stopPropagation()
        if(e.keyCode !== 38 && e.keyCode !== 40){
            // if not arrow up or down, set focus back to input
            handleClose()
            inputEl.focus()
        }
    }

    // Ref to be attched to measure box size for pop over.
    const boxRef = useRef()
    
    return (
        // reusable component : expose BoxProps for parents to use, define default padding and margin = 0
        <Box m={0} p={0} 
            position='relative' 
            {...BoxProps}
        >
            <Box  
                component='form' onSubmit={handleSubmit}
                m={0} p={0} width='100%'
                ref={boxRef}
            >
                <TextField
                    autoFocus={true}
                    {..._TextFieldProps}
                />
            </Box>
            {/* autocomplete renders options inside a Popover component */}
            <Popover
                marginThreshold={8}
                classes={{
                    // apply padding
                    paper: classes.PopoverPaperRoot
                }}
                PaperProps={{
                    style: {
                        // popover width same as input width
                        width: boxRef.current ? boxRef.current.offsetWidth : 'auto'
                    }
                }}
                anchorEl={inputEl}
                open={Boolean(optionsOpen && !hideOptions)}
                onClose={handleClose}
                disableAutoFocus={true}
				disableEnforceFocus={true}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {HelpTextComp 
                    ? <HelpTextComp />
                    : null
                }
                <Divider />
                <MenuList 
                    autoFocus={Boolean(optionsOpen && !hideOptions)}
                    onKeyDown={handleListKeyDown}
                    classes={{
                        // reset style, spacing provided by child comp
                        root : classes.MenuListroot
                    }}
                >
                    {options && options.length > 0 
                        ? options.map((option, idx) => idx < maxOptions && (
                            <MenuItem key={idx}
                                // reset style, spacing provided by child comp
                                classes={{root: classes.MenuItemRoot}}
                                disableGutters={true}
                                onClick={handleOptionSelect(option)}
                            >
                                {
                                    OptionListComp 
                                        ? <OptionListComp {...{...option, searchStr: inputStr}} />
                                        : <ListItemText primary={option} />
                                }
                            </MenuItem>)
                        )
                        : null
                    }
                </MenuList>
            </Popover>
        </Box>
    )
}