import React from 'react'
import ImageBox from '../../../lib/components/images/ImageBox'

export default function HotelLogoImg ({hotel, asBox, ...props}) {
    return (hotel && hotel.logo)
        ? (
            <React.Fragment>
                {hotel && !asBox
                    && <img {...props}
                        src={process.env.REACT_APP_API_BASE_URL.replace('api', '')+hotel.logo.image} 
                        alt="hotel_logo" />
                }
                {hotel && asBox
                    && <ImageBox {...props}
                        src={process.env.REACT_APP_API_BASE_URL.replace('api', '')+hotel.logo.image} 
                        alt="hotel_logo" />
                }
            </React.Fragment>
        )
        : null
}
