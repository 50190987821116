import React from 'react'
import { sendEmail } from '../http/tg/email'
import { COLORS } from '../theme/colors'
import pkgJson from '../../../package.json'

export const PLANS = [
    {
        name: 'Basic Membership',
        price: 'FREE'
    },
    {
        name: 'Digital Maps Upgrade',
        price: '35€'
    },
    {
        name: <span>Digital Maps + <span style={{color: COLORS.get('gold')['main']}}>Circle</span> Upgrade</span>,
        price: '65€'
    }
]

export const PLANS_PLAIN_TEXT = [
    'Basic Membership',
    'Digital Maps Upgrade',
    'Digital Maps + Circle Upgrade',
]

export const NO_CODE = '__nocode'
export const NO_PLAN = -1
export const DEFAULT_PLAN = 2

export function sendMembershipForm(stateParams){
    // because stateParams is readOnly
    const D = new Date()
    const _payload = {...stateParams}
    _payload.selected_plan = PLANS_PLAIN_TEXT[stateParams.selected_plan]
    _payload.tastegods_version = pkgJson.version

    // register agreements
    switch (stateParams.code) {
        // invitation code invitation_code
        case 'ber282':
        case 'ber286':
        case 'ber288':
            localStorage.setItem('agree:Addendum - Business - 01A', [...pkgJson.version.split('.').map(str => Number(str)), Date.now()])
            break;
        default:
            break;
    }
    localStorage.setItem('agree:Terms of Use (ToU)', [...pkgJson.version.split('.').map(str => Number(str)), Date.now()])
    localStorage.setItem('agree:Privacy Policy (PP)', [...pkgJson.version.split('.').map(str => Number(str)), Date.now()])
    
    return sendEmail({
        subject: `NEW BUSINESS ONBOARD`,
        emailBody: [
            'JOIN THE CIRCLE FORM:\n',
            ...Object.entries(_payload).map(([key, value]) => `${key} : ${value}`),
            `date: ${D.toLocaleString()}`
        ].join('\n')
    })
    
}