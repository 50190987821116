import React, { useEffect, useState } from 'react'
import { getPlace } from '../../../http/tg/ldm-place'
import ProfileHeader from './ProfileHeader'
import { Box, Button, Card, Collapse, Grid, Link, Typography } from '@material-ui/core'
import { useTypographyStyles } from '../../../theme/useTypography'
import useStyles from './profileCss.classes'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import PublicIcon from '@material-ui/icons/Public';
import ErrorIcon from '@material-ui/icons/Error';
import { OpeningHours } from './OpeningHours';
import { PlaceOpenStatus } from './PlaceOpenStatus';
import GoogleMapWithMarkerBox from '../../../../lib/google-map/components/GoogleMap/GoogleMapWithMarkerBox'
import {formatDistanceToNow, max, parse} from 'date-fns';


export function BusinessProfilePageAsync({$stateParams}){
    const [place, setPlace] = useState()
    useEffect(() => {
        // console.log('effect state params : ', $stateParams)
        getPlace($stateParams.id).subscribe({
            next: res => {
                setPlace(res.data)
            }
        })
    }, [$stateParams.id])
    return (
        <Box
            p={2} 
            height="100%">
            {place && <BusinessProfileLarge place={place}/>}
        </Box>
    )
}

export default BusinessProfilePageAsync

function BusinessProfileLarge({place}){
    const _place = place ? place.place : null
    const _lastProfileUpdateDate = max([
        parse(place.lastUpdate, 'dd.MM.yyyy HH:mm:ss', new Date(0)), 
        parse(_place.lastProfileUpdate, 'dd.MM.yyyy HH:mm:ss', new Date(0))
    ])
    const classes = useStyles()
    const typo = useTypographyStyles()
    const btns = ['hours','map','contact']
    const [active, setActive] = useState(null)
    const [open, setOpen] = useState(false)
    const toggleCollapse = (idx) => (e) => {
        setOpen(prev => {
            return !(idx === active && prev)
        })
        setActive(prev => {
            return idx === prev
            // already open for that button, close
                ? prev
                : idx
        })
    }
    return (
        <Grid container
            component={Box}
            spacing={2} 
            mt={10}
            pb={2}
            height="100%"
            style={{marginTop: '1rem'}}
        >
            <Grid item 
                xs={12} md={6}
                component={Box}
                position={'relative'}
                height="100%"
            >
                {/* profile heading */}
                {place && <ProfileHeader place={place}/>}
                {/* button groupp */}
                <Box display="flex" justifyContent="space-between">
                    {btns.map( (txt, idx) => {
                        return (
                            <Button 
                                key={idx}
                                classes={{
                                    root: `${classes.btnToggle}`,
                                    label: `${typo.subtitle6}`
                                }}
                                style={{margin: idx === 1 ? '0 .5rem 0 .5rem' : ''}}
                                className={`${active === idx && open ? 'active' : ''} ${active !== null ? 'open' : 'closed'}`}
                                endIcon={active === idx && open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
                                onClick={toggleCollapse(idx)}
                            >
                                {txt}
                            </Button>
                        )
                    })}
                </Box>
                {/* collapsable content */}
                <Collapse in={open} timeout={{ enter: 200, exit: 200 }}>
                    {btns[active] === 'hours'
                        ? (<>
                            {!!!(new RegExp('CLOSED', 'g')).test(_place.businessStatus)
                                ?(
                                    <CollapsableContentCard >
                                        <OpeningHours place={_place} />
                                    </CollapsableContentCard>
                                )
                                : (
                                    <CollapsableContentCard>
                                        <PlaceOpenStatus 
                                            place={place}
                                            color={'primary'}
                                        />
                                    </CollapsableContentCard>
                                )
                            }
                        </>)
                    : btns[active] === 'map'
                        ? <CollapsableContentCard>
                            {/* Embedded Google map instance showing the place location */}
                            <GoogleMapWithMarkerBox
                                BoxProps={{
                                    height: '30vh',
                                    // negtive margin is used to get the map to fill width beyond container padding
                                    m: -2
                                }}
                                lat={_place.lat} lng={_place.lng}
                            />
                        </CollapsableContentCard>
                    : btns[active] === 'contact'
                        ? <CollapsableContentCard>
                            <div 
                                style={{display: 'flex', alignItems: 'center', marginBottom: 2}} 
                                className={typo.body1}
                            >
                                    <LocalPhoneIcon style={{marginRight: '1rem'}}/>
                                    {_place.internationalPhoneNumber
                                        ? <Link href={`tel:${_place.internationalPhoneNumber}`} color="inherit">
                                            {_place.internationalPhoneNumber}
                                        </Link>
                                        : <span>-</span>
                                    }
                            </div>
                            <div 
                                style={{display: 'flex', alignItems: 'center'}} 
                                className={typo.body1}
                            >
                                    <MarkunreadIcon style={{marginRight: '1rem'}}/>
                                    {place.email
                                        ? <Link href={`mailto:${place.email}`} color="inherit">
                                            {place.email}
                                        </Link>
                                        : <span>-</span>
                                    }
                            </div>
                            <div 
                                style={{display: 'flex', alignItems: 'center'}} 
                                className={typo.body1}
                            >
                                    <PublicIcon style={{marginRight: '1rem'}}/>
                                    {_place.website
                                        ? <Link href={`${_place.website}`} color="inherit" target="_blank" rel="noopener">
                                            {_place.website}
                                        </Link>
                                        : <span>-</span>
                                    }

                            </div>
                        </CollapsableContentCard>
                        : null
                    }
                </Collapse>
                {/* OPERATION STATUS */}
                {place.operationStatus && <Box
                    mt={2}
                    component={Card}
                    elevation={0}
                    p={2}
                    className={classes.operationStatus}
                >
                    <Typography
                        className={typo.body4}
                        align="center"
                        style={{marginBottom: '1rem', color: '#757575'}}
                    >
                        Profile Last Updated {formatDistanceToNow(_lastProfileUpdateDate, {addSuffix: true})}
                    </Typography>
                    <Typography
                        align="center"
                        className={`${typo.body2}`}
                        // className={`${typo.body2} ${clamp['o_s'] ? classes.clamp4 : ''}`}
                    >
                        {place.operationStatus}
                    </Typography>
                    {/* {clamp.o_s && <Typography 
                        align="center"
                        color="primary"
                        onClick={unClamp('o_s')}
                        className={typo.body2}
                    >
                        Read More
                    </Typography>} */}
                </Box>}
                {/* VISIT GUIDEINES */}
                {place.visitGuidelines && <Box
                    position="relative"
                    mt={2}
                    component={Card}
                    elevation={0}
                    className={classes.visitGuidelines}
                    display="flex" alignItems="stretch"
                >
                    <Box
                        display="flex" justifyContent="center" alignItems="center"
                        className="warning"
                        px={2}
                    >
                        <ErrorIcon color="inherit"/>
                    </Box>
                    <Box p={2}>
                        <Typography
                            className={typo.body2}
                        >
                            {place.visitGuidelines}
                        </Typography>
                    </Box>
                </Box>}
                {/* ABOUT */}
                {_place.about && <Box
                    mt={2}
                >
                    <Typography
                        align="center"
                        className={`${typo.body2}`}
                        // className={`${typo.body2} ${clamp['about'] ? classes.clamp4 : ''}`}
                    >
                        {_place.about}
                    </Typography>
                    {/* {clamp.about && <Typography 
                        align="center"
                        color="primary"
                        onClick={unClamp('about')}
                        className={typo.body2}
                    >
                        Read More
                    </Typography>} */}
                </Box>}
            </Grid>
            {place.feed && place.feed.url && (
                <Grid item 
                    xs={12} md={6}
                    component={Box}
                    position={'relative'}
                    height="100%"
                >
                    {/* SMP FEED */}
                        <Box
                            mt={0}
                            width="100%" height="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Typography 
                                style={{marginBottom: '1rem'}}
                                className={typo.subtitle6} align="center"
                            >
                                social media posts
                            </Typography>
                            <iframe 
                                src={`https://www.juicer.io/api/feeds/${place.feed.url}/iframe?overlay=true`} 
                                frameBorder='0' 
                                width='100%' 
                                height='100%'
                                style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    flexGrow: 1
                                }}
                            ></iframe>
                        </Box>
                </Grid>
            )}
            
        </Grid>
    )
}

// wraps card in collapsable section of business profile
function CollapsableContentCard({...props}){
    return (
        <Box component={Card}
            elevation={0}
            mt={0.5}
            p={2}
            style={{backgroundColor: '#585879'}}
            color="white"
            {...props}
        />
    )
}