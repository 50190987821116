import produce from 'immer';
import { userInfoReducer } from './userInfo';
import { userStatusReducer } from './userStatus';

export const INITIAL_STATE = {
    details: null
}

export function userRootReducer(state, action){
    return produce(state, draft => {
        draft['details'] = userDetailsReducer(state['details'], action)
    })
}

const INITIAL_DETAILS_STATE = {
    info: null,
    status: null
}
function userDetailsReducer(state, action){
    state = state || INITIAL_DETAILS_STATE;
    return produce(state, draft => {
        draft['info'] = userInfoReducer(state['info'], action);
        draft['status'] = userStatusReducer(state['status'], action);
    })
}