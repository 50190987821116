import { useEffect, useState } from 'react';
import { $lice } from 'slice';
import { getLanguageFromLocal } from '../../../http/tg/translation';
import { translationReducer } from '../reducers/translationReducer';

export const TRANS_INITIAL_STATE = {
    language : 'en',
    // Array of the responces which contains translated texts.
    translation : [],
    error:null,
    loading : false
}

const useStateTranslation = () => {
    
    const [$Trans] = $lice(
        // state name
        'Translation',
        // reducer
        translationReducer,
        // initial state
        {
            ...TRANS_INITIAL_STATE,
            // retrive language setting from local storage.
            language : getLanguageFromLocal()
        }
    )

    const [translationState, setTranslationState] = useState(TRANS_INITIAL_STATE)

    useEffect(()=>{
        const translationSub = $Trans.subscribe((v)=>{
            setTranslationState(v)
        })
        return () => {
            translationSub.unsubscribe();
        }
    },[$Trans])
    
    return [translationState];
}

export default useStateTranslation;