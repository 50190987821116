import { Box, Card, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../theme/useTypography'
import useOBStyles from './onboarding.classes'
import img01 from '../../../../assets/png/PP-Popup_GoogleBusiness01.png'
import img02 from '../../../../assets/png/PP-Popup_GoogleBusiness02.png'
import { AddBox } from '@material-ui/icons'

export function GoogleUrlInfoDialog(){

    const typo = useTypographyStyles()
    const obClasses = useOBStyles()
    return (
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} sm={6} md={8}
                component={Box}
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <Typography
                    className={`${typo.subtitle1} ${obClasses.mbS}`}
                >
                    Step One
                </Typography>
                <Typography
                    className={`${typo.body1} ${obClasses.mbL}`}
                >Log into your Google My Business account, select the business you intend to connect</Typography>
                <Typography
                    className={`${typo.subtitle1} ${obClasses.mbS}`}
                >
                    Step Two
                </Typography>
                <Typography
                    className={`${typo.body1} ${obClasses.mbL}`}
                >Click "Info" from the left side panel</Typography>
                <Typography
                    className={`${typo.subtitle1} ${obClasses.mbS}`}
                >
                    Step Three
                </Typography>
                <Typography
                    className={`${typo.body1} ${obClasses.mbL}`}
                >Click "Share your Business Profile" from the top right corner</Typography>
                <Typography
                    className={`${typo.subtitle1} ${obClasses.mbS}`}
                >
                    Step Four
                </Typography>
                <Typography
                    className={`${typo.body1} ${obClasses.mbL}`}
                >Paste this link into the TasteGods form</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Card
                    className={`${obClasses.mS}`}
                    elevation={4}
                >
                    <img src={img01} style={{width: '100%', height: 'auto'}}></img>
                </Card>
                <Card
                    className={`${obClasses.mS}`}
                    elevation={4}
                >
                    <img src={img02} style={{width: '100%', height: 'auto'}}></img>
                </Card>
            </Grid>
        </Grid>
    )
}

export default GoogleUrlInfoDialog