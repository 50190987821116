import { Box, Card, Container, Typography } from '@material-ui/core'
import React from 'react'
import OpenModalLink from '../../../../dialogs/standard/components/OpenStandardDialog.Link'
import { useTypographyStyles } from '../../../../theme/useTypography'
import useOBStyles from '../onboarding.classes'
import { RecommendTasteGodsForm } from '../Recommendation.form'

export function MembershipOnboardingSuccess(){
    
    const typo = useTypographyStyles()
    const obClasses = useOBStyles()
    
    return (
        <Box 
            height="100vh"
            className={`${obClasses.containerMD}`}
        >
            <Card
                component={Box}
                elevation={4}
                p={2}
                style={{borderRadius: '1rem'}}
            >
                <Container maxWidth="md">
                    <Typography
                        className={`
                            ${typo.h1}
                            ${obClasses.mXL}`}
                        style={{color: '#292952'}}
                        align="center"
                    >
                        <b>SUCCESS!</b>
                    </Typography>
                    <Typography
                        className={`
                            ${typo.BODY1}
                            ${obClasses.mXL}`}
                        align="center"
                    >
                        Your membership is now pending review, business verification and account preparations. We'll reach out to you within 1-3 business days with final steps and your Circle Membership confirmation!
                    </Typography>
                </Container>
                <OpenModalLink
                    BoxProps={{
                        className: `${typo.h4} ${obClasses.mXL}`,
                        style: {textAlign: 'center'}
                    }}
                    modalTitle="RECOMMEND ANOTHER BUSINESS TO INVITE" 
                    modalComponent={RecommendTasteGodsForm}
                >
                    RECOMMEND ANOTHER BUSINESS TO INVITE
                </OpenModalLink>
            </Card>
        </Box>
    )
}
export default MembershipOnboardingSuccess