import { Divider, Typography, useMediaQuery, Box } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TranslateIcon from '@material-ui/icons/Translate';
import React, { useState } from 'react';
import { dispatch } from 'slice';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { closeModal, openModal } from '../../dialogs/standard/state/actions';
import { ImprintArticle, PrivacyPolicyArticle, TermsOfServicesArticle } from '../../legal';
import { ContentWrapper } from '../../maps/digital-map/common/components/ContentWrapper';
import { LanguageSelectComp } from '../../hotel/guest-console/components/LanguageSelectComp';
import { TYPOGRAPHY_STYLES } from '../../theme/typographies';
import { useTypographyStyles } from '../../theme/useTypography';
import { FluidBox } from '../../maps/digital-map/common/components/GridView/GridView';

const useStyles = tgMakeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: '500px',
	},
	box : {
		margin: `${theme.spacing(2)} ${theme.spacing(4)}`
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	link: {
		textDecoration: 'underline',
		...TYPOGRAPHY_STYLES.body1
	},
	listItemRoot : {
		marginLeft: 0
	}
}))

export function Settings({hotel}) {

	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'))
	const typoClasses = useTypographyStyles()

	const openLangSetting = () => {
		// dispatch 
		dispatch(openModal({
			component : SetLanguagePage
		}))
	}

	const toggleChildren = () => {
		setOpen(prev => !prev)
	}

	const openPrivacyPolicy = () => {
        dispatch(openModal({
            title: 'Privacy Policy',
			component: PrivacyPolicyArticle,
			props: {location: 'settings'},
			fullScreen : isMobile
        }))
    }
    const openTermsOfService = () => {
        dispatch(openModal({
            title: 'Terms Of Service',
			component: TermsOfServicesArticle,
			props: {location: 'settings'},
			fullScreen : isMobile
        }))
    }
    const openImprint = () => {
        dispatch(openModal({
            title: 'Imprint',
			component: ImprintArticle,
			props: {location: 'settings'},
			fullScreen : isMobile
        }))
	}

	const listItemProps = {
		classes : {
			root : classes.listItemRoot
		}
	}
	

	return (
		<FluidBox>
			<ContentWrapper hotel={hotel}>
				<Box className={classes.box}>
					<List
						component="nav"
						subheader = {
							<Typography className={`${typoClasses.h5}`}>Settings</Typography>
						}
						className={classes.root}>
						<ListItem {...listItemProps} button onClick={openLangSetting}>
							<ListItemIcon>
								<TranslateIcon color='primary'/>
							</ListItemIcon>
							<ListItemText primary={<Typography color='primary' className={`${typoClasses.subtitle2}`} >Language</Typography>} />
							<ExpandMore color='primary'/>
						</ListItem>
						<Divider />
						<ListItem  {...listItemProps} button onClick={toggleChildren}>
							<ListItemIcon>
								<InfoOutlinedIcon color='primary'/>
							</ListItemIcon>
							<ListItemText primary={<Typography color='primary' className={`${typoClasses.subtitle2}`}>About</Typography>} />
							{open ? <ExpandLess color='primary'/> : <ExpandMore color='primary'/>}
						</ListItem>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<ListItem {...listItemProps} button className={classes.nested} onClick={openImprint}>
									<Typography className={`${classes.link}`}>Imprint</Typography>
								</ListItem>
								<ListItem {...listItemProps} button className={classes.nested} onClick={openTermsOfService}>
									<Typography className={`${classes.link}`}>Terms Of Use</Typography>
								</ListItem>
								<ListItem {...listItemProps} button className={classes.nested} onClick={openPrivacyPolicy}>
									<Typography className={`${classes.link}`}>Privacy Policy</Typography>
								</ListItem>
							</List>
						</Collapse>
						<Divider />
					</List>
				</Box>
			</ContentWrapper>
		</FluidBox>
	);
}

const SetLanguagePage = () => {
    const compProps = {
		onContinueText : 'SEE IN ENGLISH',
		selectOpen : true,
        onContinue : ()=>{
			// Set language to english 
			dispatch(closeModal())
		},
        onApply : ()=>{dispatch(closeModal())}
	}

    return <LanguageSelectComp {...compProps}/>
}