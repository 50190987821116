import React from 'react';
import produce from 'immer';
import CloseNotificationButton from '../../components/CloseNotificationButton';

const DEFAULT_NOTIFICATION_MESSAGE = 'Notifications will be shown here';

export const INITIAL_STATE = {
    open: false,
    duration: 6000,
    message: DEFAULT_NOTIFICATION_MESSAGE,
    action: (<CloseNotificationButton />)
}

export function notificationsRootReducer(state, action){
    switch (action.type) {
        case 'NOTIFICATION/DISPLAY':
            return produce(state, draft => {
                draft.message = action.payload.message || DEFAULT_NOTIFICATION_MESSAGE;
                draft.action = action.payload.action || (<CloseNotificationButton />);
                draft.duration = action.payload.duration
                draft.open = true;
            })
        case 'NOTIFICATION/CLOSE':
            return produce(state, draft => {
                draft.open = false;
            })
        default:
            return state;
    }
}