import React, { useState } from 'react';
import goldDude from '../../../assets/png/logo_gold_no_text-rotated-halo.png'
import blueDude from '../../../assets/png/logo_blue_with_text.png'
import globeSvg from '../../../assets/svg/globe.svg'
import playlistSvg from '../../../assets/svg/add-playlist.svg'
import mapSvg from '../../../assets/svg/map.svg'
import infographic from '../../../assets/png/Concierge-Network.png'
import planeSvg from '../../../assets/svg/plane.svg'
import { Container, Typography, createMuiTheme, Paper, useMediaQuery } from '@material-ui/core';
import { tgMakeStyles } from '../../../lib/styles/TgMakeStyles';
import { ThemeProvider } from '@material-ui/styles';
import ImageBox from '../../../lib/components/images/ImageBox';
import {OpenModalButton} from '../../dialogs/standard/components/OpenStandardDialog.Button'
import { JoinTastegodsForm } from './JoinTastegodsForm';

// create a nested theme specifically for welcome page
/* 
    NOTE : in hindsight, it may have been better to only use custome class names
*/
const welcomeTheme = isDesktop => theme => createMuiTheme({
    ...theme,
    typography: {
        ...theme.typography,
        h1: { //h1
            fontSize: isDesktop ? '3rem' : '1.75rem',
            fontWeight: 'bold'
        },
        h2: { //T1 (Button)
            fontSize: isDesktop ? '1.75rem' : '1rem',
            fontWeight: 'bold'
        },
        body1: { // T2
            fontSize: isDesktop ? '1rem' : '1.15rem',
            fontWeight: 300
        },
        body2: { // T3
            fontSize: isDesktop ? '1rem' : '1rem',
            fontWeight: 300
        },
        subtitle1: { // S1
            fontWeight: 300,
            fontSize: isDesktop ? '1.5rem' : '1.25rem',
            lineHeight: 'initial'
        },
        subtitle2: { // undocumented
            fontSize: isDesktop ? '1rem' : '1rem',
            fontWeight: 300,
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: isDesktop ? '2rem' : '1.45rem',
                border: '0.2rem solid'
            },
            text: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                fontSize: isDesktop ? '1.75rem' : '1rem',
            }
        }
    }
})
// create css classes for custom CSS
const useStyles = tgMakeStyles(theme => ({
    container: {
        padding: 0,
        borderBottom: 'none',
        borderRadius: 0,
        boxShadow: 'none'
    },
    section: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    doubleSquareIcon: {
        width: '100px',
        height: '100px',
        margin: '1rem'
    },
    bulletPointDiv: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems:'center',
        padding: '0 3rem 0 3rem'
    },
    button1: {
        color: '#0E0E32',
        borderColor: '#0E0E32',
        '&:hover': {
            color: 'white',
            background: '#0E0E32'
        }
    },
    button2: {
        color: '#C6AD7C',
        borderColor: '#C6AD7C',
        '&:hover': {
            color: '#0E0E32',
            background: '#C6AD7C'
        }
    }
}))

export default function WelcomeDiv() {
    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const classes = useStyles()

    const [hover, setHover] = useState(false)

    return (
        <ThemeProvider theme={welcomeTheme(isDesktop)}>
            <Container maxWidth="lg"
                component={Paper}
                className={classes.container}
            >
                <section 
                    onMouseEnter={e => setHover(true)}
                    onMouseLeave={e => setHover(false)}
                    className={classes.section}
                    style={{
                        color: '#0E0E32',
                        // backgroundColor: '#F8F6F7',
                        height: 'min-height',
                        minHeight: isDesktop ? 'calc(90vh - 74px)' : 'calc(90vh - 74px)',
                        overflow: 'hidden',
                        display: 'flex',
                        position: 'relative',
                        paddingBottom: '5rem' // ensures CTA is not covered by the next section that is translated 4rem up
                    }}
                >
                    <div style={{
                        alignSelf: 'center',
                        margin: isDesktop ? '0' : 'auto',
                        paddingLeft: isDesktop ? '15%' : '',
                        maxWidth: isDesktop ? '50%' : 'initial',
                        zIndex: 1
                    }}>
                        <Typography variant="h1" style={{paddingBottom: '2rem'}}>
                            JOIN THE CIRCLE
                        </Typography>
                        <Typography variant="subtitle1" style={{paddingBottom: '2rem'}}>
                            A Digital Platform For <b>Hotel Concierges</b> to Connect with Local Businesses
                        </Typography>
                        <OpenModalButton
                            id="welcome-page-top-open-new-membership-form"
                            className="clickable"
                            classes={{root: classes.button1}}
                            modalTitle="JOIN THE CIRCLE"
                            modalComponent={JoinTastegodsForm}>
                            BECOME A MEMBER
                        </OpenModalButton>
                    </div>
                    <ImageBox
                        src={goldDude} alt="tastegods-logo"
                        style={{
                            pointerEvents: 'none',
                            opacity: isDesktop ? 0.5 : 0.25,
                            width: '100%', height: '100%',
                            position: 'absolute',
                            transform: 'translate(22%,15%)scale(1.3)',
                            zIndex: 0
                        }}
                    />
                    <ImageBox
                        src={blueDude} alt="tastegods-logo"
                        style={{

                            opacity: hover ? 0.5 : 0,
                            pointerEvents: 'none',
                            width: '100%', height: '100%',
                            position: 'absolute',
                            transform: 'translate(22%,15%)scale(1.3)',
                            zIndex: 0,
                            transition: 'all .3s'
                        }}
                    />
                </section>

                <div
                    style={{
                        pointerEvents: 'none',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: isDesktop ? 'row' : 'column',
                        margin: 0
                    }}
                >
                    <section 
                        className={classes.section}
                        style={{
                            flexBasis: isDesktop ? '60%' : 'initial',
                            flexShrink: '0',
                            backgroundColor: ' #292952',
                            color: 'white',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 2,
                            position: 'relative',
                            transform: isDesktop ? 'translateY(-4rem)' : 'initial',
                            padding: '4rem'
                        }}
                    >
                        <div style={{
                            width: '60%'
                        }}>
                            <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                                <text dominantBaseline="middle" textLength="100" fontSize="12" fill="white" x="0" y={16.5/2}>THE POWER OF</text>
                                <text dominantBaseline="middle" textLength="100" fontSize="11" fill="white" x="0" y={49.5/2}>A CONCIERGE'S</text>
                                <text dominantBaseline="middle" textLength="100" fontSize="20" fill="white" x="0" y={90/2}>NETWORK</text>
                            </svg>
                        </div>
                        <img src={infographic}
                            style={{
                                width: isDesktop ? 'auto' : '80%', 
                                height: isDesktop ? '30vh' : 'auto',
                                marginTop: '2rem'
                            }}
                        />
                    </section>

                    <section 
                        className={classes.section}
                        style={{
                            fontSize: isDesktop ? '1rem' : 'initial',
                            color: '#464646',
                            height: isDesktop ? 'auto' : 'calc(90vh - 74px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            transform: isDesktop ? 'translateY(-4rem)' : 'initial',
                            paddingTop: isDesktop ? '4rem' : 'initial'
                        }}
                    >
                        <Typography variant="body1" style={{paddingBottom: '2rem'}}>
                            Even with an increasingly tech savvy clientele and the abundance of information online, <b>guests still seek personal interactions, reliable & local sources, and trustworthy guidance.</b>
                        </Typography>
                        <Typography variant="body1" style={{paddingBottom: '2rem'}}>
                            The concierge & front desk are the primary contact for many guests; on average, <b style={{ color: '#C6AD7C' }}>1 out of 4 guests will ask for recommendations or directions during their stay.</b>
                        </Typography>
                        <Typography variant="body1" >
                            Not only does the <b>concierge provide expert local advice</b> but also tips, instructions and preparations for a smooth guest journey.
                        </Typography>
                    </section>
                </div>

                <div 
                    style={{
                        paddingTop: '4rem',
                        backgroundColor: '#F8F6F7'
                    }}
                >
                    <section 
                        style={{
                            backgroundColor: '#70708C',
                            color: 'white',
                            marginLeft: isDesktop ? '10vw' : '2.5rem',
                            padding: '2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative'
                        }}
                    >
                        <div style={{
                            paddingBottom: isDesktop ? '1rem' : '2rem',
                            width: '60%', 
                            margin: 'auto',
                            textAlign: 'center'
                        }}>
                            {isDesktop 
                                ? (<div style={{fontSize: '3rem'}}>TOOLS FOR THE <b>MODERN CONCIERGE</b></div>)
                                : (
                                    <svg viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                                        <text dominantBaseline="middle" textLength="100" fontSize="12" fill="white" x="0" y={16.5/2}>TOOLS FOR THE</text>
                                        <text dominantBaseline="middle" textLength="100" fontSize="11" fill="white" x="0" y={49.5/2}>MODERN</text>
                                        <text dominantBaseline="middle" textLength="100" fontSize="18" fill="white" x="0" y={90/2}>CONCIERGE</text>
                                    </svg>
                                )
                            }
                        </div>
                        <Typography variant="subtitle2">
                            <i>One platform. All the businesses you like and the tools to easily share them with your guests.</i>
                        </Typography>
                    </section>
                </div>
                <section 
                    className={classes.section}
                    style={{
                        backgroundColor: '#F8F6F7',
                        paddingTop: '2rem',
                        paddingBottom: '2rem',
                        minHeight: isDesktop ? 'calc(90vh - 74px)' : 'calc(90vh - 74px)',
                        display: isDesktop ? 'grid' : 'flex',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: 'repeat(2, 1fr)',
                        flexDirection: isDesktop ? 'row' : 'column',
                        justifyContent: 'center',
                        alignItems: isDesktop ? 'start' : 'center'
                    }}
                >
                    <div 
                        className={classes.bulletPointDiv}
                        style={{marginBottom: isDesktop ? '0' : '2rem'}}
                    >
                        <DoubleSquareIcon 
                            variant={1}
                            className={classes.doubleSquareIcon}
                        >
                            <img src={globeSvg} />
                        </DoubleSquareIcon>
                        <Typography 
                            variant="subtitle1"
                            style={{paddingBottom: '1rem'}}
                        >
                            <b>Explore Business Profiles & News</b>
                        </Typography>
                        <Typography variant="body2" align="center">
                            <b>Access the Circle:</b> local businesses & what you need to know in one glance, alongside special content & offers; easily find the top spots in town & the best recommendations for each guest
                        </Typography>
                    </div>
                    <div 
                        className={classes.bulletPointDiv}
                        style={{marginBottom: isDesktop ? '0' : '2rem'}}
                    >
                        <DoubleSquareIcon 
                            variant={2}
                            className={classes.doubleSquareIcon}
                        >
                            <img src={playlistSvg} />
                        </DoubleSquareIcon>
                        <Typography 
                            variant="subtitle1"
                            style={{paddingBottom: '1rem'}}
                        >
                            <b>Manage Your Portfolio of Favourites</b>
                        </Typography>
                        <Typography variant="body2" align="center">
                            Shortlist your top businesses & frequent referrals for easy access; share favourites with your colleagues and grow your own portfolio with new destinations
                        </Typography>
                    </div>
                    <div 
                        className={classes.bulletPointDiv}
                        style={{marginBottom: isDesktop ? '0' : '2rem'}}
                    >
                        <DoubleSquareIcon 
                            variant={2}
                            top={false}
                            className={classes.doubleSquareIcon}
                        >
                            <img src={mapSvg} />
                        </DoubleSquareIcon>
                        <Typography 
                            variant="subtitle1"
                            style={{paddingBottom: '1rem'}}
                        >
                            <b>Build Maps for Your Guests</b>
                        </Typography>
                        <Typography variant="body2" align="center">
                            No more paper maps to mark up! Pull business details directly from the Circle and into custom digital maps for guests, with minimal maintenance when operations change
                        </Typography>
                    </div>
                    <div 
                        className={classes.bulletPointDiv}
                        style={{marginBottom: isDesktop ? '0' : '2rem'}}
                    >
                        <DoubleSquareIcon 
                            variant={1}
                            top={false}
                            className={classes.doubleSquareIcon}
                        >
                            <img src={planeSvg} />
                        </DoubleSquareIcon>
                        <Typography 
                            variant="subtitle1"
                            style={{paddingBottom: '1rem'}}
                        >
                            <b>Share Recommendations with Guests</b>
                        </Typography>
                        <Typography variant="body2" align="center">
                            Contactless sharing can be done via QR code in person or at touch points around the hotel; guests take it on-the-go, translatable to over 100 languages
                        </Typography>
                    </div>
                </section>

                <section 
                    className={classes.section}
                    style={{
                        height: isDesktop ? 'auto' : 'calc(90vh - 74px)',
                        backgroundColor: '#0E0E32',
                        color: '#C6AD7C',
                        paddingTop: '3rem',
                        paddingBottom: '3rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="h1" 
                        style={{
                            fontWeight: 300,
                            paddingBottom: '2rem'
                        }}
                    >
                        JOIN THE CIRCLE
                    </Typography>
                    <OpenModalButton
                        id="welcome-page-bottom-open-new-membership-form"
                        className="clickable"
                        classes={{root: classes.button2}}
                        modalTitle="JOIN THE CIRCLE"
                        modalComponent={JoinTastegodsForm}>
                        BECOME A MEMBER
                    </OpenModalButton>
                </section>
            </Container>
        </ThemeProvider>
    )
}

function DoubleSquareIcon({variant, top = true, ...props}){
    const grid = {
        display: 'grid',
        gridTemplateColumns: '15% 1fr 15%',
        gridTemplateRows: '15% 1fr 15%'
    }
    const gridItem = int => {
        switch (int) {
            case 0:
                return {
                    gridColumn: '1 / -1',
                    gridRow: '1 / -1'
                }
            case 1:
                return {
                    gridColumn: '1 / -2',
                    gridRow: '1 / -2'
                }
            case 2:
                return {
                    gridColumn: '2 / -1',
                    gridRow: '1 / -2'
                }
            case 3:
                return {
                    gridColumn: '2 / -1',
                    gridRow: '2 / -1'
                }
            case 4:
                return {
                    gridColumn: '1 / -2',
                    gridRow: '2 / -1'
                }
            default:
                break;
        }
    }

    return (
        <div {...props}
            style={{
                ...grid,
                position: 'relative'
            }}
        >
            <div 
                style={{
                    ...gridItem(variant),
                    zIndex: top ? 1 : 0,
                    backgroundColor: top ? '#292952' : '#70708C'
                }}
            />
            
            <div 
                style={{
                    ...gridItem(variant+2),
                    zIndex: top ? 0 : 1,
                    backgroundColor: top ? '#70708C' : '#292952'
                }}
            />
            
            <div 
                style={{
                    ...gridItem(0),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 2
                }}
            >
                <div>
                    {props.children && props.children}
                </div>
            </div>
        </div>
    )
}