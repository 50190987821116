/* 
    just abstracting some logic to attach event listeners on a bunch of
    Data objects.

    https://developers.google.com/maps/documentation/javascript/reference/data
*/

/**
 * 
 * @param {string} event ie: click, mouseover etc. any event Data can handle
 * @param {*} listener 
 * @returns {function} that takes in a google map instance and an array of Data objects 
 * Data object = {
        gmData: actual google.maps.Data instance
        active: boolean, decides if Data is displayed on the gmMap
        name: string, the name of the layer
    }
 */
export const makeDataListener = (event, listener) => (gmMap, DataArr) => {
    if(!DataArr) return;
    if(!DataArr.length) return;
    
    DataArr.forEach(element => {
        // only bother if the Data layer is active
        if(element && element.active) {
            let gmData = element.gmData;
            // add listener
            window.google.maps.event.clearListeners(gmData, event);
            gmData.addListener(event, listener);
        }
    });   

}