import React from 'react'
import { SvgIcon } from "@material-ui/core";

export function SortZASvgIcon(){
    return <SvgIcon overflow="visible"
        preserveAspectRatio="none" viewBox="0 0 24 24" width="28" height="28"
    >
        <g>
            <path 
                id="edit"
                d="M16.34,15.32c0.03-0.1,0.06-0.2,0.08-0.3v-0.19v-0.21l0,0c0,0,0,0.1,0,0.21l0.12,0.49l0.75,2.27h-1.71  L16.34,15.32z M10.84,17.67c0.01-0.18-0.13-0.32-0.31-0.33c-0.01,0-0.01,0-0.02,0h-2V3.01C8.52,2.83,8.38,2.68,8.2,2.68  c-0.01,0-0.01,0-0.02,0h-2C6.01,2.67,5.86,2.81,5.85,2.98c0,0.01,0,0.01,0,0.02v14.33h-2c-0.14-0.01-0.27,0.08-0.31,0.21  c-0.05,0.12-0.03,0.27,0.07,0.36l3.33,3.33c0.07,0.06,0.15,0.09,0.24,0.09c0.09,0,0.17-0.03,0.24-0.09l3.32-3.32  C10.81,17.85,10.84,17.76,10.84,17.67C10.84,17.67,10.84,17.67,10.84,17.67z M20.47,20.23h-0.72l-2.39-6.89h-1.7l-2.39,6.89h-0.73  v1.1h3v-1.1h-0.79l0.49-1.5h2.53l0.49,1.5h-0.78v1.1h3v-1.1l0,0H20.47z M19.54,8.23h-1.25v1.26h-2.9h-0.15l0,0l0.11-0.1l0.22-0.27  l3.85-5.51V2.68h-5.9v2.39h1.25v-1.2h2.42c0.1,0.01,0.21,0.01,0.31,0h0.15l0,0l-0.11,0.09c-0.08,0.09-0.16,0.18-0.22,0.28l-3.85,5.5  v0.94h6.08V8.25l0,0L19.54,8.23z"
                vectorEffect="non-scaling-stroke" />
        </g>
    </SvgIcon>
}
export default SortZASvgIcon