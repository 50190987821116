import { tgMakeStyles } from "../../../../../lib/styles/TgMakeStyles";
import { TYPOGRAPHY_STYLES } from "../../../../theme/typographies";

export const useStyles = tgMakeStyles(theme => {
    return {
        card: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            borderRadius: '1rem',
            ...TYPOGRAPHY_STYLES.body1
        },
        title: {
            ...TYPOGRAPHY_STYLES.subtitle1,
            '& .valign': {
                verticalAlign: 'middle'
            },
            '& .ok': {color: 'green'},
            '& .warning': {color: 'red'}
        },
        submitbtn: {
            borderRadius: '1.5625rem',
            minWidth: '10rem',
            minHeight: '2.25rem',
            ...TYPOGRAPHY_STYLES.h4
        },
        billsummary: {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > *': {
                flexBasis: '50%',
                flexGrow: 0,
                flexShrink: 0
            },
            '& > *.right': {
                textAlign: 'right'
            }
        }
    }
})

export default useStyles