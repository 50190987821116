import { Box, Card, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import { useTypographyStyles } from '../../../../theme/useTypography'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useSref } from '@uirouter/react'
import { PLANS } from '../../../utils'
import useOBClasses from '../onboarding.classes'
import useRClasses from './detailsReview.classes'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

export function MembershipPlanReview(props) {
    // console.log('MembershipPlanReview', props)
    const { $stateParams } = props

    // const code = $stateParams.code
    const selected_plan = $stateParams.selected_plan
    const business_name = $stateParams.business_name
    const business_address = $stateParams.business_address
    const primary_phone_number = $stateParams.primary_phone_number
    const primary_email = $stateParams.primary_email
    const business_website = $stateParams.business_website
    const google_profile_link = $stateParams.google_profile_link
    const admin_full_name = $stateParams.admin_full_name
    const admin_email = $stateParams.admin_email
    const billing_name = $stateParams.billing_name
    const billing_address = $stateParams.billing_address
    const vat_id = $stateParams.vat_id

    const previousSref = useSref('^')
    const typo = useTypographyStyles()
    const obClasses = useOBClasses()
    const rClasses = useRClasses()
    
    return (
        <Box
            className={`${obClasses.container}`}
        >
            <Box className={`${obClasses.stepHeading} noPadding`}>
                <IconButton 
                    id="invite-back-to-business-details-form"
                    {...previousSref} 
                    className="backBtn clickable"
                >
                    <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
                </IconButton>
                <div style={{flexGrow: 1}}></div>
            </Box>

            <Card
                elevation={4}
                className={`${obClasses.mS} ${rClasses.card}`}
            >
                <Typography
                    className={`${rClasses.title} ${obClasses.mbXS}`}
                >
                    YOUR MEMBERSHIP
                </Typography>

                <Typography>{PLANS[0].name}</Typography>
                {selected_plan > 0 && <Typography>{PLANS[selected_plan].name}</Typography>}

                <Typography
                    className={`${rClasses.title} ${obClasses.mtL} ${obClasses.mbXS}`}
                >
                    BILLING SUMMARY
                </Typography>
                <Box className={`${rClasses.billsummary}`}>
                    <Typography>Basic Account</Typography>
                    <Typography className="right">0€</Typography>
                    {selected_plan > 0 && (
                        <React.Fragment>
                            <Typography>Upgrade</Typography>
                            <Typography className="right">{`${PLANS[selected_plan].price}/month`}</Typography>
                        </React.Fragment>
                    )}
                    <Typography>Setup Fee</Typography>
                    <Typography className="right">0€</Typography>
                </Box>

                <Typography
                    className={`${rClasses.title} ${obClasses.mtL} ${obClasses.mbXS}`}
                >
                    BILLING DETAILS
                </Typography>
                <Typography>{billing_name}</Typography>
                <Typography>{billing_address}</Typography>
                {vat_id && <Typography>{vat_id}</Typography>}

                {!!$stateParams.selected_plan && (
                    <React.Fragment>
                        <Typography
                            color="primary"
                            align="center"
                            className={`${obClasses.mtL} ${typo.subtitle4}`}
                        >
                            An invoice will be sent after membership submission
                        </Typography>
                    </React.Fragment>
                )}
            </Card>

            <Card
                elevation={4}
                className={`${obClasses.mXL} ${rClasses.card}`}
            >
                <Typography
                    className={`${rClasses.title} ${obClasses.mbXS}`}
                >
                    YOUR BUSINESS IN THE CIRCLE
                </Typography>

                <Typography>{business_name}</Typography>
                <Typography>{business_address}</Typography>
                <Typography>{primary_email}</Typography>
                <Typography>{primary_phone_number}</Typography>
                <Typography>{business_website}</Typography>

                <Typography
                    className={`${obClasses.mL} ${rClasses.title}`}
                >
                    <span className={`valign`}>Google Business Profile</span> {
                        google_profile_link 
                            ? <CheckCircleIcon className={`valign ok`}/>
                            : <ErrorOutlineIcon className={`valign warning`}/>
                        }
                </Typography>

                <Typography
                    className={`${rClasses.title} ${obClasses.mtL} ${obClasses.mbXS}`}
                >
                    YOUR ADMIN ACCESS
                </Typography>
                <Typography>{admin_full_name}</Typography>
                <Typography>{admin_email}</Typography>
            </Card>
        </Box>
    )
}

export default MembershipPlanReview