import React from 'react'
import { Box, Button, Container, Typography } from "@material-ui/core";
import { useTypographyStyles } from '../../../../theme/useTypography';
import { COLORS } from '../../../../theme/colors';
import { useRouter } from '@uirouter/react';
import { tgMakeStyles } from '../../../../../lib/styles/TgMakeStyles';
import { TYPOGRAPHY_STYLES } from '../../../../theme/typographies';
import OpenModalLink from '../../../../dialogs/standard/components/OpenStandardDialog.Link';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = tgMakeStyles(theme => ({
    btn: {
        '& .MuiButton-label': {
            ...TYPOGRAPHY_STYLES.body1
        }
    }
}))

export function UpgradeMembershipPlanDialog(props){
    const typo = useTypographyStyles()
    const classes = useStyles()
    const router = useRouter()
    const openPlanUpgradeDialog = e => {
        router.stateService.go('root.business-console.settings', {upgrade: true})
    }
    return (
        <Container maxWidth="xs">
            <Typography className={`${typo.body2}`} align="center">
                Craft the first impression and increase visibility with guests when you're recommended by a concierge.
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
                <Button
                    id="business-profile-first-impression-open-plan-upgrade"
                    className={`${classes.btn} clickable`}
                    variant='contained'
                    color="primary"
                    onClick={openPlanUpgradeDialog}
                >
                    UPGRADE
                </Button>
            </Box>
        </Container>   
    )
}

export function UpgradeMembershipPlanDialogLink({
    trackingProps,
    active=true,
    ...forwardProps
}){
    const typo = useTypographyStyles()
    return active
    ? (
        <OpenModalLink
            BoxProps={{component: 'span'}}
            {...forwardProps}
            modalTitle={(
                <Typography className={`${typo.subtitle5}`} color="primary">
                    ADDING MORE VISIBILITY ON MAPS IS A <span style={{color: COLORS.get('blue').main}}>PREMIUM FEATURE</span>
                </Typography>
            )}
            modalComponent={UpgradeMembershipPlanDialog}
            modalComponentProps={trackingProps}>
                {forwardProps.children ? forwardProps.children : <HelpOutlineIcon />}
        </OpenModalLink>
    )
    : (
        <>{forwardProps.children ? forwardProps.children : <HelpOutlineIcon />}</>
    )
}